import React, { createContext, useContext, useEffect, useMemo } from "react"

import { JobRequisitionQuery } from "types/graphql"
import { useRequisitionDataForDiffValues } from "v2/react/components/headcountPlanning/hooks/useRequisitionDataForDiffValues"
import { useWatchKeysFromAllowedAttributesForPositionDiff } from "v2/react/components/headcountPlanning/hooks/useWatchKeysFromAllowedAttributesForPositionDiff"
import { PositionFieldValuesDiffProvider } from "v2/react/components/positions/positionFieldValuesDiff"
import { useGetJobRequisitionQuery } from "v2/redux/GraphqlApi/JobRequisitionsApi"
import {
  PositionFormCollections,
  useCurrentCompanyPositionFormDataQuery,
} from "v2/redux/GraphqlApi/PositionsApi"

type OverviewContext = {
  collections: Nullish<PositionFormCollections>
  fromHeadcountPlan: Nullish<FromHeadcountPlan>
  fromHeadcountPlanChangeProjection: Nullish<FromHeadcountPlanChangeProjection>
  fromHeadcountPlanJobRequisitionData: Nullish<FromHeadcountPlanJobRequisitionData>
  fromHeadcountPlanSourceParticipantDatasheet: Nullish<FromWithinHeadcountPlanParticipantDatasheet>
  isLoading: boolean
  jobRequisition: Nullish<JobRequisition>
}

type OverviewProviderProps = React.PropsWithChildren<{ jobRequisitionId: string }>

type JobRequisition = NonNullable<JobRequisitionQuery["jobRequisition"]>
type FromHeadcountPlan = JobRequisition["createdByHeadcountPlan"]
type FromHeadcountPlanChangeProjection = JobRequisition["createdByHeadcountPlanChangeProjection"]
type FromHeadcountPlanJobRequisitionData =
  JobRequisition["createdByHeadcountPlanJobRequisitionData"]
type FromWithinHeadcountPlanParticipantDatasheet =
  JobRequisition["createdWithinHeadcountPlanSourceParticipantDatasheet"]

const InternalOverviewContext = createContext<OverviewContext>({
  collections: null,
  fromHeadcountPlan: null,
  fromHeadcountPlanChangeProjection: null,
  fromHeadcountPlanJobRequisitionData: null,
  fromHeadcountPlanSourceParticipantDatasheet: null,
  isLoading: false,
  jobRequisition: null,
})

function useJobRequisitionOverview(): OverviewContext {
  return useContext(InternalOverviewContext)
}

const OverviewProvider = ({ children, jobRequisitionId }: OverviewProviderProps) => {
  const { data, isLoading } = useGetJobRequisitionQuery({ id: jobRequisitionId })
  const jobRequisition = data?.jobRequisition
  const fromHeadcountPlan = jobRequisition?.createdByHeadcountPlan
  const fromHeadcountPlanChangeProjection = jobRequisition?.createdByHeadcountPlanChangeProjection
  const fromHeadcountPlanJobRequisitionData =
    data?.jobRequisition?.createdByHeadcountPlanJobRequisitionData
  const fromHeadcountPlanSourceParticipantDatasheet =
    data?.jobRequisition?.createdWithinHeadcountPlanSourceParticipantDatasheet

  const skipCollections = { skip: !fromHeadcountPlanJobRequisitionData }
  const { data: collectionData } = useCurrentCompanyPositionFormDataQuery({}, skipCollections)
  const collections = collectionData?.positionFormCollections

  const contextData = useMemo(
    () => ({
      collections,
      fromHeadcountPlan,
      fromHeadcountPlanChangeProjection,
      fromHeadcountPlanJobRequisitionData,
      fromHeadcountPlanSourceParticipantDatasheet,
      isLoading,
      jobRequisition,
    }),
    [
      collections,
      fromHeadcountPlan,
      fromHeadcountPlanChangeProjection,
      fromHeadcountPlanJobRequisitionData,
      fromHeadcountPlanSourceParticipantDatasheet,
      isLoading,
      jobRequisition,
    ],
  )

  const hcpFieldValues = fromHeadcountPlanJobRequisitionData?.jsonFormValues?.data
  const formValues = jobRequisition?.jsonFormValues?.data
  const { currentFieldValues, initialFieldValues, updateCurrentFieldValues } =
    useRequisitionDataForDiffValues({
      initial: hcpFieldValues,
      initializeCurrentWith: formValues,
      projectionAttributes: fromHeadcountPlanChangeProjection?.positionAttributesWithEdits,
    })
  useEffect(() => {
    if (formValues) updateCurrentFieldValues(formValues)
  }, [formValues, updateCurrentFieldValues])

  const watchFieldNames = useWatchKeysFromAllowedAttributesForPositionDiff({
    allowedAttributes: fromHeadcountPlan?.allowedAttributes,
  })

  return (
    <InternalOverviewContext.Provider value={contextData}>
      <PositionFieldValuesDiffProvider
        basis={initialFieldValues}
        current={currentFieldValues}
        positionFormCollections={collections}
        watchFieldNames={watchFieldNames}
      >
        {children}
      </PositionFieldValuesDiffProvider>
    </InternalOverviewContext.Provider>
  )
}

export {
  OverviewProvider,
  OverviewContext,
  useJobRequisitionOverview,
  FromHeadcountPlanChangeProjection,
  FromWithinHeadcountPlanParticipantDatasheet,
}
