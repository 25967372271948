import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"

import { FieldType } from "../types"

interface Props {
  showStyle: boolean
  fieldType: FieldType
  errorMessage?: string
}

export function ErrorLayer({ showStyle, fieldType, errorMessage }: Props) {
  if (!errorMessage) return null

  return showStyle ? (
    <Tooltip placement="top" closeDelay={150}>
      <TooltipTrigger className="Cell--error">
        <div>
          {fieldType === FieldType.SelectDropdown && (
            <div className="GridBody-cell__caret">
              <FontAwesomeIcon className="text-status-critical" icon={["fas", "caret-down"]} />
            </div>
          )}
        </div>
      </TooltipTrigger>
      <TooltipContent className="react-tooltip-content react-tooltip-content-error">
        <FontAwesomeIcon icon={["far", "exclamation-circle"]} />
        {errorMessage}
      </TooltipContent>
    </Tooltip>
  ) : null
}
