import axios from "axios"

interface GqlResponseBody<TData, TErrors = unknown[]> {
  data: TData
  errors?: TErrors
}

const baseGqlFetch = async (params: {}) =>
  axios.post("/graphql", params, {
    headers: { ...buildHeaders(), "content-type": "application/json" },
    withCredentials: true,
  })

const adminGqlFetch = async (params: {}) =>
  axios.post("/admin/graphql", params, {
    headers: { ...buildHeaders(), "content-type": "application/json" },
    withCredentials: true,
  })

/** @public */
const gqlFetch = async <TData, TErrors = unknown[]>(
  params = {},
): Promise<GqlResponseBody<TData, TErrors>> =>
  baseGqlFetch(params).then((res) => res.data as GqlResponseBody<TData, TErrors>)

/** @private */
function buildHeaders() {
  const headers: { "x-csrf-token"?: string } = {}
  const selector = "[name='csrf-token']"
  const maybeCsrfToken = document.querySelector<HTMLMetaElement>(selector)

  if (maybeCsrfToken && maybeCsrfToken.content) {
    headers["x-csrf-token"] = maybeCsrfToken.content
  }

  return headers
}

export { adminGqlFetch, baseGqlFetch, gqlFetch, GqlResponseBody }
