import { ControlProps } from "@jsonforms/core"
import { useJsonForms } from "@jsonforms/react"
import React from "react"

import { Option } from "types/graphql"
import { SingleDiffEntry } from "v2/react/components/positions/positionFieldValuesDiff"
import { isVariablePayFieldName } from "v2/react/components/positions/positionFieldValuesDiff/utils/entryHelpers"

import { JsonCurrencyInput } from "./CurrencyControl"
import { JsonPercentInput } from "./PercentControl"
import { JsonSelectInput } from "./SelectControl"
import { compensationLabelPrefix } from "./utils/compensation"

type VariablePayTypeData = { amount: number | null; id: string; name: string; type: string }
type ControlPropsSubset = Pick<
  ControlProps,
  "config" | "data" | "handleChange" | "path" | "schema" | "uischema" | "errors"
>

const JsonVariablePayInput = ({
  config,
  data,
  handleChange,
  path,
  schema,
  uischema,
  errors,
}: ControlPropsSubset) => {
  const context = useJsonForms()
  const formData = context?.core?.data

  if (!data || !data.length) return null

  const handleTypeChangeEvent = (path: string, value: Option, payType: VariablePayTypeData) => {
    const item: VariablePayTypeData = data.find((pt: VariablePayTypeData) => pt.id === payType.id)
    if (!item) return

    const updatedItem: VariablePayTypeData = { ...item, type: value.id }
    // When the type is changed, reset the value to zero.
    if (item.type !== value.id) {
      updatedItem.amount = 0
    }

    handleChange(path, updatedItems(data, updatedItem))
  }

  const handleAmountChangeEvent = (
    path: string,
    value: number | null,
    payType: VariablePayTypeData,
  ) => {
    const item: VariablePayTypeData = data.find((pt: VariablePayTypeData) => pt.id === payType.id)
    if (!item) return

    const updatedItem: VariablePayTypeData = { ...item, amount: value }

    handleChange("position.variablePayTypes", updatedItems(data, updatedItem))
  }

  const updatedItems = (
    data: VariablePayTypeData[],
    updatedItem: VariablePayTypeData,
  ): VariablePayTypeData[] =>
    data.map((pt: VariablePayTypeData) => {
      if (pt.id === updatedItem.id) return updatedItem
      return pt
    })

  return (
    <>
      <div className="mb-2 font-bold">{compensationLabelPrefix(formData)} Variable Pay Types</div>
      {data.map((payType: VariablePayTypeData, index: number) => (
        <div className="items-top flex" key={payType.id}>
          <div className="basis-1/3 truncate pt-3">
            {payType.name}
            {isVariablePayFieldName(payType.id) && <SingleDiffEntry fieldName={payType.id} />}
          </div>
          <div className="basis-1/3 pl-2">
            <JsonSelectInput
              errors={errors}
              config={config}
              data={{ id: payType.type, label: payType.name }}
              enabled={formData.reqType !== "backfill"}
              handleChange={(path, value) => handleTypeChangeEvent(path, value, payType)}
              id={`variable-pay-type-type-${payType.id}`}
              label=""
              path={path}
              schema={schema}
              uischema={uischema}
            />
          </div>
          <div className="basis-1/3 pl-2">
            {payType.type === "variable_pay_pay_type_amount" && (
              <JsonCurrencyInput
                errors={errors}
                config={config}
                data={payType.amount}
                enabled={formData.reqType !== "backfill"}
                handleChange={(path: string, value: number | null) =>
                  handleAmountChangeEvent(path, value, payType)
                }
                id={`variable-pay-type-amount-${payType.id}`}
                label=""
                path={`position.variablePayTypes.${index}.amount`}
                schema={schema}
              />
            )}
            {payType.type === "variable_pay_pay_type_percent" && (
              <JsonPercentInput
                config={config}
                errors={errors}
                data={payType.amount}
                enabled={formData.reqType !== "backfill"}
                handleChange={(path: string, value: number | null) =>
                  handleAmountChangeEvent(path, value, payType)
                }
                id={`variable-pay-type-amount-${payType.id}`}
                label=""
                path={`position.variablePayTypes.${index}.amount`}
                schema={schema}
              />
            )}
          </div>
        </div>
      ))}
    </>
  )
}

export { JsonVariablePayInput }
