import React from "react"
import { useTranslation } from "react-i18next"

interface ProposalCellProps {
  completed: boolean
  proposal?: string | null
  remainingBudget?: string | null
  showProposal: boolean
}

const ProposalCell = ({
  completed,
  proposal,
  remainingBudget,
  showProposal,
}: ProposalCellProps) => {
  const { t } = useTranslation()
  if (!showProposal) return null

  return (
    <td className="hcp-overview-table__budgeting-cell">
      <div className="h-14 flex-col items-end justify-center px-4 flex">
        {completed && (
          <>
            <div>{proposal}</div>
            {/* We only show the remaining budget if it's negative. */}
            {remainingBudget?.startsWith("-") && (
              <div className="badge--red badge--sm">
                {t("v2.headcount_plan.overview.over_budget", { amount: remainingBudget.slice(1) })}
              </div>
            )}
          </>
        )}
      </div>
    </td>
  )
}

export { ProposalCell }
