import { EntityId } from "@reduxjs/toolkit"
import fp from "lodash/fp"
import { createSelector, createStructuredSelector } from "reselect"

import { Direction } from "v2/react/utils/enums"
import { Field } from "v2/redux/slices/ContainerSlice"
import { selectFields } from "v2/redux/slices/ContainerSlice/containerSelectors"
import {
  inEitherWriteState,
  onNothing,
  writingOnEditableCellWithInitial,
} from "v2/redux/slices/GridSlice/cursor/cursorStates"
import type { CursorIndices } from "v2/redux/slices/GridSlice/cursor/types"
import { selectDatasheetRows } from "v2/redux/slices/GridSlice/gridSelectors/selectDatasheetRows"
import { RowType } from "v2/redux/slices/GridSlice/types"
import { nodeSelectors } from "v2/redux/slices/NodeSlice/NodeApi"
import type { FieldKey } from "v2/redux/slices/NodeSlice/types"
import type { RootState } from "v2/redux/store"

import { getFieldType } from "../gridHelpers/getFieldType"

const withId = (_: RootState, id: EntityId) => id
const withDirection = (_: RootState, direction: Direction) => direction
const withFieldKey = (_: RootState, _2: EntityId, fieldKey: FieldKey) => fieldKey

const isAvatarField = fp.propEq("fieldKey", "avatar")

const selectFieldsSansAvatar = (state: RootState) => fp.reject(isAvatarField, selectFields(state))

export const selectFieldsAsColumns = (hasCollectionMap: Record<string, boolean>) =>
  createSelector(
    selectFieldsSansAvatar,
    nodeSelectors.selectEntities,
    (state: RootState) => state.visualization.editMode,
    (state: RootState) => state.session.featureFlags,
    (fields: Field[], nodesById, editMode, featureFlags) =>
      fields.map((field) => {
        const original = {
          ...field,
          id: field.fieldKey,
          accessorFn: ({ id }: { id: string }) => nodesById[id]?.[field.fieldKey],
          editableFn: ({ id }: { id: string }) => {
            const node = nodesById[id]
            return node?.editable_fields
              ? editMode && node.editable_fields.includes(field.fieldKey)
              : false
          },
          width: "medium" as const,
          fieldType: getFieldType({
            fieldKey: field.fieldKey,
            hasCollection: hasCollectionMap[field.fieldKey],
            // Since `selectFieldsAsColumns` is only used for compatibility
            // with the hcp datasheet cells, we can assume that
            // isEditable is true here. We just want the fieldType of
            // the column here, regardless of whether it's editable for
            // particular rows.
            isEditable: true,
            featureFlags,
          }),
        }

        return { ...original, meta: { original } }
      }),
  )

export const selectTraversableRows = createSelector(selectDatasheetRows, (rows) =>
  rows.filter((row) => row.rowType === RowType.Node),
)

export const selectCursor = (state: RootState) => state.grid.cursor

export const makeSelectCursorStateForCell = (rowId: EntityId, fieldKey: FieldKey) =>
  createStructuredSelector({
    cursorIfInWrite: (state: RootState) => selectCursorIfWritingAndOnCell(state, rowId, fieldKey),
    inCursor: (state: RootState) => selectCellFocused(state, rowId, fieldKey),
    initialValue: (state: RootState) => selectInitialOrRestorableValue(state, rowId, fieldKey),
    inWrite: (state: RootState) => !!selectCursorIfWritingAndOnCell(state, rowId, fieldKey),
  })

export const selectCursorRowId = createSelector(selectCursor, (cursor) =>
  onNothing(cursor) ? undefined : cursor.rowId,
)

export const selectCursorColId = createSelector(selectCursor, (cursor) =>
  onNothing(cursor) ? undefined : cursor.fieldKey,
)

export const selectCursorIndices: (s: RootState) => CursorIndices = createSelector(
  selectCursorRowId,
  selectCursorColId,
  selectDatasheetRows,
  selectFields,
  (rowId, colId, rows, fields) => {
    if (fp.isUndefined(rowId) || fp.isUndefined(colId)) return [undefined, undefined]
    const rowIndex = fp.findIndex(fp.propEq("id", rowId), rows)
    let colIndex = fp.findIndex(fp.propEq("fieldKey", colId), fields)

    const mergeAvatarAndName =
      fields.find((f) => f.fieldKey === "avatar") && fields.find((f) => f.fieldKey === "name")

    colIndex -= mergeAvatarAndName ? 1 : 0
    return [rowIndex, colIndex]
  },
)

const selectCursorPositionForChange = createSelector(
  selectCursor,
  (state: RootState) =>
    fp.filter(fp.matches({ rowType: RowType.Node }), selectDatasheetRows(state)),
  selectFieldsSansAvatar,
  (cursor, rows, fieldsSansAvatar) => {
    const rowId = onNothing(cursor) ? undefined : cursor.rowId
    const columnId = onNothing(cursor) ? undefined : cursor.fieldKey
    const rowIndex = fp.findIndex(fp.propEq("id", rowId), rows)
    const columnIndex = fp.findIndex(fp.propEq("fieldKey", columnId), fieldsSansAvatar)
    const lastColumnIndex = fieldsSansAvatar.length - 1

    return {
      columnIndex,
      inFirstColumn: columnIndex === 0,
      inLastColumn: columnIndex === lastColumnIndex,
      lastColumnIndex,
      rowIndex,
    }
  },
)

export const selectAdjacentCell = (
  state: RootState,
  direction: Direction,
): [EntityId, FieldKey] => {
  const current = selectCursor(state)
  if (onNothing(current)) throw new Error("Unexpected call; cursor must be on something")
  const rows = fp.filter(fp.matches({ rowType: RowType.Node }), selectDatasheetRows(state))
  const fieldsSansAvatar = selectFieldsSansAvatar(state)
  const [nextRowIndex, nextColumnIndex] = selectAdjacentCellIndices(state, direction)

  const lastColumnIndex = fieldsSansAvatar.length - 1
  const lastRowIndex = rows.length - 1

  // Edge case: if the next row would put the cursor out of bounds, return the
  // current id/field key as the cursor should remain in place. This is unlike
  // the column, which will cause the cursor to "wrap" around to the last
  // column of the prior row or the first column of the next row.
  if (nextRowIndex < 0 || nextRowIndex > lastRowIndex) return [current.rowId, current.fieldKey]

  const rowIndex = fp.clamp(0, lastRowIndex, nextRowIndex)
  const columnIndex = fp.clamp(0, lastColumnIndex, nextColumnIndex)

  return [rows[rowIndex].id, fieldsSansAvatar[columnIndex]?.fieldKey]
}

export const selectAdjacentCellIndices = createSelector(
  [selectCursorPositionForChange, withDirection],
  ({ columnIndex, inFirstColumn, inLastColumn, lastColumnIndex, rowIndex }, direction) => {
    const nextCell = {
      isLastCellOfPreviousRow: direction === Direction.Left && inFirstColumn,
      isCellOnLeft: direction === Direction.Left && !inFirstColumn,
      isFirstCellOfNextRow: direction === Direction.Right && inLastColumn,
      isCellOnRight: direction === Direction.Right && !inLastColumn,
      isCellBelow: direction === Direction.Down,
      isCellAbove: direction === Direction.Up,
    }

    if (nextCell.isLastCellOfPreviousRow) return [rowIndex - 1, lastColumnIndex]
    if (nextCell.isCellOnLeft) return [rowIndex, columnIndex - 1]
    if (nextCell.isFirstCellOfNextRow) return [rowIndex + 1, 0]
    if (nextCell.isCellOnRight) return [rowIndex, columnIndex + 1]
    if (nextCell.isCellAbove) return [rowIndex - 1, columnIndex]
    if (nextCell.isCellBelow) return [rowIndex + 1, columnIndex]

    return [rowIndex, columnIndex]
  },
)

const selectCursorIfWritingAndOnCell = createSelector(
  [selectCursor, withId, withFieldKey],
  (cursor, id, fieldKey) =>
    inEitherWriteState(cursor) && cursor.rowId === id && cursor.fieldKey === fieldKey
      ? cursor
      : undefined,
)

const selectInitialOrRestorableValue = createSelector(
  [selectCursorIfWritingAndOnCell, (state: RootState) => state.grid.restorableCursorValue],
  (cursor, restorableValue) => {
    if (fp.isUndefined(cursor) || !fp.isUndefined(restorableValue)) return restorableValue
    return writingOnEditableCellWithInitial(cursor) ? cursor.initial : undefined
  },
)

const selectCellFocused = (state: RootState, rowId: EntityId, fieldKey: FieldKey) => {
  const cursor = selectCursor(state)
  return !onNothing(cursor) && fp.matches({ rowId, fieldKey }, cursor)
}
