import { Boundary } from "@floating-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import React, { useRef } from "react"
import { defaultMemoize } from "reselect"

import { NodeInterface } from "types/graphql"
import { DisplayValueCell } from "v2/react/components/headcountPlanning/TableDatasheet/DisplayValueCell"
import { StandardEditableCell } from "v2/react/components/headcountPlanning/TableDatasheet/StandardEditableCell"
import { CollectionAutocomplete } from "v2/react/components/orgChart/Datasheet/Cell/Autocomplete/CollectionAutocomplete"
import { OrgUnitAutocomplete } from "v2/react/components/orgChart/Datasheet/Cell/Autocomplete/OrgUnitAutocomplete"
import { PersonComplete } from "v2/react/components/orgChart/Datasheet/Cell/Autocomplete/PersonComplete"
import { PositionAutocomplete } from "v2/react/components/orgChart/Datasheet/Cell/Autocomplete/PositionAutocomplete"
import { PositionTypeAutocomplete } from "v2/react/components/orgChart/Datasheet/Cell/Autocomplete/PositionTypeAutocomplete"
import { AvatarCell } from "v2/react/components/orgChart/Datasheet/Cell/AvatarCell"
import { GroupCell } from "v2/react/components/orgChart/Datasheet/Cell/GroupCell"
import {
  CellStyleType,
  StyleLayers,
} from "v2/react/components/orgChart/Datasheet/Cell/StyleLayers/StyleLayers"
import {
  ChangeMeta,
  Column,
  CursorConnection,
  FieldType,
  GroupRow,
  NodeRow,
  RowType,
  StatsRow,
} from "v2/react/components/orgChart/Datasheet/types"
import { formattedNodeProp } from "v2/redux/slices/NodeSlice/nodeHelpers/nodeProps"
import { useAppSelector } from "v2/redux/store"

import { DropdownCell } from "../../headcountPlanning/TableDatasheet/DropdownCell"
import { StatsCell } from "./Cell/StatsCell"
import { fallbackCursorConnection } from "./utils/constants"

interface CellProps<TNode, CType = TNode> {
  column: Column<CType>
  changeInfo?: ChangeMeta
  currentValue?: string
  cursorConnection?: CursorConnection
  errorMessage?: string
  fieldTransition?: { type: "saved"; duration: number }
  footerHeight: number
  isEditable?: boolean
  isFirst: boolean
  isFirstRow: boolean
  isInEditMode?: boolean
  isLast: boolean
  isLastRow: boolean
  isSelected?: boolean
  onClick: (row: NodeRow<TNode>, column: Column<CType>) => void
  onExpandCollapseGrouping: (row: GroupRow) => void
  row: GroupRow | NodeRow<TNode> | StatsRow
  boundary?: Boundary
  style: React.CSSProperties
  fieldType?: FieldType
  focusCell?: boolean
}

function Cell<TNode, CType = TNode>({
  boundary,
  column,
  changeInfo,
  currentValue,
  cursorConnection = fallbackCursorConnection,
  row,
  onClick,
  errorMessage,
  fieldTransition,
  focusCell = false,
  footerHeight,
  isEditable = false,
  isInEditMode = false,
  isSelected = false,
  fieldType = FieldType.NonEditable,
  ...props
}: CellProps<TNode, CType>) {
  const avatar = checkForAvatar(row, column)
  const cellRef = useRef<HTMLDivElement>(null)
  let cellStyleLayer: CellStyleType = focusCell ? "enter" : "default"
  if (fieldTransition?.type === "saved") cellStyleLayer = "save"
  const cellStyle = {
    ...props.style,
    borderTop:
      Number(props.style.top) === 0 && !props.isFirstRow
        ? "1px solid rgba(012, 020, 075, 0.08)"
        : "none",
  }
  const mergeAvatarAndName = useAppSelector((state) => state.grid.mergeAvatarAndName)
  const collection = column.collection
  const selected = row.rowType === RowType.Node ? isSelected : false
  const selectedNone = !selected
  const selectedEditable = selected && isEditable
  const selectedStatic = selected && !isEditable

  const handleClick = () => onClick(row as NodeRow<TNode>, column)

  if (row.rowType === RowType.Group) {
    return (
      <GroupCell
        row={row}
        isFirst={props.isFirst}
        onExpandCollapseGrouping={props.onExpandCollapseGrouping}
        style={cellStyle}
      />
    )
  }

  if (row.rowType === RowType.Stats) {
    return (
      <StatsCell
        row={row}
        className={classNames({
          "Cell__content Cell__content--changed": changeInfo,
          "last-row": props.isLastRow,
        })}
        isFirst={props.isFirst}
        isLast={props.isLast}
        style={cellStyle}
        fieldKey={column.fieldKey as string}
        height={footerHeight}
        format={column.format}
      />
    )
  }

  if (row.rowType === RowType.Node && column.fieldKey === "avatar" && !mergeAvatarAndName) {
    return (
      <AvatarCell
        row={row}
        style={cellStyle}
        isFirst={props.isFirst}
        isLast={props.isLast}
        column={column}
      />
    )
  }

  const preventMouseDownDefault = (event: React.MouseEvent<HTMLInputElement>) => {
    if (isInEditMode) event.preventDefault()
  }

  const isDropdownField = selectedEditable && fieldType === FieldType.SelectDropdown && collection

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={classNames({ "last-row": props.isLastRow, "first-row": props.isFirstRow })}
      id={`cell-${row.id}-${String(column.fieldKey)}`}
      role="button"
      tabIndex={-1}
      ref={cellRef}
      onClick={handleClick}
      onMouseDown={preventMouseDownDefault}
    >
      {selectedNone && (
        <DisplayValueCell
          style={getCellStyle(cellStyle, props.isFirst, row.color)}
          className={classNames(nodeClassName(props.isLast), {
            "nested-avatar items-center gap-3 flex": avatar,
            "Cell__content Cell__content--changed": changeInfo,
          })}
        >
          {mergeAvatarAndName && avatar}
          {currentValue}
        </DisplayValueCell>
      )}
      {selectedEditable && fieldType === FieldType.Standard && (
        <StandardEditableCell
          classNames={classNames("GridBody-cell", "EditableTextCell", {
            last: props.isLast,
            "--color-coded": props.isFirst && row.color,
            "Cell__editable--changed": changeInfo,
          })}
          currentValue={currentValue ?? ""}
          saveFn={cursorConnection.saveFn}
          columnId={String(column.fieldKey)}
          rowId={row.id}
          editable
          style={{ ...getCellStyle(cellStyle, props.isFirst, row.color), padding: "0" }}
        />
      )}
      {selectedEditable && fieldType === FieldType.PersonAutocomplete && (
        <PersonComplete
          cursorConnection={cursorConnection}
          row={row}
          isFirst={props.isFirst}
          isLast={props.isLast}
          style={cellStyle}
          html={currentValue || null}
          focusCell={focusCell}
          noBorder
        />
      )}
      {selectedEditable && fieldType === FieldType.OrgUnitAutocomplete && (
        <OrgUnitAutocomplete<TNode, CType>
          cursorConnection={cursorConnection}
          row={row}
          column={column}
          isFirst={props.isFirst}
          isLast={props.isLast}
          style={cellStyle}
          currentValue={currentValue}
        />
      )}
      {selectedEditable && fieldType === FieldType.PositionAutocomplete && (
        <PositionAutocomplete<TNode, CType>
          cursorConnection={cursorConnection}
          row={row}
          column={column}
          isFirst={props.isFirst}
          isLast={props.isLast}
          style={cellStyle}
          html={currentValue || null}
          noBorder
        />
      )}
      {selectedEditable && fieldType === FieldType.PositionTypeAutocomplete && (
        <PositionTypeAutocomplete
          cursorConnection={cursorConnection}
          row={row}
          column={column}
          isFirst={props.isFirst}
          isLast={props.isLast}
          style={cellStyle}
          currentValue={currentValue}
        />
      )}
      {selectedEditable && fieldType === FieldType.SuggestedAutocomplete && (
        <CollectionAutocomplete<TNode, CType>
          cursorConnection={cursorConnection}
          row={row}
          column={column}
          isFirst={props.isFirst}
          isLast={props.isLast}
          style={cellStyle}
          html={currentValue || null}
          focusCell={focusCell}
          allowCustomInput
          noBorder
        />
      )}
      {selectedEditable && fieldType === FieldType.ForcedAutocomplete && (
        <CollectionAutocomplete<TNode, CType>
          cursorConnection={cursorConnection}
          row={row}
          column={column}
          isFirst={props.isFirst}
          isLast={props.isLast}
          style={cellStyle}
          html={currentValue || null}
          focusCell={focusCell}
          allowCustomInput={false}
          noBorder
        />
      )}
      {isDropdownField && (
        <DropdownCell
          rowId={row.id}
          columnId={String(column.fieldKey)}
          includeBlankOption={["employee_type", "flsa_classification"].includes(
            String(column.fieldKey),
          )}
          options={collection.options.nodes}
          saveFn={cursorConnection.saveFn}
          currentValue={
            collection.options.nodes.find((node) => node.label === currentValue)?.id || ""
          }
          editable
          className="GridBody-cell Cell__select-field bg-transparent"
          style={{ ...getCellStyle(cellStyle, props.isFirst, row.color), padding: "0" }}
        />
      )}
      {selectedStatic && fieldType === FieldType.NonEditable && (
        <DisplayValueCell
          style={getCellStyle(cellStyle, props.isFirst, row.color)}
          className={classNames(nodeClassName(props.isLast), {
            "nested-avatar items-center gap-3 flex": avatar,
          })}
        >
          {mergeAvatarAndName && avatar}
          {currentValue}
        </DisplayValueCell>
      )}
      {isDropdownField ? null : (
        <StyleLayers
          boundary={boundary}
          cellStyleLayer={cellStyleLayer}
          changeInfo={changeInfo}
          currentValue={currentValue}
          errorMessage={errorMessage}
          isLast={props.isLast}
          styles={props.style}
        />
      )}
    </div>
  )
}

const nodeClassName = (isLast: boolean) =>
  classNames("GridBody-cell !place-content-start", { last: isLast })

const getCellStyle = defaultMemoize((style, isFirst, color?: string | null) => {
  if (color && isFirst) return { ...(style || {}), borderLeft: `5px solid ${color}` }
  if (isFirst) return { ...(style || {}), borderLeft: "none" }

  return style || {}
})

function checkForAvatar<TNode>(
  row: NodeRow | StatsRow | GroupRow,
  column: Column<TNode>,
): React.ReactNode {
  if (row.rowType === RowType.Group || row.rowType === RowType.Stats) return null

  const data = row.data as NodeInterface

  if (column.fieldKey !== "name" || !("avatar" in data)) return null
  const imageSrc = formattedNodeProp(
    column.fieldKey as keyof NodeInterface,
    row.data as NodeInterface,
  )

  return imageSrc ? (
    <img
      role="presentation"
      className="circle circle-sm m-0"
      alt="user avatar"
      src={formattedNodeProp("avatar", row.data as NodeInterface)}
    />
  ) : (
    <div className="circle circle-sm items-center justify-center  bg-primary-8-solid text-primary-100">
      <FontAwesomeIcon icon={["far", "chair-office"]} className="icon-14" />
    </div>
  )
}

const MemoCell = React.memo(Cell)

export { Cell, CellProps, MemoCell }
