const participantFragmentAtDepth = (depth: number): string => {
  if (depth === 0) {
    return `{
        ...participantAttributesNoChildren
      }`
  }
  return `{
      ...participantAttributesNoChildren
      children
        ${participantFragmentAtDepth(depth - 1)}
    }
    `
}

/**
 * This query is used to fetch the headcount plan with the tree of participants
 * to a certain depth.  One way we determine the maximum depth is through
 * `HeadcountPlanning::MaxPlanDepthQuery`.
 *
 * Unfortunately building the recursive query in this way means we don't get the
 * benefits (type- and otherwise) of using an operation.  A potential refactor
 * in the future could be to return a flat list of participants and then build
 * the tree in the client.
 * @see: https://stackoverflow.com/a/52220475
 */
const TreeToDepthQuery = (maxChartDepth: number) => `
  fragment personAttributes on Person {
      avatarThumbUrl
      id
      name
      workEmail
    }
    fragment participantAttributesNoChildren on HeadcountPlanParticipant {
      id
      uniqueKey
      role
      formattedBudget: budget(format: truncate)
      budget
      formattedProposedBudget: proposedBudget(format: truncate)
      proposedBudget
      remainingBudget
      position {
        id
        title
        orgUnits {
          id
          name
          orgUnitType {
            id
            name
          }
        }
      }
      status
      person {
        ...personAttributes
      }
      people {
        ...personAttributes
      }
    }
    fragment participantAttributes on HeadcountPlanParticipant
      ${participantFragmentAtDepth(maxChartDepth)}
    query HeadcountPlanWithTree($id: ID!, $rootPositionId: ID, $participantIds: [ID!]) {
      headcountPlan(id: $id) {
        activeStatus
        endDate
        id
        uniqueKey
        currentUserCanManage
        lockedAt
        name
        startDate
        canBeFinalized
        setBudget
        formattedBudget: budget(format: truncate)
        budget
        formattedProposedBudget: proposedBudget(format: truncate)
        proposedBudget
        remainingBudget
        planAggregations {
          newPositionsCount
        }
        company {
          id
          logoThumbUrl
          name
        }
        participants {
          id
          status
        }
        allPositionsInPlan {
          status
          type
        }
        participantsTree(rootPositionId: $rootPositionId, participantIds: $participantIds) {
          ...participantAttributes
        }
      }
    }
  `

export { TreeToDepthQuery }
