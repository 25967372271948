import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { ChangeEvent, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { Error, SuccessionPanelSettings } from "types/graphql"
import { CustomFieldModel } from "types/graphql.enums"
import { DraggableList } from "v2/react/shared/collection/DraggableList/DraggableList"
import { InputLabel } from "v2/react/shared/forms/InputLabel"
import { RadioGroup } from "v2/react/shared/forms/RadioGroup"
import { Modal, ModalFooter } from "v2/react/shared/overlay/Modal"
import { useGetCustomFieldsQuery } from "v2/redux/GraphqlApi"
import { useUpdatePersonSuccessionPanelSettingsMutation } from "v2/redux/GraphqlApi/SuccessionApi"

interface CompareSettingsModalProps {
  handleClose: () => void
  isOpen: boolean
  settings: SuccessionPanelSettings
}

function CompareSettingsModal({ handleClose, isOpen, settings }: CompareSettingsModalProps) {
  const { t } = useTranslation()
  const dragContainerRef = useRef<HTMLDivElement>(null)
  const { data } = useGetCustomFieldsQuery({ modelType: CustomFieldModel.Person })
  const customFields = data?.currentCompany?.customFields || []
  const [showFilledBy, setShowFilledBy] = useState(settings.compareShowFilledBy)
  const [selectedOptions, setSelectedOptions] = useState(settings.compareSelectedRows)
  const [compareRowsOrder, setCompareRowsOrder] = useState(settings.compareRowsOrder)
  const [errors, setErrors] = useState<Error[]>([])
  const generalError = [
    {
      message: t("v2.succession_plan_panel.compare.could_not_be_updated"),
      path: ["general"],
    },
  ]
  const [updateCompareSettings, { isLoading }] = useUpdatePersonSuccessionPanelSettingsMutation()

  const onReorder = (updatedOrder: string[]) => {
    setCompareRowsOrder(updatedOrder)
    const updatedSelectedOptions = updatedOrder.filter((option) => selectedOptions.includes(option))
    setSelectedOptions(updatedSelectedOptions)
  }

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const checkedOptions = compareRowsOrder.filter(
        (option) => selectedOptions.includes(option) || option === event.target.value,
      )
      setSelectedOptions(checkedOptions)
    } else {
      const filtered = selectedOptions.filter((option) => option !== event.target.value)
      setSelectedOptions(filtered)
    }
  }

  const handleShowFilledBy = (event: ChangeEvent<HTMLInputElement>) => {
    setShowFilledBy(event.target.value === "1")
  }

  const handleSave = async () => {
    try {
      const { updatePersonSuccessionPanelSettings: response } = await updateCompareSettings({
        compareRowsOrder,
        compareSelectedRows: selectedOptions,
        compareShowFilledBy: showFilledBy,
      }).unwrap()

      if (response.errors && response.errors.length > 0) {
        setErrors(response.errors)
      } else {
        handleClose()
      }
    } catch (error) {
      setErrors(generalError)
    }
  }

  if (compareRowsOrder.includes("custom_field") && !customFields) return null

  return (
    <Modal
      footer={
        <ModalFooter
          onClose={handleClose}
          onSave={handleSave}
          saveButtonText={t("v2.defaults.save")}
          disabled={isLoading}
        />
      }
      isOpen={isOpen}
      onClose={handleClose}
      size="md"
      title={t("v2.succession_plan_panel.modals.compare_settings.configure")}
    >
      <div className="p-6">
        <p className="mb-2">
          {t("v2.succession_plan_panel.modals.compare_settings.select_rows_to_show")}
        </p>

        <div>
          <DraggableList
            dragContainerRef={dragContainerRef}
            items={compareRowsOrder}
            handleReorder={onReorder}
          >
            {compareRowsOrder.map((item) => (
              <DraggableList.Item key={item} item={item} dragContainerRef={dragContainerRef}>
                <div className="w-full gap-3 flex">
                  <FontAwesomeIcon
                    icon={["fas", "grip-vertical"]}
                    size="sm"
                    className="mt-1 items-start self-stretch"
                  />
                  <label
                    htmlFor={item}
                    className="!m-0 w-full min-w-0 items-start self-stretch font-medium transition-colors flex"
                  >
                    <input
                      type="checkbox"
                      className="!mr-2 mt-1"
                      id={item}
                      name={
                        item.includes("custom_field")
                          ? customFields.find((field) => field.id === item.split("_")[2])?.name ||
                            ""
                          : item
                      }
                      data-show-field={item}
                      onChange={handleCheckboxChange}
                      checked={selectedOptions.includes(item)}
                      value={item}
                    />
                    <div className="w-full min-w-0 break-words">
                      {item.includes("custom_field")
                        ? customFields.find((field) => field.id === item.split("_")[2])?.name || ""
                        : t(`v2.succession_plan_panel.compare.${item}`)}
                    </div>
                  </label>
                </div>
              </DraggableList.Item>
            ))}
          </DraggableList>
        </div>

        <div className="mt-6">
          <InputLabel
            className="mb-2"
            label={t("v2.succession_plan_panel.compare.include_filled_by")}
          />
          <RadioGroup
            groupName="compare_show_filled_by"
            defaultChecked={settings.compareShowFilledBy ? "1" : "0"}
            options={[
              { id: "0", value: "0", label: t("v2.defaults.no") },
              { id: "1", value: "1", label: t("v2.defaults.yes") },
            ]}
            onChange={handleShowFilledBy}
          />
        </div>

        {errors.length > 0 && (
          <div className="form-error mt-6">
            {errors.map((error) => (
              <div key={error.message} className="form-error-message">
                {error.message}
              </div>
            ))}
          </div>
        )}
      </div>
    </Modal>
  )
}

export { CompareSettingsModal }
