import classNames from "classnames"
import React from "react"

import { Cell } from "./hooks/useCellState"
import { StyleLayers } from "./StyleLayers"
import { FieldType } from "./types"

type Props = {
  id?: string
  className?: string
  style?: React.CSSProperties
  children?: React.ReactNode
  cell?: Cell
  errorMessage?: string
}
// eslint-disable-next-line prefer-arrow-callback
export const DisplayValueCell = React.memo(function DisplayValueCell({
  id,
  className,
  style,
  children,
  cell,
  errorMessage,
}: Props) {
  return (
    <div
      id={id}
      className={classNames("relative place-content-center px-4 grid", className)}
      style={style}
    >
      {children}
      {cell && errorMessage && (
        <StyleLayers cell={cell} errorMessage={errorMessage} fieldType={FieldType.NonEditable} />
      )}
    </div>
  )
})
