import dayjs from "dayjs"
import React from "react"
import { useTranslation } from "react-i18next"
import { useWindowSize } from "usehooks-ts"

import { FeatureFlags, HeadcountPlanActiveStatus } from "types/graphql.d"
import { NavActiveIcon } from "v2/react/components/headcountPlanning/shared/NavActiveIcon"
import { getNavLinks } from "v2/react/components/headcountPlanning/shared/navigation"
import { PlanTypeIndicator } from "v2/react/components/headcountPlanning/ShowParticipant/PlanTypeIndicator"
import { DisplayState } from "v2/react/components/headcountPlanning/types"
import { ExportButton } from "v2/react/shared/buttons/ExportButton"
import { Spinner } from "v2/react/shared/loaders/Spinner"
import PageNav from "v2/react/shared/navigation/PageNav"
import { ActionBlock } from "v2/react/shared/navigation/PageNav/ActionBlock"
import { LinkBlockSmall } from "v2/react/shared/navigation/PageNav/LinkBlock"
import { LinkGroup } from "v2/react/shared/navigation/PageNav/LinkGroup"
import { TitleBlockLarge } from "v2/react/shared/navigation/PageNav/TitleBlock"
import { getCookie } from "v2/react/utils/cookies"
import { UrlHelper } from "v2/react/utils/urls"
import { useGetFeatureFlagsQuery } from "v2/redux/GraphqlApi"
import { useGetParticipantDatasheetPageQuery } from "v2/redux/GraphqlApi/HeadcountPlanningApi"

import RootProvider from "../RootProvider"
import { HeadcountPlanningNavTitleWithSubtitle } from "./HeadcountPlanningNav"
import { ParticipantTopbarMenu } from "./ParticipantTopbarMenu"
import { TimelineView } from "./shared/TimelineView"
import { SubmitProposalButton, SubmitProposalModal } from "./ShowParticipant/SubmitProposalButton"

interface Props {
  headcountPlanId: string
  participantId: string
}

export function ParticipantTimeline({ headcountPlanId, participantId }: Props) {
  const featureFlags = useGetFeatureFlagsQuery()
  const participantPage = useGetParticipantDatasheetPageQuery({ headcountPlanId, participantId })

  const headcountPlan = participantPage.data?.headcountPlan
  const participant = headcountPlan?.participant
  const person = participant?.person
  const { t } = useTranslation()
  const csvDownloadName = [
    headcountPlan?.name?.replace(" ", ""),
    person?.name?.replace(" ", ""),
    t("v2.defaults.timeline"),
    dayjs(new Date()).format("YYYYMMDD"),
  ].join("_")
  const position = participant?.position
  const submitsTo = participant?.submitsTo
  const hasSubmitted = participant?.status === "complete"
  const isFinalized = !!headcountPlan?.lockedAt
  const hasActiveStatus = headcountPlan?.activeStatus === HeadcountPlanActiveStatus.Active
  const displayStateCookie = getCookie(
    `built__display-state-for-hcp-${headcountPlanId}`,
  ) as DisplayState
  const [csvDownloadRef, setCsvDownloadRef] = React.useState<HTMLButtonElement | null>(null)

  const [submitProposalModalIsOpen, setSubmitProposalModalIsOpen] = React.useState(false)

  const w = useWindowSize()

  const isExtraSmallScreen = w.width < 640

  if (
    participantPage.isLoading ||
    featureFlags.isFetching ||
    !featureFlags.data?.currentCompany?.featureFlags
  )
    return <Spinner />

  const ff: FeatureFlags = featureFlags.data.currentCompany.featureFlags

  return (
    <RootProvider>
      <PageNav>
        <TitleBlockLarge>
          <HeadcountPlanningNavTitleWithSubtitle
            title={`${person?.name}, ${position?.title}`}
            subtitle={headcountPlan?.name}
            subtitleLinkTo={UrlHelper.headcountPlanPath(headcountPlanId)}
          />
        </TitleBlockLarge>
        <LinkBlockSmall>
          <LinkGroup
            links={getNavLinks({
              isOwner: false,
              active: "Timeline",
              headcountPlanId,
              participantId,
              ff,
              t,
            })}
          />
        </LinkBlockSmall>
        <ActionBlock>
          <div className="items-center gap-2 flex">
            {hasActiveStatus ? <NavActiveIcon /> : null}
            {isFinalized ? <PlanTypeIndicator type={displayStateCookie || "approved"} /> : null}
            {!hasSubmitted && !isExtraSmallScreen ? (
              <>
                <SubmitProposalModal
                  allowedAttributes={headcountPlan.allowedAttributes}
                  budget={participant?.budget}
                  headcountPlanId={headcountPlanId}
                  isOpen={submitProposalModalIsOpen}
                  participantId={participantId}
                  positions={participant.allPositionsInPlan}
                  proposedBudget={participant?.proposedBudget}
                  remainingBudget={participant?.remainingBudget}
                  setIsOpen={setSubmitProposalModalIsOpen}
                  submitsTo={submitsTo}
                />
                <SubmitProposalButton
                  disabled={!participant?.canSubmit}
                  setIsOpen={setSubmitProposalModalIsOpen}
                />
              </>
            ) : null}
            {isExtraSmallScreen ? (
              <ParticipantTopbarMenu
                allowedAttributes={headcountPlan.allowedAttributes}
                budget={participant?.budget}
                headcountPlanId={headcountPlanId}
                participantId={participantId}
                positions={participant.allPositionsInPlan}
                proposedBudget={participant?.proposedBudget}
                remainingBudget={participant?.remainingBudget}
                setRef={(element) => {
                  setCsvDownloadRef(element)
                }}
                showExportButton
                showSubmitProposalButton={!hasSubmitted}
                submitsTo={submitsTo}
              />
            ) : (
              <ExportButton setRef={(element) => setCsvDownloadRef(element)} />
            )}
          </div>
        </ActionBlock>
      </PageNav>
      <TimelineView
        csvDownloadRef={{ current: csvDownloadRef }}
        csvDownloadName={csvDownloadName}
        headcountPlanId={headcountPlanId}
        participantId={participantId}
      />
    </RootProvider>
  )
}
