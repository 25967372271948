import classNames from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import { setActiveSuccessionPlanPanelTab } from "v2/redux/slices/SuccessionPlanPanelSlice"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

type SuccessionPlanTabType = "summary" | "compare" | "matrix"

function Tabs() {
  const dispatch = useAppDispatch()
  const activeTab = useAppSelector(
    (state) => state.successionPlanPanel.activeSuccessionPlanPanelTab,
  )
  const { t } = useTranslation()

  const handleNavButtonClick = (activeTab: SuccessionPlanTabType) => {
    dispatch(setActiveSuccessionPlanPanelTab(activeTab))
  }

  return (
    <div className="btn-group justify-end flex">
      <button
        className={classNames("btn", { active: activeTab === "summary" })}
        onClick={() => handleNavButtonClick("summary")}
        type="button"
      >
        {t("v2.succession_plan_panel.summary.summary")}
      </button>
      <button
        className={classNames("btn", { active: activeTab === "compare" })}
        onClick={() => handleNavButtonClick("compare")}
        type="button"
      >
        {t("v2.succession_plan_panel.compare.compare")}
      </button>
      <button
        className={classNames("btn", { active: activeTab === "matrix" })}
        onClick={() => handleNavButtonClick("matrix")}
        type="button"
      >
        {t("v2.succession_plan_panel.matrix.matrix")}
      </button>
    </div>
  )
}

export { Tabs }
