import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import { isEmpty } from "lodash"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useIntersectionObserver } from "usehooks-ts"

import {
  Dropdown,
  DropdownContext,
  type DropdownProps,
} from "v2/react/shared/collection/menus/Dropdown/Dropdown"
import {
  OnOpenChange,
  useDropdownSelect,
} from "v2/react/shared/collection/menus/Dropdown/hooks/useDropdownSelect"
import { MenuOption } from "v2/react/shared/forms/CheckboxMenu/types"
import { getButtonText } from "v2/react/shared/forms/CheckboxMenu/utils"
import { Spinner } from "v2/react/shared/loaders/Spinner"

interface CheckboxItem {
  option: MenuOption
  fieldId: string
  onClick: (optionKey: string) => void
}

const CheckboxItem = ({ option, fieldId, onClick }: CheckboxItem) => (
  <Dropdown.Item
    id={`${fieldId}-${option.id}`}
    onClick={() => onClick(option.id)}
    className="items-center gap-1.5 p-2 flex"
    as="button"
    closeAfterClick={false}
    useActiveStyles
  >
    <input
      type="checkbox"
      id={`${fieldId}-${option.id}-checkbox`}
      checked={option.selected}
      className="!mr-0 mt-[2.25px] h-4 w-4 self-start"
      readOnly
    />
    <span className="side-label w-inherit cursor-pointer hyphens-auto break-words break-all text-base">
      {option.label}
    </span>
  </Dropdown.Item>
)

interface CheckboxMenuWithSearchProps<T extends MenuOption = MenuOption> {
  inputId: string
  options: T[]
  /** Set of initial selected options. */
  initialSelectedOptionKeys: T["id"][]
  /** The callback that is called when the user selects an option. */
  onSelect: (optionKey: T["id"]) => void
  /** The callback that is called when the user searches for an option. */
  onSearch?: (searchTerm: string | null) => void
  /** The callback that is called when the user paginates. */
  onPageChange?: (searchTerm: string | null) => void
  /** The callback that is called when the dropdown is blurred. */
  onBlur?: (searchTerm: string) => void
  align?: DropdownProps["align"]
  /**
   * Whether or not to search internally in this component using the data that
   * is loaded or to manage searching externally.
   */
  clientSearch?: boolean
  /**
   * Setting this prop to true will show a loading spinner within the text
   * input.
   */
  isSearching?: boolean
  hasNextPage?: boolean
}

const CheckboxMenuWithSearch = <T extends MenuOption = MenuOption>({
  inputId,
  options,
  initialSelectedOptionKeys,
  onSelect,
  onSearch,
  onPageChange,
  onBlur,
  align,
  clientSearch,
  isSearching,
  hasNextPage,
}: CheckboxMenuWithSearchProps<T>) => {
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState("")
  const [isOpen, setIsOpen] = useState(false)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const initialSelectedKeysSet = useMemo(
    () => new Set(initialSelectedOptionKeys),
    [initialSelectedOptionKeys],
  )
  const paginationRef = useRef<HTMLDivElement | null>(null)
  const observer = useIntersectionObserver(paginationRef, {
    threshold: 0.1,
  })

  const isIntersecting = observer?.isIntersecting
  useEffect(() => {
    if (isIntersecting) {
      onPageChange?.(processSearchTerm(inputValue))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIntersecting])

  const initialSelectedOptions = options.filter((option) => initialSelectedKeysSet.has(option.id))
  const hasInitialSelectedOptions = !isEmpty(initialSelectedOptions)
  const selectedOptions = options.filter((option) => option.selected)
  let searchableOptions = options.filter((option) => !initialSelectedKeysSet.has(option.id))
  if (clientSearch) {
    searchableOptions = searchableOptions.filter((option) =>
      option.label.toLowerCase().includes(inputValue.toLowerCase().trim()),
    )
  }
  const allOptions = [...initialSelectedOptions, ...searchableOptions]
  const noResults = searchableOptions.length === 0

  const handleBlur = () => {
    setInputValue("")
    onBlur?.(inputValue)
  }

  const onOpenChange: OnOpenChange = (isOpen, event, reason) => {
    // Hack: When tabbing out of the search field, the reason here is undefined.
    // We leverage this to close the dropdown.
    if (!reason) {
      setIsOpen(false)
      handleBlur()
      floatingInfo.setActiveIndex(0)
    }

    const target = event?.target
    const targetInContainer =
      target instanceof HTMLElement && containerRef.current?.contains(target)
    if (targetInContainer) {
      return
    }

    setIsOpen(isOpen)
    if (!isOpen) {
      handleBlur()
    }
  }

  const floatingInfo = useDropdownSelect({
    showDropdown: isOpen,
    setShowDropdown: onOpenChange,
    align,
    ariaRole: "combobox",
    virtual: true,
    useDynamicSizing: true,
    maxHeight: 384, // 24rem
  })

  const contextValue = useMemo(
    () => ({ isOpen, setIsOpen, floatingInfo }),
    [isOpen, setIsOpen, floatingInfo],
  )

  const handleItemSelect = (itemKey: T["id"]) => onSelect?.(itemKey)

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value
    setInputValue(searchTerm)
    // We want to focus on the first search result.
    floatingInfo.setActiveIndex(selectedOptions.length)
    onSearch?.(processSearchTerm(searchTerm))
  }

  const handleClearValue = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    setInputValue("")
    onSearch?.(null)
    floatingInfo.setActiveIndex(0)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key === "Enter" &&
      floatingInfo.activeIndex != null &&
      options[floatingInfo.activeIndex]
    ) {
      e.preventDefault()
      handleItemSelect(allOptions[floatingInfo.activeIndex]?.id)
    }
  }

  const handleFocus = () => {
    if (!isOpen) {
      floatingInfo.setActiveIndex(0)
      setIsOpen(true)
    }
  }

  return (
    <DropdownContext.Provider value={contextValue}>
      <div id={`${inputId}-wrapper`} className="dropdown w-full" ref={containerRef}>
        <div>
          <input
            id={inputId}
            className={cn("input suffix-pad", {
              "suffix-pad--large": isSearching,
            })}
            data-testid="combobox-trigger"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...floatingInfo.getReferenceProps({
              ref: floatingInfo.refs.setReference,
              onChange: handleSearch,
              value: inputValue,
              placeholder: getButtonText(options.length, selectedOptions, t, isSearching),
              onKeyDown: handleKeyDown,
              onFocus: handleFocus,
            })}
          />
          <button className="suffix" onMouseDown={handleClearValue} type="button" tabIndex={-1}>
            <FontAwesomeIcon icon={["far", "times"]} className="pointer-events-none" />
          </button>
          {isSearching && (
            <div
              className={cn("absolute top-0 h-full w-4 flex-col items-center justify-center flex", {
                "right-7": inputValue,
                "right-2": !inputValue,
              })}
            >
              <Spinner className="static m-0 h-4 w-4" />
            </div>
          )}
        </div>
        <Dropdown.Menu
          id={`${inputId}-checkbox-items`}
          captureInitialFocus={false}
          returnFocus={false}
        >
          {hasInitialSelectedOptions &&
            initialSelectedOptions.map((option) => (
              <CheckboxItem
                key={`${inputId}-${option.id}`}
                option={option}
                fieldId={inputId}
                onClick={handleItemSelect}
              />
            ))}
          {hasInitialSelectedOptions && <hr className="mx-[-0.5rem] my-2 p-0" />}
          {noResults ? (
            <div id={`${inputId}--no-results-message`} className="p-2">
              {t("v2.defaults.no_results_found")}
            </div>
          ) : (
            searchableOptions.map((option) => (
              <CheckboxItem
                key={`${inputId}-${option.id}`}
                option={option}
                fieldId={inputId}
                onClick={handleItemSelect}
              />
            ))
          )}
          {hasNextPage && (
            <>
              <div ref={paginationRef} />
              <div className="h-8 w-full items-center justify-items-center p-2 flex">
                <Spinner className="relative h-4 w-4" />
              </div>
            </>
          )}
        </Dropdown.Menu>
      </div>
    </DropdownContext.Provider>
  )
}

const processSearchTerm = (searchTerm?: string) => {
  const term = searchTerm?.trim()
  return !term || isEmpty(term) ? null : term
}

export { CheckboxMenuWithSearch }
