import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { FilterableTables } from "types/graphql.enums"
import {
  extractFiltersFromQueryParams,
  getEmptyFilter,
  validateFilters,
  type Filter,
} from "v2/react/shared/tables/TableUtilities/FilterTable/utils/filters"
import { TablesApi } from "v2/redux/GraphqlApi/TablesApi"

interface TableFiltersState {
  /**
   * The table that the filters are associated with.
   */
  table: FilterableTables | null
  /**
   * The set of filters currently applied. This is a combination of the user's
   * selected filters with filter selections populated from the url params.
   */
  appliedFilters: Filter[]
  /**
   * The current set of filters.
   */
  filters: Filter[]
  /**
   * A key that is incremented when the filters are reset. This is used to
   * potentially force a re-render of any forms that the filters are attached
   * to. This comes in handy when resetting uncontrolled inputs.
   *
   * Additionally, we use this key to trigger effects when the form is reset.
   * @see `useFilterPanelState`
   */
  formKey: number
}

const InitialState: TableFiltersState = {
  table: null,
  appliedFilters: [],
  filters: [],
  formKey: 0,
}

const TableFiltersSlice = createSlice({
  name: "tableFilters",
  initialState: InitialState,
  reducers: {
    setTable: (state, { payload }: PayloadAction<FilterableTables>) => ({
      ...state,
      table: payload,
    }),
    /**
     * Resets the filters to match the initial filters. Also increments the form
     * key to force a re-render of any forms it's attached to.
     */
    resetFilterChanges: (state) => ({
      ...state,
      filters: state.appliedFilters,
      formKey: state.formKey + 1,
    }),
    updateFilters: (state, { payload }: PayloadAction<Filter[]>) => ({
      ...state,
      filters: payload,
    }),
    addFilter: (state, { payload }: PayloadAction<Filter>) => ({
      ...state,
      filters: [...state.filters, payload],
    }),
    removeFilter: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      filters: state.filters.filter((filter) => filter.field !== payload),
    }),
    applyFilters: (
      state,
      { payload }: PayloadAction<{ filters: Filter[]; table: FilterableTables }>,
    ) => {
      const filters = validateFilters({ filters: payload.filters, replaceInvalidWithEmpty: true })

      return {
        ...state,
        filters: [...filters],
        appliedFilters: [...filters],
        // Resets the the form key to force a re-render of the form.
        formKey: state.formKey + 1,
      }
    },
  },
  extraReducers: (builder) => {
    /**
     * Ensures that when new data is loaded, the filters are updated to match
     * the selected filters from the API.
     */
    builder.addMatcher(
      TablesApi.endpoints.getFilterSettings.matchFulfilled,
      (state, { payload }) => {
        const filterSettings = payload.currentPerson?.settings?.tableFilterSettings
        const selectedFilters = filterSettings?.selectedFilters || []
        const enrichedSelectedFilters = filterSettings?.enrichedSelectedFilters || []

        const filtersFromParams = extractFiltersFromQueryParams({ only: selectedFilters })
        const activeFilters = enrichedSelectedFilters.map((field) => {
          const filter = filtersFromParams.find((f) => f.field === field.id)
          return filter || getEmptyFilter({ field: field.id, dataType: field.dataType })
        })

        return {
          ...state,
          appliedFilters: [...activeFilters],
          filters: [...activeFilters],
        }
      },
    )
  },
})

export const {
  setTable,
  resetFilterChanges,
  addFilter,
  removeFilter,
  applyFilters,
  updateFilters,
} = TableFiltersSlice.actions
export { TableFiltersSlice }
