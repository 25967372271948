import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"

import { Stepper } from "v2/react/shared/forms/Stepper"

interface HCPStepperProps {
  /**
   * If true, the third step will be "Set Budgets", if false, "Start Planning"
   */
  setBudget: boolean
  currentStep: 1 | 2 | 3
}

const HCPStepper = ({ setBudget, currentStep }: HCPStepperProps) => {
  const { t } = useTranslation()

  const hcpSteps = useMemo(() => {
    const steps = [
      { step: 1, label: t("v2.headcount_plan.setup.plan_details") },
      { step: 2, label: t("v2.headcount_plan.setup.invite_participants") },
    ]

    if (setBudget) {
      steps.push({ step: 3, label: t("v2.headcount_plan.setup.set_budgets") })
    } else {
      steps.push({ step: 3, label: t("v2.headcount_plan.setup.start_planning") })
    }
    return steps
  }, [setBudget, t])

  return <Stepper steps={hcpSteps} currentStep={currentStep} wrapperClassName="sm:w-[544px]" />
}

export { HCPStepper }
