import React from "react"

import { Cell } from "./hooks/useCellState"
import { ErrorLayer } from "./StyleLayers/ErrorLayer"
import { SaveLayer } from "./StyleLayers/SaveLayer"
import { FieldType } from "./types"

export const StyleLayers = React.memo(
  // eslint-disable-next-line prefer-arrow-callback
  function StyleLayers({
    cell,
    errorMessage,
    fieldType,
  }: {
    cell: Cell
    errorMessage?: string
    fieldType: FieldType
  }) {
    return (
      <>
        <SaveLayer showStyle={cell.isSaved} />
        <ErrorLayer
          showStyle={
            cell.isErrored ||
            cell.isErroredSaving ||
            cell.isErroredEditing ||
            cell.isErroredSelected
          }
          errorMessage={errorMessage || cell.errorMessage}
          fieldType={fieldType}
        />
      </>
    )
  },
)
