import {
  AdminChangeLogQuery,
  AdminChangeLogQueryVariables,
  PastDueAutoImportsQuery,
  PastDueAutoImportsQueryVariables,
} from "types/graphql.d"
import { GraphqlApi } from "v2/redux/GraphqlApi"
import { queryOperation } from "v2/redux/utils/endpoints"

export const AdminApi = GraphqlApi.injectEndpoints({
  endpoints: (builder) => ({
    adminChangeLog: builder.query<AdminChangeLogQuery, AdminChangeLogQueryVariables>({
      query: queryOperation<AdminChangeLogQueryVariables>("AdminChangeLog"),
      extraOptions: { isAdmin: true },
    }),
    pastDueAutoImports: builder.query<PastDueAutoImportsQuery, PastDueAutoImportsQueryVariables>({
      query: queryOperation<PastDueAutoImportsQueryVariables>("PastDueAutoImports"),
      extraOptions: { isAdmin: true },
    }),
  }),
})

export const { useAdminChangeLogQuery, usePastDueAutoImportsQuery } = AdminApi
