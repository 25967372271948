import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import dayjs from "dayjs"
import React, { ReactNode } from "react"

import { HeadcountPlanActiveStatus, HeadcountPlanStatus } from "types/graphql.d"
import { PlanEllipsisMenu } from "v2/react/components/headcountPlanning/ListPlans/PlanEllipsisMenu"
import { StatusBadge } from "v2/react/shared/status/StatusBadge"

export type ListViewHeadcountPlan = {
  activeStatus?: HeadcountPlanActiveStatus | null
  currentUserCanManage?: boolean | null
  id: string
  name: string
  startDate: string
  endDate: string
  status: HeadcountPlanStatus
  finalAggregations: {
    totalPositionsCount?: number | null | undefined
    newPositionsCount?: number | null | undefined
  }
}

export type PlanTableProps = {
  emptyState?: string
  isCompanyManagerOrHrAdmin: boolean
  title: ReactNode
  plans?: ListViewHeadcountPlan[]
  showMenuColumn: boolean
}
function PlanTable({
  emptyState,
  isCompanyManagerOrHrAdmin,
  title,
  plans,
  showMenuColumn,
}: PlanTableProps) {
  return (
    <div className="table">
      <table className="rounded-xl bg-white">
        <thead>
          <tr className="border-b-solid border-b border-b-neutral-8">
            <th className="w-4/6">{title}</th>
            <th className="w-1/12 text-right">
              {"plan_table_column_total_positions".t("headcount_plan")}
            </th>
            <th className="w-1/12 text-right">
              {"plan_table_column_new_positions".t("headcount_plan")}
            </th>
            <th className="w-1/6 text-center">{"plan_table_column_status".t("headcount_plan")}</th>
            {showMenuColumn && (
              <th>
                <div className="w-14" />
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {plans?.length === 0 && emptyState ? (
            <tr>
              <td>{emptyState}</td>
            </tr>
          ) : null}
          {plans?.map((plan) => (
            <tr key={plan.id}>
              <td>
                <div className="gap-4 flex">
                  <div className="flex-col justify-center flex">
                    <PlanIcon />
                  </div>
                  <div>
                    <a
                      href={`/headcount_plans/${plan.id}`}
                      className="text-base font-bold text-neutral-100"
                    >
                      {plan.name}
                    </a>
                    <p className="text-sm">
                      {formatDate(plan.startDate)} - {formatDate(plan.endDate)}
                    </p>
                  </div>
                </div>
              </td>
              <td className="text-right">{plan.finalAggregations.totalPositionsCount}</td>
              <td className="text-right">{plan.finalAggregations.newPositionsCount}</td>
              <td>
                <div className="justify-center flex">
                  <HCPStatusBadge status={plan.status} />
                </div>
              </td>
              {(isCompanyManagerOrHrAdmin || plan.currentUserCanManage) && (
                <td>
                  <PlanEllipsisMenu
                    isCompanyOrHrAdmin={isCompanyManagerOrHrAdmin}
                    isPlanAdmin={!!plan.currentUserCanManage}
                    planId={plan.id}
                    planStatus={plan.status}
                    planActiveStatus={plan.activeStatus}
                  />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

function HCPStatusBadge({ status }: { status: HeadcountPlanStatus }) {
  if (status === HeadcountPlanStatus.Approved) {
    return <StatusBadge status="plan_approved" displayType="button" checkmarkOnApproved />
  }
  if (status === HeadcountPlanStatus.InProgress) {
    return <div className="badge--yellow">{"plan_status_in_progress".t("headcount_plan")}</div>
  }
  if (status === HeadcountPlanStatus.AllPlansSubmitted) {
    return <div className="badge--purple">{"plan_status_all_submitted".t("headcount_plan")}</div>
  }
}

function formatDate(date: string) {
  return dayjs(date).format("MMM D, YYYY")
}

function PlanIcon() {
  return (
    <div className="h-9 w-9 grid-cols-1 items-center justify-items-center rounded-full bg-primary-10 p-2 grid">
      <FontAwesomeIcon icon={["far", "usd-square"]} />
    </div>
  )
}

export { PlanTable }
