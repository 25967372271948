import {
  ColumnRangeMap,
  ColumnWidthMap,
} from "v2/react/components/headcountPlanning/TableDatasheet/types"

export const COLUMN_WIDTHS: ColumnWidthMap = {
  small: 64,
  medium: 150,
  large: 320,
}

export const COLUMN_RANGES: ColumnRangeMap = {
  small: [COLUMN_WIDTHS.small, COLUMN_WIDTHS.medium],
  medium: [COLUMN_WIDTHS.medium, COLUMN_WIDTHS.large],
  large: [COLUMN_WIDTHS.large, COLUMN_WIDTHS.large],
}

export const APPROXIMATE_SORT_ICON_WIDTH = 24
export const APPROXIMATE_HEADER_PADDING_H = 16 * 2
export const ROW_HEIGHT_PX = 56
export const HEADER_HEIGHT_PX = 80
