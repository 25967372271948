import { useMergeRefs } from "@floating-ui/react"
import { Table as ReactTable } from "@tanstack/react-table"
import cn from "classnames"
import { motion } from "framer-motion"
import React, { useRef } from "react"

import { LoadingIndicator } from "v2/react/shared/loaders/LoadingIndicator"
import { Spinner } from "v2/react/shared/loaders/Spinner"
import { Pagination } from "v2/react/shared/tables/Table/Pagination"
import { TableBody } from "v2/react/shared/tables/Table/TableBody"
import { TableFooter } from "v2/react/shared/tables/Table/TableFooter"
import { TableHeader } from "v2/react/shared/tables/Table/TableHeader"

interface TableProps<TData> {
  table: ReactTable<TData>
  className?: string
  footerContent?: string
  /**
   * If true, a translucent overlay will be displayed over the table, along with
   * a spinner. Fetching in this context means that the table is being updated
   * with new data, not that the data is being fetched for the first time.
   */
  isFetching?: boolean
  /**
   * If true, a spinner will be displayed instead of the table. Loading in this
   * context means that the data is being fetched for the first time.
   */
  isLoading?: boolean
  /**
   * If true, the table fades in on initial render. Defaults to false.
   */
  animateEntrance?: boolean
  onPageChange?: () => void
  optOutOfScrollReset?: boolean
  tableRef?: React.RefObject<HTMLDivElement>
}

const initialHeight = 200 // px
const animationDuration = 250 // ms

export const Table = <TData extends object>({
  table,
  className,
  footerContent,
  isFetching,
  isLoading,
  animateEntrance = false,
  onPageChange,
  optOutOfScrollReset,
  tableRef: theirTableRef,
}: TableProps<TData>) => {
  const numPages = table.getPageCount()
  const numRows = table.getRowModel().rows.length
  const tableRef = useRef<HTMLDivElement>(null)
  const refSetter = useMergeRefs([tableRef, theirTableRef])

  // Reset scroll on new render
  if (tableRef.current && !isFetching && !optOutOfScrollReset) {
    tableRef.current.scrollTop = 0
    tableRef.current.scrollLeft = 0
  }

  return (
    <LoadingIndicator
      isLoading={!!isLoading}
      spinnerStyle={{ position: "relative" }}
      wrapperStyle={{ height: initialHeight }}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: animateEntrance ? animationDuration / 1000.0 : 0 }}
      >
        <div className="relative">
          <div className={cn("table", className)} ref={refSetter}>
            <table>
              <TableHeader table={table} />
              <TableBody table={table} isFetching={isFetching} />
              {footerContent && (
                <TableFooter columnLength={table.getAllColumns().length} content={footerContent} />
              )}
            </table>
          </div>
          {isFetching && numRows > 0 && <Spinner style={{ zIndex: 2, marginTop: "-20px" }} />}
        </div>
        {numPages > 1 && <Pagination onPageChange={onPageChange} table={table} />}
      </motion.div>
    </LoadingIndicator>
  )
}
