import React from "react"
import { createRoot } from "react-dom/client"

import { Invitations } from "v2/react/components/headcountPlanning/Invitations"
import RootProvider from "v2/react/components/RootProvider"

$.onmount("#react-headcount-plans-invitations-container", () => {
  const container = document.getElementById("react-headcount-plans-invitations-container")
  const root = createRoot(container)
  const { headcountPlanId, headcountPlanName, currentCompanyId, currentPersonId } =
    container.dataset

  root.render(
    <RootProvider>
      <Invitations
        headcountPlanId={headcountPlanId}
        headcountPlanName={headcountPlanName}
        companyId={currentCompanyId}
        currentPersonId={currentPersonId}
      />
    </RootProvider>,
  )
})
