import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { HeadcountPlanActiveStatus } from "types/graphql.d"
import { NavActiveIcon } from "v2/react/components/headcountPlanning/shared/NavActiveIcon"
import RootProvider from "v2/react/components/RootProvider"
import { LoadingWrapper } from "v2/react/shared/loaders/LoadingWrapper"
import PageNav from "v2/react/shared/navigation/PageNav"
import { ActionBlock } from "v2/react/shared/navigation/PageNav/ActionBlock"
import { TitleBlockLarge } from "v2/react/shared/navigation/PageNav/TitleBlock"
import { getCookie, setCookie } from "v2/react/utils/cookies"
import { UrlHelper } from "v2/react/utils/urls"
import { useGetHeadcountPlanWithTreeQuery } from "v2/redux/GraphqlApi/HeadcountPlanningApi"

import { HeadcountPlanningNavTitleWithSubtitle } from "./HeadcountPlanningNav"
import { FinalizePlanButton, FinalizePlanModal } from "./Overview/FinalizePlanButton"
import { Notifications } from "./Overview/Notifications"
import { ParticipantTable } from "./Overview/ParticipantTable"
import { PlanTopbarMenu } from "./PlanTopbarMenu"

interface Props {
  headcountPlanId: string
  rootPositionId: string | undefined
  participantIds: string[]
  maxChartDepth: number
}

function WithProvider({ headcountPlanId, rootPositionId, participantIds, maxChartDepth }: Props) {
  const { t } = useTranslation()
  const { data, isError } = useGetHeadcountPlanWithTreeQuery({
    id: headcountPlanId,
    maxChartDepth,
    rootPositionId,
    participantIds,
  })
  const headcountPlan = data?.headcountPlan
  const setBudget = headcountPlan?.setBudget
  const cookieName = `built__dismissed-invite-notification-for-hcp-${headcountPlanId}`
  const [showNotifications, setShowNotifications] = useState(getCookie(cookieName) !== "true")
  const isFinalized = !!headcountPlan?.lockedAt
  const hasActiveStatus = headcountPlan?.activeStatus === HeadcountPlanActiveStatus.Active

  const [finalizePlanModalIsOpen, setFinalizePlanModalIsOpen] = useState(false)

  const handleNotificationsCloseClick = () => {
    setShowNotifications(false)
    setCookie(cookieName, "true", 365 * 5) // 5 years since we'll have another solution by then
  }

  return (
    <>
      <PageNav>
        {!!headcountPlan && (
          <>
            <TitleBlockLarge>
              <HeadcountPlanningNavTitleWithSubtitle
                title={headcountPlan.name || ""}
                subtitle={t("v2.headcount_plan.headcount_planning")}
                subtitleLinkTo={UrlHelper.headcountPlansPath()}
              />
            </TitleBlockLarge>
            <ActionBlock>
              <div className="items-center gap-2 flex">
                {hasActiveStatus ? <NavActiveIcon /> : null}
                {!headcountPlan.currentUserCanManage || headcountPlan.lockedAt ? null : (
                  <>
                    <FinalizePlanModal
                      headcountPlanId={headcountPlanId}
                      headcountPlanName={headcountPlan.name}
                      isOpen={finalizePlanModalIsOpen}
                      setIsOpen={setFinalizePlanModalIsOpen}
                    />
                    <FinalizePlanButton
                      canBeFinalized={headcountPlan.canBeFinalized}
                      setIsOpen={setFinalizePlanModalIsOpen}
                    />
                  </>
                )}
                {!headcountPlan.currentUserCanManage || isFinalized ? null : (
                  <PlanTopbarMenu
                    headcountPlanId={headcountPlanId}
                    isFinalized={isFinalized}
                    setBudget={setBudget}
                  />
                )}
              </div>
            </ActionBlock>
          </>
        )}
      </PageNav>

      <LoadingWrapper isLoading={!headcountPlan} isError={isError}>
        {!!headcountPlan && (
          <div className="p-12">
            <Notifications
              headcountPlan={headcountPlan}
              onClose={handleNotificationsCloseClick}
              showNotifications={showNotifications}
            />
            <ParticipantTable
              showEntireOrgRow={headcountPlan.currentUserCanManage}
              headcountPlanId={headcountPlan.id}
              logoThumbUrl={headcountPlan.company.logoThumbUrl || ""}
              companyName={headcountPlan.company.name || ""}
              totalBudget={headcountPlan.formattedBudget}
              totalProposal={headcountPlan.formattedProposedBudget}
              totalRemainingBudget={headcountPlan.remainingBudget}
              participants={headcountPlan.participantsTree}
              showBudget={headcountPlan.setBudget}
              showProposal={headcountPlan.setBudget && !isFinalized}
              canBeFinalized={headcountPlan.canBeFinalized}
            />
          </div>
        )}
      </LoadingWrapper>
    </>
  )
}

const Overview = (props: Props) => (
  <RootProvider>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <WithProvider {...props} />
  </RootProvider>
)

export { Overview }
