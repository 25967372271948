import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { TFunction } from "i18next"
import defaultAvatar from "images/avatar/fallback/default-avatar.png"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { Maybe } from "types/graphql"
import RootProvider from "v2/react/components/RootProvider"
import { checkImageValidity } from "v2/react/shared/icons/Avatar"
import { Spinner } from "v2/react/shared/loaders/Spinner"
import { EditImageModal } from "v2/react/shared/overlay/EditImageModal"
import { convertBase64 } from "v2/react/utils/files"
import { idFromUniqueKey } from "v2/react/utils/uniqueKey"
import { updatePersonAvatar } from "v2/redux/slices/ProfilePanelSlice"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

interface AvatarWithUploadProps {
  personId: string | null
  thumbUrl: Maybe<string> | undefined
  canUploadAvatar: boolean
}

// Alert the user of an error, and attempt to log it in Sentry.
const avatarErrorAlert = ({
  error,
  t,
  logSentry = false,
}: {
  error: Error
  t: TFunction
  logSentry?: boolean
}) => {
  // eslint-disable-next-line no-alert
  alert(t("v2.profile_panel.avatar_upload_error"))
  if (logSentry) {
    window?.Sentry?.captureException(error)
  }
}

function WithProvider({ personId, thumbUrl, canUploadAvatar }: AvatarWithUploadProps) {
  const { t } = useTranslation()
  const currentPersonIdKey = useAppSelector((state) => state.session.currentPersonId)
  const currentPersonId = currentPersonIdKey && idFromUniqueKey(currentPersonIdKey.toString())
  const fileInput = useRef<HTMLInputElement | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [base64Image, setBase64Image] = useState<string | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const [avatarUrl, setAvatarUrl] = useState(thumbUrl)

  const dispatch = useAppDispatch()

  // When this component is standalone (e.g. on the profile page), we need to
  // keep track of the thumb url in local state rather than relying on the
  // props.  This effect is to ensure that in contexts where we we do rely on
  // the props (e.g. the org chart), the avatar url is updated when the thumb
  // url changes.
  useEffect(() => {
    setAvatarUrl(thumbUrl)
  }, [thumbUrl])

  if (!personId) return null

  const handleModalClose = () => setIsModalOpen(false)

  const handleUploadClick = () => fileInput.current?.click()

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]

    if (file) {
      // Handles the case where the file is not an image.
      if (!file.type.startsWith("image/")) {
        if (fileInput.current) fileInput.current.value = ""
        return
      }

      const base64 = await convertBase64(file)
      if (base64) {
        setBase64Image(base64 as string)
        setIsModalOpen(true)
      }
    }
  }

  const submitAvatar = async (blob: Blob) => {
    const fileName = fileInput.current?.value.split(/(\\|\/)/g).pop() || "avatar.png"
    const fieldName = fileInput.current?.name || "person[avatar]"

    const formData = new FormData()
    formData.append(fieldName, blob, fileName)

    const response = await fetch(window.App.endpoint(["people", personId]), {
      method: "PUT",
      body: formData,
    })

    if (response.ok) return response.json()

    throw new Error(`HTTP error: ${response.status}`)
  }

  const handleImageSave = (blob: Blob) => {
    setIsLoading(true)
    submitAvatar(blob)
      .then((data) => {
        const avatarThumbUrl = data.avatar_thumb_url
        if (avatarThumbUrl) {
          dispatch(updatePersonAvatar(avatarThumbUrl))
          setAvatarUrl(avatarThumbUrl)
          setIsLoading(false)
          maybeUpdateNavAvatar(personId.toString() === currentPersonId, avatarThumbUrl)
        } else {
          throw new Error("Error uploading image.")
        }
      })
      .catch((error) => {
        setIsLoading(false)
        avatarErrorAlert({ error, t, logSentry: true })
      })
  }

  return (
    <>
      <div className="image-uploader tooltip tooltip-center m-0 h-fit w-fit !rounded-full">
        {avatarUrl && (
          <img
            src={
              avatarUrl.includes("avatar/fallback/default-avatar.png") ||
              !checkImageValidity(avatarUrl)
                ? defaultAvatar
                : avatarUrl
            }
            alt={t("v2.profile_panel.profile_image")}
            className="image-uploader-image img-large !rounded-full"
            onLoad={() => setIsLoading(false)}
          />
        )}
        {canUploadAvatar ? (
          <>
            <button
              type="button"
              className="image-uploader-action !rounded-full"
              onClick={handleUploadClick}
            >
              <FontAwesomeIcon icon={["far", "upload"]} />
              <input
                type="file"
                name="person[avatar]"
                id="person_avatar"
                className="file_upload hidden"
                ref={fileInput}
                onChange={handleFileChange}
              />
            </button>
            <div className="tooltip-content tooltip-content--sm">
              {t("v2.profile_panel.upload_image")}
            </div>
            {isLoading && (
              <div className="image-uploader-loading-indicator show !rounded-full">
                <Spinner />
              </div>
            )}
          </>
        ) : null}
      </div>
      {base64Image && (
        <EditImageModal
          isOpen={isModalOpen}
          onModalClose={handleModalClose}
          onModalSave={handleImageSave}
          uploadedImage={base64Image}
        />
      )}
    </>
  )
}

const AvatarWithUpload = ({ personId, thumbUrl, canUploadAvatar }: AvatarWithUploadProps) => (
  <RootProvider>
    <WithProvider canUploadAvatar={canUploadAvatar} personId={personId} thumbUrl={thumbUrl} />
  </RootProvider>
)

const maybeUpdateNavAvatar = (isCurrentUser: boolean, avatarThumbUrl: string) => {
  if (!isCurrentUser) return

  const navAvatar: HTMLImageElement | null = document.querySelector(
    ".nav .person-dropdown-link > img",
  )

  if (navAvatar) {
    navAvatar.src = avatarThumbUrl
  }
}

export { AvatarWithUpload }
