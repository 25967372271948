import React from "react"
import { useTranslation } from "react-i18next"

import { Toggle, ToggleButton, ToggleLabel, ToggleSwitch } from "v2/react/shared/forms/Toggle"

export function PlanSetBudgetToggle({
  budgetOn,
  setBudgetOn,
}: {
  budgetOn: boolean
  setBudgetOn: (budgetOn: boolean) => void
}) {
  const { t } = useTranslation()

  const handleToggleBudget = () => {
    setBudgetOn(!budgetOn)
  }

  return (
    <div className="input-group">
      <Toggle initialValue={budgetOn} onToggle={handleToggleBudget}>
        <ToggleButton classes="flex items-center gap-1.5 m-0">
          <ToggleSwitch />
          <ToggleLabel
            classes="text-base-bold"
            disabledLabel={t("v2.headcount_plan.setup.set_budget")}
            enabledLabel={t("v2.headcount_plan.setup.set_budget")}
          />
          <input type="hidden" name="setBudget" id="setBudget" value={budgetOn ? 1 : 0} />
        </ToggleButton>
      </Toggle>
    </div>
  )
}
