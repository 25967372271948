import cn from "classnames"
import React from "react"
// @ts-ignore
import DatePicker from "react-pikaday-datepicker"

import { useSelectedDate } from "v2/react/hooks/useSelectedDate"
import { transitionTableCursor } from "v2/redux/slices/DatasheetSlice"
import { CursorState } from "v2/redux/slices/DatasheetSlice/cursor/types"
import { useAppDispatch } from "v2/redux/store"

import { CompareValues } from "./CompareValues"
import { useCellHandlers } from "./hooks/useCellHandlers"
import { useCellState } from "./hooks/useCellState"
import { useCursorSync } from "./hooks/useCursorSync"
import { StyleLayers } from "./StyleLayers"
import { FieldType, SaveFn } from "./types"

type Props = {
  currentValue: string
  compareValue?: string
  name: string
  allowBlank?: boolean
  onSaved?: () => void
  onErrored?: () => void
  columnId: string
  rowId: string
  saveFn: SaveFn
  openModalOnDateSelected?: (value: string) => void
  editable?: boolean
}

export function DatePickerCell({
  saveFn,
  currentValue,
  compareValue,
  allowBlank,
  name,
  onSaved,
  onErrored,
  openModalOnDateSelected,
  columnId,
  rowId,
  editable,
}: Props) {
  const inputRef = React.useRef<{ pikadayEl: HTMLInputElement }>(null)

  const cell = useCellState({
    currentValue,
    fieldType: FieldType.DatePicker,
    rowId,
    columnId,
    editable,
    saveFn: (state) => saveFn(state.value),
    onEditing: () => {
      inputRef.current?.pikadayEl?.click()
      inputRef.current?.pikadayEl?.focus()
    },
    onSaved: () => onSaved?.(),
    onErrored: () => onErrored?.(),
  })

  const cellRef = React.useRef<HTMLDivElement>(null)
  useCursorSync({ cell, cellRef, value: currentValue })
  const { handleCellClick, handleCellKeyUp } = useCellHandlers(cell, cellRef, {
    getSaveValue: () => currentValue,
  })

  const appDispatch = useAppDispatch()
  const [selectedDate, setSelectedDate] = useSelectedDate(currentValue)

  const handleSelectedDate = (value: Date) => {
    const valueInString = value.toISOString().split("T")[0]
    if (openModalOnDateSelected) {
      openModalOnDateSelected(valueInString)
      appDispatch(
        transitionTableCursor({
          rowId: cell.rowId,
          columnId: cell.columnId,
          editable: true,
          state: CursorState.OnEditable,
          fieldType: FieldType.DatePicker,
          enteredBy: "transition",
          currentValue: null,
        }),
      )
    } else {
      setSelectedDate(value)
      cell.dispatch({ type: "save", value: valueInString })
    }
  }

  const onBlankCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelectedDate("")
      cell.dispatch({ type: "save", value: "" })
    } else {
      handleSelectedDate(new Date())
    }
  }

  const pikadayRef = React.useRef<HTMLDivElement>(null)
  const noDateLabel = `No ${name.replace(/_/g, " ")}`

  const renderBlankCheckbox = () => {
    const showBlankCheckbox = cell.isEditing || cell.isErroredEditing

    if (!showBlankCheckbox || !allowBlank) return <div />

    return (
      <div className="checkbox-container">
        <label className="checkbox">
          <input type="checkbox" onChange={onBlankCheckboxChange} checked={!currentValue} />
          <span>{noDateLabel}</span>
        </label>
      </div>
    )
  }

  const selectedDateLabel =
    typeof selectedDate === "string" ? selectedDate : selectedDate.toISOString().split("T")[0]

  const hideCompareValue =
    cell.isEditing ||
    cell.isSaving ||
    cell.isSaved ||
    cell.isErrored ||
    cell.isErroredEditing ||
    cell.isErroredSaving

  const errored =
    cell.isErrored || cell.isErroredEditing || cell.isErroredSaving || cell.isErroredSelected

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      ref={cellRef}
      id={`${columnId}-${rowId}`}
      className={cn("editable-cell date-input-cell", { "editable-cell--errored": errored })}
      onClick={handleCellClick}
      onKeyUp={(e) => {
        handleCellKeyUp(e)
        // Edge case - due to the way the date picker works (it doesn't respect react state),
        // we need to manually remove the date picker from the dom when the user presses enter or tab to
        // move to the next cell. This is only applicable when the date picker cell is the
        // last cell in the last row, but it doesn't hurt to do it for all date picker cells.
        if (e.key === "Enter" || e.key === "Tab") {
          const pikaContainer = cellRef.current?.getElementsByClassName("pika-single")?.[0]
          if (pikaContainer) {
            pikaContainer.classList.add("is-hidden")
            pikaContainer.innerHTML = ""
          }
        }
      }}
      onKeyDown={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
    >
      <CompareValues compareValue={hideCompareValue ? null : compareValue}>
        {editable === false || cell.isIdle ? (
          <span>{selectedDateLabel}</span>
        ) : (
          <DatePicker
            ref={inputRef}
            container={pikadayRef.current}
            id={name}
            name={name}
            value={typeof selectedDate !== "string" ? selectedDate : undefined}
            onChange={handleSelectedDate}
            format="YYYY-MM-DD"
            trigger={cellRef.current}
            autocomplete="off"
            disabled={!hideCompareValue}
          />
        )}
      </CompareValues>
      {renderBlankCheckbox()}
      <div ref={pikadayRef} className={allowBlank ? "with-blank-option" : ""} />
      <StyleLayers cell={cell} fieldType={FieldType.Standard} />
    </div>
  )
}
