import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useTranslation } from "react-i18next"

import { HeadcountPlanParticipant } from "types/graphql"
import { AlertBanner } from "v2/react/shared/status/AlertBanner"

type BudgetOverProps = {
  onClose?: () => void
  budget: HeadcountPlanParticipant["budget"]
  proposedBudget: HeadcountPlanParticipant["proposedBudget"]
  remainingBudget: HeadcountPlanParticipant["remainingBudget"]
}

export function BudgetOverBanner({
  onClose,
  budget,
  proposedBudget,
  remainingBudget,
}: BudgetOverProps) {
  return (
    <AlertBanner
      type="caution"
      className="mb-4"
      messages={[AddMessage(budget, proposedBudget, remainingBudget)]}
      onClose={onClose}
    />
  )
}

const AddMessage = (
  budget: HeadcountPlanParticipant["budget"],
  proposedBudget: HeadcountPlanParticipant["proposedBudget"],
  remainingBudget: HeadcountPlanParticipant["remainingBudget"],
) => {
  const { t } = useTranslation()
  return {
    text: (
      <div>
        <p>
          <b>
            {t("v2.headcount_plan.datasheet.budget_over", {
              amount: remainingBudget?.replace("-", ""),
            })}
          </b>
        </p>
        <p>
          {t("v2.headcount_plan.datasheet.targeted_budget", {
            amount: budget,
          })}
        </p>
        <p>
          {t("v2.headcount_plan.datasheet.budgeted_comp_in_proposal", {
            amount: proposedBudget,
          })}
        </p>
      </div>
    ),
    icon: (
      <FontAwesomeIcon
        icon={["far", "exclamation-triangle"]}
        className="h-4 w-4 text-status-caution"
      />
    ),
  }
}
