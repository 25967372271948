/* eslint-disable no-underscore-dangle, camelcase */
import dayjs from "dayjs"
import { TFunction } from "i18next"
import fp from "lodash/fp"
import { UseFormSetError } from "react-hook-form"

import { Error as GraphQLError, PositionAttributes } from "types/graphql"
import { FundingSourceAllocation, SourcePay, StandardRank } from "types/graphql.enums"
import { safeNumber } from "v2/react/utils/safeNumber"
import { maybeGetIDFromUniqueKey } from "v2/react/utils/uniqueKey"
import { PositionFormCollections, PositionFormDefaults } from "v2/redux/GraphqlApi/PositionsApi"

import { FieldName, FieldValues, PositionData } from "./types"

type MapGraphQLErrorsIntoFormArg = {
  errors: GraphQLError[]
  positionFormCollections: PositionFormCollections | undefined
  setError: UseFormSetError<FieldValues>
  t: TFunction
}

const mapFieldValuesToPositionAttributes = (data: FieldValues): PositionAttributes => ({
  allocationAmountsAttributes: data.position.allocationAmountsAttributes?.map((attrs, index) => ({
    index,
    id: fp.isNil(attrs.id) || attrs.id?.trim() === "" ? null : `allocation_amount_${attrs.id}`,
    allocated_id: attrs.allocated.id ?? "",
    allocated_type: attrs.allocated.__typename ?? "",
    allocation_type_id: `allocation_type_${attrs.allocationType.id}`,
    destroy: false,
    effective_date: data.position.allocationEffectiveDate ?? "",
    percent: attrs.percent ?? 0,
    position_number: attrs.positionNumber,
  })),
  chartId: data.position.chartId,
  customFieldValuesAttributes: data.position.customFieldValuesAttributes?.map((attrs, index) => ({
    id: attrs.id,
    custom_field_id: `custom_field_${attrs.customField.id}`,
    value: toEmptyStringIfNullLike(attrs.value),
    index,
  })),
  description: data.position.description,
  companyCode: data.position.companyCode,
  eeocClassification: data.position.eeocClassification,
  employeeTypeId: data.position.employeeTypeId,
  externalIdentifier: data.position.externalIdentifier,
  flsaClassification: data.position.flsaClassification || null,
  fte: safeNumber(data.position.fte, { fallback: undefined }),
  hiringPriority: data.position.hiringPriority || null,
  isAssistant: data.position.isAssistant,
  locationId: data.position.locationId,
  parentId: data.position.parentId,
  people: data.position.people.map((person) => ({
    id: person.id,
    action: person.action,
    name: person.name,
  })),
  positionBasePayType: data.position.positionBasePayType,
  positionBasePay: data.position.positionBasePay ? `${data.position.positionBasePay}` : undefined,
  positionHoursPerWeek: data.position.positionHoursPerWeek
    ? `${data.position.positionHoursPerWeek}`
    : undefined,
  importance: data.position.importance === "none" ? null : data.position.importance,
  fundingSourcePositionsAttributes: data.position.fundingSourcePositionsAttributes?.map(
    (attrs, index) => ({
      id: `funding_source_position_${attrs.id}`,
      destroy: attrs._destroy ? Boolean(attrs._destroy) : false,
      funding_source_id: `funding_source_${attrs.fundingSourceId}`,
      // Allocation type is tracked at this level but is always set to what was
      // configured at the position level.
      allocation_type: data.position.fundingSourceAllocationType,
      percent_of_budget:
        typeof attrs.percentOfBudget === "number"
          ? `${attrs.percentOfBudget}`
          : attrs.percentOfBudget,
      amount: typeof attrs.amount === "number" ? `${attrs.amount}` : attrs.amount,
      index,
    }),
  ),
  orgUnitsAttributes: data.position.orgUnitsAttributes?.map((attrs) => ({
    org_unit_id: attrs.id ? `org_unit_${attrs.id}` : "",
    org_unit_type_id: attrs.orgUnitType.id,
    org_unit_full_name: attrs.fullName,
  })),
  positionStatusId: data.position.positionStatusId,
  positionTypeId: data.position.positionTypeId,
  projectedHireDate: data.position.projectedHireDate,
  variablePaysAttributes: data.position.variablePaysAttributes?.map((attrs, index) => ({
    id: attrs.id ? `variable_pay_${attrs.id}` : undefined,
    destroy: attrs._destroy ? Boolean(attrs._destroy) : null,
    amount: fp.isNaN(attrs.amount) || fp.isNil(attrs.amount) ? undefined : `${attrs.amount}`,
    pay_type: attrs.payType,
    variable_pay_type_id: `variable_pay_type_${attrs.variablePayType.id}`,
    index,
  })),
})

const mapPositionDataToFieldValues = (
  data: PositionData,
  collections: PositionFormCollections,
  defaults: PositionFormDefaults,
): FieldValues => ({
  position: {
    // Only provided to capture errors.
    allocationTypes: collections.allocationTypes.map(({ id }) => ({ id })),
    allocationAmountsAttributes: prepareAllocationAmounts(
      collections.allocationTypes,
      data.allocationAmounts,
    ),
    // Assumes effective date is only ever set for cost number type
    allocationEffectiveDate: prepareAllocationAmounts(
      collections.allocationTypes,
      data.allocationAmounts,
    )
      ?.filter(({ effectiveDate }) => effectiveDate)
      ?.at(0)?.effectiveDate,
    description: data.description ?? undefined,
    companyCode: data.companyCode ?? undefined,
    eeocClassification: data.eeocClassification ?? undefined,
    employeeTypeId: data.employeeType?.id ?? undefined,
    externalIdentifier: data.externalIdentifier ?? undefined,
    flsaClassification: data.flsaClassification ?? undefined,
    customFieldValuesAttributes: combineCustomFieldsWithValues(
      collections.customFields,
      data.customFieldValues,
    ).map(({ customField, customFieldValue }) => ({
      id: customFieldValue?.id ?? undefined,
      value: customFieldValue?.formatted_value ?? "",
      customField,
    })),
    fundingSourceAllocationType:
      data.fundingSourcePositions?.at(0)?.allocationType ?? FundingSourceAllocation.Amount,
    fundingSourcePositionsAttributes: data.fundingSourcePositions?.map((fsp) => ({
      id: fsp.id ?? undefined,
      fundingSourceId: fsp.fundingSource?.id ?? "",
      allocationType: fsp.allocationType ?? FundingSourceAllocation.Amount,
      amount: fsp.amount ? `${fsp.amount}` : undefined,
      percentOfBudget: fsp.percentOfBudget ? `${fsp.percentOfBudget}` : undefined,
      fundingSourcePosition: fsp,
    })),
    fte: data.fte,
    hiringPriority: data.hiringPriority ?? undefined,
    isAssistant: data.isAssistant ?? false,
    locationId: data.location?.id ?? defaults.location?.id,
    locationName: data.location?.label ?? defaults.location?.label,
    orgUnitsAttributes: combineOrgUnitTypesAndUnits(collections.orgUnitTypes, data.orgUnits).map(
      ({ orgUnit, orgUnitType }) => ({
        code: orgUnit?.code ?? undefined,
        name: orgUnit?.name ?? undefined,
        fullName: orgUnit?.full_name ?? undefined,
        id: orgUnit?.id ? maybeGetIDFromUniqueKey(orgUnit.id) ?? "" : "",
        orgUnitType,
      }),
    ),
    parentId: data.parent?.id ?? undefined,
    people:
      data.people?.map((person) => ({
        id: person.id,
        name: person.name,
        action: undefined,
        person,
      })) ?? [],
    positionBasePayType: data.positionBasePayType ?? undefined,
    positionBasePay: data.positionBasePay ?? undefined,
    positionHoursPerWeek: data.positionHoursPerWeek ?? undefined,
    positionType: data.positionType,
    importance: (data.importance as StandardRank) ?? "none",
    positionStatusId: data.positionStatus?.id ?? undefined,
    positionTypeId: data.positionType?.id ?? undefined,
    projectedHireDate: data.projectedHireDate
      ? dayjs(data.projectedHireDate).format("YYYY-MM-DD")
      : undefined,
    reportsToName: data.reportsToName ?? undefined,
    systemIdentifier: data.systemIdentifier ?? "--",
    variablePaysAttributes: combineVariablePayTypesAndPays(
      collections.variablePayTypes,
      data.variablePays,
    ).map(({ variablePay, variablePayType }) => ({
      id: variablePay?.id ? maybeGetIDFromUniqueKey(variablePay.id) ?? undefined : undefined,
      payType: variablePay?.pay_type ?? SourcePay.Amount,
      amount: safeNumber(variablePay?.amount, { fallback: null }),
      variablePayType,
    })),
  },
})

const combineCustomFieldsWithValues = (
  customFields: PositionFormCollections["customFields"],
  customFieldValues?: PositionData["customFieldValues"],
) =>
  customFields.map((customField) => ({
    customField,
    customFieldValue: customFieldValues?.find(
      (customFieldValue) => maybeGetIDFromUniqueKey(customFieldValue.field_id) === customField.id,
    ),
  }))

const combineVariablePayTypesAndPays = (
  variablePayTypes: PositionFormCollections["variablePayTypes"],
  variablePays?: PositionData["variablePays"],
) =>
  variablePayTypes.map((variablePayType) => ({
    variablePayType,
    variablePay: variablePays?.find(
      (variablePay) =>
        maybeGetIDFromUniqueKey(variablePay.variable_pay_type_id ?? "") === variablePayType?.id,
    ),
  }))

const combineOrgUnitTypesAndUnits = (
  orgUnitTypes: PositionFormCollections["orgUnitTypes"],
  orgUnits?: PositionData["orgUnits"],
) =>
  orgUnitTypes.map((orgUnitType) => ({
    orgUnitType,
    orgUnit: orgUnits?.find((orgUnit) => orgUnit.org_unit_type_id === orgUnitType.id),
  }))

const prepareAllocationAmounts = (
  allocationTypes?: PositionFormCollections["allocationTypes"],
  allocationAmounts?: PositionData["allocationAmounts"],
): FieldValues["position"]["allocationAmountsAttributes"] | undefined =>
  allocationTypes?.flatMap((allocationType) =>
    allocationType.allocations.map((allocated) => {
      const allocationAmount = allocationAmounts?.find(
        ({ allocation_type_id: allocationTypeId, allocated_id: allocatedId }) =>
          allocationTypeId === allocationType.id &&
          allocatedId === maybeGetIDFromUniqueKey(allocated.id ?? ""),
      )

      return {
        id: allocationAmount?.id ?? undefined,
        percent: safeNumber(allocationAmount?.percent),
        allocated,
        allocationType,
        effectiveDate: allocationAmount?.effective_date ?? undefined,
        positionNumber: allocationAmount?.position_number ?? undefined,
      }
    }),
  )

const toEmptyStringIfNullLike = (value: number | string | undefined | null) => {
  if (value === undefined || value === null) return ""
  if (typeof value === "number" && Number.isNaN(value)) return ""

  return `${value}`
}

const mapGraphQLErrorsIntoForm = ({
  errors,
  positionFormCollections,
  setError,
  t,
}: MapGraphQLErrorsIntoFormArg) => {
  const mapStandardGraphQLErrorIntoForm = (error: GraphQLError) => {
    if (error.path && !fp.equals(["base"], error.path)) {
      setError(`position.${error.path.join(".")}` as FieldName, {
        type: "custom",
        message: fp.capitalize(error.message),
      })
    } else if (error.path && fp.equals(["base"], error.path)) {
      setError("root.base", {
        type: "custom",
        message: fp.capitalize(error.message),
      })
    } else {
      setError("root.serverErrors", {
        type: "custom",
        message: fp.capitalize(error.message),
      })
    }
  }

  errors.forEach((fromGraphQL) => {
    // Camel-case errors from the server.
    const error = { ...fromGraphQL, path: fromGraphQL.path?.map(fp.camelCase) }

    // An error directly on "allocationAmounts" receives special handling.
    // This single error may actually represent many errors depending on
    // the nested `error.details`.
    if (fp.equals(["allocationAmounts"], error.path) && Array.isArray(error.details)) {
      error.details.forEach((detail) => {
        if (typeof detail !== "object" || !("error" in detail)) {
          mapStandardGraphQLErrorIntoForm(error)
          return
        }

        const embeddedError = typeof detail.error === "string" ? detail.error : null
        if (embeddedError === "allocation_effective_date_blank") {
          const message = t("v2.defaults.blank_validation")
          setError("position.allocationEffectiveDate", { message })
          return
        }

        const errorDetailId = embeddedError ? maybeGetIDFromUniqueKey(embeddedError) : undefined
        const allocationTypes = positionFormCollections?.allocationTypes
        const index = allocationTypes?.findIndex(({ id }) => errorDetailId === id)
        if (typeof index !== "number" || index < 0) {
          mapStandardGraphQLErrorIntoForm(error)
          return
        }

        // The embedded error message will actually be a failed translation
        // call, so we need to generate a message manually.
        const message = t("v2.positions.form.allocation_types_form.invalid_total")
        setError(`position.allocationTypes.${index}.id`, { message })
      })

      return
    }

    // If here, we got a standard error where the path should map to a field.
    mapStandardGraphQLErrorIntoForm(error)
  })
}

export {
  combineCustomFieldsWithValues,
  combineOrgUnitTypesAndUnits,
  combineVariablePayTypesAndPays,
  mapFieldValuesToPositionAttributes,
  mapGraphQLErrorsIntoForm,
  mapPositionDataToFieldValues,
}
