import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
  prepareAutoBatched,
  Update,
} from "@reduxjs/toolkit"
import fp from "lodash/fp"

import { Maybe, NodeInterface } from "types/graphql.d"
import { cursorUnit } from "v2/redux/slices/GridSlice/cursor/cursorActions"
import {
  inEitherWriteState,
  onNothing,
  transitionValid,
} from "v2/redux/slices/GridSlice/cursor/cursorStates"
import { Field, GridState, GroupRow, SkeletonRow } from "v2/redux/slices/GridSlice/types"
import { removeNode } from "v2/redux/slices/NodeSlice"
import { FieldKey } from "v2/redux/slices/NodeSlice/types"
import { RootState } from "v2/redux/store"

import { transitionTableCursor, updateCellState } from "./DatasheetSlice"
import { CellCursor, CursorState } from "./GridSlice/cursor/types"

const sortComparer = (lhs: GroupRow, rhs: GroupRow) =>
  fp.cond<[string, string], 0 | -1 | 1>([
    [fp.spread(fp.lt), fp.always(-1)],
    [fp.spread(fp.gt), fp.always(1)],
    [fp.always(true), fp.always(0)],
  ])([lhs.id, rhs.id])

const groupsAdapter = createEntityAdapter<GroupRow>({ sortComparer })
const groupSelectors = groupsAdapter.getSelectors<RootState>((state) => state.grid.groupEntities)

const skeletonAdapter = createEntityAdapter<SkeletonRow>()
const skeletonSelectors = skeletonAdapter.getSelectors<RootState>((state) => state.grid.skeleton)

const InitialState: GridState = {
  cursor: cursorUnit,
  nextCursorFromDatasheet: null,
  restorableCursorValue: undefined,
  showColumnStats: false,
  groupEntities: groupsAdapter.getInitialState(),
  groupFieldKeys: [],
  mergeAvatarAndName: true,
  positionStatusFilter: "all",
  sorting: { fieldKey: "name", direction: "asc" },
  fields: [],
  filters: [],
  skeleton: skeletonAdapter.getInitialState(),
  followUpModal: {
    isOpen: false,
    field: null,
    row: null,
  },
}

type ToggleOrderByAction = PayloadAction<{
  fieldKey: keyof NodeInterface
  direction?: Maybe<"asc" | "desc">
}>
type GroupByAction = PayloadAction<{ fieldKey: keyof NodeInterface; useTo: "add" | "remove" }>

const GridSlice = createSlice({
  name: "grid",
  initialState: InitialState,
  reducers: {
    setGroupFieldKeys: (state, { payload }: PayloadAction<FieldKey[]>) =>
      fp.set("groupFieldKeys", payload, state),

    setGroupEntities: (state, { payload }: PayloadAction<GroupRow[]>) => {
      groupsAdapter.setAll(state.groupEntities, payload)
    },

    setShowColumnStats: (state, { payload }: PayloadAction<boolean>) =>
      fp.set("showColumnStats", payload)(state),

    updateManySkeletonRows: (state, { payload }: PayloadAction<Update<GroupRow>[]>) => {
      skeletonAdapter.updateMany(state.skeleton, payload)
    },

    setSkeletonState: {
      reducer: (state, payload: PayloadAction<SkeletonRow[]>) => {
        skeletonAdapter.setAll(state.skeleton, payload)
      },
      prepare: prepareAutoBatched<SkeletonRow[]>(),
    },

    setMergeAvatarAndName: (state, { payload }: PayloadAction<boolean>) =>
      fp.set("mergeAvatarAndName", payload)(state),
    setFields: (state, { payload }: PayloadAction<Field[]>) => fp.set("fields", payload)(state),

    addFilter: (state, { payload }: PayloadAction<{ fieldKey: FieldKey; term: string }>) =>
      fp.update("filters", fp.unionBy("fieldKey", [payload]))(state),

    removeFilter: (state, { payload }: PayloadAction<FieldKey>) =>
      fp.update("filters", fp.reject(fp.propEq("fieldKey", payload)))(state),

    toggleOrderBy: (state, { payload }: ToggleOrderByAction) => {
      const { direction, fieldKey } = payload
      if (direction) return fp.set("sorting", { fieldKey, direction }, state)

      return fieldKey === state.sorting.fieldKey
        ? fp.update(
            ["sorting", "direction"],
            (current) => (current === "asc" ? "desc" : "asc"),
            state,
          )
        : fp.set("sorting", { fieldKey, direction: "asc" }, state)
    },

    setFollowUpModal: (state, { payload }: PayloadAction<GridState["followUpModal"]>) =>
      fp.set("followUpModal", payload, state),

    transitionCursor: (state, { payload: next }: PayloadAction<GridState["cursor"]>) =>
      transitionValid(state.cursor, next)
        ? fp.assign(state, { cursor: next, restorableCursorValue: undefined })
        : state,

    cursorInputUnmountedWhileWriting: (state, { payload: value }: PayloadAction<string>) =>
      inEitherWriteState(state.cursor) ? fp.set("restorableCursorValue", value, state) : state,
  },
  extraReducers: (builder) =>
    builder
      .addMatcher(removeNode.match, (state, { payload: id }) => {
        // Always remove the entry in the skeleton for the node that was removed.
        skeletonAdapter.removeOne(state.skeleton, id)
        // This currently assumes only one selection, but should be broadened if
        // we add support for multi-selections.
        if (onNothing(state.cursor)) return
        if (state.cursor.rowId !== id) return
        state.cursor = cursorUnit // eslint-disable-line no-param-reassign
      })
      .addMatcher(transitionTableCursor.match, (state, { payload: cursor }) => {
        const next = "columnId" in cursor ? { ...cursor, fieldKey: cursor.columnId } : { ...cursor }
        return transitionValid(state.cursor, next as CellCursor)
          ? fp.assign(state, { cursor: next })
          : state
      })
      .addMatcher(updateCellState.match, (state, { payload: event }) => {
        if (event.nextCursor) {
          const next =
            "columnId" in event.nextCursor
              ? { ...event.nextCursor, fieldKey: event.nextCursor.columnId }
              : { ...event.nextCursor }
          return transitionValid(state.cursor, next as CellCursor)
            ? fp.assign(state, { nextCursorFromDatasheet: next })
            : state
        }
        if (["saveSuccess", "noop"].includes(event.type) && state.nextCursorFromDatasheet) {
          return fp.assign(state, {
            cursor: state.nextCursorFromDatasheet,
            nextCursorFromDatasheet: null,
          })
        }
        if (["saveSuccess", "noop", "cancelFollowUp"].includes(event.type)) {
          return fp.assign(state, {
            cursor: { ...state.cursor, state: CursorState.OnEditable },
            nextCursorFromDatasheet: null,
          })
        }
        return state
      }),
})

export const {
  addFilter,
  removeFilter,
  setFields,
  setGroupEntities,
  setGroupFieldKeys,
  setMergeAvatarAndName,
  setSkeletonState,
  toggleOrderBy,
  transitionCursor,
  updateManySkeletonRows,
  setFollowUpModal,
  cursorInputUnmountedWhileWriting,
  setShowColumnStats,
} = GridSlice.actions
export { GridSlice, GroupByAction, groupSelectors, skeletonSelectors, ToggleOrderByAction }
