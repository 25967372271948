import React from "react"
import { useTranslation } from "react-i18next"

import { GetPanelSuccessionPlanQuery, Person } from "types/graphql"
import { positionTitleDisplay } from "v2/react/components/succession/SuccessionPlanPanel/utils/positionMappers"
import { PaginationCircles } from "v2/react/shared/buttons/PaginationCircles"
import { Avatar } from "v2/react/shared/icons/Avatar"
import { BlueIcon } from "v2/react/shared/icons/BlueIcon"

interface FilledByHeaderProps {
  handlePagination: (newIndex: number) => void
  people: Person[]
  position: NonNullable<GetPanelSuccessionPlanQuery["position"]>
  visiblePerson: Person | null
}

function FilledByHeader({
  handlePagination,
  people,
  position,
  visiblePerson,
}: FilledByHeaderProps) {
  const { t } = useTranslation()

  return (
    <div className="grid-rows-[auto_1fr] items-center bg-primary-3 grid">
      <p className="w-full border-b-neutral-8 p-1 text-center text-neutral-64">
        {t("v2.succession_plan_panel.compare.currently_filled_by")}
      </p>
      {visiblePerson ? (
        <div className="p-3 text-center">
          <Avatar person={visiblePerson} size="lg" />
          <div className="pt-2 text-center">
            <p>{visiblePerson.name}</p>
            <p className="text-sm text-neutral-64">{positionTitleDisplay(position, t)}</p>
          </div>
          {people.length > 1 && (
            <div className="pt-2">
              <PaginationCircles itemCount={people.length} handlePagination={handlePagination} />
            </div>
          )}
        </div>
      ) : (
        <div className="p-3 text-center">
          <BlueIcon classNames="mx-auto mb-[5px]" icon={["far", "chair-office"]} size="lg" />
          <div className="mt-3 text-center">
            <p className="mb-1 text-sm text-neutral-64">{positionTitleDisplay(position, t)}</p>
            <div className="badge--yellow badge--sm mx-auto">
              {t("v2.succession_plan_panel.compare.open_position")}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export { FilledByHeader }
