import { autoUpdate, computePosition, offset } from "@floating-ui/react"

/**
 * Builds a tooltip handler that creates a tooltip on the fly and positions it
 * relative to a trigger element.
 *
 * Example usage:
 * ```typescript
 *  const handleTooltip = tooltipHandler(".trigger", "This is a tooltip")
 *  document.addEventListener("mouseenter", handleTooltip, true)
 * ```
 */
const tooltipHandler = (triggerSelector: string, tooltipText: string) => (event: MouseEvent) => {
  const target = event.target
  if (!(target instanceof Element)) return
  const trigger = target.closest(triggerSelector)
  if (!trigger) return

  // If tooltip is already open, don't create a new one
  if (trigger.hasAttribute("data-tooltip-open")) return
  trigger.setAttribute("data-tooltip-open", "true")

  const tooltip = document.createElement("div")
  tooltip.className =
    "react-tooltip-content react-tooltip-content--sm visible opacity-0 transition-opacity duration-200"
  tooltip.id = makeTooltipId(triggerSelector)
  tooltip.innerText = tooltipText
  document.body.appendChild(tooltip)

  const cleanup = autoUpdate(trigger, tooltip, () => {
    computePosition(trigger, tooltip, {
      placement: "bottom-start",
      middleware: [offset(4)],
    }).then(({ x, y }) => {
      Object.assign(tooltip.style, {
        left: `${x}px`,
        top: `${y}px`,
        position: "absolute",
        zIndex: 15,
      })

      requestAnimationFrame(() => {
        tooltip.classList.add("opacity-100")
      })
    })
  })

  trigger.addEventListener(
    "mouseleave",
    () => {
      cleanup()
      tooltip.remove()
      trigger.removeAttribute("data-tooltip-open")
    },
    { once: true },
  )
}

const removeTooltip = (trigger: Element, triggerSelector: string) => {
  const id = makeTooltipId(triggerSelector)
  const tooltip = document.getElementById(id)
  if (!tooltip) return
  tooltip.remove()
  trigger.removeAttribute("data-tooltip-open")
}

const makeTooltipId = (triggerSelector: string) => `${triggerSelector}-tooltip`

export { tooltipHandler, removeTooltip }
