import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import React from "react"

export type BannerType = "critical" | "info" | "neutral" | "caution" | "success"

export type Message = {
  text: string | React.ReactNode
  subText?: string | React.ReactNode
  icon?: React.ReactNode
}

type AlertBannerProps = {
  messages: Message[]
  type: BannerType
  onClose?: () => void
  className?: string
}

export function AlertBanner({ messages, type, onClose, className }: AlertBannerProps) {
  return (
    <div className={classNames(`alert alert-${type} flex`, className)}>
      <div className="flex-1 gap-1 grid">
        {messages.map((message, index) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={`banner-message-${index}`}
            className={classNames("items-center gap-x-2 grid", gridCols(message))}
          >
            <Text message={message} />
            <SubText message={message} />
          </div>
        ))}
      </div>
      {onClose ? (
        <div className="flex-col flex">
          <span>
            <button className="ml-auto bg-transparent" onClick={onClose} type="button">
              <FontAwesomeIcon icon={["far", "times"]} className="!mr-0" />
            </button>
          </span>
        </div>
      ) : null}
    </div>
  )
}

function gridCols(message: Message) {
  if (message.icon) return "grid-cols-[min-content_1fr]"
  return "grid-cols-1"
}

function Text({ message }: { message: Message }) {
  return (
    <>
      <div className="mt-[0.08rem] self-start">{message.icon}</div>
      {typeof message.text === "string" ? (
        <p className="font-bold">{message.text}</p>
      ) : (
        message.text
      )}
    </>
  )
}

function SubText({ message }: { message: Message }) {
  if (!message.subText) return null

  return (
    <>
      {message.icon ? <div /> : null}
      {typeof message.subText === "string" ? (
        <p className="font-normal">{message.subText}</p>
      ) : (
        message.subText
      )}
    </>
  )
}
