import React, { useMemo } from "react"

import { AllowedAttribute } from "types/graphql"
import { usePositionControlOptions } from "v2/react/components/jobRequisitions/RequisitionForm/hooks/usePositionControlOptions"
import { ModalForm } from "v2/react/components/jobRequisitions/RequisitionForm/ModalForm"
import { PositionFieldValuesDiffProvider } from "v2/react/components/positions/positionFieldValuesDiff"
import { JsonFormControlsOptions } from "v2/react/shared/jsonForms/JsonFormControlsOptions"
import { useModalOverlayRef } from "v2/react/shared/overlay/Modal"
import { useHeadcountPlansGetInitialJobRequisitionDataQuery } from "v2/redux/GraphqlApi/HeadcountPlanningApi"
import { useCurrentCompanyPositionFormDataQuery } from "v2/redux/GraphqlApi/PositionsApi"

import { HeadcountPlanDatasheetRow } from "./HeadcountPlanDatasheet/types"
import { useRequisitionDataForDiffValues } from "./hooks/useRequisitionDataForDiffValues"
import { useWatchKeysFromAllowedAttributesForPositionDiff } from "./hooks/useWatchKeysFromAllowedAttributesForPositionDiff"

type AddJobRequisitionFromRowModalProps = {
  allowedAttributes: AllowedAttribute[]
  headcountPlanId: string
  headcountPlanParticipantId: string | undefined
  headcountPlanParticipantPositionId: string | undefined
  isOpen?: boolean
  onClose: () => void
  row: HeadcountPlanDatasheetRow
}

function AddJobRequisitionFromRowModal({
  allowedAttributes,
  headcountPlanId,
  headcountPlanParticipantId,
  headcountPlanParticipantPositionId,
  isOpen,
  onClose,
  row,
}: AddJobRequisitionFromRowModalProps) {
  const { modalRef, scrollToTop } = useModalOverlayRef()

  const { data: initialJobRequisition } = useHeadcountPlansGetInitialJobRequisitionDataQuery(
    {
      rootEventId: row.rootEventId ?? "",
      headcountPlanId,
    },
    { skip: !isOpen },
  )
  const jobRequisition =
    initialJobRequisition?.headcountPlan?.initialJobRequisitionFromHeadcountChangeProjection
  const jsonForm = jobRequisition?.jsonFormValues
  const finalJsonForm = useMemo(
    () => ({
      ...jsonForm,
      schema: { ...jsonForm },
      options: { schema_type: jsonForm?.data?.schemaType },
    }),
    [jsonForm],
  )

  const { data: collectionData } = useCurrentCompanyPositionFormDataQuery({}, { skip: !isOpen })
  const collections = collectionData?.positionFormCollections

  const watchFieldNames = useWatchKeysFromAllowedAttributesForPositionDiff({ allowedAttributes })

  const { currentFieldValues, initialFieldValues, updateCurrentFieldValues } =
    useRequisitionDataForDiffValues({
      initial: jsonForm?.data,
      projectionAttributes: row.positionAttributesWithEdits,
    })

  const positionControlOptions = usePositionControlOptions({
    fromHeadcountPlan: true,
    headcountPlanRootPositionIdForDatasheet: headcountPlanParticipantPositionId,
  })

  if (!jsonForm || !jobRequisition) return null

  return (
    <JsonFormControlsOptions positionControl={positionControlOptions}>
      <PositionFieldValuesDiffProvider
        basis={initialFieldValues}
        current={currentFieldValues}
        positionFormCollections={collections}
        watchFieldNames={watchFieldNames}
      >
        <ModalForm
          closeModal={onClose}
          edit={false}
          revisionNumber={row.revisionNumber}
          rootEventId={row.rootEventId}
          headcountPlanId={headcountPlanId}
          headcountPlanParticipantId={headcountPlanParticipantId}
          initialData={jobRequisition.jsonFormValues.data}
          isOpen={isOpen}
          jobRequisition={jobRequisition}
          jsonForm={finalJsonForm}
          modalOverlayRef={modalRef}
          onDataChange={updateCurrentFieldValues}
          scrollToTop={scrollToTop}
        />
      </PositionFieldValuesDiffProvider>
    </JsonFormControlsOptions>
  )
}

export { AddJobRequisitionFromRowModal }
