import { useMemo } from "react"

import { SourcePay } from "types/graphql.enums"
import { safeNumber } from "v2/react/utils/safeNumber"

import { usePositionFormContext } from "../PositionFormProvider"
import { FieldValues } from "../types"
import { useBudgetedBasePayTotal } from "./useBudgetedBasePayTotal"
import { usePositionWatchField } from "./useConvenientReactHookFormHooks"

type VariablePayAttributes = NonNullable<FieldValues["position"]["variablePaysAttributes"]>[0]

type BudgetedVariablePayArg = {
  budgetedBasePayTotal: number
  variablePay: VariablePayAttributes
}

type CalculateBudgetedVariablePayArg = {
  budgetedBasePayTotal: number
  variablePay: Pick<VariablePayAttributes, "amount" | "payType">
}

const DEFAULT_VARIABLE_PAYS: VariablePayAttributes[] = []

function useBudgetedVariablePays() {
  const {
    formState: { defaultValues },
  } = usePositionFormContext()
  const initialVariablePays = (defaultValues?.position?.variablePaysAttributes ??
    DEFAULT_VARIABLE_PAYS) as VariablePayAttributes[]

  const { current: budgetedBasePayTotal, initial: initialTotal } = useBudgetedBasePayTotal()
  const variablePays =
    usePositionWatchField({ name: "position.variablePaysAttributes" }) ?? DEFAULT_VARIABLE_PAYS

  return useMemo(
    () => ({
      initial: initialVariablePays.map((variablePay) =>
        getBudgetedVariablePayHash({ budgetedBasePayTotal: initialTotal, variablePay }),
      ),
      current: variablePays.map((variablePay) =>
        getBudgetedVariablePayHash({ budgetedBasePayTotal, variablePay }),
      ),
    }),
    [budgetedBasePayTotal, initialTotal, initialVariablePays, variablePays],
  )
}

const getBudgetedVariablePayHash = (arg: BudgetedVariablePayArg) => ({
  variablePayTypeId: arg.variablePay.variablePayType.id,
  variablePayPayType: arg.variablePay.payType ?? SourcePay.Amount,
  variablePayAmount: safeNumber(arg.variablePay.amount),
  hadVariablePayAmount: safeNumber(arg.variablePay.amount, { fallback: null }) !== null,
  calculatedVariablePayAmount: calculateBudgetedVariablePay(arg),
})

const calculateBudgetedVariablePay = (arg: CalculateBudgetedVariablePayArg) =>
  arg.variablePay.payType === SourcePay.Percent
    ? (safeNumber(arg.variablePay.amount) / 100) * arg.budgetedBasePayTotal
    : safeNumber(arg.variablePay.amount)

export { calculateBudgetedVariablePay, getBudgetedVariablePayHash, useBudgetedVariablePays }
