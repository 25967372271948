import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import { useSelectList } from "v2/react/hooks/useSelectList"
import { DropdownMenu } from "v2/react/shared/collection/menus/DropdownMenu"
import { MenuOption } from "v2/react/shared/forms/CheckboxMenu/types"
import { getButtonText } from "v2/react/shared/forms/CheckboxMenu/utils"

type CheckboxMenuProps = {
  fieldLabel: string
  fieldId: string
  options: MenuOption[]
  onSelect: (optionId: string) => void
}

function CheckboxMenu({ fieldLabel, fieldId, options, onSelect }: CheckboxMenuProps) {
  const { t } = useTranslation()
  const [showList, setShowList] = React.useState(false)
  const { context, floatingStyles, getFloatingProps, refs } = useSelectList({
    showList,
    setShowList,
    floatOverrides: { placement: "bottom-start" },
    offset: 8,
  })

  const handleButtonKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === "Tab" && showList) {
      setShowList(false)
    }
  }

  const selectedOptions = options.filter((option) => option.selected)
  const optionCount = options.length
  const buttonText = getButtonText(optionCount, selectedOptions, t)
  const isPlaceholder = optionCount <= 0 || selectedOptions.length === 0

  return (
    <div className="input-group !mb-6" data-testid="checkbox-menu" id={`${fieldId}-checkbox-menu`}>
      <div className="mb-2 items-center justify-between flex">
        <span className="text-base-bold">{fieldLabel}</span>
      </div>
      <div className="dropdown--react">
        <div
          className={cn("select--responsive", { "bg-neutral-3 text-neutral-64": !options.length })}
        >
          <button
            type="button"
            onClick={() => setShowList((s) => !s)}
            ref={refs.setReference}
            onKeyDown={handleButtonKeyDown}
            className={cn("dropdown-link  overflow-hidden text-ellipsis whitespace-nowrap", {
              "btn-disabled": !options.length,
              "hover:border--main-hover": options.length,
            })}
            disabled={!options.length}
          >
            <span className={cn(isPlaceholder ? "text-neutral-64" : "text-neutral-100")}>
              {buttonText}
            </span>
          </button>
        </div>
        <DropdownMenu
          context={context}
          showList={showList}
          floatingRef={refs.setFloating}
          floatingStyles={floatingStyles}
          floatingProps={getFloatingProps}
          wrapperClasses="dropdown-menu overflow-y-auto !block !w-inherit !max-w-[16rem] right-0 p-2 z-10 !m-0"
        >
          {options.map((option) => (
            <button
              className="dropdown-menu-link !m-0 cursor-pointer gap-1.5 p-[10px] pl-1 flex"
              key={option.id}
              onClick={() => onSelect(option.id)}
              type="button"
            >
              <input
                type="checkbox"
                id={`${fieldId}-${option.id}-checkbox`}
                checked={option.selected}
                className="!mr-0 mt-0.5 h-4 w-4"
                readOnly
              />
              <span className="side-label w-inherit hyphens-auto break-words break-all text-base">
                {option.label}
              </span>
            </button>
          ))}
        </DropdownMenu>
      </div>
    </div>
  )
}

export { CheckboxMenu }
