import { parseDate } from "@internationalized/date"
import { createColumnHelper } from "@tanstack/react-table"
import dayjs from "dayjs"
import React, { useState } from "react"
import type { DateRange as DateRangeType } from "react-aria"
import { useTranslation } from "react-i18next"

import { AdminChangeLogRow } from "types/graphql"
import { SortDirection } from "types/graphql.enums"
import { useCsvDownloadListener } from "v2/react/components/headcountPlanning/TableDatasheet/hooks/useCsvDownloadListener"
import { useServerTable, useTableState } from "v2/react/hooks/useServerTable"
import { ExportButton } from "v2/react/shared/buttons/ExportButton"
import { DateRange } from "v2/react/shared/forms/DateInputs/DateRange/DateRange"
import { DateRangeOption } from "v2/react/shared/forms/DateInputs/utils/dates"
import { Table } from "v2/react/shared/tables/Table/Table"
import { useAdminChangeLogQuery } from "v2/redux/GraphqlApi/AdminApi"

import RootProvider from "../../RootProvider"

const selectOptions: DateRangeOption[] = ["last_7_days", "last_30_days"]

function WithProvider() {
  const { t } = useTranslation()
  const [csvDownloadRef, setCsvDownloadRef] = useState<HTMLButtonElement | null>(null)

  const initialStartDate = parseDate(dayjs().subtract(7, "days").format("YYYY-MM-DD"))
  const initialEndDate = parseDate(dayjs().format("YYYY-MM-DD"))
  const [activeDateRange, setActiveDateRange] = useState<DateRangeType>({
    start: initialStartDate,
    end: initialEndDate,
  })
  const { sorting, pagination, setSorting, setPagination } = useTableState()

  const { data, isLoading } = useAdminChangeLogQuery({
    startDate: activeDateRange.start?.toString(),
    endDate: activeDateRange.end?.toString(),
    // #paginate in rails is 1-based, but the table is 0-based
    page: pagination.pageIndex + 1,
    sortKey: sorting[0]?.id,
    sortDirection: sorting[0]?.desc ? SortDirection.Desc : SortDirection.Asc,
  })

  const rows = data?.adminReports?.adminChangeLog?.rows || []
  const columnHelper = createColumnHelper<AdminChangeLogRow>()
  const columns = [
    columnHelper.accessor("companyId", {
      header: t("admin.reports.admin_change_log.company_id"),
    }),
    columnHelper.accessor("companyName", {
      header: t("admin.reports.admin_change_log.company_name"),
    }),
    columnHelper.accessor("subscription", {
      header: t("admin.reports.admin_change_log.subscription"),
    }),
    columnHelper.accessor("name", {
      header: t("admin.reports.admin_change_log.name"),
    }),
    columnHelper.accessor("workEmail", {
      header: t("admin.reports.admin_change_log.work_email"),
    }),
    columnHelper.accessor((row) => t(`admin.reports.admin_change_log.change_${row.event}`), {
      id: "event",
      header: t("admin.reports.admin_change_log.change"),
    }),
    columnHelper.accessor((row) => t(`v2.defaults.user_roles.${row.roleName}`), {
      id: "role_name",
      header: t("admin.reports.admin_change_log.role"),
    }),
    columnHelper.accessor((row) => dayjs(row.createdAt).format("MM-DD-YYYY"), {
      id: "created_at",
      header: t("admin.reports.admin_change_log.changed_on"),
    }),
  ]
  const table = useServerTable({
    columns,
    data: rows,
    sorting,
    pagination,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    totalRows: data?.adminReports?.adminChangeLog?.totalEntries || 0,
  })

  useCsvDownloadListener({ current: csvDownloadRef }, table, "admin_change_log_report")

  return (
    <>
      <h1>{t("admin.reports.admin_change_log.title")}</h1>
      <p>{t("admin.reports.admin_change_log.description")}</p>
      <div className="mb-2 place-content-between items-end flex">
        <DateRange
          id="report-date-range"
          quickSelectOptions={selectOptions}
          label={t("admin.reports.admin_change_log.date_range")}
          showTimeSpanSelection
          value={activeDateRange}
          onChange={setActiveDateRange}
        />
        <ExportButton
          setRef={(element) => setCsvDownloadRef(element)}
          disabled={rows?.length === 0}
        />
      </div>
      <Table table={table} isLoading={isLoading} />
    </>
  )
}

const AdminChangeLog = () => (
  <RootProvider>
    <WithProvider />
  </RootProvider>
)

export default AdminChangeLog
