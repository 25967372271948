import React, { createContext, ReactNode, useContext, useMemo, useState } from "react"

import { Error as GraphQLError } from "types/graphql"

type OverlappingPlan = { id: string; name: string }

interface PlanActiveContextType {
  followUpModalOpen: boolean
  overlappingActivePlans: OverlappingPlan[]
  pendingActivePlanId: string | null
  errors: GraphQLError[]
  openModal: (overlappingPlans: OverlappingPlan[], pendingId: string) => void
  closeModal: () => void
  handleSetErrors: (errors: GraphQLError[]) => void
  handleClearErrors: () => void
}

const PlanActiveContext = createContext<PlanActiveContextType | undefined>(undefined)

export const PlanActiveContextProvider = ({ children }: { children: ReactNode }) => {
  const [followUpModalOpen, setFollowUpModalOpen] = useState(false)
  const [overlappingActivePlans, setOverlappingActivePlans] = useState<OverlappingPlan[]>([])
  const [pendingActivePlanId, setPendingActivePlanId] = useState<string | null>(null)
  const [errors, setErrors] = useState<GraphQLError[]>([])

  const openModal = (overlappingPlans: OverlappingPlan[], pendingId: string) => {
    setOverlappingActivePlans(overlappingPlans)
    setPendingActivePlanId(pendingId)
    setFollowUpModalOpen(true)
  }
  const closeModal = () => {
    setOverlappingActivePlans([])
    setPendingActivePlanId("")
    setFollowUpModalOpen(false)
  }
  const handleSetErrors = (errors: GraphQLError[]) => setErrors(errors)
  const handleClearErrors = () => setErrors([])

  const contextValue = useMemo(
    () => ({
      followUpModalOpen,
      overlappingActivePlans,
      pendingActivePlanId,
      errors,
      openModal,
      closeModal,
      handleSetErrors,
      handleClearErrors,
    }),
    [followUpModalOpen, overlappingActivePlans, pendingActivePlanId, errors],
  )

  return <PlanActiveContext.Provider value={contextValue}>{children}</PlanActiveContext.Provider>
}

export const usePlanActiveContext = () => {
  const context = useContext(PlanActiveContext)
  if (!context) {
    throw new Error("usePlanActiveContext must be used within a PlanActiveContextProvider")
  }
  return context
}
