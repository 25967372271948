import { skipToken } from "@reduxjs/toolkit/dist/query"
import { useEffect, useMemo } from "react"

import { FilterableTables } from "types/graphql.enums"
import { extractAppliedInclusionFiltersFromParams } from "v2/react/shared/tables/TableUtilities/FilterTable/utils/filters"
import {
  useGetFilterSettingsQuery,
  useUpdateSelectedFiltersMutation,
} from "v2/redux/GraphqlApi/TablesApi"
import { startListeningForFilterSubmission } from "v2/redux/listeners/tableFiltersListeners"
import {
  selectFilterSettings,
  selectTable,
} from "v2/redux/slices/TableFilterSlice/tableFiltersSelectors"
import { setTable } from "v2/redux/slices/TableFiltersSlice"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

interface UseFilterPanelProps {
  table: FilterableTables
  /**
   * If true, mounts a listener that will update the url and initiate a pjax
   * submission when new filters are applied.
   */
  mountListener?: boolean
}

/**
 * This hook is used to manage state for filtering tables. It accomplishes the
 * following:
 * - Fetches the filter settings for the provided table.
 * - Provides a mutation for updating the selected filters for the provided
 *   table.
 * - Optionally mounts a listener that will update the url and initiate a pjax
 *   submission when new filters are applied (see:
 *   `startListeningForFilterSubmission`).
 * - Sets the provided table in the `TableFiltersSlice`. This is used down the
 *   line to fetch additional filter data when new filters are selected.
 * - Returns data loading and error information.
 *
 * This will typically be used in conjunction with a few other hooks to manage
 * the full filtering experience:
 * - `useFilterPanelState` to manage the state of the filter panel (selecting,
 *   removing, and clearing filters).
 * - `useFilterBadges` to manage the state of the filter badges.
 */
const useFilters = ({ table, mountListener = false }: UseFilterPanelProps) => {
  const [updateFilters, updateFiltersState] = useUpdateSelectedFiltersMutation()

  const dispatch = useAppDispatch()

  const selectedFilterValues = useMemo(() => extractAppliedInclusionFiltersFromParams(), [])

  const currentTable = useAppSelector(selectTable)
  if (currentTable !== table) {
    dispatch(setTable(table))
  }

  const { isLoading, isError } = useGetFilterSettingsQuery(
    currentTable === table
      ? {
          table,
          selectedFilterValues,
        }
      : skipToken,
  )

  useEffect(() => {
    const listener = mountListener ? startListeningForFilterSubmission() : null

    return () => {
      listener?.()
    }
  }, [mountListener])

  const filterSettings = useAppSelector(selectFilterSettings)
  const anyErrors = isError || updateFiltersState.isError
  const dataLoaded = !isLoading && !anyErrors && !!filterSettings

  return {
    updateFilters,
    updateFiltersState,
    dataLoaded,
    anyErrors,
  }
}

export { useFilters }
