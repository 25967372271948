import { TFunction } from "i18next"

export function truncateValue(value: string, maxTextLength: number) {
  if (value.length <= maxTextLength) return value

  return `${value.slice(0, maxTextLength - 3).trim()}...`
}

export function measureText(text: string, font = "700 13px Satoshi, sans-serif") {
  const canvas = document.createElement("canvas")
  const context = canvas.getContext("2d")
  if (!context) return null
  context.font = font
  return context.measureText(text)
}

export const joinStrings = (items: string[], t: TFunction) => {
  if (items.length <= 1) return items[0]
  return `${items.slice(0, -1).join(", ")} ${t("v2.defaults.and")} ${items[items.length - 1]}`
}
