import { NodeInterface } from "types/graphql"
import { formatCurrency, parseCurrency } from "v2/react/utils/currency"

enum Statistic {
  total,
  max,
  min,
  average,
  median,
}

export type Statistics = {
  total?: number
  max?: number
  min?: number
  average?: number
  median?: number
}

const AllStatistics = [
  Statistic.total,
  Statistic.max,
  Statistic.min,
  Statistic.average,
  Statistic.median,
]

export const ColumnStatisticInfo: Record<string, Statistic[]> = {
  fte: [Statistic.total],
  open_for: [Statistic.min, Statistic.max, Statistic.average],
  base_pay: AllStatistics,
  variablePay: AllStatistics,
  customFields: AllStatistics,
  position_base_pay: AllStatistics,
  employment_tenure: [Statistic.max, Statistic.min, Statistic.average],
  total_variable_pay: AllStatistics,
  total_budgeted_variable_pay: AllStatistics,
  total_subordinates: [Statistic.max, Statistic.min, Statistic.average],
  total_direct_reports: [Statistic.max, Statistic.min, Statistic.average],
  total_annual_compensation: AllStatistics,
  total_budgeted_compensation: AllStatistics,
}

const CurrencyColumns = [
  "base_pay",
  "variablePay",
  "position_base_pay",
  "total_variable_pay",
  "total_budgeted_variable_pay",
  "total_annual_compensation",
  "total_budgeted_compensation",
]

export function formatStatistic(value: number | undefined, fieldKey: string, format: string) {
  if (value === undefined) return "N/A"
  if (fieldKey === "employment_tenure") return `${value} years`
  if (
    format === "currency" ||
    CurrencyColumns.includes(fieldKey) ||
    fieldKey.match(/variable_pay_type_/) ||
    fieldKey.match(/custom_field_/)
  )
    return formatCurrency({ value, omitSymbol: false })
  return value
}

export function statisticsRequired(fieldKey: string) {
  if (fieldKey.match(/custom_field_/)) return ColumnStatisticInfo.customFields
  if (fieldKey.match(/variable_pay_type_\d$/)) return ColumnStatisticInfo.variablePay
  return ColumnStatisticInfo[fieldKey]
}

const isCfvKey = (key: unknown): key is keyof NodeInterface["custom_field_values"] =>
  Boolean(typeof key === "string" && key.match(/custom_field_/))

const isVpKey = (key: unknown): key is keyof NodeInterface["variable_pays"] =>
  Boolean(typeof key === "string" && key.match(/variable_pay_type_/))

function rowData(row: NodeInterface, fieldKey: keyof NodeInterface) {
  if (isCfvKey(fieldKey))
    // @ts-ignore
    return row.custom_field_values_original.find((r) => r.field_id === fieldKey)?.reporting_amount
  if (isVpKey(fieldKey))
    // @ts-ignore
    return row.variable_pays_original.find((r) => r.field_id === fieldKey)?.reporting_amount
  return row[fieldKey]
}

function isEmptyValue(value: unknown) {
  return value === null || value === undefined || value === ""
}

function roundToTwoDecimals(value: number) {
  return Number(value.toFixed(2))
}

export function calculateStatistics(data: NodeInterface[], fieldKey: keyof NodeInterface) {
  const toCalculate = statisticsRequired(fieldKey)
  if (!toCalculate) return {}

  let realFieldKey = fieldKey
  if (["base_pay", "total_annual_compensation", "position_base_pay"].includes(fieldKey)) {
    realFieldKey = `${fieldKey}_reporting` as unknown as keyof NodeInterface
  }

  const amounts = data
    .map((row) => {
      const value = rowData(row, realFieldKey)
      if (isEmptyValue(value)) return null
      const notation = typeof value === "string" && value.includes("K") ? "compact" : "standard"
      return parseCurrency(value, { notation })
    })
    .filter((amount) => amount !== null) as number[]

  const calculators: Record<string, () => number> = {
    total: () =>
      roundToTwoDecimals(amounts.reduce((total: number, amount: number) => total + amount, 0)),
    max: () => amounts.reduce((total: number, amount: number) => Math.max(total, amount), 0),
    min: () =>
      amounts.reduce((total: number, amount: number) => Math.min(total, amount), amounts[0]),
    average: () =>
      roundToTwoDecimals(
        amounts.reduce((total: number, amount: number) => total + amount, 0) / amounts.length,
      ),
    median: () => {
      const sorted = [...amounts].sort((a, b) => a - b)
      if (sorted.length % 2 === 1) return sorted[(sorted.length - 1) / 2]
      return roundToTwoDecimals((sorted[sorted.length / 2 - 1] + sorted[sorted.length / 2]) / 2)
    },
  }

  return toCalculate.reduce((result: Record<string, number | undefined>, stat) => {
    const res = result
    let alternate
    if (
      stat === Statistic.min &&
      (fieldKey === "employment_tenure" ||
        fieldKey === "total_direct_reports" ||
        fieldKey === "total_subordinates")
    ) {
      alternate = 0
    }
    res[Statistic[stat]] = calculators[Statistic[stat]]() || alternate
    return res
  }, {})
}

export function calculateAllStatistics(data: NodeInterface[]) {
  const firstEntry = data[0]
  const baseKeys = Object.keys(firstEntry).filter((key) => statisticsRequired(key))
  const variablePayKeys = Object.keys(firstEntry.variable_pays || {})
  const customFieldKeys = Object.keys(firstEntry.custom_field_values || {})

  const fieldKeys = [...baseKeys, ...variablePayKeys, ...customFieldKeys]

  const result: Record<string, Record<string, number | undefined>> = {}
  fieldKeys.forEach((fieldKey) => {
    result[fieldKey] = calculateStatistics(data, fieldKey as keyof NodeInterface)
  })
  return result
}
