import React from "react"

interface BudgetCellProps {
  budget?: string | null
  proposal?: string | null
  showBudget: boolean
  planApproved: boolean
}

/**
 * Shows the budget that's set for the planning environment. In the case that
 * the plan is approved, we show the proposed amount instead, as those are the
 * final approved numbers.
 */
const BudgetCell = ({ budget, proposal, showBudget, planApproved }: BudgetCellProps) => {
  if (!showBudget) return null

  const cellValue = planApproved ? proposal : budget

  return (
    <td className="hcp-overview-table__budgeting-cell">
      <div className="h-14 place-content-center px-4">{cellValue}</div>
    </td>
  )
}

export { BudgetCell }
