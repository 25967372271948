import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useTranslation } from "react-i18next"
import { useLocalStorage } from "usehooks-ts"

import {
  ListDiffEntries,
  RenderWhenDiffEntriesNonEmpty,
  WatchFieldName,
} from "v2/react/components/positions/positionFieldValuesDiff"
import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"
import { Notice } from "v2/react/shared/status/Notice"
import { isApprovalDone } from "v2/react/shared/status/statusUtils"

import { useJobRequisitionOverview } from "./OverviewProvider"

type DiffFromHeadcountPlanPositionDataProps = {
  mode: "alert" | "tooltip"
}

// We show individual entries for the aggregated budgeted base comp.
const IGNORE_FIELD_NAMES: WatchFieldName[] = ["position.budgetedBaseCompensation"]

function DiffFromHeadcountPlanPositionData({ mode }: DiffFromHeadcountPlanPositionDataProps) {
  const { jobRequisition } = useJobRequisitionOverview()
  const { t } = useTranslation()
  const [isHidden, setIsHidden] = useLocalStorage<boolean>(
    `${jobRequisition?.id}-${jobRequisition?.updatedAt}`,
    false,
  )

  // The tooltip or alert banner should not show once the job req is done with
  // the approval process.
  if (isApprovalDone(jobRequisition?.approvalState)) {
    return null
  }

  if (mode === "tooltip") {
    return (
      <RenderWhenDiffEntriesNonEmpty ignore={IGNORE_FIELD_NAMES}>
        <Tooltip>
          <TooltipTrigger>
            <button className="btn--ghost" type="button">
              <FontAwesomeIcon
                icon={["far", "exclamation-triangle"]}
                className="fa-w-18 text-2xl text-status-caution"
              />
            </button>
          </TooltipTrigger>
          <TooltipContent className="react-tooltip-content max-w-[308px]">
            <div className="flex-col flex">
              <div>
                <strong className="text-neutral-3-solid">
                  {t("v2.headcount_plan.add_position_from_row_modal.form_diff_alert_into")}
                </strong>
              </div>
              <ListDiffEntries className="text-neutral-8-solid" ignore={IGNORE_FIELD_NAMES} />
            </div>
          </TooltipContent>
        </Tooltip>
      </RenderWhenDiffEntriesNonEmpty>
    )
  }

  // We let the tooltip render when `isHidden` = true, but not the alert.
  if (isHidden) return null

  return (
    <RenderWhenDiffEntriesNonEmpty ignore={IGNORE_FIELD_NAMES}>
      <Notice wrapperClass="mb-8" type="caution" icon={["far", "exclamation-triangle"]}>
        <div className="flex-1 flex-col flex">
          <div>
            <strong className="text-neutral-100">
              {t("v2.headcount_plan.add_position_from_row_modal.form_diff_alert_into")}
            </strong>
          </div>
          <ListDiffEntries ignore={IGNORE_FIELD_NAMES} />
        </div>
        <div className="flex-col">
          <button className="btn--ghost -m-2 p-2" onClick={() => setIsHidden(true)} type="button">
            <FontAwesomeIcon icon={["far", "times"]} className="!text-neutral-80" />
          </button>
        </div>
      </Notice>
    </RenderWhenDiffEntriesNonEmpty>
  )
}

export { DiffFromHeadcountPlanPositionData }
