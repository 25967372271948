import React, { Fragment } from "react"
import { useTranslation } from "react-i18next"

import { HeadcountPlan, HeadcountPlanParticipant } from "types/graphql.d"

import { BudgetTableRow } from "./BudgetTableRow"
import { EntireOrgRow } from "./EntireOrgRow"

interface Props {
  headcountPlan: HeadcountPlan
  logoThumbUrl: string
  companyName: string
  participants: HeadcountPlanParticipant[]
  erroredRows: string[]
}

function BudgetTable({
  headcountPlan,
  logoThumbUrl,
  companyName,
  participants,
  erroredRows,
}: Props) {
  const { t } = useTranslation()

  return (
    <div className="table hcp-overview-table relative sm:overflow-x-visible">
      <table className="table-datasheet">
        <thead id="table-datasheet-thead">
          <tr>
            <th>
              <div className="place-content-center px-4">
                {t("v2.headcount_plan.set_budgets.planning_environments")}
              </div>
            </th>
            <th>
              <div className="place-content-center px-4">
                {t("v2.headcount_plan.set_budgets.planner")}
              </div>
            </th>
            <th className="align-right">
              <div className="place-content-center px-4 text-right">
                {t("v2.headcount_plan.set_budgets.budget")}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <EntireOrgRow
            headcountPlan={headcountPlan}
            logoThumbUrl={logoThumbUrl}
            companyName={companyName}
            isErrored={erroredRows.includes(headcountPlan.uniqueKey)}
          />
          {rowsAtLevel(participants, 1, [1], headcountPlan, erroredRows)}
        </tbody>
      </table>
    </div>
  )
}

const rowsAtLevel = (
  rows: HeadcountPlanParticipant[],
  level: number,
  continuationLineLevels: number[],
  headcountPlan: HeadcountPlan,
  erroredRows: string[],
): React.ReactNode[] =>
  rows.map((participant: HeadcountPlanParticipant, index: number) => {
    const isLastSibling = index === rows.length - 1
    const updatedContinuationLineLevels = updateContinuationLineLevels(
      isLastSibling,
      level,
      continuationLineLevels,
    )

    return (
      <Fragment key={`${participant.id}-${participant.role || ""}`}>
        <BudgetTableRow
          continuationLineLevels={updatedContinuationLineLevels}
          headcountPlan={headcountPlan}
          isLastSibling={isLastSibling}
          level={level}
          participant={participant}
          isErrored={erroredRows.includes(participant.uniqueKey)}
        />
        {participant.children &&
          rowsAtLevel(
            participant.children,
            level + 1,
            updatedContinuationLineLevels,
            headcountPlan,
            erroredRows,
          )}
      </Fragment>
    )
  })

// Dictates at which levels there is a continuation line to the next sibling.
// When a node is the last child, end any continuation line by removing the
// level from this lookup array.
const updateContinuationLineLevels = (
  isLastSibling: boolean,
  level: number,
  continuationLineLevels: number[],
) => {
  if (!isLastSibling) return [...continuationLineLevels, level]
  return continuationLineLevels.filter((continuationLevel) => continuationLevel !== level)
}

export { BudgetTable }
