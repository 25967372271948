import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"

import { Error } from "types/graphql.d"
import { HeadcountPlanActiveStatus } from "types/graphql.enums"
import { ArchivedPlans } from "v2/react/components/headcountPlanning/ListPlans/ArchivedPlans"
import {
  PlanActiveContextProvider,
  usePlanActiveContext,
} from "v2/react/components/headcountPlanning/ListPlans/PlanActiveStatusContext"
import { PlanTable } from "v2/react/components/headcountPlanning/ListPlans/PlanTable"
import { useChangeActiveStatus } from "v2/react/components/headcountPlanning/ListPlans/useChangeActiveStatus"
import { Spinner } from "v2/react/shared/loaders/Spinner"
import PageNav from "v2/react/shared/navigation/PageNav"
import { ActionBlock } from "v2/react/shared/navigation/PageNav/ActionBlock"
import { TitleBlockLarge } from "v2/react/shared/navigation/PageNav/TitleBlock"
import { Modal, ModalFooter } from "v2/react/shared/overlay/Modal"
import { useGetListHeadcountPlansPageQuery } from "v2/redux/GraphqlApi/HeadcountPlanningApi"

import RootProvider from "../RootProvider"
import { CreatePlanButton } from "./ListPlans/CreatePlanButton"

function Internal() {
  const { t } = useTranslation()
  const { closeModal, errors, followUpModalOpen, overlappingActivePlans, pendingActivePlanId } =
    usePlanActiveContext()
  const { changeActiveStatus, isLoading } = useChangeActiveStatus()

  const listPlansPage = useGetListHeadcountPlansPageQuery({})
  const currentPerson = listPlansPage.data?.currentPerson
  const activeHeadcountPlans = listPlansPage.data?.currentCompany?.activeHeadcountPlans
  const additionalHeadcountPlans = listPlansPage.data?.currentCompany?.additionalHeadcountPlans
  const archivedHeadcountPlans = listPlansPage.data?.currentCompany?.archivedHeadcountPlans

  const showMenuColumn = useMemo(
    () =>
      currentPerson?.isCompanyManagerOrHrAdmin ||
      [activeHeadcountPlans, additionalHeadcountPlans, archivedHeadcountPlans].some(
        (a) => a?.some((p) => p.currentUserCanManage),
      ),
    [currentPerson, activeHeadcountPlans, additionalHeadcountPlans, archivedHeadcountPlans],
  )

  if (listPlansPage.isLoading || !currentPerson) return <Spinner />

  const activePlansTitle = (
    <div className="items-center gap-1 flex">
      <p className="text-base-bold">{t("v2.headcount_plan.lists.active")}</p>
      <p className="tooltip btn--icon btn--ghost">
        <FontAwesomeIcon className="icon-14" icon={["far", "info-circle"]} />
        <span className="tooltip-content text-wrap-auto max-w-[14rem]">
          {t("v2.headcount_plan.lists.active_info")}
        </span>
      </p>
    </div>
  )

  const handleDeactivatePlans = async () => {
    if (pendingActivePlanId) {
      const res = await changeActiveStatus(
        HeadcountPlanActiveStatus.Active,
        pendingActivePlanId,
        true,
      )
      if (res?.updateHeadcountPlanActiveStatus.success) {
        closeModal()
      }
    }
  }

  return (
    <>
      <PageNav>
        <TitleBlockLarge>
          <h1>{"list_plans_title".t("headcount_plan")}</h1>
        </TitleBlockLarge>
        <ActionBlock>
          {currentPerson?.isCompanyManagerOrHrAdmin ? <CreatePlanButton /> : null}
        </ActionBlock>
      </PageNav>
      <div className="m-8 flex-col gap-6 flex">
        {errors.length > 0 && (
          <div className="alert alert-critical mb-4 items-center gap-1.5 flex">
            <FontAwesomeIcon icon={["far", "info-circle"]} />
            <div>
              {errors.map((s: Error) => (
                <span key={s.message}>
                  {s.message}
                  <br />
                </span>
              ))}
            </div>
          </div>
        )}
        <PlanTable
          emptyState={t("v2.headcount_plan.lists.no_active_plans")}
          isCompanyManagerOrHrAdmin={!!currentPerson.isCompanyManagerOrHrAdmin}
          title={activePlansTitle}
          plans={activeHeadcountPlans}
          showMenuColumn={showMenuColumn}
        />
        <PlanTable
          isCompanyManagerOrHrAdmin={!!currentPerson.isCompanyManagerOrHrAdmin}
          title={t("v2.headcount_plan.lists.additional")}
          plans={additionalHeadcountPlans}
          showMenuColumn={showMenuColumn}
        />
        {archivedHeadcountPlans && archivedHeadcountPlans.length > 0 ? (
          <ArchivedPlans
            isCompanyManagerOrHrAdmin={!!currentPerson.isCompanyManagerOrHrAdmin}
            plans={archivedHeadcountPlans}
            showMenuColumn={showMenuColumn}
          />
        ) : null}
      </div>
      <Modal
        isOpen={followUpModalOpen}
        onClose={closeModal}
        size="md"
        title={t("v2.headcount_plan.lists.menu_options.activate_plan")}
      >
        <div className="react-modal__body">
          <div className="alert--yellow gap-1.5 flex">
            <FontAwesomeIcon className="mt-[3px]" icon={["far", "exclamation-triangle"]} />
            <div>
              <p className="text-base-bold">{t("v2.headcount_plan.lists.active_alert_heading")}</p>
              <p>
                {t("v2.headcount_plan.lists.active_alert_body", {
                  plan: joinNames(overlappingActivePlans),
                  count: overlappingActivePlans.length,
                })}
              </p>
            </div>
          </div>
        </div>
        <ModalFooter
          className="justify-end px-6 py-3"
          disabled={isLoading}
          onClose={closeModal}
          onSave={handleDeactivatePlans}
          saveButtonText={t("v2.headcount_plan.lists.yes_activate")}
        />
      </Modal>
    </>
  )
}

const joinNames = (plans: { id: string; name: string }[]) => {
  const names = plans.map((p) => p.name)
  if (names.length <= 1) return names[0]
  return `${names.slice(0, -1).join(", ")} and ${names[names.length - 1]}`
}

export function ListPlans() {
  return (
    <RootProvider>
      <PlanActiveContextProvider>
        <Internal />
      </PlanActiveContextProvider>
    </RootProvider>
  )
}
