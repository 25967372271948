import { EntityId } from "@reduxjs/toolkit"
import React, { RefObject, useCallback, useEffect, useMemo, useRef } from "react"

import type { Maybe, NodeInterface } from "types/graphql"
import {
  FieldType,
  type CellInput,
  type Column,
  type CursorConnection,
} from "v2/react/components/orgChart/Datasheet/types"
import { cursorInputUnmountedWhileWriting } from "v2/redux/slices/GridSlice"
import {
  Direction,
  endWriteWithCursor,
  moveCursor,
  placeOrTransitionCursor,
} from "v2/redux/slices/GridSlice/cursor/cursorActions"
import { makeSelectCursorStateForCell } from "v2/redux/slices/GridSlice/cursor/cursorSelectors"
import {
  inEitherWriteState,
  writingOnEditableCell,
  writingOnEditableCellWithInitial,
} from "v2/redux/slices/GridSlice/cursor/cursorStates"
import type { CellCursor } from "v2/redux/slices/GridSlice/cursor/types"
import { useAppDispatch, useAppSelector, type AppDispatch } from "v2/redux/store"

import { ArrowKeys, TransitionKeys } from "./cursorKeyMovements"
import { useSingleAttributeUpdateForPersonPositionNode } from "./useUpdatePersonPositionNodeFunc"

type StopWriting = CursorConnection["stopWriting"]
type HookState = {
  cellInput: RefObject<CellInput>
  column: Column<NodeInterface>
  cursorIfInWrite: CellCursor | undefined
  dispatch: AppDispatch
  inCursor: boolean
  initialValue: string | undefined
  inWrite: boolean
  rowId: EntityId
}

/**
 * Provides a "connection" between a cell component and the cursor. It includes
 * cursor state *relative* to the cell, callbacks to move or request the
 * cursor, and callbacks to persist changes made to a cell during/after a
 * write.
 */
export function useCellCursorConnection(
  rowId: EntityId,
  column: Column<NodeInterface>,
): CursorConnection {
  const selector = useMemo(
    () => makeSelectCursorStateForCell(rowId, column.fieldKey),
    [rowId, column.fieldKey],
  )

  const cursorState = useAppSelector(selector)
  const cellInput = useRef<CellInput>(null)
  const dispatch = useAppDispatch()

  const hookState = { ...cursorState, cellInput, column, dispatch, rowId }
  const saveWrite = useSaveWriteCallback(hookState)

  useBeginWriteEffect(hookState)
  useSnapshotInputValueIfWritingEffect(hookState)

  return {
    cellInputRef: hookState.cellInput,
    inCursor: hookState.inCursor,
    initialWriteValue: hookState.initialValue,
    inWrite: hookState.inWrite,
    keyDownListenerWhileWriting: useKeyDownWhileWritingCallback(hookState),
    keyUpListenerWhileWriting: useKeyUpWhileWritingCallback(saveWrite, hookState),
    requestCursorTransition: useCursorTransitionAction(hookState),
    saveFn: useSaveFn(hookState),
    saveWrite,
    stopWriting: useStopWritingCallback(hookState),
  }
}

/**
 * Focuses the cell input, passing it an initial value (if relevant) when
 * `inWrite` changes to true.
 */
const useBeginWriteEffect = ({ inWrite, cellInput, initialValue }: HookState) =>
  useEffect(() => {
    if (inWrite) cellInput.current?.focus?.(initialValue)
  }, [cellInput, inWrite, initialValue])

/**
 * Signals that the cell is no longer collecting input, but doesn't necessarily
 * want to save anything collected. The cell is free to discard its input, kick
 * off a follow-up interaction, or hold onto its input for a save in the near
 * future.
 */
const useStopWritingCallback = ({ dispatch, cursorIfInWrite, cellInput }: HookState): StopWriting =>
  useCallback(
    (arg) => {
      if (!cursorIfInWrite) return

      const cursor = cursorIfInWrite
      let moveAfter: Direction | undefined
      if (arg && "moveAfterByEvent" in arg)
        moveAfter = extractMoveAfterTo(cursor, arg.moveAfterByEvent)?.moveAfterTo
      else if (arg && "moveAfterTo" in arg) moveAfter = arg.moveAfterTo

      if (arg && "transitionKeyCanMove" in arg) dispatch(endWriteWithCursor(arg))
      else dispatch(endWriteWithCursor())

      cellInput.current?.blur?.()
      if (moveAfter) dispatch(moveCursor({ direction: moveAfter }))
    },
    [dispatch, cursorIfInWrite, cellInput],
  )

/**
 * Prepares a callback so a cell can request the cursor. If the cursor is on
 * the cell, this will transition to "write" mode (if available).
 */
const useCursorTransitionAction = ({ dispatch, rowId, column: { fieldKey } }: HookState) =>
  useCallback(
    () => dispatch(placeOrTransitionCursor({ rowId, fieldKey })),
    [dispatch, rowId, fieldKey],
  )

/**
 * Callback that saves any changes made to the cell. The cell may optionally
 * pass an option indicating what the cursor ought to do next (such as move
 * to the next cell).
 */
const useSaveWriteCallback = (hookArg: HookState): CursorConnection["saveWrite"] => {
  const { rowId, column, cursorIfInWrite } = hookArg
  const { execute } = useSingleAttributeUpdateForPersonPositionNode(rowId, column)

  return useCallback(
    async (value, options) => {
      if (!cursorIfInWrite) return { ok: false }
      const nextOptions =
        options && "moveAfterByEvent" in options
          ? extractMoveAfterTo(cursorIfInWrite, options.moveAfterByEvent)
          : options
      return execute(value, nextOptions)
    },
    [cursorIfInWrite, execute],
  )
}

const useSaveFn = (hookArg: HookState): CursorConnection["saveFn"] => {
  const { rowId, column } = hookArg
  const { execute } = useSingleAttributeUpdateForPersonPositionNode(rowId, column)

  return useCallback(
    async (value) => {
      // We don't enforce `cursorIfInWrite` since this should be enforced by
      // the table datasheet cell.
      const result = await execute(value ?? null)

      if (!result.ok && result.error.message === "followUp") return result

      if (result.ok && result.message === "noChange") return result

      // For now we always return { ok: true } if the error isn't "followUp" because
      // errors are mapped into Redux through webpack/v2/redux/slices/NodeSlice/NodeApi.ts
      // in `applyOptimisticUpdate`.
      return { ok: true }
    },
    [execute],
  )
}

/**
 * Utility function that cells can optionally use to squelch transition/arrow
 * keys.
 */
const useKeyDownWhileWritingCallback = ({ cursorIfInWrite: cursor }: HookState) =>
  useCallback(
    (event: KeyboardEvent | React.KeyboardEvent) => {
      const nativeEvent = "nativeEvent" in event ? event.nativeEvent : event
      if (!cursor || writingOnEditableCell(cursor)) return { handled: false, event }
      if (!ArrowKeys.matchEvent(nativeEvent)) return { handled: false, event }

      event.preventDefault()
      event.stopPropagation()
      return { handled: true, event }
    },
    [cursor],
  )

/**
 * Utility that cells can use to opt-in to default hotkey behavior. Does
 * nothing for most keys. If given a transition key, this will save and the
 * cursor will move to the appropriate adjacent cell.
 *
 * If the user began making changes with a standard key press, thereby
 * replacing the current input, an arrow key will act like a transition key.
 * Otherwise arrow keys are ignored.
 */
const useKeyUpWhileWritingCallback = (
  update: CursorConnection["saveWrite"],
  hookArg: HookState,
) => {
  const { cellInput, cursorIfInWrite } = hookArg

  return useCallback(
    (event: KeyboardEvent | React.KeyboardEvent) => {
      const nativeEvent = "nativeEvent" in event ? event.nativeEvent : event
      if (!cursorIfInWrite) return { handled: false, event }

      const options = extractMoveAfterTo(cursorIfInWrite, nativeEvent)
      if (!options) return { handled: false, event }

      event.preventDefault()
      event.stopPropagation()
      cellInput.current?.blur?.()
      update(cellInput.current?.getValue?.() ?? "", options)
      return { handled: true, event }
    },
    [cellInput, cursorIfInWrite, update],
  )
}

/**
 * Snapshots the current cell input just before it unmounts. This is handy if
 * the user scrolls far enough away from an active write that the virtualized
 * container ejects the component. When the user scrolls back, the cell will be
 * focused with this snapshot when it mounts.
 */
function useSnapshotInputValueIfWritingEffect({ cellInput, dispatch, inWrite }: HookState) {
  const cellInputValueRef = useRef<Maybe<string>>(null)
  cellInputValueRef.current = cellInput?.current?.getValue?.() ?? null

  // useEffect runs a cleanup function before triggering a subsequent effect
  // OR before the component unmounts. Return a cleanup function that grabs
  // the latest value and puts it into Redux in order to restore the input.
  //
  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      const { current: cellInputValue } = cellInputValueRef
      if (!inWrite || cellInputValue === null) return

      dispatch(cursorInputUnmountedWhileWriting(cellInputValue))
    }
  }, [cellInputValueRef, inWrite, dispatch])
}

const extractMoveAfterTo = (cursor: CellCursor, event: KeyboardEvent) => {
  if (!inEitherWriteState(cursor)) return undefined

  const transitionMovement = TransitionKeys.findMovement(event)
  const arrowMovement = ArrowKeys.findMovement(event)

  // Edge case: forced autocomplete supports kicking off a write with an
  // initial value, but arrow keys lead to wonky behavior. Flag this so arrow
  // keys don't submit.
  const ignoreArrows = cursor.fieldType === FieldType.ForcedAutocomplete

  if (writingOnEditableCellWithInitial(cursor) && transitionMovement)
    return { moveAfterTo: transitionMovement.direction }
  if (writingOnEditableCellWithInitial(cursor) && arrowMovement && !ignoreArrows)
    return { moveAfterTo: arrowMovement.direction }
  if (writingOnEditableCell(cursor) && transitionMovement)
    return { moveAfterTo: transitionMovement.direction }

  return undefined
}
