import classNames from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"
import { defaultMemoize } from "reselect"

import { Maybe } from "types/graphql"
import { StatsRow } from "v2/react/components/orgChart/Datasheet/types"
import { statKeys } from "v2/react/components/orgChart/Datasheet/utils/constants"

import { formatStatistic } from "../utils/statistics"

export interface StatsCellProps {
  className?: string
  row: StatsRow
  height: number
  isFirst: boolean
  isLast: boolean
  style: React.CSSProperties
  fieldKey: string
  format: string
}

export function StatsCell({
  className,
  row,
  height,
  isFirst,
  isLast,
  style,
  fieldKey,
  format,
}: StatsCellProps) {
  const { t } = useTranslation()

  const renderStats = () => {
    const stats = row.stats[fieldKey]
    if (!stats) return null
    return (
      <div className="h-full w-full flex-col justify-center flex">
        {statKeys.map((statKey) =>
          !Object.prototype.hasOwnProperty.call(stats, statKey) ? null : (
            <div
              key={statKey}
              className="justify-between rounded px-1 transition-all duration-200 ease-out flex hover:bg-neutral-3"
            >
              <p className="text-sm font-medium text-neutral-64">
                {t(`v2.defaults.statistic_abbreviations.${statKey}`)}
              </p>
              <p className="text-sm font-bold text-neutral-80">
                {formatStatistic(stats[statKey], fieldKey, format)}
              </p>
            </div>
          ),
        )}
      </div>
    )
  }
  return (
    <div
      style={{ ...cellStyle(style, isFirst, row.color), height }}
      className={classNames(className, nodeClassName(isLast, isFirst))}
    >
      {renderStats()}
    </div>
  )
}

const nodeClassName = (isLast: boolean, isFirst: boolean) =>
  classNames("GridBody-cell stats", { last: isLast, first: isFirst })

const cellStyle = defaultMemoize((style, isFirst, color?: Maybe<string>) =>
  color && isFirst ? { ...(style || {}), borderLeft: `5px solid ${color}` } : style,
)
