import React from "react"

import { HeadcountPlan, HeadcountPlanParticipant } from "types/graphql.d"

import { BudgetOverBanner } from "../banners/BudgetOverBanner"
import { ParticipantFinalizedBanner } from "../banners/ParticipantFinalizedBanner"
import { ProposalSubmittedBanner } from "../banners/ProposalSubmittedBanner"

type Props = {
  headcountPlan?: HeadcountPlan
  participant?: HeadcountPlanParticipant
  displayState: "approved" | "original"
  setDisplayState: (value: "approved" | "original") => void
  showBudgetOverBanner: boolean
  onCloseBudgetOverBanner: () => void
}
export function ParticipantBanner({
  displayState,
  setDisplayState,
  headcountPlan,
  participant,
  showBudgetOverBanner,
  onCloseBudgetOverBanner,
}: Props) {
  if (headcountPlan?.lockedAt) {
    return (
      <ParticipantFinalizedBanner
        displayState={displayState}
        setDisplayState={setDisplayState}
        positionsApproved={
          participant?.allPositionsInPlan?.filter(
            (position) => position.type === "new" && position.status === "approved",
          ).length ?? 0
        }
      />
    )
  }

  if (participant?.status === "complete")
    return <ProposalSubmittedBanner participant={participant} />

  if (participant && showBudgetOverBanner && participant.remainingBudget?.includes("-"))
    return (
      <BudgetOverBanner
        budget={participant.budget}
        proposedBudget={participant.proposedBudget}
        remainingBudget={participant.remainingBudget}
        onClose={onCloseBudgetOverBanner}
      />
    )
}
