import { Placement } from "@floating-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React, { ReactNode, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { SelectorModuleButton } from "v2/react/shared/buttons/SelectorModuleButtonGroup"
import { Popover, PopoverContent, PopoverTrigger } from "v2/react/shared/overlay/Popover"
import { prepareIconClass } from "v2/react/utils/misc"

interface Props {
  ariaLabel?: string
  children?: ReactNode
  colors: string[]
  currentColor: string
  disabled?: boolean
  dropdownPlacement?: Placement
  /** (optional) Determines whether or not to show the no color option selector button. It will require `noColorHex` to use */
  includeNoColorOption?: boolean
  /** Color hex code to apply when "No Color" is selected */
  noColorHex?: string
  onSubmit: (color: string) => void
  /** Classes to apply to the trigger button when popover is open */
  popoverTriggerActiveClassNames?: string
  /** Classes to apply to the trigger button */
  popoverTriggerClassNames?: string
  showAlert?: boolean
  /** Additional styles that may be applied to the PopoverTrigger when classnames are not a suitable option */
  style?: React.CSSProperties
}

function ColorPicker({
  ariaLabel,
  children,
  colors,
  currentColor,
  disabled,
  dropdownPlacement,
  includeNoColorOption,
  noColorHex,
  onSubmit,
  popoverTriggerActiveClassNames,
  popoverTriggerClassNames,
  showAlert,
  style,
}: Props) {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [selectedColor, setSelectedColor] = useState(currentColor)

  useEffect(() => setSelectedColor(currentColor), [currentColor])

  const handleSubmit = () => {
    onSubmit(selectedColor)
    setIsOpen(false)
  }

  const selectColor = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) =>
    setSelectedColor(e.currentTarget.value)

  const selectNoColor = (noColor: string) => setSelectedColor(noColor)

  const createNoColorDisplay = () => (
    <div className="items-center gap-1.5 flex">
      <div className="h-6 w-6 place-content-center rounded-sm border border-solid border-neutral-8 text-sm text-primary-100 grid">
        <FontAwesomeIcon icon={prepareIconClass("fac droplet-splash")} />
      </div>
      <span
        className={cn("font-base text-neutral-100", {
          "text-primary-100": selectedColor === noColorHex,
        })}
      >
        {t("v2.shared.colored_label_editor.no_color")}
      </span>
    </div>
  )

  if (!selectedColor) return null

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen} placement={dropdownPlacement}>
      <PopoverTrigger
        aria-label={ariaLabel}
        className={cn(popoverTriggerClassNames, isOpen ? popoverTriggerActiveClassNames : "")}
        disabled={disabled}
        onClick={() => setIsOpen(true)}
        style={style}
      >
        {children}
      </PopoverTrigger>
      <PopoverContent className="elevation--overlay color-picker z-[21] max-w-[21.5rem] rounded-lg bg-white">
        <div className="bg-primary-3">
          <div className="items-center justify-between border-b-neutral-8 px-6 py-5 flex">
            <p className="text-large">{t("v2.shared.colored_label_editor.select_color")}</p>
            <button className="btn--ghost btn--icon" onClick={() => setIsOpen(false)} type="button">
              <FontAwesomeIcon icon={["far", "times"]} />
            </button>
          </div>
          <div className="form-control p-6">
            {showAlert && (
              <div className="alert alert--yellow mb-6 flex">
                <FontAwesomeIcon icon={["far", "exclamation-triangle"]} className="mt-[3px]" />
                <p>{t("v2.orgchart.super_panel.color_code_alert")}</p>
              </div>
            )}
            <div className="color-palette-widget m-0 p-0">
              {colors.map((color, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className="relative" key={color + index}>
                  <input
                    readOnly
                    type="button"
                    id="color"
                    value={color}
                    className={cn("color-palette-color m-0 h-8 w-8 rounded-lg", {
                      "border--focus": selectedColor === color,
                    })}
                    style={{ backgroundColor: color, color: "transparent" }}
                    onClick={(e) => selectColor(e)}
                  />
                  {selectedColor === color && (
                    <div className="check absolute -right-1.5 -top-1.5 h-3.5 w-3.5 place-content-center rounded-full border border-solid border-neutral-8 bg-primary-100 text-[7px] text-white grid">
                      <FontAwesomeIcon icon={["fas", "check"]} />
                    </div>
                  )}
                </div>
              ))}
            </div>
            {includeNoColorOption && noColorHex && (
              <div className="mt-3">
                <SelectorModuleButton
                  formatDisplay={createNoColorDisplay}
                  option={{
                    selected: selectedColor === noColorHex,
                    label: t("v2.shared.colored_label_editor.no_ccolor"),
                    id: noColorHex,
                  }}
                  fieldId="no_color"
                  onSelect={selectNoColor}
                />
              </div>
            )}
          </div>
          <div className="items-center justify-end gap-2 border-t-neutral-8 px-6 py-3 flex">
            <button
              onClick={() => setIsOpen(false)}
              type="button"
              className="btn--large btn--secondary"
            >
              {t("v2.defaults.cancel")}
            </button>
            <button onClick={handleSubmit} type="submit" className="btn--large btn--primary">
              {t("v2.defaults.save")}
            </button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  )
}

export { ColorPicker }
