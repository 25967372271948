import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import { HeadcountPlanActiveStatus, HeadcountPlanStatus } from "types/graphql.enums"
import { useChangeActiveStatus } from "v2/react/components/headcountPlanning/ListPlans/useChangeActiveStatus"
import { Dropdown } from "v2/react/shared/collection/menus/Dropdown/Dropdown"
import { prepareIconClass } from "v2/react/utils/misc"
import { UrlHelper } from "v2/react/utils/urls"

interface PlanEllipsisMenuProps {
  isCompanyOrHrAdmin: boolean
  isPlanAdmin: boolean
  planId: string
  planStatus: HeadcountPlanStatus
  planActiveStatus?: HeadcountPlanActiveStatus | null
}

function PlanEllipsisMenu({
  isCompanyOrHrAdmin,
  isPlanAdmin,
  planId,
  planStatus,
  planActiveStatus,
}: PlanEllipsisMenuProps) {
  const { t } = useTranslation()
  const { changeActiveStatus, isLoading } = useChangeActiveStatus()

  const showActivatePlan =
    planStatus === HeadcountPlanStatus.Approved &&
    planActiveStatus !== HeadcountPlanActiveStatus.Active &&
    isCompanyOrHrAdmin
  const showDeactivatePlan =
    planActiveStatus === HeadcountPlanActiveStatus.Active && isCompanyOrHrAdmin
  const showEditPlanSettings = planStatus !== HeadcountPlanStatus.Approved
  const showManageParticipants = planStatus !== HeadcountPlanStatus.Approved
  const showEditBudgets = planStatus !== HeadcountPlanStatus.Approved
  const showUnarchivePlan = planActiveStatus === HeadcountPlanActiveStatus.Archived
  const showArchivePlan =
    planActiveStatus !== HeadcountPlanActiveStatus.Archived &&
    (isCompanyOrHrAdmin || (isPlanAdmin && planActiveStatus !== HeadcountPlanActiveStatus.Active))
  const includeHr = planStatus !== HeadcountPlanStatus.Approved

  const handleArchivePlan = () => changeActiveStatus(HeadcountPlanActiveStatus.Archived, planId)
  const handleUnarchivePlan = () => changeActiveStatus(null, planId)
  const handleActivatePlan = () => changeActiveStatus(HeadcountPlanActiveStatus.Active, planId)
  const handleDeactivatePlan = () => changeActiveStatus(null, planId)

  if (isPlanAdmin && !isCompanyOrHrAdmin && planActiveStatus === HeadcountPlanActiveStatus.Active)
    return null

  return (
    <Dropdown align="right">
      <Dropdown.Trigger
        disabled={isLoading}
        triggerClassName={cn("btn--icon btn--ghost dropdown-link cursor-pointer", {
          disabled: isLoading,
        })}
      >
        <FontAwesomeIcon icon={["far", "ellipsis-h"]} className="icon-14" />
      </Dropdown.Trigger>

      <Dropdown.Menu className="dropdown-menu right-0 z-0 mt-2 !w-fit p-2 !block">
        {showEditPlanSettings && (
          <Dropdown.Item
            as="a"
            className="text-nowrap grid-cols-[1.25rem_auto] items-center gap-2 grid"
            href={UrlHelper.headcountPlanEditSettings(planId)}
          >
            <FontAwesomeIcon icon={["far", "cog"]} />
            {t("v2.headcount_plan.lists.menu_options.edit_settings")}
          </Dropdown.Item>
        )}
        {showManageParticipants && (
          <Dropdown.Item
            as="a"
            className="text-nowrap grid-cols-[1.25rem_auto] items-center gap-2 grid"
            href={UrlHelper.headcountPlanManageParticipants(planId)}
          >
            <FontAwesomeIcon icon={["far", "users"]} />
            {t("v2.headcount_plan.lists.menu_options.manage_participants")}
          </Dropdown.Item>
        )}
        {showEditBudgets && (
          <Dropdown.Item
            as="a"
            className="text-nowrap grid-cols-[1.25rem_auto] items-center gap-2 grid"
            href={UrlHelper.headcountPlanSetBudgetsPath(planId)}
          >
            <FontAwesomeIcon icon={["far", "usd-square"]} />
            {t("v2.headcount_plan.lists.menu_options.edit_budgets")}
          </Dropdown.Item>
        )}

        {showActivatePlan && (
          <Dropdown.Item
            className="text-nowrap grid-cols-[1.25rem_auto] items-center gap-2 grid"
            onClick={handleActivatePlan}
          >
            <FontAwesomeIcon icon={prepareIconClass("fac bolt")} />
            {t("v2.headcount_plan.lists.menu_options.activate_plan")}
          </Dropdown.Item>
        )}
        {showDeactivatePlan && (
          <Dropdown.Item
            className="text-nowrap grid-cols-[1.25rem_auto] items-center gap-2 grid"
            onClick={handleDeactivatePlan}
          >
            <FontAwesomeIcon icon={prepareIconClass("fac bolt-slash")} />
            {t("v2.headcount_plan.lists.menu_options.deactivate_plan")}
          </Dropdown.Item>
        )}

        {includeHr && <hr className="-mx-2 my-2" />}

        {showArchivePlan && (
          <Dropdown.Item
            className="text-nowrap grid-cols-[1.25rem_auto] items-center gap-2 grid"
            onClick={handleArchivePlan}
          >
            <FontAwesomeIcon icon={prepareIconClass("fac box-archive")} />
            {t("v2.headcount_plan.lists.menu_options.archive_plan")}
          </Dropdown.Item>
        )}
        {showUnarchivePlan && (
          <Dropdown.Item
            className="text-nowrap grid-cols-[1.25rem_auto] items-center gap-2 grid"
            onClick={handleUnarchivePlan}
          >
            <FontAwesomeIcon icon={prepareIconClass("fac box-unarchive")} />
            {t("v2.headcount_plan.lists.menu_options.unarchive_plan")}
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export { PlanEllipsisMenu }
