import cn from "classnames"
import { camelCase } from "lodash/fp"
import React from "react"
import { useTranslation } from "react-i18next"

import { BioEducations, Candidate, Person } from "types/graphql"
import {
  attributeColorMapper,
  attributeColorMapperInverted,
} from "v2/react/components/succession/SuccessionPlanPanel/utils/successionUtils"
import { formatCurrency } from "v2/react/utils/currency"

interface CellMapperProps {
  candidate?: Candidate
  person: Person
  row: string
}

function CellMapper({ candidate, person, row }: CellMapperProps) {
  const { t } = useTranslation()

  const isMatch = (condition: string) => row === condition
  const field = (candidate || person).customFieldValues?.find((field) => field.field_id === row)
  const isCurrencyType = field?.custom_field?.field_type === "currency"
  const none = t("v2.succession_plan_panel.candidate.none")

  switch (true) {
    case isMatch("readiness"):
      if (!candidate) return ""
      return (
        <AttributeBadge
          attribute={row}
          attributeLabel={candidate.readiness || none}
          attributeLevel={candidate.readinessLevel || "none"}
        />
      )
    case isMatch("employee_id"):
      return <p>{candidate ? candidate.employeeId : person.employeeId}</p>
    case isMatch("employee_type"):
      return (
        <p>
          {candidate
            ? candidate.employeeType?.labelTranslated
            : person.employeeType?.labelTranslated}
        </p>
      )
    case isMatch("base_pay_calculated"):
      return (
        <p>
          {person.basePayCalculated ? formatCurrency({ value: person.basePayCalculated ?? 0 }) : ""}
        </p>
      )
    case isMatch("total_annual_compensation"):
      return (
        <p>
          {person.totalAnnualCompensation
            ? formatCurrency({ value: person.totalAnnualCompensation })
            : ""}
        </p>
      )
    case isMatch("location"):
      return <p>{person.primaryPosition?.location?.label || ""}</p>
    case isMatch("performance"):
      return (
        <AttributeBadge
          attribute={row}
          attributeLabel={candidate?.performance || none}
          attributeLevel={candidate?.performanceLevel || "none"}
        />
      )
    case isMatch("potential"):
      return (
        <AttributeBadge
          attribute={row}
          attributeLabel={candidate?.potential || none}
          attributeLevel={candidate?.potentialLevel || "none"}
        />
      )
    case isMatch("flight_risk"):
      return (
        <AttributeBadge
          attribute={row}
          attributeLabel={candidate?.flightRisk || none}
          attributeLevel={candidate?.flightRiskLevel || "none"}
          invertedColors
        />
      )
    case isMatch("employment_tenure"):
      return (
        <p>
          {t(`v2.succession_plan_panel.summary.years`, { count: person.employmentTenure || 0 })}
        </p>
      )
    case isMatch("bio_skills"):
    case isMatch("bio_licenses"):
      return (
        <div className="gap-2 flex">
          {(candidate
            ? candidate[camelCase(row) as keyof Candidate]
            : person[camelCase(row) as keyof Person] || []
          ).map((bio: { id: string; name: string }) => (
            <div className="badge--gray text-sm-bold" key={bio.name}>
              {bio.name}
            </div>
          ))}
        </div>
      )
    case isMatch("bio_educations"):
      return (
        <div className="flex-col gap-2 p-4 flex">
          {((candidate ? candidate.bioEducations : person.bioEducations) || []).map(
            (edu: BioEducations) => (
              <div key={edu.id}>
                <p className="text-base-bold">{edu.degree}</p>
                <p>
                  {edu.school}
                  {edu.school && edu.year && ", "}
                  {edu.year}
                </p>
              </div>
            ),
          )}
        </div>
      )
    case row.includes("custom_field"):
      return (
        <div>
          {isCurrencyType && field.formatted_value
            ? formatCurrency({ value: Number(field.formatted_value) })
            : field?.formatted_value || ""}
        </div>
      )
    default:
      return ""
  }
}

export { CellMapper }

interface AttributeBadgeProps {
  attribute: string
  attributeLabel: string
  attributeLevel: string
  invertedColors?: boolean
}

const AttributeBadge = ({
  attribute,
  attributeLabel,
  attributeLevel,
  invertedColors,
}: AttributeBadgeProps) => (
  <div
    className={cn(
      `badge--${
        (invertedColors ? attributeColorMapperInverted : attributeColorMapper)[attributeLevel]
      }`,
      attribute,
    )}
  >
    <p className="text-sm-bold">{attributeLabel}</p>
  </div>
)
