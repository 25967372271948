import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import React from "react"

import { DisplayValueCell } from "v2/react/components/headcountPlanning/TableDatasheet/DisplayValueCell"

export function StatusCell({
  rowId,
  status,
}: {
  rowId: string
  status: string | null | undefined
}) {
  if (!status) {
    return <DisplayValueCell id={`status-${rowId}`} className="non-editable-cell" />
  }

  const display = getStatusDisplay(status)
  return (
    <DisplayValueCell id={`status-${rowId}`} className="non-editable-cell">
      <div className="justify-center flex">
        <div
          className={classNames(
            "whitespace-nowrap rounded-full px-2 py-1 font-bold",
            "border border-solid border-neutral-8 text-sm",
            "grid-cols-[min-content_1fr] items-center gap-1 grid",
            display.color,
          )}
        >
          {display.icon}
          {display.text}
        </div>
      </div>
    </DisplayValueCell>
  )
}

function getStatusDisplay(status: string) {
  if (status === "denied") {
    return {
      color: "bg-status-critical-light text-status-critical",
      text: "position_status_denied".t("headcount_plan"),
      icon: <FontAwesomeIcon icon={["far", "times"]} className="text-status-critical" />,
    }
  }
  return {
    color: "bg-status-success-light text-status-success",
    text: "position_status_approved".t("headcount_plan"),
    icon: <FontAwesomeIcon icon={["far", "check"]} className="text-status-success" />,
  }
}
