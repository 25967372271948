import { parseCurrency, ParseCurrencyOptions } from "./currency"

type MaybeNumeric = string | number | null | undefined

type SafeNumberOptionsWithoutFallback =
  | { from?: "decimal" }
  | { from: "currency"; fromOptions?: ParseCurrencyOptions }
type SafeNumberOptionsWithFallback<Fallback> = {
  fallback: Fallback
  from?: "currency" | "decimal"
}
type SafeNumberOptions<Fallback> =
  | SafeNumberOptionsWithoutFallback
  | SafeNumberOptionsWithFallback<Fallback>

type CalculatePercentArgWithoutFallback = {
  of?: MaybeNumeric
  outOf?: MaybeNumeric
  forHumans?: boolean
}
type CalculatePercentArgWithFallback<Value = MaybeNumeric> = CalculatePercentArgWithoutFallback & {
  orElse: Value
}
type CalculatePercentArg<Fallback> =
  | CalculatePercentArgWithoutFallback
  | CalculatePercentArgWithFallback<Fallback>

/**
 * Casts `value` into a number reliably; if the cast results in `NaN`, then
 * zero is returned in place.
 *
 * @example Basic casting
 * ```ts
 * const num1 = safeNumber(3)
 * const num2 = safeNumber('3')
 * num1 === num2
 * ```
 *
 * @example Unparsable or non-existent values
 * ```ts
 * const num1 = safeNumber("Not a number!")
 * const num2 = safeNumber(null)
 * const num3 = safeNumber(NaN)
 * num1 === num2 === num3 === 0
 * ```
 *
 * @example With options
 * ```ts
 * safeNumber("Not a number!", { fallback: null })
 * //=> null
 * safeNumber("300 000,92 €", { from: "currency", fromOptions: { currency: "EUR", locale: "fr" } })
 * //=> 300000.92
 * ```
 */
function safeNumber(value: MaybeNumeric): number
function safeNumber(value: MaybeNumeric, options: SafeNumberOptionsWithoutFallback): number
function safeNumber<Fallback>(
  value: MaybeNumeric,
  options: SafeNumberOptionsWithFallback<Fallback>,
): number | Fallback
function safeNumber<Fallback>(value: MaybeNumeric, options?: SafeNumberOptions<Fallback>) {
  const fallback = options && "fallback" in options ? options.fallback : 0

  if (typeof value === "string" && value.trim() === "") return fallback
  if (value === undefined || value === null) return fallback
  if (typeof value === "string" && !value.match(/[0-9]/)) return fallback

  const number = castToNumber(value, options)
  return Number.isNaN(number) ? fallback : number
}

const castToNumber = <Fallback>(value: string | number, options?: SafeNumberOptions<Fallback>) => {
  if (options?.from === "currency" && "fromOptions" in options)
    return parseCurrency(value, options.fromOptions)
  if (options?.from === "currency") return parseCurrency(value)

  return typeof value === "number" ? value : Number(value)
}

/**
 * Calculates percent using `arg.of` and `arg.outOf`; optionally falls back to
 * `arg.fallback`.
 *
 * @example
 * ```ts
 * calculatePercent({ of: 10, outOf: 100 })
 * //=> 0.1
 * calculatePercent({ of: 10, outOf: 100, forHumans: true })
 * //=> 10
 * calculatePercent({ of: 10, outOf: 0 })
 * //=> undefined
 * calculatePercent({ of: 10, outOf: 0, orElse: 0 })
 * //=> 0
 * ```
 */
function calculatePercent(arg: CalculatePercentArgWithoutFallback): number | undefined
function calculatePercent<Fallback = MaybeNumeric>(
  arg: CalculatePercentArgWithFallback<Fallback>,
): number | Fallback
function calculatePercent<Fallback = MaybeNumeric>(arg: CalculatePercentArg<Fallback>) {
  const numerator = safeNumber(arg.of)
  const denominator = safeNumber(arg.outOf)

  if (denominator === 0 && "fallback" in arg) return arg.fallback
  if (denominator === 0) return undefined

  const rawPercent = numerator / denominator
  const multiplier = arg.forHumans ? 100 : 1

  return multiplier * rawPercent
}

export { calculatePercent, MaybeNumeric, safeNumber, SafeNumberOptionsWithoutFallback }
