import fp from "lodash/fp"
import { z } from "zod"

import {
  BasePay,
  FlsaClassification,
  FundingSourceAllocation,
  SourcePay,
  StandardRank,
} from "types/graphql.enums"
import { FormShape } from "v2/react/components/jobRequisitions/RequisitionForm/types/FormShape"
import { GenericFormShape } from "v2/react/components/jobRequisitions/RequisitionForm/types/GenericFormShape"
import { PosigenFormShape } from "v2/react/components/jobRequisitions/RequisitionForm/types/PosigenFormShape"
import { FieldValues } from "v2/react/components/positions/positionForm"
import { calculateBudgetedTotal as calculateBudgetedBaseCompensation } from "v2/react/components/positions/positionForm/hooks/useBudgetedBasePayTotal"
import { calculateBudgetedVariablePay } from "v2/react/components/positions/positionForm/hooks/useBudgetedVariablePays"
import { safeNumber } from "v2/react/utils/safeNumber"
import {
  fromUniqueKey,
  numericInputSchema,
  UniqueKeyOptions,
  uniqueKeySchema,
} from "v2/react/utils/zod"

/**
 * Casts given requisition form data into position field values.
 *
 * @public
 */
const mapToPositionData = (form: FormShape): FieldValues =>
  form.schemaType === "generic" ? mapFromGenericForm(form) : mapFromPosigenForm(form)

const idNameHashSchema = (uniqueKeyOptions: UniqueKeyOptions) =>
  z.object({
    id: uniqueKeySchema(uniqueKeyOptions).transform(fromUniqueKey()).nullish(),
    name: z.string().nullish(),
  })

//
// Base Form Position Schemas
// TODO: Move these base schemas into their respective type files
//

const genericFormPositionSchema = z.object({
  position: z.object({
    basePay: z
      .object({
        amount: numericInputSchema({ from: "currency" }).nullish(),
        hoursPerWeek: numericInputSchema().nullish(),
        payType: z
          .object({
            id: uniqueKeySchema().transform(fromUniqueKey()).pipe(z.nativeEnum(BasePay)).nullish(),
          })
          .passthrough()
          .nullish(),
      })
      .nullish(),
    companyCode: z.string().nullish(),
    eeocClassification: idNameHashSchema({ only: "eeoc_classification" }).nullish(),
    employeeType: idNameHashSchema({ only: "employee_type" }).nullish(),
    externalIdentifier: z.string().nullish(),
    flsa: z
      .object({
        id: uniqueKeySchema({ only: "flsa_classification" })
          .transform(fromUniqueKey())
          .pipe(z.nativeEnum(FlsaClassification).nullish())
          .nullish(),
        name: z.string().nullish(),
      })
      .nullish(),
    fte: numericInputSchema().nullish(),
    hiringPriority: z
      .object({
        id: uniqueKeySchema().transform(fromUniqueKey()).pipe(z.nativeEnum(StandardRank).nullish()),
        name: z.string().nullish(),
      })
      .nullish(),
    jobTitle: z
      .object({
        id: uniqueKeySchema({ only: "position_type" }).nullish(),
        jobCode: z.string().nullish(),
        jobCodeTitleLabel: z.string().nullish(),
        title: z.string().nullish(),
      })
      .nullish(),
    location: idNameHashSchema({ only: "location" }).nullish(),
    orgUnits: idNameHashSchema({ only: "org_unit" })
      .extend({
        typeName: z.string().nullish(),
        typeId: uniqueKeySchema({ only: "org_unit_type" }).nullish(),
      })
      .array()
      .nullish(),
    projectedHireDate: z.string().nullish(),
    reportsTo: idNameHashSchema({ only: "position" }),
    variablePayTypes: idNameHashSchema({ only: "variable_pay_type" })
      .extend({
        // This doesn't come from the server as null, but can become null as
        // the user interacts with the form.
        amount: numericInputSchema().nullish(),
        type: uniqueKeySchema()
          .transform(fromUniqueKey())
          .pipe(
            z
              .nativeEnum(SourcePay)
              .or(z.literal(""))
              .nullish()
              .transform((val) => (fp.isNil(val) || val === "" ? undefined : val)),
          ),
      })
      .array()
      .nullish(),
  }),
})

const posigenFormPositionSchema = z.object({
  position: z.object({
    basePay: z.object({
      amount: numericInputSchema({ from: "currency" }).nullish(),
      hoursPerWeek: numericInputSchema().nullish(),
      payType: z
        .object({
          id: uniqueKeySchema().transform(fromUniqueKey()).pipe(z.nativeEnum(BasePay)).nullish(),
        })
        .passthrough()
        .nullish(),
    }),
    department: idNameHashSchema({ only: "org_unit" })
      .extend({
        typeName: z.string().nullish(),
        typeId: uniqueKeySchema({ only: "org_unit_type" }).nullish(),
      })
      .nullish(),
    employeeType: idNameHashSchema({ only: "employee_type" }),
    flsa: z.object({
      id: uniqueKeySchema({ only: "flsa_classification" })
        .transform(fromUniqueKey())
        .pipe(z.nativeEnum(FlsaClassification).nullish())
        .nullish(),
      name: z.string().nullish(),
    }),
    fte: numericInputSchema().nullish(),
    jobTitle: z.object({
      id: uniqueKeySchema({ only: "position_type" }).nullish(),
      jobCode: z.string().nullish(),
      jobCodeTitleLabel: z.string().nullish(),
      title: z.string().nullish(),
    }),
    location: idNameHashSchema({ only: "location" }),
    projectedHireDate: z.string().nullish(),
    reportsTo: idNameHashSchema({ only: "position" }),
  }),
})

type GenericFormPositionNormalized = z.infer<typeof genericFormPositionSchema>["position"]
type PosigenFormPositionNormalized = z.infer<typeof posigenFormPositionSchema>["position"]
type PositionNormalized = GenericFormPositionNormalized | PosigenFormPositionNormalized

//
// Form Position to Position Data Mappings
//

/** @private */
const mapFromGenericForm = (form: GenericFormShape): FieldValues =>
  genericFormPositionSchema
    .passthrough()
    .transform(
      (data): FieldValues => ({
        position: {
          // Uncollected fields; assigned reasonable defaults.
          importance: "none",
          systemIdentifier: "--",
          isAssistant: false,
          fundingSourceAllocationType: FundingSourceAllocation.Amount,
          people: [],

          // Derived fields
          ...aggregateBudgetingData(data.position),

          // Collected fields
          companyCode: data.position.companyCode ?? undefined,
          eeocClassification: data.position.eeocClassification?.id ?? undefined,
          employeeTypeId: data.position.employeeType?.id ?? undefined,
          externalIdentifier: data.position.externalIdentifier ?? undefined,
          flsaClassification: data.position.flsa?.id ?? undefined,
          fte: safeNumber(data.position.fte, { fallback: undefined }),
          hiringPriority: data.position.hiringPriority?.id ?? undefined,
          locationId: data.position.location?.id ?? undefined,
          orgUnitsAttributes: data.position?.orgUnits?.map((ou) => ({
            id: ou.id ?? "",
            fullName: ou.name ?? undefined,
            orgUnitType: { id: ou.typeId ?? "", label: ou.typeName ?? "" },
          })),
          reportsToName: data.position.reportsTo.name ?? undefined,
          parentId: data.position.reportsTo.id ?? undefined,
          positionBasePay: data.position.basePay?.amount,
          positionBasePayType: data.position.basePay?.payType?.id ?? undefined,
          positionHoursPerWeek: data.position.basePay?.hoursPerWeek,
          positionType: !fp.isNil(data.position.jobTitle?.id)
            ? {
                id: data.position.jobTitle?.id ?? "0",
                jobCode: data.position.jobTitle?.jobCode,
                jobCodeTitleLabel: data.position.jobTitle?.jobCodeTitleLabel ?? "",
                title: data.position.jobTitle?.title,
                uniqueKey: data.position.jobTitle?.id ?? "position_type_0",
              }
            : undefined,
          projectedHireDate: data.position.projectedHireDate ?? undefined,

          variablePaysAttributes: data.position.variablePayTypes?.map((vpt) => ({
            id: undefined,
            amount: vpt.amount,
            payType: vpt.type,
            variablePayType: { id: vpt.id ?? "", label: vpt.name ?? "" },
          })),
        },
      }),
    )
    .parse(form)

/** @private */
const mapFromPosigenForm = (form: PosigenFormShape): FieldValues =>
  posigenFormPositionSchema
    .passthrough()
    .transform(
      (data): FieldValues => ({
        position: {
          // Uncollected fields; assigned reasonable defaults.
          importance: "none" as const,
          systemIdentifier: "--",
          isAssistant: false,
          fundingSourceAllocationType: FundingSourceAllocation.Amount,
          people: [],

          // Derived fields
          ...aggregateBudgetingData(data.position),

          // Collected fields
          employeeTypeId: data.position.employeeType.id ?? undefined,
          flsaClassification: data.position.flsa.id ?? undefined,
          fte: safeNumber(data.position.fte, { fallback: undefined }),
          locationId: data.position.location.id ?? undefined,
          orgUnitsAttributes: data.position.department
            ? [
                {
                  id: data.position.department.id ?? "",
                  fullName: data.position.department.name ?? "",
                  orgUnitType: {
                    id: data.position.department.typeId ?? "",
                    label: data.position.department.typeName ?? "",
                  },
                },
              ]
            : [],
          reportsToName: data.position.reportsTo.name ?? undefined,
          parentId: data.position.reportsTo.id ?? undefined,
          positionBasePay: data.position.basePay.amount,
          positionBasePayType: data.position.basePay.payType?.id ?? undefined,
          positionHoursPerWeek: data.position.basePay.hoursPerWeek,
          positionType: !fp.isNil(data.position.jobTitle.id)
            ? {
                id: data.position.jobTitle.id ?? "0",
                jobCode: data.position.jobTitle.jobCode,
                jobCodeTitleLabel: data.position.jobTitle.jobCodeTitleLabel ?? "",
                title: data.position.jobTitle.title,
                uniqueKey: data.position.jobTitle.id ?? "position_type_0",
              }
            : undefined,
          projectedHireDate: data.position.projectedHireDate ?? undefined,
          variablePaysAttributes: [],
        },
      }),
    )
    .parse(form)

const aggregateBudgetingData = (position: PositionNormalized) => {
  const totalBudgetedBaseCompensation = calculateBudgetedBaseCompensation(
    position.basePay?.amount,
    position.basePay?.hoursPerWeek,
    position.basePay?.payType?.id ?? undefined,
  )
  const variablePayTypes = "variablePayTypes" in position ? position.variablePayTypes : []
  const totalBudgetedCompensation = variablePayTypes?.reduce(
    (memo, { amount, type }) =>
      calculateBudgetedVariablePay({
        budgetedBasePayTotal: totalBudgetedBaseCompensation,
        variablePay: { amount, payType: type },
      }) + memo,
    totalBudgetedBaseCompensation,
  )

  return {
    budgetedBaseCompensation: {
      amount: position.basePay?.amount,
      hoursPerWeek: position.basePay?.hoursPerWeek,
      payType: position.basePay?.payType?.id ?? undefined,
    },
    totalBudgetedBaseCompensation,
    totalBudgetedCompensation,
  }
}

export { mapToPositionData }
