import cn from "classnames"
import React, { useEffect, useId, useRef } from "react"
import { Editor, EditorEvent, RawEditorOptions } from "tinymce"
import { useBoolean } from "usehooks-ts"

import { tinyMceDefaultConfig } from "v2/react/utils/tinyMceDefaultConfig"

import { Spinner } from "../loaders/Spinner"
import { InputWrapper } from "./InputWrapper"

type WysiwygTextAreaProps = {
  className?: string
  defaultValue?: string
  disabled?: boolean
  id?: string
  name?: string
  onChange?: (value: string) => void
  onBlur?: (ev: EditorEvent<{ focusedEditor: Editor | null }>) => void
  onFocus?: (ev: EditorEvent<{ blurredEditor: Editor | null }>) => void
  placeholder?: string
}

type WysiwygTextAreaWithWrapperProps = WysiwygTextAreaProps & {
  errors?: string
  label?: string
  showAttention?: boolean
  wrapperClassName?: string
}

function WysiwygTextArea({
  className,
  defaultValue,
  disabled,
  id,
  name,
  onBlur: callerOnBlur,
  onChange: callerOnChange,
  onFocus: callerOnFocus,
  placeholder,
}: WysiwygTextAreaProps) {
  const editorRef = useRef<Editor | null>(null)
  const { setFalse: flagUninitialized, setTrue: flagInitialized, value: initialized } = useBoolean()
  const reactId = useId()
  const finalId = id ?? reactId
  // Little ugly, but supports the caller passing in non-optimized callback
  // functions without us needing to trigger the effect which mounts TinyMCE.
  const onBlurRef = useRef(callerOnBlur)
  const onChangeRef = useRef(callerOnChange)
  const onFocusRef = useRef(callerOnFocus)
  useEffect(() => {
    onBlurRef.current = callerOnBlur
    onChangeRef.current = callerOnChange
    onFocusRef.current = callerOnFocus
  }, [callerOnBlur, callerOnChange, callerOnFocus])
  useEffect(() => {
    const tinyMceConfig: RawEditorOptions = {
      ...tinyMceDefaultConfig(finalId),
      readonly: disabled,
      height: 200,
      setup: (editor: Editor) => {
        editor.on("change", (ev) => {
          editor.save(ev)
          onChangeRef.current?.(editor.getContent())
        })
        editor.on("blur", (ev) => onBlurRef.current?.(ev))
        editor.on("focus", (ev) => onFocusRef.current?.(ev))
        editor.on("init", flagInitialized)
      },
    }
    window.tinymce.init(tinyMceConfig)
    editorRef.current = window.tinymce.get(finalId)
    return () => {
      const { current: editor } = editorRef
      if (editor && editor.initialized) {
        window.tinymce.remove(editor)
        editorRef.current = null
        flagUninitialized()
      }
    }
  }, [disabled, finalId, flagUninitialized, flagInitialized])

  return (
    <div className="relative">
      <textarea
        disabled={disabled}
        id={finalId}
        name={name}
        className={cn("input input-med tinymce", className)}
        defaultValue={defaultValue}
        placeholder={placeholder}
      />
      {!initialized && <Spinner />}
    </div>
  )
}

function WysiwygTextAreaWithWrapper({
  label,
  errors,
  showAttention,
  wrapperClassName,
  ...props
}: WysiwygTextAreaWithWrapperProps) {
  return (
    <InputWrapper
      label={label}
      errors={errors}
      showErrorMessage={Boolean(errors)}
      id={props.id}
      className={cn(wrapperClassName, {
        attention: showAttention,
      })}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <WysiwygTextArea {...props} />
    </InputWrapper>
  )
}

export { WysiwygTextArea, WysiwygTextAreaWithWrapper }
