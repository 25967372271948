import {
  ColumnDef,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  InitialTableState,
  TableState,
  useReactTable,
} from "@tanstack/react-table"

import { DEFAULT_PAGE_SIZE } from "v2/react/constants"

interface TableProps<TData> {
  autoResetPageIndex?: boolean
  data: TData[]
  // When using `createColumnHelper`, to build table columns, the types get
  // pretty specific, so we need to allow for any type here.
  // see: https://github.com/TanStack/table/discussions/5218
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<TData, any>[]
  initialState?: InitialTableState
  state?: Partial<TableState>
}

/**
 * When you want to load all the table data at once and let the client handle
 * sorting, filtering, and pagination, this hook comes in handy.
 *
 * If, by contrast, you want to manage table state (sorting, pagination,
 * filtering, etc.) manually (i.e. on the server), see `useServerTable`.
 */
export const useClientTable = <TData extends object>({
  autoResetPageIndex,
  data,
  columns,
  initialState,
  state,
}: TableProps<TData>) => {
  const table = useReactTable({
    data,
    columns,
    initialState: { pagination: { pageSize: DEFAULT_PAGE_SIZE }, ...initialState },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex,
    state,
  })

  return table
}
