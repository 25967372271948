/**
 * Primarily copy/paste from floating-ui's docs -- provides a generic "popover"
 * component.
 *
 * @see https://floating-ui.com/docs/popover#reusable-popover-component
 */
import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingFocusManagerProps,
  FloatingPortal,
  offset,
  Placement,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useMergeRefs,
  useRole,
} from "@floating-ui/react"
import React, { createContext, useState } from "react"

interface PopoverOptions {
  allowFlip?: boolean
  initialOpen?: boolean
  modal?: boolean
  placement?: Placement
  open?: boolean
  onOpenChange?: (open: boolean) => void
}
export function usePopover({
  allowFlip = true,
  initialOpen = false,
  modal,
  placement = "bottom",
  open: controlledOpen,
  onOpenChange: setControlledOpen,
}: PopoverOptions = {}) {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen)
  const [labelId, setLabelId] = useState<string | undefined>()
  const [descriptionId, setDescriptionId] = useState<string | undefined>()

  const open = controlledOpen ?? uncontrolledOpen
  const setOpen = setControlledOpen ?? setUncontrolledOpen

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      !allowFlip &&
        size({
          apply({ availableHeight, elements }) {
            Object.assign(elements.floating.style, {
              maxHeight: `${availableHeight}px`,
            })
          },
        }),
      flip({
        mainAxis: allowFlip,
        crossAxis: placement.includes("-"),
        fallbackAxisSideDirection: "end",
        padding: 5,
      }),
      shift({ padding: 5 }),
    ],
  })

  const context = data.context

  const click = useClick(context, {
    enabled: controlledOpen == null,
  })
  const dismiss = useDismiss(context)
  const role = useRole(context)

  const interactions = useInteractions([click, dismiss, role])

  return React.useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
      modal,
      labelId,
      descriptionId,
      setLabelId,
      setDescriptionId,
    }),
    [open, setOpen, interactions, data, modal, labelId, descriptionId],
  )
}
type ContextType =
  | (ReturnType<typeof usePopover> & {
      setLabelId: React.Dispatch<React.SetStateAction<string | undefined>>
      setDescriptionId: React.Dispatch<React.SetStateAction<string | undefined>>
    })
  | null

const PopoverContext = createContext<ContextType>(null)

export const usePopoverContext = () => {
  const context = React.useContext(PopoverContext)

  if (context == null) {
    throw new Error("Popover components must be wrapped in <Popover />")
  }

  return context
}

export function Popover({
  children,
  modal = false,
  ...restOptions
}: {
  children: React.ReactNode
} & PopoverOptions) {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const popover = usePopover({ modal, ...restOptions })

  return <PopoverContext.Provider value={popover}>{children}</PopoverContext.Provider>
}

interface PopoverTriggerProps {
  children: React.ReactNode
  asChild?: boolean
}
export const PopoverTrigger = React.forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement> & PopoverTriggerProps
>(({ children, asChild = false, ...props }, propRef) => {
  const context = usePopoverContext()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const childrenRef = (children as any).ref
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef])
  // `asChild` allows the user to pass any element as the anchor
  if (asChild && React.isValidElement(children)) {
    return React.cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        "data-state": context.open ? "open" : "closed",
      }),
    )
  }
  return (
    <button
      ref={ref}
      type="button"
      // The user can style the trigger based on the state
      data-state={context.open ? "open" : "closed"}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...context.getReferenceProps(props)}
    >
      {children}
    </button>
  )
})
type PopoverContentProps = {
  focusManagerCloseOnFocusOut?: FloatingFocusManagerProps["closeOnFocusOut"]
  focusManagerDisabled?: FloatingFocusManagerProps["disabled"]
  focusManagerGuards?: FloatingFocusManagerProps["guards"]
  focusManagerInitialFocus?: FloatingFocusManagerProps["initialFocus"]
  focusManagerOrder?: FloatingFocusManagerProps["order"]
  focusManagerReturnFocus?: FloatingFocusManagerProps["returnFocus"]
  focusManagerVisuallyHiddenDismiss?: FloatingFocusManagerProps["visuallyHiddenDismiss"]
  style?: React.CSSProperties
} & React.HTMLProps<HTMLDivElement>
export const PopoverContent = React.forwardRef<HTMLDivElement, PopoverContentProps>(
  // eslint-disable-next-line react/prop-types
  (
    {
      focusManagerCloseOnFocusOut,
      focusManagerDisabled,
      focusManagerGuards,
      focusManagerInitialFocus,
      focusManagerOrder,
      focusManagerReturnFocus,
      focusManagerVisuallyHiddenDismiss,
      style,
      ...props
    },
    propRef,
  ) => {
    const { context: floatingContext, ...context } = usePopoverContext()
    const ref = useMergeRefs([context.refs.setFloating, propRef])

    if (!floatingContext.open) return null

    return (
      <FloatingPortal>
        <FloatingFocusManager
          closeOnFocusOut={focusManagerCloseOnFocusOut}
          disabled={focusManagerDisabled}
          guards={focusManagerGuards}
          initialFocus={focusManagerInitialFocus}
          order={focusManagerOrder}
          returnFocus={focusManagerReturnFocus}
          visuallyHiddenDismiss={focusManagerVisuallyHiddenDismiss}
          context={floatingContext}
          modal={context.modal}
        >
          <div
            ref={ref}
            style={{ ...context.floatingStyles, ...style }}
            aria-labelledby={context.labelId}
            aria-describedby={context.descriptionId}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...context.getFloatingProps(props)}
          >
            {props.children}
          </div>
        </FloatingFocusManager>
      </FloatingPortal>
    )
  },
)
