import React, { Ref, useCallback, useEffect, useImperativeHandle, useMemo, useRef } from "react"
import { defaultCellRangeRenderer, Grid, ScrollParams } from "react-virtualized"

import { Cell, CellProps, MemoCell } from "v2/react/components/orgChart/Datasheet/Cell"
import {
  Column,
  CursorConnection,
  GroupRow,
  NodeRow,
  RowType,
  StatsRow,
} from "v2/react/components/orgChart/Datasheet/types"
import {
  CELL_PADDING_PX,
  ROW_HEIGHT_PX,
} from "v2/react/components/orgChart/Datasheet/utils/constants"

interface GridBodyProps<TNode, CType = TNode> {
  CellComponent?: (props: CellProps<TNode, CType>) => React.ReactNode
  onCellClick: (row: NodeRow<TNode>, column: Column<CType>) => void
  onExpandCollapseGrouping: (row: GroupRow) => void
  columns: Column<CType>[]
  cellRangeRenderer?: typeof defaultCellRangeRenderer
  cursorConnection?: CursorConnection
  sheetRef?: Ref<{
    contains: (element: Node | null) => boolean
    scrollToCell: (c: { rowIndex: number; columnIndex: number }) => void
  }>
  height: number
  width: number
  onScroll?: ((params: ScrollParams) => void) | undefined
  rows: (GroupRow | NodeRow<TNode> | StatsRow)[]
  rowCount: number
  showColumnStats: boolean
  containerRef: React.RefObject<HTMLDivElement>
  footerHeight: number
}

type CellRenderer = (a: {
  columnIndex: number
  key: string
  rowIndex: number
  style: React.CSSProperties
}) => ReturnType<typeof MemoCell>

function GridBody<TNode, CType = TNode>({
  CellComponent = Cell,
  onExpandCollapseGrouping,
  onCellClick,
  cellRangeRenderer = defaultCellRangeRenderer,
  columns,
  cursorConnection,
  sheetRef,
  rows,
  rowCount,
  height,
  width,
  showColumnStats,
  containerRef,
  footerHeight,
  onScroll,
}: GridBodyProps<TNode, CType>) {
  const gridRef = useRef<Grid>(null)
  const htmlRef = useRef<HTMLDivElement>(null)

  useImperativeHandle(
    sheetRef,
    () => ({
      contains: (maybeNode) => htmlRef.current?.contains?.(maybeNode) ?? false,
      scrollToCell: (params) => {
        gridRef.current?.scrollToCell?.(params)
        const cellButton = document.getElementById(
          `cell-${rows[params.rowIndex].id}-${String(columns[params.columnIndex].fieldKey)}`,
        )
        const cell = cellButton?.firstChild
        if (cell instanceof HTMLElement && containerRef.current) {
          const cellRect = cell.getBoundingClientRect()
          const tableContainerRect = containerRef.current.getBoundingClientRect()

          if (cellRect.right > tableContainerRect.right)
            cell.scrollIntoView({
              behavior: "auto",
              block: "nearest",
              inline: "start",
            })

          if (cellRect.left < tableContainerRect.left)
            cell.scrollIntoView({
              behavior: "auto",
              block: "nearest",
              inline: "end",
            })
        }
      },
    }),
    [gridRef, containerRef, columns, rows],
  )

  const statRowIndexes = useMemo(
    () =>
      rows.reduce(
        (acc, row, idx) => (row.rowType === RowType.Stats ? [...acc, idx] : acc),
        [] as number[],
      ),
    [rows],
  )
  const statRowIndexKey = statRowIndexes.join(".")

  useEffect(() => {
    if (gridRef.current === null) return
    gridRef.current.recomputeGridSize()
  }, [width, statRowIndexKey])

  const cellRenderer: CellRenderer = useCallback(
    ({ columnIndex, key, rowIndex, style }) => (
      <CellComponent
        boundary={htmlRef.current ?? undefined}
        column={columns[columnIndex]}
        cursorConnection={cursorConnection}
        footerHeight={footerHeight}
        isFirst={columnIndex === 0}
        isFirstRow={rowIndex === 0}
        isLast={columnIndex + 1 >= columns.length}
        isLastRow={rowIndex === rowCount - 1}
        key={key}
        onExpandCollapseGrouping={onExpandCollapseGrouping}
        row={rows[rowIndex]}
        onClick={onCellClick}
        style={style}
      />
    ),
    [
      CellComponent,
      columns,
      cursorConnection,
      footerHeight,
      onCellClick,
      onExpandCollapseGrouping,
      rowCount,
      rows,
    ],
  )
  const containerStyle = useMemo(
    () =>
      innerGridStyles(
        width,
        rowCount,
        statRowIndexes.length,
        ROW_HEIGHT_PX,
        CELL_PADDING_PX,
        showColumnStats,
        footerHeight,
      ),
    [width, rowCount, statRowIndexes, showColumnStats, footerHeight],
  )
  const memoizedColumnWidth = useCallback(
    ({ index }: { index: number }) => columns[index].width,
    [columns],
  )
  const memoizedRowHeight = useCallback(
    ({ index }: { index: number }) =>
      rows[index].rowType === RowType.Stats ? footerHeight : ROW_HEIGHT_PX,
    [rows, footerHeight],
  )
  const gridStyle = useMemo(() => ({ width }), [width])

  return (
    <div className="grid-body" ref={htmlRef}>
      <Grid
        ref={gridRef}
        cellRenderer={cellRenderer}
        cellRangeRenderer={cellRangeRenderer}
        columnCount={columns.length}
        style={gridStyle}
        containerStyle={containerStyle}
        columnWidth={memoizedColumnWidth}
        height={height}
        rowCount={rowCount}
        rowHeight={memoizedRowHeight}
        width={width}
        onScroll={onScroll}
      />
    </div>
  )
}

export { GridBody }

const innerGridStyles = (
  width: number,
  rowCount: number,
  statRowCount: number,
  rowHeight: number,
  padding: number,
  showColumnStats: boolean,
  footerHeight: number,
) => ({
  width,
  maxWidth: width,
  // prettier-ignore
  height: ((rowCount - statRowCount) * rowHeight) + (statRowCount * footerHeight),
  // prettier-ignore
  maxHeight: ((rowCount - statRowCount) * rowHeight) + (statRowCount * footerHeight),
})
