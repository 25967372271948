import { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { removeTooltip, tooltipHandler } from "v2/react/utils/tooltipHandler"
import { closeProfilePlanSidebar } from "v2/redux/slices/ProfilePanelSlice"
import { openSuccessionPlanSidebar } from "v2/redux/slices/SuccessionPlanPanelSlice"
import { useAppDispatch } from "v2/redux/store"

const TRIGGER_SELECTOR = ".succession-panel-trigger"

/**
 * In various spots throughout the app we need to open the succession panel
 * outside of a React component. This component adds an event listener that has
 * access to the redux context, so it can open the succession panel when a
 * trigger is clicked.
 *
 * In order for things to work correctly, the trigger element must have the
 * following data attributes defined:
 * - data-position-id: The ID of the position to open the succession panel for.
 *
 * Example usage:
 * ```html
 *  <button
 *    class="succession-panel-trigger"
 *    data-position-id="123"
 *   >
 *    Open Succession Panel!!!
 *   </button>
 * ```
 */
const usePanelListeners = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const target = event.target
      if (!(target instanceof Element)) return

      const trigger = target?.closest(TRIGGER_SELECTOR)

      if (trigger?.contains(target)) {
        event.preventDefault()
        event.stopPropagation()

        const positionId = trigger.getAttribute("data-position-id")

        if (trigger.getAttribute("data-remove-tooltip-on-click") === "true") {
          removeTooltip(trigger, TRIGGER_SELECTOR)
        }

        if (!positionId) return

        dispatch(closeProfilePlanSidebar())
        dispatch(openSuccessionPlanSidebar({ positionId }))
      }
    }

    const handleMouseEnter = tooltipHandler(TRIGGER_SELECTOR, t("v2.succession.info_panel"))

    document.addEventListener("click", handleClick)
    document.addEventListener("mouseenter", handleMouseEnter, true)
    return () => {
      document.removeEventListener("click", handleClick)
      document.removeEventListener("mouseenter", handleMouseEnter, true)
    }
  }, [dispatch, t])
}

export { usePanelListeners }
