import React, { Fragment } from "react"
import { useTranslation } from "react-i18next"

import { HeadcountPlan } from "types/graphql.d"
import { BudgetingHeaders } from "v2/react/components/headcountPlanning/Overview/ParticipantTable/BudgetingHeaders"

import { EntireOrgRow } from "./EntireOrgRow"
import { ParticipantTableRow, type Participant } from "./ParticipantTableRow"

interface Props {
  headcountPlanId: string
  logoThumbUrl: string
  canBeFinalized: boolean
  companyName: string
  participants: Participant[]
  showEntireOrgRow: boolean
  totalBudget: HeadcountPlan["budget"]
  totalProposal: HeadcountPlan["proposedBudget"]
  totalRemainingBudget: HeadcountPlan["remainingBudget"]
  showBudget: boolean
  showProposal: boolean
}

function ParticipantTable({
  headcountPlanId,
  logoThumbUrl,
  canBeFinalized,
  companyName,
  participants,
  showEntireOrgRow,
  totalBudget,
  totalProposal,
  totalRemainingBudget,
  showBudget,
  showProposal,
}: Props) {
  const { t } = useTranslation()

  return (
    <div className="table hcp-overview-table">
      <table>
        <thead>
          <tr>
            <th>
              <div className="place-content-center px-4">
                {t("v2.headcount_plan.overview.columns.planning_environments")}
              </div>
            </th>
            <th>
              <div className="place-content-center px-4">
                {t("v2.headcount_plan.overview.columns.plan_owner")}
              </div>
            </th>
            <th>
              <div className="place-content-center px-4">
                {t("v2.headcount_plan.overview.columns.department")}
              </div>
            </th>
            <BudgetingHeaders showBudget={showBudget} showProposal={showProposal} />
            <th className="hcp-overview-table__status-cell">
              <div className="place-content-center px-4">
                {t("v2.headcount_plan.overview.columns.status")}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {showEntireOrgRow ? (
            <EntireOrgRow
              headcountPlanId={headcountPlanId}
              logoThumbUrl={logoThumbUrl}
              canBeFinalized={canBeFinalized}
              companyName={companyName}
              showBudget={showBudget}
              showProposal={showProposal}
              budget={totalBudget}
              proposal={totalProposal}
              remainingBudget={totalRemainingBudget}
            />
          ) : null}
          {rowsAtLevel({
            rows: participants,
            level: showEntireOrgRow ? 1 : 0,
            continuationLineLevels: [1],
            headcountPlanId,
            showBudget,
            showProposal,
          })}
        </tbody>
      </table>
    </div>
  )
}

interface RowsAtLevel {
  rows: Participant[]
  level: number
  continuationLineLevels: number[]
  headcountPlanId: string
  showBudget: boolean
  showProposal: boolean
}

const rowsAtLevel = ({
  rows,
  level,
  continuationLineLevels,
  headcountPlanId,
  showBudget,
  showProposal,
}: RowsAtLevel): React.ReactNode[] =>
  rows.map((participant: Participant, index: number) => {
    const isLastSibling = index === rows.length - 1
    const updatedContinuationLineLevels = updateContinuationLineLevels(
      isLastSibling,
      level,
      continuationLineLevels,
    )

    return (
      <Fragment key={`${participant.id}-${participant.role || ""}`}>
        <ParticipantTableRow
          continuationLineLevels={updatedContinuationLineLevels}
          headcountPlanId={headcountPlanId}
          isLastSibling={isLastSibling}
          level={level}
          participant={participant}
          showBudget={showBudget}
          showProposal={showProposal}
        />
        {participant.children &&
          rowsAtLevel({
            rows: participant.children,
            level: level + 1,
            continuationLineLevels: updatedContinuationLineLevels,
            headcountPlanId,
            showBudget,
            showProposal,
          })}
      </Fragment>
    )
  })

// Dictates at which levels there is a continuation line to the next sibling.
// When a node is the last child, end any continuation line by removing the
// level from this lookup array.
const updateContinuationLineLevels = (
  isLastSibling: boolean,
  level: number,
  continuationLineLevels: number[],
) => {
  if (!isLastSibling) return [...continuationLineLevels, level]
  return continuationLineLevels.filter((continuationLevel) => continuationLevel !== level)
}

export { ParticipantTable }
