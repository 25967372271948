import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import { AnimatePresence } from "framer-motion"
import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { Position } from "types/graphql"
import { CompareSettingsModal } from "v2/react/components/succession/SuccessionPlanPanel/Compare/CompareSettingsModal"
import { EditMatrixSettingsButton } from "v2/react/components/succession/SuccessionPlanPanel/Matrix/EditMatrixSettingsButton"
import { MotionOpaqueScale } from "v2/react/components/succession/SuccessionPlanPanel/shared/MotionWrappers"
import { TruncatedText } from "v2/react/components/succession/SuccessionPlanPanel/shared/TruncatedText"
import { CandidateFormDropdown } from "v2/react/components/succession/SuccessionPlanPanel/Summary/CandidateFormDropdown"
import { Tabs } from "v2/react/components/succession/SuccessionPlanPanel/Tabs"
import { positionTitleDisplay } from "v2/react/components/succession/SuccessionPlanPanel/utils/positionMappers"
import { useDetectSticky } from "v2/react/components/succession/SuccessionPlanPanel/utils/useDetectSticky"
import { useGetSuccessionPanelSettingsQuery } from "v2/redux/GraphqlApi/SuccessionApi"
import { useAppSelector } from "v2/redux/store"

interface Props {
  canManageSuccessionSettings: boolean
  hidePanel: () => void
  position: Position
}

function PanelHeader({ canManageSuccessionSettings, hidePanel, position }: Props) {
  const { t } = useTranslation()
  const [compareSettingsOpen, setCompareSettingsOpen] = useState(false)
  const activeTab = useAppSelector(
    (state) => state.successionPlanPanel.activeSuccessionPlanPanelTab,
  )
  const { data: settings } = useGetSuccessionPanelSettingsQuery({})

  const headerRef = useRef<HTMLElement>(null)
  const scrollRef = document.querySelector(".panel-scroll-container")
  const isSticky = useDetectSticky(headerRef, { root: scrollRef, threshold: [1] })
  const showTabButtons = !isSticky || (isSticky && activeTab !== "summary")
  const showTitleContent = isSticky || activeTab !== "summary"
  const showAddCandidateButton =
    activeTab === "compare" ||
    (activeTab === "matrix" &&
      position.successionPlan?.candidates &&
      position.successionPlan?.candidates.length > 0)

  if (!settings || !settings.currentPerson?.settings?.successionPanelSettings) return null

  return (
    <>
      <header
        ref={headerRef}
        className={cn(
          "sticky inset-x-0 top-[-1px] z-4 w-full grid-cols-[1fr_auto_1fr] gap-2 border-b-neutral-8 bg-white p-2 grid",
          {
            "grid-cols-[2fr_1fr]": isSticky && activeTab === "summary",
          },
        )}
      >
        {showTitleContent ? (
          <MotionOpaqueScale classNames="w-full max-w-full overflow-hidden" show>
            <h3 className="text-sm text-neutral-64">
              {t("v2.succession_plan_panel.header.plan_for")}
            </h3>
            <TruncatedText
              displayClasses="text-large"
              displayContent={`${position.systemIdentifier} - ${positionTitleDisplay(position, t)}`}
              tooltipContent={positionTitleDisplay(position, t)}
            />
          </MotionOpaqueScale>
        ) : (
          <span />
        )}
        <MotionOpaqueScale show={showTabButtons}>
          <Tabs />
        </MotionOpaqueScale>

        <div className="ml-auto items-center gap-2 flex">
          <AnimatePresence initial>
            {showAddCandidateButton && (
              <CandidateFormDropdown
                buttonType="secondary"
                dropdownPlacement="bottom-end"
                positionId={position.id}
              />
            )}
            <MotionOpaqueScale key="compare-settings" show={activeTab === "compare"}>
              <button
                className="btn--large btn--secondary tooltip tooltip-right"
                id="configure-succession-plan-panel"
                onClick={() => setCompareSettingsOpen(true)}
                type="button"
              >
                <FontAwesomeIcon icon={["far", "cog"]} />
                <span className="tooltip-content tooltip-content--sm">
                  {t("v2.succession_plan_panel.compare.configure")}
                </span>
              </button>
            </MotionOpaqueScale>

            <MotionOpaqueScale
              key="matrix-settings"
              show={activeTab === "matrix" && canManageSuccessionSettings}
            >
              <EditMatrixSettingsButton />
            </MotionOpaqueScale>
          </AnimatePresence>
          <button type="button" className="btn--sm btn--ghost close" onClick={hidePanel}>
            <FontAwesomeIcon icon={["far", "times"]} />
          </button>
        </div>
      </header>

      <CompareSettingsModal
        isOpen={compareSettingsOpen}
        handleClose={() => setCompareSettingsOpen(false)}
        settings={settings.currentPerson.settings.successionPanelSettings}
      />
    </>
  )
}

export { PanelHeader }
