import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useTranslation } from "react-i18next"

import { Dropdown } from "v2/react/shared/collection/menus/Dropdown/Dropdown"
import { useUpdatePersonOrgChartSettingsMutation } from "v2/redux/GraphqlApi"
import { configureColorCoding } from "v2/redux/slices/VisualizationSlice"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

function ColorPalettePicker() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const colorPalette = useAppSelector((state) => state.visualization.useColorsFrom)
  const [updateOrgChartSettings] = useUpdatePersonOrgChartSettingsMutation()

  const handleChangePalette = async (selection: "company" | "person") => {
    updateOrgChartSettings({ input: { useColorsFrom: selection } })
    dispatch(configureColorCoding({ useColorsFrom: selection }))
  }

  return (
    <Dropdown align="right">
      <Dropdown.Trigger triggerClassName="border--main h-10 rounded-lg px-3 w-full bg-transparent flex items-center space-between">
        <div className="items-center gap-2 flex">
          <FontAwesomeIcon icon={["far", "palette"]} />
          <p className="text-neutral-64">{t(`v2.orgchart.super_panel.palette_${colorPalette}`)}</p>
        </div>
        <FontAwesomeIcon icon={["fas", "caret-down"]} className="icon-14 ml-auto" />
      </Dropdown.Trigger>

      <Dropdown.Menu className="dropdown-menu right-0 z-0 mt-2 w-[16rem] p-2 !block">
        <Dropdown.Item
          className="text-nowrap grid-cols-[1.25rem_auto] items-center gap-2 grid"
          onClick={() => handleChangePalette("company")}
        >
          <p>{t("v2.orgchart.super_panel.palette_company")}</p>
        </Dropdown.Item>
        <Dropdown.Item
          className="text-nowrap grid-cols-[1.25rem_auto] items-center gap-2 grid"
          onClick={() => handleChangePalette("person")}
        >
          <p>{t("v2.orgchart.super_panel.palette_person")}</p>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export { ColorPalettePicker }
