import React, { useEffect, useMemo, useRef } from "react"

import { FieldsetWithSearch } from "v2/react/shared/forms/FieldsetWithSearch"
import { useFieldsetWithSearch } from "v2/react/shared/forms/FieldsetWithSearch/hooks/useFieldsetWithSearch"

import { HeadcountPlanColumn } from "./types"

type HCPFieldsetField = Omit<HeadcountPlanColumn, "name" | "isSelected"> & {
  label: string
  disabled: boolean
  checked: boolean
}

function PlanColumns({ columns, budgetOn }: { columns: HeadcountPlanColumn[]; budgetOn: boolean }) {
  const firstRender = useRef(true)
  // We only need to build these on the initial render.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const hcpFields = useMemo(() => buildInitialFields(columns, budgetOn), [])

  const {
    selectedFields,
    setSelectedFields,
    unselectedFields,
    setUnselectedFields,
    handleReorder,
    handleSearchSelect: onSearchSelect,
  } = useFieldsetWithSearch({
    selectedFieldOptions: hcpFields.filter((field) => field.checked),
    allFields: hcpFields,
  })

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }

    handleSetBudgetChange(columns, budgetOn, selectedFields, setSelectedFields, setUnselectedFields)

    // We only want this to run when `budgetOn` changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgetOn])

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, id } = e.target

    const newSelectedFields = selectedFields.map((field) =>
      field.id === id ? { ...field, checked } : field,
    )

    setSelectedFields(newSelectedFields)
  }

  const handleSearchSelect = (selectedField: HCPFieldsetField) => {
    onSearchSelect(selectedField)
    setSelectedFields((prevSelectedFields) =>
      prevSelectedFields.map((field) =>
        field.id === selectedField.id ? { ...field, checked: true } : field,
      ),
    )
  }

  return (
    <div className="input-group">
      <FieldsetWithSearch
        label={"setup_columns_label".t("headcount_plan")}
        id="hcp-columns"
        selectedItems={selectedFields}
        searchableItems={unselectedFields}
        onReorder={handleReorder}
        onSearchSelect={handleSearchSelect}
        onCheckboxChange={handleCheckboxChange}
        makeCheckboxName={() => "columns[]"}
      />
    </div>
  )
}

/**
 * Any fields that aren't removable are initialized as checked and disabled
 * here.
 *
 * If the budget is on, then all budget fields are checked and disabled.
 */
const buildInitialFields = (
  columns: HeadcountPlanColumn[],
  budgetOn: boolean,
): HCPFieldsetField[] =>
  columns
    .filter((column) => !column.dependsOn)
    .map(({ name, isSelected, ...rest }) => ({
      label: name,
      disabled: !rest.isRemovable || (budgetOn && !!rest.isBudget),
      checked: !!isSelected || (budgetOn && !!rest.isBudget),
      ...rest,
    }))

/**
 * When the budget is on, we ensure that all budget fields are checked and
 * disabled.
 *
 * When the budget is off, we ensure that all budget fields are now
 * removable.
 */
const handleSetBudgetChange = (
  columns: HeadcountPlanColumn[],
  budgetOn: boolean,
  selectedFields: HCPFieldsetField[],
  setSelectedFields: (value: React.SetStateAction<HCPFieldsetField[]>) => void,
  setUnselectedFields: (value: React.SetStateAction<HCPFieldsetField[]>) => void,
) => {
  if (budgetOn) {
    const budgetFieldUnselected = selectedFields.filter((field) => field.isBudget).length === 0
    if (budgetFieldUnselected) {
      setSelectedFields((prev) => {
        const budgetColumns = buildInitialFields(
          columns.filter((column) => column.isBudget),
          budgetOn,
        )
        return prev.concat(budgetColumns)
      })
      setUnselectedFields((prev) => prev.filter((field) => !field.isBudget))
    } else {
      setSelectedFields((prev) =>
        prev.map((field) => {
          if (field.isRemovable && field.isBudget) {
            return { ...field, disabled: true, checked: true }
          }
          return field
        }),
      )
    }
  } else {
    setSelectedFields((prev) =>
      prev.map((field) => {
        if (field.isRemovable && field.isBudget) {
          return { ...field, disabled: false }
        }
        return field
      }),
    )
  }
}

export { PlanColumns }
