import React from "react"
import { useTranslation } from "react-i18next"

import { HeadcountPlan } from "types/graphql"
import { formatCurrency, parseCurrency } from "v2/react/utils/currency"

import { BudgetEditableCell } from "./BudgetEditableCell"

interface Props {
  headcountPlan: HeadcountPlan
  logoThumbUrl: string
  companyName: string
  isErrored: boolean
}

function EntireOrgRow({ headcountPlan, logoThumbUrl, companyName, isErrored }: Props) {
  const { t } = useTranslation()

  return (
    <tr data-virtual-row-start="0">
      <td>
        <div className="hcp-overview-table__entire-org-label">
          <div className="hcp-overview-table__node-label">
            <div className="hcp-overview-table__entire-org-node-logo">
              <img src={logoThumbUrl} alt="logoThumbUrl" />
            </div>
          </div>
          <a
            href={`/headcount_plans/${headcountPlan.id}/owner`}
            className="hcp-overview-table__env-link p-3"
          >
            {companyName}
          </a>
        </div>
      </td>
      <td aria-label="empty">
        <div className="h-[56px]" />
      </td>
      <td>
        <div className="h-[56px]">
          <BudgetEditableCell
            inputName={headcountPlan.uniqueKey}
            isErrored={isErrored}
            currentValue={
              headcountPlan.budget === null
                ? ""
                : formatCurrency({
                    value: parseCurrency(headcountPlan.budget || 0),
                    omitSymbol: false,
                  })
            }
            inputPlaceholder={t("v2.headcount_plan.set_budgets.set_budget")}
          />
        </div>
      </td>
    </tr>
  )
}

export { EntireOrgRow }
