import {
  autoUpdate,
  flip,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
} from "@floating-ui/react"
import { useRef, useState } from "react"

interface AutocompleteProps {
  showList: boolean
}

export function useSelectList({ showList }: AutocompleteProps) {
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const listRef = useRef<Array<HTMLElement | null>>([])

  const { refs, floatingStyles, context } = useFloating<HTMLInputElement>({
    whileElementsMounted: autoUpdate,
    open: showList,
    placement: "bottom-start",
    middleware: [
      flip((state) => ({
        mainAxis: state.rects.floating.width > state.rects.reference.width,
      })),
      size({
        apply({ rects, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            // 32px is the width of the down arrow on the select cell
            // This makes sure that the dropdown doesn't cover up the arrow
            // unless it needs to.
            minWidth: `${rects.reference.width - 32}px`,
            maxHeight: `${availableHeight}px`,
          })
        },
      }),
    ],
  })

  const click = useClick(context, { event: "mousedown" })
  const role = useRole(context, { role: "listbox" })
  const dismiss = useDismiss(context)
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
    virtual: true,
    loop: true,
  })

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    role,
    dismiss,
    listNav,
    click,
  ])

  return {
    activeIndex,
    setActiveIndex,
    listRef,
    refs,
    floatingStyles,
    context,
    getReferenceProps,
    getFloatingProps,
    getItemProps,
  }
}
