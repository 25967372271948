import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { JsonForms } from "@jsonforms/react"
import { JsonFormsStyleContext, vanillaCells } from "@jsonforms/vanilla-renderers"
import React from "react"

import { Header } from "v2/react/components/jobRequisitions/Overview/Header/Header"
import { RequisitionApprovalBar } from "v2/react/components/jobRequisitions/Overview/RequisitionApprovalBar"
import { RightSection } from "v2/react/components/jobRequisitions/Overview/RightSection/RightSection"
import { SidebarModals } from "v2/react/components/jobRequisitions/Overview/SidebarModals"
import { viewRenderers } from "v2/react/components/jobRequisitions/renderers"
import { JobRequisitionAbilities } from "v2/react/components/jobRequisitions/types"
import RootProvider from "v2/react/components/RootProvider"
import { setEditApproversModalOpen } from "v2/redux/slices/RequisitionSlice"
import { useAppDispatch } from "v2/redux/store"

import { DiffFromHeadcountPlanPositionData } from "./DiffFromHeadcountPlanPositionData"
import { OverviewEditRequisitionModal } from "./OverviewEditRequisitionModal"
import { OverviewProvider, useJobRequisitionOverview } from "./OverviewProvider"

const styleContextValue = { styles: [] }
interface Props {
  abilities: JobRequisitionAbilities
  jobRequisitionId: string
}

function OverviewInner({ abilities }: Omit<Props, "jobRequisitionId">) {
  const dispatch = useAppDispatch()
  const { jobRequisition, isLoading } = useJobRequisitionOverview()

  const showApproversModal = () => {
    dispatch(setEditApproversModalOpen(true))
  }

  if (isLoading || !jobRequisition) return null

  return (
    <>
      <Header
        abilities={abilities}
        data={jobRequisition.jsonFormValues.data}
        jobRequisition={jobRequisition}
      />

      <div className="requisition-overview page-pad !pb-0">
        {jobRequisition.approvalState === "no_configuration" && (
          <div className="alert-critical page-margin-b gap-1.5 flex">
            <FontAwesomeIcon icon={["far", "exclamation-circle"]} className="mt-1" />
            <div>
              <p className="text-base-bold">{"no_configuration".t("status_defaults")}</p>
              <p>{"no_configuration_subheading".t("job_requisition")}</p>
            </div>
            {!window.gon.limited_or_nonadmin_manager && (
              <button
                className="btn--large btn--secondary ml-auto"
                onClick={showApproversModal}
                type="button"
              >
                {"configure_approvers".t("job_requisition")}
              </button>
            )}
          </div>
        )}
        <RequisitionApprovalBar
          workflowStatus={jobRequisition.approvalState || ""}
          creatorName={jobRequisition.creator?.name}
          approvers={jobRequisition.approvers || []}
        />

        <div className="page-gap grid-cols-1 grid 900:grid-cols-[2fr_1fr]">
          <div className="order-2 900:order-1">
            <DiffFromHeadcountPlanPositionData mode="alert" />
            <JsonFormsStyleContext.Provider value={styleContextValue}>
              <div className="create-req-form flex-col p-0 flex">
                <JsonForms
                  schema={jobRequisition.jsonFormValues.data_schema}
                  uischema={jobRequisition.jsonFormValues.ui_table_schema}
                  data={jobRequisition.jsonFormValues.data}
                  renderers={viewRenderers}
                  cells={vanillaCells}
                />
              </div>
            </JsonFormsStyleContext.Provider>
          </div>
          <div className="order-1 900:order-2">
            <RightSection
              abilities={abilities}
              approvalState={jobRequisition.approvalState || ""}
              approvers={jobRequisition.approvers || []}
              atsIdentifier={jobRequisition.atsIdentifier || ""}
              clientIdentifier={jobRequisition.clientIdentifier || ""}
              externalStatus={jobRequisition.status || ""}
              isReplacement={(jobRequisition.replacementFor || "").length !== 0}
              jobDescription={jobRequisition.jobDescription || ""}
              jobRequisitionId={jobRequisition.id || ""}
              notificationRecipients={jobRequisition.notificationRecipients || []}
              recruiters={jobRequisition.recruiters || []}
              replacementFor={jobRequisition.replacementFor || ""}
              systemUid={jobRequisition.systemUid || ""}
              title={jobRequisition.jobTitle || ""}
              workflowDate={jobRequisition.createdAt}
              numPositions={
                (jobRequisition.replacementFor || "").length !== 0
                  ? 1
                  : jobRequisition.sourceOpenings ?? 0
              }
            />
          </div>
        </div>
      </div>
      <SidebarModals
        approvers={jobRequisition.approvers || []}
        recruiters={jobRequisition.recruiters || []}
        jobRequisitionId={jobRequisition.id || ""}
        notificationRecipients={jobRequisition.notificationRecipients || []}
      />
      <OverviewEditRequisitionModal />
    </>
  )
}

const Overview = ({ abilities, jobRequisitionId }: Props) => (
  <RootProvider>
    <OverviewProvider jobRequisitionId={jobRequisitionId}>
      <OverviewInner abilities={abilities} />
    </OverviewProvider>
  </RootProvider>
)

export { Overview }
