import { TFunction } from "i18next"

import type { Option } from "types/graphql"

const getButtonText = (
  optionCount: number,
  selectedOptions: Option[],
  t: TFunction,
  isSearching?: boolean,
) => {
  if (optionCount <= 0 && !isSearching) {
    return t("v2.defaults.no_options")
  }
  if (selectedOptions.length === 0) {
    return t("v2.defaults.select_an_option")
  }
  if (selectedOptions.length === 1) {
    return selectedOptions[0].label
  }

  return `${selectedOptions[0].label} +${selectedOptions.length - 1}`
}

export { getButtonText }
