import React, { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"

import { AllowedAttribute } from "types/graphql"
import { WatchFieldName } from "v2/react/components/positions/positionFieldValuesDiff"
import {
  mapFieldValuesToPositionAttributes,
  mapGraphQLErrorsIntoForm,
  PositionForm,
  SubmitHandler,
} from "v2/react/components/positions/positionForm"
import { useCurrentActiveSession } from "v2/react/hooks/useSessionHooks"
import { Modal, ModalFooter } from "v2/react/shared/overlay/Modal"
import { uniqueKeyFromEntityAndId } from "v2/react/utils/uniqueKey"
import {
  useHeadcountPlansCreateChartPositionMutation,
  useHeadcountPlansGetInitialChartPositionDataQuery,
} from "v2/redux/GraphqlApi/HeadcountPlanningApi"
import {
  PositionFormCollections,
  useCurrentCompanyPositionFormDataQuery,
} from "v2/redux/GraphqlApi/PositionsApi"

import { DiffOfPositionAndHeadcountPlanDatasheetRow } from "./DiffOfPositionAndHeadcountPlanDatasheetRow"
import { HeadcountPlanDatasheetRow } from "./HeadcountPlanDatasheet/types"
import { useWatchKeysFromAllowedAttributesForPositionDiff } from "./hooks/useWatchKeysFromAllowedAttributesForPositionDiff"

const IGNORE_WATCH_FIELD_NAMES: WatchFieldName[] = [
  "position.budgetedBaseCompensation",
  "position.totalBudgetedCompensation",
]

type AddPositionFormRowModalProps = {
  allowedAttributes: AllowedAttribute[]
  chartKey: string
  headcountPlanId: string
  isOpen: boolean
  onClose: () => void
  row: HeadcountPlanDatasheetRow
}
type UseCreateChartPositionSubmitHandlerArg = {
  headcountPlanId: string
  onClose: () => void
  positionFormCollections: PositionFormCollections | undefined
  rootEventId: string
  revisionNumber: number
}

function AddPositionFormRowModal({
  allowedAttributes,
  chartKey,
  headcountPlanId,
  isOpen,
  onClose,
  row,
}: AddPositionFormRowModalProps) {
  const { t } = useTranslation()
  const { data: auxiliaryData } = useCurrentCompanyPositionFormDataQuery(
    {
      chartKey,
      parentKey: row.positionAttributesWithEdits.reports_to?.id
        ? uniqueKeyFromEntityAndId("position", row.positionAttributesWithEdits.reports_to.id)
        : null,
    },
    { skip: !isOpen },
  )
  const { data: initialChartPosition } = useHeadcountPlansGetInitialChartPositionDataQuery(
    {
      rootEventId: row.rootEventId ?? "",
      headcountPlanId,
      chartId: chartKey,
    },
    { skip: !isOpen },
  )
  const positionData =
    initialChartPosition?.headcountPlan?.initialPositionFromHeadcountChangeProjection
  const positionFormCollections = auxiliaryData?.positionFormCollections
  const { hasFeature } = useCurrentActiveSession()
  const overlayRef = useRef<HTMLDivElement>(null)
  const watchKeys = useWatchKeysFromAllowedAttributesForPositionDiff({
    allowedAttributes,
    omit: IGNORE_WATCH_FIELD_NAMES,
  })

  const { handleSubmit, isLoading } = useCreateChartPositionSubmitHandler({
    headcountPlanId,
    onClose,
    positionFormCollections,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    rootEventId: row.rootEventId!,
    revisionNumber: row.revisionNumber,
  })

  useEffect(() => {
    if (!isOpen) return
    if (typeof window === "undefined") return
    if (typeof window.NProgress === "undefined") return

    if (positionFormCollections && positionData) {
      window.NProgress?.done?.()
    } else if (!window.NProgress?.isStarted?.()) {
      window.NProgress?.start?.()
    }
  }, [isOpen, positionFormCollections, positionData])

  return positionFormCollections && positionData ? (
    <Modal
      className="new-position-modal"
      isOpen={isOpen}
      onClose={onClose}
      title={t("v2.positions.new.modal_title")}
      size="md"
      overlayRef={overlayRef}
    >
      <PositionForm
        chartKey={chartKey}
        defaults={auxiliaryData.defaults}
        positionData={positionData}
        positionFormAbilities={{
          canShowCustomFieldsTab: Boolean(
            hasFeature("customFields") && positionFormCollections.customFields.length > 0,
          ),
          ...auxiliaryData.positionFormAbilities,
        }}
        positionFormCollections={positionFormCollections}
        onSubmit={handleSubmit}
      >
        <div className="flex-col gap-4 p-6 flex">
          <PositionForm.BaseFormErrors />
          <DiffOfPositionAndHeadcountPlanDatasheetRow row={row} watchKeys={watchKeys} />

          <div className="flex-col flex">
            <PositionForm.PositionDetails />
            {hasFeature("positionManagement") && <PositionForm.PositionManagementTabs />}
            {!hasFeature("positionManagement") && <PositionForm.NonPositionManagementContent />}
          </div>
        </div>
        <hr className="my-0" />
        <ModalFooter
          className="justify-end px-6 py-3"
          disabled={isLoading}
          onClose={onClose}
          saveButtonText={isLoading ? t("v2.defaults.adding") : t("v2.defaults.add")}
        />
      </PositionForm>
    </Modal>
  ) : null
}

const useCreateChartPositionSubmitHandler = ({
  headcountPlanId,
  onClose,
  positionFormCollections,
  rootEventId,
  revisionNumber,
}: UseCreateChartPositionSubmitHandlerArg) => {
  const [createChartPosition, mutationState] = useHeadcountPlansCreateChartPositionMutation()
  const { t } = useTranslation()

  const save: SubmitHandler = async (fieldValues, setError) => {
    try {
      const result = await createChartPosition({
        chartPositionAttributes: mapFieldValuesToPositionAttributes(fieldValues),
        headcountPlanId,
        rootEventId,
        revisionNumber,
      }).unwrap()

      const savedUniqueKey = result.headcountPlansCreateChartPosition?.chartPosition?.uniqueKey
      const errors = result.headcountPlansCreateChartPosition?.errors

      if (errors?.length) {
        mapGraphQLErrorsIntoForm({
          errors,
          positionFormCollections,
          setError,
          t,
        })

        return { ok: false }
      }

      if (!savedUniqueKey) {
        setError("root.base", { message: t("v2.defaults.error") })
        return { ok: false }
      }

      return { ok: true, uniqueKey: savedUniqueKey }
    } catch (error) {
      if (window.Sentry) window.Sentry.captureException(error)
      setError("root.base", { message: t("v2.defaults.error") })
      return { ok: false }
    }
  }

  const handleSubmit: SubmitHandler = async (fieldValues, setError) => {
    globalThis.NProgress?.start()

    const saveResult = await save(fieldValues, setError)
    if (saveResult.ok) onClose()

    globalThis.NProgress?.done()

    return saveResult
  }

  return {
    ...mutationState,
    handleSubmit,
  }
}

export { AddPositionFormRowModal }
