import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import {
  ListViewHeadcountPlan,
  PlanTable,
} from "v2/react/components/headcountPlanning/ListPlans/PlanTable"

interface ArchivedPlansProps {
  isCompanyManagerOrHrAdmin: boolean
  plans: ListViewHeadcountPlan[]
  showMenuColumn: boolean
}

function ArchivedPlans({ isCompanyManagerOrHrAdmin, plans, showMenuColumn }: ArchivedPlansProps) {
  const { t } = useTranslation()
  const [showArchivedTable, setShowArchivedTable] = useState(false)

  return (
    <>
      <button
        className="link mx-auto border-none bg-none"
        onClick={() => setShowArchivedTable(!showArchivedTable)}
        type="button"
      >
        {t(`v2.headcount_plan.lists.${showArchivedTable ? "hide" : "show"}_archived`, {
          count: plans.length,
        })}
      </button>
      {showArchivedTable && (
        <PlanTable
          isCompanyManagerOrHrAdmin={isCompanyManagerOrHrAdmin}
          title={t("v2.headcount_plan.lists.archived")}
          plans={plans}
          showMenuColumn={showMenuColumn}
        />
      )}
    </>
  )
}

export { ArchivedPlans }
