/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { BasePay } from "types/graphql.enums"
import {
  usePositionController,
  usePositionWatchField,
} from "v2/react/components/positions/positionForm/hooks"
import { usePositionFormContext } from "v2/react/components/positions/positionForm/PositionFormProvider"
import { CurrencyInput, NumericInputWithoutWrapper, Select } from "v2/react/shared/forms"

/** Based on app/views/v2/shared/budget_table/_base_pay_form_fields.html.slim */
function BasePayFormFields() {
  const { t } = useTranslation()
  const {
    field: { onChange: changeBasePayType, value: basePayType, ref: basePayTypeRef },
  } = usePositionController({ name: "position.positionBasePayType" })
  const {
    positionFormCollections: { positionBasePayTypes },
    setFocus,
    setValue,
  } = usePositionFormContext()

  const handleBasePayTypeSelect: React.ChangeEventHandler<HTMLSelectElement> = (ev) => {
    changeBasePayType(ev.target.value)
    setValue("position.positionBasePay", "")
    setValue("position.positionHoursPerWeek", "")
    setFocus("position.positionBasePay")
  }

  // We maintain a hash of values to support use cases beyond the form, e.g.
  // diffing field values. For the form it's easier to maintain a roughly 1:1
  // mapping with the server.
  const amount = usePositionWatchField({ name: "position.positionBasePay" })
  const hoursPerWeek = usePositionWatchField({ name: "position.positionHoursPerWeek" })
  useEffect(() => {
    const nextValue = { amount, payType: basePayType, hoursPerWeek }
    setValue("position.budgetedBaseCompensation", nextValue)
  }, [amount, basePayType, hoursPerWeek, setValue])

  return (
    <>
      <Select
        id="position_position_base_pay_type"
        name="position.positionBasePayType"
        className="min-w-0 flex-1"
        options={positionBasePayTypes}
        onSelect={handleBasePayTypeSelect}
        selected={basePayType}
        ref={basePayTypeRef}
      />
      <div className="input-group mb-0 min-w-0 flex-1">
        <CurrencyInput
          formatDefaultValue
          id="position_position_base_pay"
          name="position.positionBasePay"
          useInReactHookForm
        />
      </div>
      {basePayType === BasePay.Hourly && (
        <div className="input-group mb-0 min-w-0 flex-1">
          <div className="relative">
            <NumericInputWithoutWrapper
              id="position_position_hours_per_week"
              className="input suffix-pad suffix-pad--large hourly-field"
              name="position.positionHoursPerWeek"
              useInReactHookForm
            />
            <div className="label-wrapper hourly-field suffix suffix--large">
              {t("v2.shared.budget_table.hrs/wk")}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export { BasePayFormFields }
