import { TFunction } from "i18next"
import { debounce, isEmpty } from "lodash"
import React, { useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import type { EnrichedFilterOption, EnrichedTableFilterQuery, Option } from "types/graphql"
import { NONE_KEY } from "v2/react/constants"
import { CheckboxMenuWithSearch, MenuOption } from "v2/react/shared/forms/CheckboxMenu"
import { InputWrapper } from "v2/react/shared/forms/InputWrapper"
import {
  getInclusionFilterValue,
  type Filter,
} from "v2/react/shared/tables/TableUtilities/FilterTable/utils/filters"
import { RemovableField } from "v2/react/shared/tables/TableUtilities/shared/RemovableField"
import { noneOption } from "v2/react/utils/collections"
import { TablesApi } from "v2/redux/GraphqlApi/TablesApi"
import { selectTable } from "v2/redux/slices/TableFilterSlice/tableFiltersSelectors"
import { useAppSelector } from "v2/redux/store"

interface MultiSelectionFilterWithSearchProps {
  enrichedFilter: EnrichedFilterOption
  filter?: Filter
  onRemove: (id: string) => void
  onSelect?: (filterId: string, options: MenuOption[]) => void
  includeNoneOption?: boolean
}

const MultiSelectionFilterWithSearch = ({
  enrichedFilter,
  filter,
  onRemove,
  onSelect,
  includeNoneOption,
}: MultiSelectionFilterWithSearchProps) => {
  const { t } = useTranslation()
  const table = useAppSelector(selectTable)
  const [trigger] = TablesApi.endpoints.getUpdatedEnrichedFilter.useLazyQuery()

  const { collection, id: filterId, label, totalCount, selectedOptions } = enrichedFilter
  const nodes = useMemo(() => collection?.nodes || [], [collection?.nodes])
  const pageInfo = collection?.pageInfo
  const allOptionsLoaded = nodes.length === totalCount

  const options = useMemo(() => {
    let opts = [...nodes]

    if (selectedOptions) {
      const selectedOptionKeysMap = new Map(selectedOptions.map((option) => [option.id, option]))
      opts = [...selectedOptions, ...opts.filter((node) => !selectedOptionKeysMap.has(node.id))]
    }

    if (includeNoneOption) {
      opts.unshift(noneOption(t))
    }

    return opts
  }, [nodes, selectedOptions, includeNoneOption, t])

  const [currentPageInfo, setCurrentPageInfo] = useState(pageInfo)
  const [initialSelectedOptionKeys, setInitialSelectedOptionKeys] = useState<string[]>(
    getInclusionFilterValue(filter),
  )
  const [menuOptions, setMenuOptions] = useState<MenuOption[]>(
    mapOptionsToMenuOptions(options, initialSelectedOptionKeys),
  )
  const [isSearching, setIsSearching] = useState(false)

  const hasNextPage = currentPageInfo?.hasNextPage
  // We only want to use client search if all of the data is loaded.
  const useClientSearch = !hasNextPage && allOptionsLoaded

  const handleSelect = (optionId: string) => {
    setMenuOptions((currentOptions) =>
      currentOptions.map((opt) =>
        opt.id === optionId ? { ...opt, selected: !opt.selected } : opt,
      ),
    )
  }

  const handleBlur = (searchTerm?: string) => {
    const selectedOptions = menuOptions
      .filter((option) => option.selected)
      .sort((a, b) => a.label.localeCompare(b.label))
    const newSelectedOptionKeys = selectedOptions.map((option) => option.id)

    onSelect?.(filterId, selectedOptions)
    setInitialSelectedOptionKeys(newSelectedOptionKeys)

    const updatedMenuOptions = mapOptionsToMenuOptions(options, newSelectedOptionKeys)
    setMenuOptions(updatedMenuOptions)

    if (searchTerm) {
      // If there's a search term when blurring, we trigger a fetch without a
      // search term to reset the options in state.
      handleSearch()
    }
  }

  const handlePageChange = (searchTerm: string | null) => {
    if (!table) return

    trigger({
      table,
      filter: filterId,
      searchTerm,
      after: currentPageInfo?.endCursor,
    }).then((result) => {
      const { newOptions, newPageInfo } = getNewOptionsAndPageInfo(result?.data)
      const updatedOptions = mergeExistingOptionsWithNewOptions(menuOptions, newOptions)

      setMenuOptions(updatedOptions)
      if (newPageInfo) {
        setCurrentPageInfo(newPageInfo)
      }
    })
  }

  const handleSearch = (searchTerm?: string | null) => {
    const newSelectedOptions = menuOptions
      .filter((option) => option.selected)
      .sort((a, b) => a.label.localeCompare(b.label))

    // Bump selected options to the top of the list.
    setInitialSelectedOptionKeys(newSelectedOptions.map((option) => option.id))

    if (!useClientSearch) {
      setIsSearching(true)
      debouncedTriggerSearch(newSelectedOptions, searchTerm)
    }
  }

  const triggerSearch = useCallback(
    (selectedOptions: MenuOption[], searchTerm?: string | null) => {
      if (!table) return

      trigger(
        {
          table,
          filter: filterId,
          searchTerm,
        },
        true,
      )
        .then((result) => {
          const { newOptions, newPageInfo } = getNewOptionsAndPageInfo(result.data)
          const updatedOptions = mergeExistingOptionsWithNewOptions(selectedOptions, newOptions)

          if (
            includeNoneOption &&
            (searchMatchesNoneOption(t, searchTerm) || isEmpty(searchTerm)) &&
            !noneOptionPresent(updatedOptions, t)
          ) {
            updatedOptions.unshift({ ...noneOption(t), selected: false })
          }

          setMenuOptions(updatedOptions)
          if (newPageInfo) {
            setCurrentPageInfo(newPageInfo)
          }
        })
        .finally(() => {
          setIsSearching(false)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterId, table],
  )
  const debouncedTriggerSearch = useMemo(() => debounce(triggerSearch, 350), [triggerSearch])

  const filterInputId = `${filterId}-filter`

  return (
    <RemovableField key={filterId} id={filterId} removeField={onRemove}>
      <InputWrapper id={filterInputId} label={label} className="mb-6">
        <CheckboxMenuWithSearch
          options={menuOptions}
          initialSelectedOptionKeys={initialSelectedOptionKeys}
          inputId={filterInputId}
          onSelect={handleSelect}
          onBlur={handleBlur}
          onPageChange={handlePageChange}
          onSearch={handleSearch}
          clientSearch={useClientSearch}
          isSearching={isSearching}
          hasNextPage={hasNextPage}
        />
      </InputWrapper>
    </RemovableField>
  )
}

const mapOptionsToMenuOptions = (options: Option[], initialSelectedOptionKeys?: string[]) =>
  options
    .map((option) => ({
      ...option,
      selected: !!initialSelectedOptionKeys?.includes(option.id),
    }))
    .sort(sortFilterOptions)

const searchMatchesNoneOption = (t: TFunction, searchTerm?: string | null) => {
  const searchTermLower = searchTerm?.toLowerCase()
  if (!searchTermLower || isEmpty(searchTermLower)) return false
  return noneOption(t).id.toLowerCase().includes(searchTermLower)
}

const noneOptionPresent = (options: MenuOption[], t: TFunction) =>
  options.some((option) => option.id === noneOption(t).id)

const mergeExistingOptionsWithNewOptions = (
  existingOptions: MenuOption[],
  newOptions: Option[],
) => {
  const existingOptionsMap = new Map(existingOptions.map((option) => [option.id, option]))

  return [
    ...existingOptions,
    ...newOptions
      .filter((newOption) => !existingOptionsMap.has(newOption.id))
      .map((newOption) => ({
        ...newOption,
        selected: false,
      })),
  ].sort(sortFilterOptions)
}

/**
 * We ensure that the "None" filter option is always the first option in the
 * list of filter options.
 */
const sortFilterOptions = (a: Option, b: Option) => {
  if (a.id === NONE_KEY) return -1
  if (b.id === NONE_KEY) return 1
  return a.label.localeCompare(b.label)
}

const getNewOptionsAndPageInfo = (data: EnrichedTableFilterQuery | undefined) => {
  const updatedEnrichedFilter = data?.currentPerson?.settings?.tableFilterSettings?.enrichedFilter
  const newOptions = updatedEnrichedFilter?.collection?.nodes || []
  const newPageInfo = updatedEnrichedFilter?.collection?.pageInfo

  return {
    newOptions,
    newPageInfo,
  }
}

export { MultiSelectionFilterWithSearch }
