/* eslint-disable react/jsx-props-no-spreading */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import currencyIcons from "app_config/currency_icons.json"
import classNames from "classnames"
import React, { forwardRef, useEffect, useState } from "react"
import { useController } from "react-hook-form"

import { InputWrapper } from "v2/react/shared/forms/InputWrapper"
import { formatCurrency } from "v2/react/utils/currency"
import { prepareIconClass } from "v2/react/utils/misc"
import { safeNumber } from "v2/react/utils/safeNumber"

import { useWithReactHookFormRegister } from "./hooks/useWithReactHookFormHooks"
import { UseInReactHookFormProp } from "./types"

type IconClass = (typeof currencyIcons)[keyof typeof currencyIcons]

export interface CurrencyInputProps {
  defaultValue?: string | undefined | number
  disabled?: boolean
  errors?: string
  formatDefaultValue?: boolean
  iconClass?: IconClass
  id: string
  inputClassName?: string
  inputIconWrapperClassName?: string
  label?: string
  name: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  readOnly?: boolean
  renderErrors?: (errors: string) => React.ReactNode
  showErrorMessage?: boolean
  useAttentionState?: boolean
  value?: string | undefined | number
  wrapperClassName?: string
  wrapperCombineClassNames?: boolean
  useInReactHookForm?: UseInReactHookFormProp
}

type CurrencyInputWithoutWrapperProps = Omit<
  CurrencyInputProps,
  | "errors"
  | "label"
  | "renderErrors"
  | "showErrorMessage"
  | "useAttentionState"
  | "wrapperClassName"
  | "wrapperCombineClassNames"
> & {
  isFocused?: boolean
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onFocus?: React.FocusEventHandler<HTMLInputElement>
  showAttention?: boolean
}

type CurrencyInputOnlyInputProps = Omit<
  CurrencyInputWithoutWrapperProps,
  "wrapperClassName" | "showAttention" | "isFocused" | "iconClass"
> & {
  className?: string
}

const CurrencyInputOnlyInput = forwardRef<HTMLInputElement, CurrencyInputOnlyInputProps>(
  (
    {
      className,
      inputClassName,
      defaultValue,
      disabled = false,
      formatDefaultValue,
      id,
      name,
      onBlur,
      onChange,
      onFocus,
      placeholder = "",
      readOnly = false,
      useInReactHookForm,
      value,
    },
    ref,
  ) => (
    <input
      className={classNames("input prefix-pad", className, inputClassName)}
      defaultValue={getDefaultValue(defaultValue, formatDefaultValue) ?? undefined}
      disabled={disabled}
      id={id}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      placeholder={placeholder}
      readOnly={readOnly}
      ref={ref}
      type="text"
      value={typeof value === "number" ? `${value}` : value}
      {...useWithReactHookFormRegister({ useInReactHookForm, name })?.(name, {
        setValueAs: (value) => safeNumber(value, { from: "currency", fallback: null }),
      })}
    />
  ),
)

const RegisteredInput = forwardRef<HTMLInputElement, Omit<CurrencyInputOnlyInputProps, "register">>(
  ({ defaultValue, name, onBlur, onChange, ...props }, ref) => {
    const { field } = useController({ name, defaultValue })
    const fieldValue = field.value
    const [inputValue, setInputValue] = useState(
      getDefaultValue(field.value, props.formatDefaultValue)?.toString() ?? "",
    )

    // In order to support formatting the default value, we have to manage the
    // field value. But we need to react to changes in field.value that may have
    // come through something such as react-hook-form's `setValue`. Handle here.
    useEffect(() => {
      setInputValue((current) => {
        const safeCurrent = safeNumber(current, { from: "currency", fallback: null })
        const safeFieldValue = safeNumber(fieldValue, { from: "currency", fallback: null })
        return safeCurrent !== safeFieldValue ? fieldValue?.toString() : current
      })
    }, [fieldValue])

    return (
      <CurrencyInputOnlyInput
        {...props}
        name={name}
        onBlur={(ev) => {
          field.onBlur()
          onBlur?.(ev)
        }}
        onChange={(ev) => {
          setInputValue(ev.target.value)
          field.onChange(safeNumber(ev.target.value, { from: "currency", fallback: null }))
          onChange?.(ev)
        }}
        ref={ref ?? field.ref}
        value={inputValue}
      />
    )
  },
)

const CurrencyInputWithoutWrapper = forwardRef<HTMLInputElement, CurrencyInputWithoutWrapperProps>(
  (
    {
      defaultValue,
      disabled = false,
      iconClass = window.gon.currency_icon_class || "far fa-dollar-sign",
      inputIconWrapperClassName,
      isFocused,
      readOnly = false,
      showAttention,
      value,
      useInReactHookForm,
      ...rest
    },
    ref,
  ) => (
    <div
      className={classNames("relative", inputIconWrapperClassName, {
        readonly: readOnly || disabled,
        active: isFocused,
        attention: showAttention,
      })}
    >
      <div className="prefix">
        <FontAwesomeIcon icon={prepareIconClass(iconClass)} />
      </div>
      {/* Purposely omits `register` from either call. When calling
          `RegisteredInput`, `value` gets downgraded to `defaultValue` (if
          given) since we defer control to react-hook-form. Will probably
          revisit both of these points. */}
      {useInReactHookForm ? (
        <RegisteredInput
          {...rest}
          defaultValue={value ?? defaultValue}
          disabled={disabled}
          readOnly={readOnly}
          ref={ref}
        />
      ) : (
        <CurrencyInputOnlyInput
          {...rest}
          defaultValue={defaultValue}
          disabled={disabled}
          readOnly={readOnly}
          ref={ref}
          value={value}
        />
      )}
    </div>
  ),
)

const CurrencyInput = forwardRef<HTMLInputElement, CurrencyInputProps>(
  (
    {
      defaultValue,
      disabled = false,
      errors,
      formatDefaultValue,
      iconClass = window?.gon?.currency_icon_class || "far fa-dollar-sign",
      id,
      inputClassName,
      inputIconWrapperClassName,
      label,
      name,
      onChange,
      placeholder = "",
      readOnly = false,
      renderErrors,
      showErrorMessage = true,
      useAttentionState = false,
      value,
      wrapperClassName,
      wrapperCombineClassNames,
      useInReactHookForm,
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false)
    const [inputValue, setInputValue] = useState<string>(
      `${getDefaultValue(value ?? defaultValue, formatDefaultValue) ?? ""}`,
    )

    // If the caller provides a value, let them control the component. Otherwise
    // retain control with `inputValue`.
    const currentValue = value?.toString() ?? inputValue
    const showAttention = useAttentionState && !isFocused && !errors && !currentValue.trim()

    const handleFocus = () => setIsFocused(true)
    const handleBlur = () => setIsFocused(false)
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value)
      onChange?.(e)
    }

    return (
      <InputWrapper
        label={label}
        errors={errors}
        showErrorMessage={showErrorMessage}
        renderErrors={renderErrors}
        id={id}
        className={wrapperClassName}
        combineClassNames={wrapperCombineClassNames}
        name={name}
        onMouseEnterLabel={handleFocus}
        onMouseLeaveLabel={handleBlur}
        useInReactHookForm={useInReactHookForm}
      >
        <CurrencyInputWithoutWrapper
          disabled={disabled}
          formatDefaultValue={formatDefaultValue}
          iconClass={iconClass}
          id={id}
          inputClassName={inputClassName}
          inputIconWrapperClassName={inputIconWrapperClassName}
          isFocused={isFocused}
          name={name}
          onChange={handleChange}
          placeholder={placeholder}
          readOnly={readOnly}
          ref={ref}
          showAttention={showAttention}
          value={currentValue}
          useInReactHookForm={useInReactHookForm}
        />
      </InputWrapper>
    )
  },
)

const getDefaultValue = (
  defaultValue?: number | string | null | undefined,
  formatDefaultValue?: boolean,
) => {
  // Forward the value as given if the caller doesn't want us to format it.
  if (!formatDefaultValue) return defaultValue?.toString()

  let num = typeof defaultValue === "number" ? defaultValue : null
  if (typeof defaultValue === "string") num = safeNumber(defaultValue, { from: "currency" })

  if (num === null) return null

  return formatDefaultValue ? formatCurrency({ value: num, omitSymbol: true, trailing: true }) : num
}

export { CurrencyInput, CurrencyInputWithoutWrapper }
