import classNames from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import { AllowedAttribute } from "types/graphql"
import { DisplayValueCell } from "v2/react/components/headcountPlanning/TableDatasheet/DisplayValueCell"
import { selectErrorsForRow } from "v2/redux/slices/DatasheetSlice/datasheetSelectors"
import { useAppSelector } from "v2/redux/store"

import { useCellState } from "../TableDatasheet/hooks/useCellState"
import { FieldType } from "../TableDatasheet/types"

export function TypeCell({
  rowId,
  type,
  excluded,
  endDate,
  allowedAttributes,
}: {
  rowId: string
  type: "new" | "modified" | "unchanged"
  excluded?: boolean | null
  endDate?: string | null
  allowedAttributes?: AllowedAttribute[]
}) {
  const { t } = useTranslation()
  const display = getTypeDisplay(type, endDate)
  const cell = useCellState({
    currentValue: type,
    fieldType: FieldType.NonEditable,
    rowId,
    columnId: "type",
    editable: false,
    saveFn: () => Promise.resolve({ ok: true } as const),
  })

  const errors = useAppSelector((state) => selectErrorsForRow(state, rowId))
  const errorKeys = errors?.fieldErrors ? Object.keys(errors?.fieldErrors) : null
  const showErrors = errors?.fieldErrors && !!errorKeys?.length
  const erroredLabels =
    errors?.fieldErrors &&
    errorKeys
      ?.map((key) => allowedAttributes?.find((a) => a.id === key)?.name)
      .filter((a) => a)
      .join(", ")
  const errorMessage = t("v2.headcount_plan.datasheet.information_missing_or_invalid", {
    missing: erroredLabels,
  })
  if (showErrors) cell.isErrored = true

  return (
    <DisplayValueCell
      id={`type-${rowId}`}
      className={classNames("non-editable-cell h-full flex-col justify-center flex", {
        "editable-cell--errored": showErrors,
      })}
      errorMessage={errorMessage}
      cell={cell}
    >
      <div
        className={classNames("justify-center flex", {
          "opacity-50": excluded,
        })}
      >
        <div className="headcount-plan-position-type">
          <div className="headcount-plan-position-type__dot-container">
            <div className={classNames("headcount-plan-position-type__dot", display.color)} />
          </div>
          <span>{display.text}</span>
        </div>
      </div>
    </DisplayValueCell>
  )
}

function getTypeDisplay(type: "new" | "modified" | "unchanged", endDate?: string | null) {
  if (type !== "new" && endDate) {
    return { color: "eliminated", text: "position_type_eliminated".t("headcount_plan") }
  }
  return { color: type, text: `position_type_${type}`.t("headcount_plan") }
}
