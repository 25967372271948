import cn from "classnames"
import React, { useContext } from "react"

import { DropdownContext } from "v2/react/shared/collection/menus/Dropdown/Dropdown"

const DropdownTrigger = ({
  id,
  children,
  disabled,
  triggerClassName,
  onClick,
}: {
  id?: string
  children: React.ReactNode
  disabled?: boolean
  triggerClassName?: string
  onClick?: () => void
}) => {
  const { isOpen, setIsOpen, floatingInfo } = useContext(DropdownContext)
  const { refs, getReferenceProps } = floatingInfo

  return (
    <button
      ref={refs.setReference}
      tabIndex={0}
      data-testid={id || "dropdown-trigger"}
      className={cn(
        !triggerClassName && "m-0 cursor-pointer appearance-none border-none bg-transparent p-0",
        triggerClassName,
      )}
      disabled={disabled}
      type="button"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...getReferenceProps({
        onClick: () => {
          onClick?.()
          setIsOpen(!isOpen)
        },
      })}
    >
      {children}
    </button>
  )
}

export { DropdownTrigger }
