import dayjs from "dayjs"
import React from "react"
import { useTranslation } from "react-i18next"
import { useWindowSize } from "usehooks-ts"

import { FeatureFlags, HeadcountPlanActiveStatus } from "types/graphql.d"
import { NavActiveIcon } from "v2/react/components/headcountPlanning/shared/NavActiveIcon"
import { getNavLinks } from "v2/react/components/headcountPlanning/shared/navigation"
import { PlanTypeIndicator } from "v2/react/components/headcountPlanning/ShowParticipant/PlanTypeIndicator"
import { DisplayState } from "v2/react/components/headcountPlanning/types"
import { ExportButton } from "v2/react/shared/buttons/ExportButton"
import { Spinner } from "v2/react/shared/loaders/Spinner"
import PageNav from "v2/react/shared/navigation/PageNav"
import { ActionBlock } from "v2/react/shared/navigation/PageNav/ActionBlock"
import { LinkBlockSmall } from "v2/react/shared/navigation/PageNav/LinkBlock"
import { LinkGroup } from "v2/react/shared/navigation/PageNav/LinkGroup"
import { TitleBlockLarge } from "v2/react/shared/navigation/PageNav/TitleBlock"
import { getCookie } from "v2/react/utils/cookies"
import { UrlHelper } from "v2/react/utils/urls"
import { useGetFeatureFlagsQuery } from "v2/redux/GraphqlApi"
import { useGetOwnerDatasheetPageQuery } from "v2/redux/GraphqlApi/HeadcountPlanningApi"

import RootProvider from "../RootProvider"
import { HeadcountPlanningNavTitleWithSubtitle } from "./HeadcountPlanningNav"
import { FinalizePlanButton, FinalizePlanModal } from "./Overview/FinalizePlanButton"
import { PlanTopbarMenu } from "./PlanTopbarMenu"
import { TimelineView } from "./shared/TimelineView"

export function OwnerTimeline({ headcountPlanId }: { headcountPlanId: string }) {
  const featureFlags = useGetFeatureFlagsQuery()
  const ownerPage = useGetOwnerDatasheetPageQuery({ headcountPlanId })
  const headcountPlan = ownerPage.data?.headcountPlan
  const setBudget = headcountPlan?.setBudget
  const isFinalized = !!headcountPlan?.lockedAt
  const hasActiveStatus = headcountPlan?.activeStatus === HeadcountPlanActiveStatus.Active
  const displayStateCookie = getCookie(
    `built__display-state-for-hcp-${headcountPlanId}`,
  ) as DisplayState
  const { t } = useTranslation()
  const csvDownloadName = [
    headcountPlan?.name?.replace(" ", ""),
    t("v2.defaults.timeline"),
    dayjs(new Date()).format("YYYYMMDD"),
  ].join("_")

  const [csvDownloadRef, setCsvDownloadRef] = React.useState<HTMLButtonElement | null>(null)

  const [finalizePlanModalIsOpen, setFinalizePlanModalIsOpen] = React.useState(false)

  const w = useWindowSize()

  const isExtraSmallScreen = w.width < 640

  if (
    ownerPage.isLoading ||
    featureFlags.isFetching ||
    !featureFlags.data?.currentCompany?.featureFlags
  )
    return <Spinner />

  const ff: FeatureFlags = featureFlags.data.currentCompany.featureFlags

  return (
    <RootProvider>
      <PageNav>
        <TitleBlockLarge>
          <HeadcountPlanningNavTitleWithSubtitle
            title={headcountPlan.company.name}
            subtitle={headcountPlan.name}
            subtitleLinkTo={UrlHelper.headcountPlanPath(headcountPlanId)}
          />
        </TitleBlockLarge>
        <LinkBlockSmall>
          <LinkGroup
            links={getNavLinks({ isOwner: true, active: "Timeline", headcountPlanId, ff, t })}
          />
        </LinkBlockSmall>
        <ActionBlock>
          <div className="items-center gap-2 flex">
            {hasActiveStatus ? <NavActiveIcon /> : null}
            {isFinalized ? <PlanTypeIndicator type={displayStateCookie || "approved"} /> : null}
            {!isFinalized && !isExtraSmallScreen ? (
              <>
                <FinalizePlanModal
                  headcountPlanId={headcountPlanId}
                  headcountPlanName={headcountPlan.name}
                  isOpen={finalizePlanModalIsOpen}
                  setIsOpen={setFinalizePlanModalIsOpen}
                />
                <FinalizePlanButton
                  canBeFinalized={headcountPlan.canBeFinalized}
                  setIsOpen={setFinalizePlanModalIsOpen}
                />
              </>
            ) : null}
            {!isExtraSmallScreen ? (
              <ExportButton setRef={(element) => setCsvDownloadRef(element)} />
            ) : null}
            <PlanTopbarMenu
              isFinalized={isFinalized}
              headcountPlanId={headcountPlanId}
              showExportButton={isExtraSmallScreen}
              showFinalizeButton={
                isExtraSmallScreen && headcountPlan.canBeFinalized && !isFinalized
              }
              setRef={(element) => setCsvDownloadRef(element)}
              setBudget={setBudget}
            />
          </div>
        </ActionBlock>
      </PageNav>
      <TimelineView
        headcountPlanId={headcountPlanId}
        csvDownloadRef={{ current: csvDownloadRef }}
        csvDownloadName={csvDownloadName}
      />
    </RootProvider>
  )
}
