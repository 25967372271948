import classNames from "classnames"
import React, { ChangeEvent, FC } from "react"

import { isDisabledFromSchema } from "v2/react/shared/jsonForms/JsonFormControls/utils/forms"

import { useValidation } from "./hooks/useValidation"
import { JsonFormControlLabel } from "./JsonFormControlLabel"
import { EnhancedControlProps } from "./types"

const JsonTextInput: FC<EnhancedControlProps> = ({
  config,
  data,
  handleChange,
  id,
  label,
  path,
  schema,
  uischema,
  visible,
  enabled,
  errors,
}) => {
  const isDisabled = isDisabledFromSchema({ enabled, schema })
  const { showError, errorMessage } = useValidation({
    path,
    schema,
    submitting: config.submitting,
    schemaError: errors,
  })

  const handleChangeEvent = (event: ChangeEvent<HTMLInputElement>) =>
    handleChange(path, event.target.value)

  if (!visible) return null

  return (
    <div className={classNames("input-group", { "form-error": showError })}>
      <JsonFormControlLabel
        className="items-center whitespace-pre-wrap flex"
        id={id}
        label={label}
        uischema={uischema}
      />
      <input
        type="text"
        className="input"
        name={id}
        id={id}
        placeholder={uischema.options?.placeholder && uischema.options.placeholder}
        defaultValue={data}
        onChange={handleChangeEvent}
        disabled={isDisabled}
      />
      {showError && <p className="my-1 text-status-critical">{errorMessage}</p>}
    </div>
  )
}

export { JsonTextInput }
