import cn from "classnames"
import React from "react"

import { SingleDiffEntry } from "v2/react/components/positions/positionFieldValuesDiff"
import { Info } from "v2/react/shared/status/Info"

import { EnhancedControlProps } from "./types"

type JsonFormControlLabelProps = Pick<EnhancedControlProps, "id" | "label" | "uischema"> & {
  className?: string
}

function JsonFormControlLabel({ className, id, label, uischema }: JsonFormControlLabelProps) {
  // Assumption: We use either a label or subLabel, not both
  return (
    label &&
    !uischema?.options?.subLabel && (
      <label className={cn("cursor-default", className)} htmlFor={id}>
        {label}
        {uischema?.options?.tooltip && <Info description={uischema?.options.tooltip} />}
        <SingleDiffEntry fieldName={uischema?.diffMeta?.withPosition} />
      </label>
    )
  )
}

export { JsonFormControlLabel }
