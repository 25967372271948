import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import { Candidate } from "types/graphql"
import { StandardRank } from "types/graphql.enums"
import { HighRiskTooltip } from "v2/react/components/succession/SuccessionPlanPanel/shared/HighRiskTooltip"
import { positionTitleDisplay } from "v2/react/components/succession/SuccessionPlanPanel/utils/positionMappers"
import { Avatar } from "v2/react/shared/icons/Avatar"
import { BlueIcon } from "v2/react/shared/icons/BlueIcon"
import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"

interface CandidateHeaderProps {
  candidate: Candidate
  showFilledBy: boolean
}

function CandidateHeader({ candidate, showFilledBy }: CandidateHeaderProps) {
  const { t } = useTranslation()
  const isRisky =
    (candidate.person && candidate.flightRiskLevel === StandardRank.High) ||
    (candidate.crossCandidacies && candidate.crossCandidacies.length > 0)

  return (
    <div
      className={cn("grid-rows-[auto_1fr] items-center grid", {
        "absolute bottom-0 left-0 right-0 top-0": showFilledBy,
      })}
    >
      {isRisky ? (
        <Tooltip placement="bottom-end">
          <TooltipTrigger>
            <p className="risky w-full border-b-neutral-8 bg-status-caution-light p-1 text-center text-neutral-64">
              {t("v2.succession_plan_panel.compare.candidate_sort_order", {
                order: candidate.sortOrder,
              })}
            </p>
          </TooltipTrigger>
          <TooltipContent className="react-tooltip-content w-fit max-w-[25rem]">
            <HighRiskTooltip
              crossCandidacies={candidate.crossCandidacies || []}
              highFlightRisk={
                candidate.person ? candidate.flightRiskLevel === StandardRank.High : false
              }
            />
          </TooltipContent>
        </Tooltip>
      ) : (
        <p className="w-full border-b-neutral-8 bg-primary-3 p-1 text-center text-neutral-64">
          {t("v2.succession_plan_panel.compare.candidate_sort_order", {
            order: candidate.sortOrder,
          })}
        </p>
      )}
      {candidate.person ? (
        <div className="p-3 text-center">
          <Avatar person={candidate.person} size="lg" />
          <div className="pt-2 text-center">
            <p>{candidate.person.name}</p>
            <p className="text-sm text-neutral-64">
              {positionTitleDisplay(candidate.person.primaryPosition, t)}
            </p>
          </div>
        </div>
      ) : (
        <div className="p-3 text-center">
          <BlueIcon classNames="mx-auto mb-[5px]" icon={["far", "briefcase"]} size="lg" />
          <div className="mt-3 text-center">
            <p>{candidate.outsiderName}</p>
            <p className="text-sm text-neutral-64">
              {t("v2.succession_plan_panel.compare.external_candidate")}
            </p>
          </div>
        </div>
      )}
    </div>
  )
}

export { CandidateHeader }
