import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

import { RequisitionModal } from "v2/react/components/jobRequisitions/RequisitionModal"
import { JobRequisitionAbilities } from "v2/react/components/jobRequisitions/types"
import RootProvider from "v2/react/components/RootProvider"
import { JobRequisition } from "v2/redux/GraphqlApi/JobRequisitionsApi"
import { setReqModalOpen } from "v2/redux/slices/RequisitionSlice"
import { useAppDispatch } from "v2/redux/store"

import { FormShape } from "./RequisitionForm/types/FormShape"

interface Props {
  abilities: Partial<JobRequisitionAbilities>
  data?: FormShape
  jobRequisition?: JobRequisition
  onComplete?: () => void
}

function RequisitionFormInner({ abilities, data, jobRequisition, onComplete }: Props) {
  const dispatch = useAppDispatch()
  const handleAddButtonClick = () => dispatch(setReqModalOpen(true))

  return (
    <>
      {abilities.canCreate && (
        <button className="btn--large btn--primary" onClick={handleAddButtonClick} type="button">
          <FontAwesomeIcon icon={["far", "plus"]} />
          <span className="hidden sm:block">{"create_requisition".t("job_requisition")}</span>
        </button>
      )}
      <RequisitionModal
        data={data}
        edit={false}
        jobRequisition={jobRequisition}
        onClose={onComplete}
      />
    </>
  )
}

const RequisitionForm = ({ abilities, data, jobRequisition, onComplete }: Props) => (
  <RootProvider>
    <RequisitionFormInner
      abilities={abilities}
      data={data}
      onComplete={onComplete}
      jobRequisition={jobRequisition}
    />
  </RootProvider>
)

export { RequisitionForm }
