import { Column, RowModel } from "@tanstack/react-table"
import { defaultRangeExtractor, Range, useVirtualizer } from "@tanstack/react-virtual"
import { useCallback, useMemo } from "react"

import { cellStateSelectors } from "v2/redux/slices/DatasheetSlice"
import { selectCursor } from "v2/redux/slices/DatasheetSlice/cursor/cursorSelectors"
import { onNothing } from "v2/redux/slices/DatasheetSlice/cursor/cursorStates"
import { CellCursor } from "v2/redux/slices/DatasheetSlice/cursor/types"
import { useAppSelector } from "v2/redux/store"

type VirtualizersArg<TRow extends { id: string }> = {
  getColumnWidth: (column: Column<TRow, unknown>, index: number) => number
  getRowHeight: (row: RowModel<TRow>["rows"][0], index: number) => number
  headerHeight: number
  tableColumns: Column<TRow, unknown>[]
  tableContainerRef: React.RefObject<HTMLDivElement>
  tableRows: RowModel<TRow>["rows"]
}

/**
 * Provides virtualizers for table rows and columns.
 *
 * The main purpose of this function is to support virtualizing the table while
 * keeping most logic in React hooks and components. Specifically, this hook
 * helps ensure that we always render whatever cell the cursor is on, and any
 * non-idle cells (e.g. a cell that is saving).
 *
 * Put differently: virtualization achieves performance gains by only rendering
 * a subset of table rows and columns. The trade-off is that logic/hooks within
 * out-of-bounds components don't fire. This minimizes that trade-off.
 *
 * IMPORTANT: This assumes that we're ok to unmount any cell that is idle, not
 * under the cursor, and outside of the virtualizers' bounds.
 */
function useVirtualizers<TRow extends { id: string }>({
  getColumnWidth,
  getRowHeight,
  headerHeight,
  tableColumns,
  tableContainerRef,
  tableRows,
}: VirtualizersArg<TRow>) {
  // Gather important row/column indexes so we continue to render them when
  // they're out of the virtualizer's bounds.
  const { keepColIndexes, keepRowIndexes } = useRowAndColumnIndexKeepLists({
    cursor: useAppSelector(selectCursor),
    tableColumns,
    tableRows,
  })

  return {
    columnVirtualizer: useVirtualizer({
      count: tableColumns.length,
      estimateSize: (index) => getColumnWidth(tableColumns[index], index),
      getScrollElement: () => tableContainerRef.current,
      horizontal: true,
      rangeExtractor: useCallback(
        (range: Range) => extractRange(range, keepColIndexes),
        [keepColIndexes],
      ),
      overscan: 3,
    }),
    rowVirtualizer: useVirtualizer({
      count: tableRows.length,
      getScrollElement: () => tableContainerRef.current,
      scrollMargin: headerHeight,
      estimateSize: (index) => getRowHeight(tableRows[index], index),
      rangeExtractor: useCallback(
        (range: Range) => extractRange(range, keepRowIndexes),
        [keepRowIndexes],
      ),
      overscan: 4,
    }),
  }
}

// Helper that extracts the default virtual item indexes and augments this list
// with any indexes that we need to continue to render.
//
// ASSUMPTION: `keepIndexes` is sorted in ascending order.
const extractRange = (range: Range, keepIndexes: number[]) => {
  const indices = defaultRangeExtractor(range)
  if (keepIndexes.length === 0) return indices

  const putBefore: number[] = []
  const putAfter: number[] = []

  keepIndexes.forEach((index) => {
    if (index < indices[0]) putBefore.push(index)
    if (index > indices[indices.length - 1]) putAfter.push(index)
  })

  return [...putBefore, ...indices, ...putAfter]
}

/**
 * Gets a pair of lists holding row and column indexes, respectively, that
 * should still be rendered if outside of their virtualizer's bounds.
 *
 * By continuing to render these columns or rows, we enable proper support
 * for e.g. `useCellState`, `useDatasheetCellCursor`, component callbacks, and
 * so on.
 */
function useRowAndColumnIndexKeepLists<TRow extends { id: string }>({
  cursor,
  tableRows,
  tableColumns,
}: {
  cursor: CellCursor
  tableRows: RowModel<TRow>["rows"]
  tableColumns: Column<TRow, unknown>[]
}) {
  // We only want to track cells that aren't in an idle state. We want to
  // continue rendering non-idle cells so their internal state machines
  // transition properly (e.g. `useCellState`).
  //
  // Cells with a state of "idle", "selected", or "editing" are not
  // kept in Redux, so `cellStateSelectors.selectAll` returns a list of
  // non-idle cells. See `translateCellStateToStoredCellState` in
  // webpack/v2/redux/slices/DatasheetSlice/translators.ts for reference.
  // The cursor will point us to a cell that is "selected" or "editing".
  const nonIdleCells = useAppSelector(cellStateSelectors.selectAll)

  return useMemo(() => {
    const rowIds: string[] = []
    const colIds: string[] = []

    // If the cell cursor is on a cell, we want to continue rendering that cell
    // in order to support keyboard event/callbacks.
    const [cursorColumnId, cursorRowId] = onNothing(cursor)
      ? [undefined, undefined]
      : [cursor.columnId, cursor.rowId]

    nonIdleCells.forEach((cellState) => {
      const [rowId, colId] = cellState.id.split(".")
      if (rowId !== undefined) rowIds.push(rowId)
      if (colId !== undefined) colIds.push(colId)
    })

    return {
      keepRowIndexes: getNextList(tableRows, cursorRowId, rowIds),
      keepColIndexes: getNextList(tableColumns, cursorColumnId, colIds),
    }
  }, [cursor, nonIdleCells, tableRows, tableColumns])
}

function getNextList(
  referenceList: { id: string }[],
  maybeCursorId: string | undefined,
  cellIds: string[],
) {
  const nextList: number[] = []
  const pushIndexIfPresent = (maybeId: string | undefined) => {
    if (maybeId === undefined) return

    const index = referenceList.findIndex(({ id }) => id === maybeId)
    if (index === -1) return

    nextList.push(index)
  }

  pushIndexIfPresent(maybeCursorId)
  cellIds.forEach(pushIndexIfPresent)

  nextList.sort()
  return nextList
}

export { useVirtualizers }
