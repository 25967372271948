import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React, { useRef } from "react"
import { useTranslation } from "react-i18next"
import { useBoolean } from "usehooks-ts"

import { AllowedAttribute } from "types/graphql"
import { AddPositionFormRowModal } from "v2/react/components/headcountPlanning/AddPositionFromRowModal"
import { DisplayValueCell } from "v2/react/components/headcountPlanning/TableDatasheet/DisplayValueCell"
import { useCurrentActiveSession } from "v2/react/hooks/useSessionHooks"
import { Dropdown } from "v2/react/shared/collection/menus/Dropdown/Dropdown"
import { UrlHelper } from "v2/react/utils/urls"

import { AddJobRequisitionFromRowModal } from "../AddJobRequisitionFromRowModal"
import { HeadcountPlanDatasheetRow } from "./types"

const useIsAddPositionModalShownFlag = () => {
  const {
    setFalse: hideAddPositionModal,
    setTrue: showAddPositionModal,
    toggle: toggleAddPositionModalShown,
    value: isAddPositionModalShown,
  } = useBoolean()

  return {
    hideAddPositionModal,
    isAddPositionModalShown,
    showAddPositionModal,
    toggleAddPositionModalShown,
  }
}

const useIsCreateRequisitionModalShownFlag = () => {
  const {
    setFalse: hideCreateRequisitionModal,
    setTrue: showCreateRequisitionModal,
    toggle: toggleCreateRequisitionShown,
    value: isCreateRequisitionShown,
  } = useBoolean()

  return {
    hideCreateRequisitionModal,
    isCreateRequisitionShown,
    showCreateRequisitionModal,
    toggleCreateRequisitionShown,
  }
}

type Props = {
  allowedAttributes?: AllowedAttribute[]
  headcountPlanId: string
  participantId: string | undefined
  participantPositionId: string | undefined
  row: HeadcountPlanDatasheetRow
}

const DEFAULT_ALLOWED_ATTRIBUTES: AllowedAttribute[] = []

export function FinalizedPositionMenuCell({
  allowedAttributes,
  headcountPlanId,
  participantId,
  participantPositionId,
  row,
}: Props) {
  const { t } = useTranslation()
  const containerRef = useRef<HTMLDivElement | null>(null)
  const { hideAddPositionModal, showAddPositionModal, isAddPositionModalShown } =
    useIsAddPositionModalShownFlag()
  const { hideCreateRequisitionModal, showCreateRequisitionModal, isCreateRequisitionShown } =
    useIsCreateRequisitionModalShownFlag()
  const isAnyMenuOptionAvailable =
    row.canCreateChartPosition || row.canCreateJobRequisition || row.createdJobRequisition
  const canShowMenu = !row.position && !row.createdPosition && isAnyMenuOptionAvailable
  const { officialChartKey } = useCurrentActiveSession()

  if (!canShowMenu)
    return <DisplayValueCell className="non-editable-cell" style={{ borderLeft: "none" }} />

  return (
    <DisplayValueCell className="non-editable-cell" style={{ borderLeft: "none" }}>
      <div ref={containerRef} className="relative">
        <Dropdown>
          <Dropdown.Trigger triggerClassName={cn("btn--icon btn--ghost")}>
            <FontAwesomeIcon icon={["far", "ellipsis-h"]} />
          </Dropdown.Trigger>
          <Dropdown.Menu>
            {row.canCreateJobRequisition && (
              <Dropdown.Item
                onClick={showCreateRequisitionModal}
                withIcon={<FontAwesomeIcon icon={["far", "pen"]} />}
              >
                {t("v2.headcount_plan.datasheet.create_requisition")}
              </Dropdown.Item>
            )}
            {row.createdJobRequisition && (
              <Dropdown.Item
                as="a"
                href={UrlHelper.jobRequisitionOverviewPath(row.createdJobRequisition.id)}
                withIcon={<FontAwesomeIcon icon={["far", "briefcase"]} />}
              >
                {t("v2.headcount_plan.datasheet.view_requisition")}
              </Dropdown.Item>
            )}
            {row.canCreateChartPosition && (
              <Dropdown.Item
                onClick={showAddPositionModal}
                withIcon={<FontAwesomeIcon icon={["far", "plus"]} />}
              >
                {t("v2.headcount_plan.datasheet.add_position")}
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
        <AddPositionFormRowModal
          allowedAttributes={allowedAttributes ?? DEFAULT_ALLOWED_ATTRIBUTES}
          chartKey={officialChartKey}
          headcountPlanId={headcountPlanId}
          isOpen={isAddPositionModalShown}
          onClose={hideAddPositionModal}
          row={row}
        />
        <AddJobRequisitionFromRowModal
          allowedAttributes={allowedAttributes ?? DEFAULT_ALLOWED_ATTRIBUTES}
          headcountPlanId={headcountPlanId}
          headcountPlanParticipantId={participantId}
          headcountPlanParticipantPositionId={participantPositionId}
          isOpen={isCreateRequisitionShown}
          onClose={hideCreateRequisitionModal}
          row={row}
        />
      </div>
    </DisplayValueCell>
  )
}
