import cn from "classnames"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { CustomField, GetPanelSuccessionPlanQuery } from "types/graphql"
import { CustomFieldModel } from "types/graphql.enums"
import { CandidateHeader } from "v2/react/components/succession/SuccessionPlanPanel/Compare/CandidateHeader"
import { CellMapper } from "v2/react/components/succession/SuccessionPlanPanel/Compare/CellMapper"
import { FilledByHeader } from "v2/react/components/succession/SuccessionPlanPanel/Compare/FilledByHeader"
import { CandidateFormDropdown } from "v2/react/components/succession/SuccessionPlanPanel/Summary/CandidateFormDropdown"
import { BlueIcon } from "v2/react/shared/icons/BlueIcon"
import { sanitizeParse } from "v2/react/utils/sanitizeParse"
import { idFromUniqueKey } from "v2/react/utils/uniqueKey"
import { useGetCustomFieldsQuery } from "v2/redux/GraphqlApi"
import { useGetSuccessionPanelSettingsQuery } from "v2/redux/GraphqlApi/SuccessionApi"

interface CompareTabProps {
  position: NonNullable<GetPanelSuccessionPlanQuery["position"]>
}

function CompareTab({ position }: CompareTabProps) {
  const { t } = useTranslation()
  const { data: settings } = useGetSuccessionPanelSettingsQuery({})
  const [visiblePerson, setVisiblePerson] = useState(
    position.filledBy ? position.filledBy[0] : null,
  )

  const { data } = useGetCustomFieldsQuery({ modelType: CustomFieldModel.Person })

  const customFields = data?.currentCompany?.customFields

  if (!settings || !settings.currentPerson?.settings?.successionPanelSettings) return null

  const { compareSelectedRows, compareShowFilledBy } =
    settings.currentPerson.settings.successionPanelSettings

  const noCandidates =
    (position.successionPlan?.candidates && position.successionPlan?.candidates.length === 0) ||
    !position.successionPlan?.candidates

  const findCustomField = (customFieldId: string) =>
    (customFields || []).find((field: CustomField) => field.id === idFromUniqueKey(customFieldId))

  const handleRotatePeople = (newIndex: number) => {
    const person = (position.filledBy || [])[newIndex]
    if (person.id === visiblePerson?.id) return
    setVisiblePerson(person)
  }

  return (
    <div className="compare table">
      <table
        className={cn("sticky-table w-full border-separate overflow-x-auto", {
          "1016:table-fixed":
            (position.successionPlan?.candidates &&
              position.successionPlan.candidates.length < 4) ||
            noCandidates,
        })}
      >
        <tbody>
          <tr className="p-0">
            <td className="sticky left-0 z-1 w-[8.375rem] bg-white" aria-label="spacer" />
            {compareShowFilledBy && (
              <td className="min-w-[12rem] border-l-neutral-8 p-0 [&:nth-child(2)]:border-l-0">
                <FilledByHeader
                  handlePagination={handleRotatePeople}
                  people={position.filledBy || []}
                  position={position}
                  visiblePerson={visiblePerson}
                />
              </td>
            )}
            {noCandidates && (
              <td
                className={cn("border-b-0 border-l-neutral-8 p-0 [&:nth-child(2)]:border-l-0", {
                  "": compareShowFilledBy,
                })}
                rowSpan={compareSelectedRows.length + 1}
              >
                <EmptyState positionId={position.id} />
              </td>
            )}
            {(position.successionPlan?.candidates || []).map((candidate) => (
              <td
                key={candidate.id}
                className="relative min-w-[12rem] border-l-neutral-8 p-0 [&:nth-child(2)]:border-l-0"
              >
                <CandidateHeader candidate={candidate} showFilledBy={compareShowFilledBy} />
              </td>
            ))}
          </tr>

          {compareSelectedRows.map((row, rowIndex) => (
            <tr key={row} className="p-0">
              <td className="text-wrap w-[8.375rem] min-w-[8.375rem] px-4 py-3">
                <div className="max-h-10 text-base-bold">
                  {row.includes("custom_field")
                    ? findCustomField(row)?.name || ""
                    : t(`v2.succession_plan_panel.compare.${row}`)}
                </div>
              </td>
              {compareShowFilledBy && visiblePerson && (
                <td className="border-l-neutral-8 bg-primary-3 [&:nth-child(2)]:border-l-0">
                  <div className="mx-auto w-fit text-center">
                    <CellMapper row={row} person={visiblePerson} />
                  </div>
                </td>
              )}
              {compareShowFilledBy && !visiblePerson && (
                <td
                  className="border-l-neutral-8 [&:nth-child(2)]:border-l-0"
                  aria-label="no content"
                />
              )}
              {(position.successionPlan?.candidates || []).map((candidate) =>
                candidate.person ? (
                  <td
                    key={candidate.id}
                    className={cn("border-l-neutral-8", {
                      "[&:nth-child(2)]:border-l-0":
                        position.successionPlan?.candidates &&
                        position.successionPlan?.candidates[0].person,
                    })}
                  >
                    <div className="mx-auto w-fit text-center">
                      <CellMapper row={row} candidate={candidate} person={candidate.person} />
                    </div>
                  </td>
                ) : (
                  !candidate.person &&
                  rowIndex === 0 && (
                    <td
                      key={candidate.id}
                      className="!border-b-0 border-l-neutral-8 p-4 [&:nth-child(2)]:border-l-0"
                      rowSpan={compareSelectedRows.length}
                    >
                      <p className="text-wrap">{sanitizeParse(candidate.additionalInfo || "")}</p>
                    </td>
                  )
                ),
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export { CompareTab }

interface EmptyStateProps {
  positionId: string
}

const EmptyState = ({ positionId }: EmptyStateProps) => {
  const { t } = useTranslation()
  return (
    <div className="min-w-[12rem] p-8">
      <div className="flex-col items-center gap-4 flex">
        <BlueIcon icon={["far", "users"]} size="med-lg" />
        <div className="text-wrap text-center">
          <p className="text-base-bold">
            {t("v2.succession_plan_panel.summary.no_candidates_yet")}
          </p>
          <p className="max-w-[50ch]">
            {t("v2.succession_plan_panel.summary.add_a_candidate_from")}
          </p>
        </div>
        <CandidateFormDropdown buttonType="primary" positionId={positionId} />
      </div>
    </div>
  )
}
