import classNames from "classnames"
import React, { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { defaultCellRangeRenderer, Grid } from "react-virtualized"

import { NodeInterface } from "types/graphql"
import { selectDatasheetRows } from "v2/redux/slices/GridSlice/gridSelectors/selectDatasheetRows"
import { nodeSelectors } from "v2/redux/slices/NodeSlice/NodeApi"
import { useAppSelector } from "v2/redux/store"

import { CellRenderer } from "../Datasheet"
import { Column } from "./types"
import { CELL_PADDING_PX, statKeys } from "./utils/constants"
import { calculateStatistics, formatStatistic } from "./utils/statistics"

export const FOOTER_EXTRA_HEIGHT = 125
export const FOOTER_EXTRA_MAX_HEIGHT = 200
export const BOTTOM_OFFSET = 40
export const FOOTER_MARGIN_OFFSET = 8
export const FOOTER_STAT_HEIGHT = 20
export const FOOTER_STAT_HEIGHT_SM = 16
const BORDER_OFFSET = 1.14
const AVATAR_FIELD_WIDTH = 40.5

interface FloatingFooterProps<CType> {
  columns: Column<CType>[]
  height: number
  rowCount: number
  stickyFooter: boolean
  width: number
}

export function FloatingFooter<CType>({
  columns,
  height,
  rowCount,
  stickyFooter,
  width,
}: FloatingFooterProps<CType>) {
  const firstColumnIsAvatar = columns[0]?.fieldKey === "avatar"
  const columnIndexOffset = firstColumnIsAvatar ? 1 : 0
  const pixelOffset = firstColumnIsAvatar ? AVATAR_FIELD_WIDTH : 0
  const gridRef = useRef<Grid>(null)
  // subtracts the footer padding from the height. Allows everything to mathematically align
  // while creating the offset between the footer border and column dividers
  const heightWithoutPadding = height - CELL_PADDING_PX * 4

  useEffect(() => {
    if (gridRef.current === null) return
    gridRef.current.recomputeGridSize()
  }, [width])

  const visibleRowsIds = useAppSelector((state) => selectDatasheetRows(state)).map((row) => row.id)
  const data = useAppSelector((state) => nodeSelectors.selectAll(state)).filter((row) =>
    visibleRowsIds.includes(row.id),
  )

  const { t } = useTranslation()

  const cellRenderer: CellRenderer = ({ columnIndex, key, style }) => {
    const column = columns[columnIndex + columnIndexOffset]
    const fieldKey = String(column.fieldKey)
    const stats = calculateStatistics(data, fieldKey as keyof NodeInterface)
    const isFirst = columnIndex === 0
    const isLast = columnIndex === columns.length - 1

    const calculateLeft = () => {
      if (!stickyFooter && isFirst) return (style.left as number) + BORDER_OFFSET
      if (stickyFooter && !isFirst)
        return (style.left as number) - FOOTER_MARGIN_OFFSET + BORDER_OFFSET
      return (style.left as number) + BORDER_OFFSET
    }

    return (
      <div
        key={key}
        style={{
          ...style,
          height: heightWithoutPadding,
          width:
            (style.width as number) -
            ((isFirst || isLast) && stickyFooter ? FOOTER_MARGIN_OFFSET : 0),
          left: calculateLeft(),
        }}
      >
        <div
          className={classNames("overflow-hidden", {
            "border-l-0": !isFirst,
            "border-r-0": !isLast,
            height: heightWithoutPadding,
          })}
        >
          <div
            style={{
              height: heightWithoutPadding,
              maxHeight: Number(style.maxHeight ?? heightWithoutPadding),
            }}
            className={classNames(
              "border-y-0 border-l-0 border-r-[1px] border-solid border-neutral-8-solid",
              {
                "border-l-0": isFirst,
                "border-r-0": isLast,
              },
            )}
          >
            <div className="h-full flex-col justify-center px-2 flex">
              {statKeys.map((statKey) =>
                !Object.prototype.hasOwnProperty.call(stats, statKey) || !stats[statKey] ? null : (
                  <div
                    key={statKey}
                    className="justify-between rounded px-1 transition-all duration-200 ease-out flex hover:bg-neutral-3"
                  >
                    <p className="text-base font-medium text-neutral-64">
                      {t(`v2.defaults.statistic_abbreviations.${statKey}`)}
                    </p>
                    <p className="text-base font-bold text-neutral-80">
                      {formatStatistic(stats[statKey], fieldKey, column.format)}
                    </p>
                  </div>
                ),
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      className={classNames("datasheet-footer transition-all duration-200 ease-out", {
        hidden: !stickyFooter,
        sticky: stickyFooter,
        relative: !stickyFooter,
      })}
      style={{
        height,
        width: width - pixelOffset,
        marginLeft: pixelOffset + 0.15,
        bottom: BOTTOM_OFFSET,
      }}
    >
      <Grid
        ref={gridRef}
        cellRenderer={cellRenderer}
        cellRangeRenderer={defaultCellRangeRenderer}
        columnCount={columns.length - columnIndexOffset}
        className={classNames("bg-white py-2", {
          "elevation--overlay mx-2 rounded-xl shadow-lg": stickyFooter,
          " rounded-b-xl border-x-0 border-y border-b-0 border-solid border-neutral-8-solid":
            !stickyFooter,
          "border-t-0": rowCount === 0,
        })}
        style={{
          width: stickyFooter ? width - FOOTER_MARGIN_OFFSET * 2 : width,
        }}
        containerStyle={innerGridStyles(
          stickyFooter ? width - FOOTER_MARGIN_OFFSET * 2 : width,
          1,
          heightWithoutPadding,
          CELL_PADDING_PX,
        )}
        columnWidth={({ index }) => columns[index + columnIndexOffset].width}
        height={height}
        rowCount={1}
        rowHeight={height}
        width={stickyFooter ? width - FOOTER_MARGIN_OFFSET * 2 : width}
      />
    </div>
  )
}

const innerGridStyles = (width: number, rowCount: number, rowHeight: number, padding: number) => ({
  width,
  maxWidth: width,
  height: rowCount * rowHeight,
  // prettier-ignore
  maxHeight: (rowCount * rowHeight) + padding,
})
