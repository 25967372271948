import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { range } from "lodash"
import React from "react"
import { useTranslation } from "react-i18next"

import { HeadcountPlan, HeadcountPlanParticipant } from "types/graphql.d"
import { formatCurrency, parseCurrency } from "v2/react/utils/currency"

import { BudgetEditableCell } from "./BudgetEditableCell"

interface Props {
  continuationLineLevels: number[]
  headcountPlan: HeadcountPlan
  isLastSibling: boolean
  level: number
  participant: HeadcountPlanParticipant
  isErrored: boolean
}

function BudgetTableRow({
  continuationLineLevels,
  headcountPlan,
  isLastSibling,
  level,
  participant,
  isErrored,
}: Props) {
  const { t } = useTranslation()

  return (
    <tr>
      <td>
        <div className="hcp-overview-table__environment-label">
          {level > 0 &&
            range(level - 1).map((i) => (
              <div key={i} className="hcp-overview-table__node-label">
                {hasConnectionLineToDownlineSibling(continuationLineLevels, i + 1) && (
                  <div className="hcp-overview-table__node-line-connect-sibling" />
                )}
              </div>
            ))}
          <div className={classNames("hcp-overview-table__node-label", { "w-0": level === 0 })}>
            {level > 0 && (
              <>
                {isLastSibling ? (
                  <div className="hcp-overview-table__node-line-last-sibling" />
                ) : (
                  <div className="hcp-overview-table__node-line-sibling" />
                )}

                <div className="hcp-overview-table__node-line-horizontal" />
              </>
            )}
          </div>
          <FontAwesomeIcon icon={["far", "sitemap"]} className="fa-w-14" />
          <a
            href={`/headcount_plans/${headcountPlan.id}/participants/${participant.id}`}
            className="hcp-overview-table__env-link"
          >
            {participant.position?.title || "untitled_position".t("headcount_plan")}
          </a>
        </div>
      </td>
      <td>
        <div className="h-[56px] items-center px-4 flex">
          {participant.person.avatarThumbUrl && (
            <img
              className="circle mr-2"
              src={participant.person.avatarThumbUrl}
              alt={participant.person.name}
            />
          )}
          <span>{participant.person.name}</span>
        </div>
      </td>
      <td>
        <div className="h-[56px]">
          <BudgetEditableCell
            inputName={participant.uniqueKey}
            isErrored={isErrored}
            currentValue={
              participant.budget === null
                ? ""
                : formatCurrency({
                    value: parseCurrency(participant.budget || 0),
                    omitSymbol: false,
                  })
            }
            inputPlaceholder={t("v2.headcount_plan.set_budgets.set_budget")}
          />
        </div>
      </td>
    </tr>
  )
}

const hasConnectionLineToDownlineSibling = (continuationLineLevels: number[], level: number) =>
  continuationLineLevels.includes(level)

export { BudgetTableRow }
