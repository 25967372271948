import cn from "classnames"
import React from "react"

type Props = {
  currentValue: string
  inputType?: string
  inputPlaceholder?: string
  isErrored: boolean
  inputName: string
}
export function BudgetEditableCell({
  isErrored,
  currentValue,
  inputPlaceholder,
  inputName,
  inputType = "text",
}: Props) {
  function handleEnter(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      event.preventDefault()
      const target = event.target as HTMLInputElement
      const form = target.form
      if (form) {
        const nextElement = form.elements[
          Array.prototype.indexOf.call(form.elements, target) + 1
        ] as HTMLInputElement
        nextElement?.focus()
      }
    }
  }
  return (
    <input
      name={inputName}
      type={inputType}
      defaultValue={currentValue}
      placeholder={inputPlaceholder}
      onKeyDown={handleEnter}
      className={cn(
        "width-full h-[55px] border border-solid px-4 text-right focus:border-primary-100",
        {
          "border-transparent": !isErrored,
          "border-status-critical": isErrored,
        },
      )}
    />
  )
}
