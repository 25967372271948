import React, { forwardRef, useState } from "react"

import {
  HeadcountPlanMember,
  HeadcountPlanMemberInput,
  Person,
  UpdateHeadcountPlanInput,
} from "types/graphql.d"
import { HeadcountPlanRole } from "types/graphql.enums"
import { PersonSearchInput } from "v2/react/shared/forms/PersonSearchInput"
import { UrlHelper } from "v2/react/utils/urls"
import { useSaveHeadcountPlanMembersMutation } from "v2/redux/GraphqlApi/HeadcountPlanningApi"

import { PlanMemberList } from "./PlanMemberList"

interface Props {
  currentPersonId: number
  headcountPlanId: string
  isLoadingSave: boolean
  mode: string | null
  onMemberRemoved: (member: HeadcountPlanMember) => void
  onMemberUpdated: (member: HeadcountPlanMember) => void
  onPersonAdded: (person: Person) => void
  savePlanParticipants: ReturnType<typeof useSaveHeadcountPlanMembersMutation>[0]
  selectedMembers: HeadcountPlanMember[]
  setBudget: boolean
}

export type InvitationsFormRef = {
  handleInvitationsSubmit: () => void
}

const InvitationsForm = forwardRef(
  (
    {
      currentPersonId,
      headcountPlanId,
      isLoadingSave,
      mode,
      onMemberRemoved,
      onMemberUpdated,
      onPersonAdded,
      savePlanParticipants,
      selectedMembers,
      setBudget,
    }: Props,
    ref,
  ) => {
    const existingParticipants: HeadcountPlanMemberInput[] = React.useMemo(
      () =>
        selectedMembers.map((m) => ({
          personId: m.person.id,
          positionId: m.person.primaryPosition ? m.person.primaryPosition.id.toString() : "",
          role: m.role,
        })),
      [selectedMembers],
    )
    const selectedPersonIds: string[] = selectedMembers.map((m) => m.person.id)

    const [invitesErrorMessage, setInvitesErrorMessage] = useState<string | React.ReactNode[]>()

    const saveParticipants = React.useCallback(
      // eslint-disable-next-line
      async (input: UpdateHeadcountPlanInput, callback: Function) => {
        const result = await savePlanParticipants({ input }).unwrap()
        if (result.updateHeadcountPlan?.success) {
          callback()
        } else {
          const errors = result.updateHeadcountPlan?.errors || []
          const message: React.ReactNode[] = errors.map((s: Error) => (
            <span key={s.message}>
              {s.message}
              <br />
            </span>
          ))
          setInvitesErrorMessage(message)
        }
      },
      [savePlanParticipants],
    )

    const handleInvitationsSubmit = React.useCallback(() => {
      const input: UpdateHeadcountPlanInput = {
        id: headcountPlanId,
        members: existingParticipants,
      }

      saveParticipants(input, () => {
        setInvitesErrorMessage("")
        if (setBudget && mode === "invite")
          window.location.href = UrlHelper.headcountPlanSetBudgetsPath(
            headcountPlanId,
            `?cancelTo=${UrlHelper.headcountPlansPath()}&mode=initialize`,
          )
        else window.location.href = UrlHelper.headcountPlanPath(headcountPlanId)
      })
    }, [headcountPlanId, existingParticipants, saveParticipants, setBudget, mode])

    const handlePersonSelect = (selectedPerson: Person) => {
      if (!selectedPerson.primaryPosition) {
        setInvitesErrorMessage("invitations_participant_position_error".t("headcount_plan"))
      } else {
        onPersonAdded(selectedPerson)
        setInvitesErrorMessage("")
      }
    }

    const handleMemberUpdate = (member: HeadcountPlanMember) => {
      if (!member.person.primaryPosition && member.role !== HeadcountPlanRole.Owner) {
        setInvitesErrorMessage("invitations_participant_position_error".t("headcount_plan"))
      } else {
        onMemberUpdated(member)
        setInvitesErrorMessage("")
      }
    }

    React.useImperativeHandle(ref, () => ({ handleInvitationsSubmit }), [handleInvitationsSubmit])

    return (
      <div className="justify-center flex">
        <div className="list-group mb-16 w-full p-6 sm:w-[544px]">
          <div className="mb-4">{"role_types_info".t("headcount_plan")} </div>
          <div className="mb-4">
            {"role_type_admins".t("headcount_plan")}{" "}
            <span className="text-neutral-64">
              {"role_type_admin_description".t("headcount_plan")}
            </span>
          </div>
          <div>
            {"role_type_owners".t("headcount_plan")}{" "}
            <span className="text-neutral-64">
              {"role_type_owner_description".t("headcount_plan")}
            </span>
          </div>
          {invitesErrorMessage && (
            <div className="alert alert-error mt-4">
              <span className="">{invitesErrorMessage}</span>
            </div>
          )}
          <div style={{ maxWidth: "31.25rem" }}>
            <div className="mb-1 mt-4">
              <strong>{"invitations_title".t("headcount_plan")}</strong>
            </div>
            <PersonSearchInput
              errorMessage=""
              htmlForId="approver_search_input"
              omitValues={selectedPersonIds}
              onSelect={handlePersonSelect}
              placeholder={`${"example_abbreviated".t("defaults")}: ${"example_name".t(
                "defaults",
              )}`}
            />
            <div className="mb-2 mt-4">
              <strong>{"invitations_participant_list_label".t("headcount_plan")}</strong>
              <PlanMemberList
                currentPersonId={currentPersonId}
                isSaving={isLoadingSave}
                onRemove={onMemberRemoved}
                onUpdate={handleMemberUpdate}
                selectedMembers={selectedMembers}
                setErrorMessage={setInvitesErrorMessage}
              />
              {selectedMembers.length === 0 && (
                <div className="mt-4">
                  {"invitations_participant_list_placeholder".t("headcount_plan")}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  },
)

export { InvitationsForm }
