import cn from "classnames"
import React from "react"

import { Spinner } from "v2/react/shared/loaders/Spinner"

interface LoadingIndicatorProps {
  children?: React.ReactNode
  isLoading: boolean
  spinnerStyle?: React.CSSProperties
  spinnerClass?: string
  wrapperStyle?: React.CSSProperties
  wrapperClass?: string
}

function LoadingIndicator({
  children,
  isLoading,
  spinnerStyle,
  spinnerClass,
  wrapperStyle,
  wrapperClass,
}: LoadingIndicatorProps) {
  if (isLoading)
    return (
      <div className={cn(wrapperClass)} style={wrapperStyle}>
        <Spinner className={cn(spinnerClass)} style={spinnerStyle} />
      </div>
    )

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}

export { LoadingIndicator }
