import fp from "lodash/fp"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"

import { FieldName } from "v2/react/components/positions/positionForm/types"

type TLabelFn = (name: FieldName, defaultValue?: string) => string
type FieldLabelHelpers = { tLabel: TLabelFn }

const smartSnakeCase = (fieldName: string) => fieldName.split(".").map(fp.snakeCase).join(".")

/**
 * Returns a utility function which translates `FieldName` values, excluding
 * those for dynamic collection items.
 */
const useFieldLabelHelpers = (): FieldLabelHelpers => {
  const [t, { exists: tExists }] = useTranslation()

  const tLabel = useCallback<TLabelFn>(
    (fieldNameInCamel, defaultValue) => {
      const fieldName = smartSnakeCase(fieldNameInCamel)
      if (isPeopleInput(fieldNameInCamel)) return t("v2.simple_form.labels.position.person_name")
      if (isReportsToName(fieldNameInCamel)) return t("v2.simple_form.labels.position.parent_id")
      if (isLocationKey(fieldNameInCamel)) return t("v2.simple_form.labels.position.location")
      if (tExists(`v2.simple_form.labels.${fieldName}`))
        return t(`v2.simple_form.labels.${fieldName}`)
      if (tExists(`v2.positions.form.${fieldName}`)) return t(`v2.positions.form.${fieldName}`)
      if (fieldNameInCamel === "position.positionBasePayType")
        return t(`v2.display_fields.position_base_pay_type`)
      if (fieldNameInCamel === "position.positionHoursPerWeek")
        return t(`v2.display_fields.position_hours_per_week`)
      if (fieldNameInCamel === "position.positionType")
        return t("v2.simple_form.labels.position.title")
      if (fieldNameInCamel === "position.totalBudgetedCompensation")
        return t("v2.simple_form.labels.position.total_budgeted_compensation")

      return defaultValue ?? fieldName
    },
    [t, tExists],
  )

  return { tLabel }
}

const isPeopleInput = (name: FieldName) => name === "position.people"

const isReportsToName = (name: FieldName) => name === "position.reportsToName"

const isLocationKey = (name: FieldName) =>
  name === "position.locationId" || name === "position.locationName"

export { useFieldLabelHelpers }
