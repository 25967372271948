import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { z } from "zod"

import { ProfilePanelMode } from "types/graphql.enums"
import { removeTooltip, tooltipHandler } from "v2/react/utils/tooltipHandler"
import { openProfilePanelSidebar, setProfilePanelMode } from "v2/redux/slices/ProfilePanelSlice"
import { closeSuccessionPlanSidebar } from "v2/redux/slices/SuccessionPlanPanelSlice"
import { useAppDispatch } from "v2/redux/store"

const PanelMode = z.enum([ProfilePanelMode.Person, ProfilePanelMode.Position])
const TRIGGER_SELECTOR = ".profile-panel-trigger"

/**
 * In various spots throughout the app we need to open the person/position
 * profile panel outside of a React component. This component adds an event
 * listener that has access to the redux context, so it can open the profile
 * panel when a trigger is clicked.
 *
 * In order for things to work correctly, the trigger element must have the
 * following data attributes defined:
 * - data-position-id: The ID of the position to open the profile panel for (if
 *   present)
 * - data-person-id: The ID of the person to open the profile panel for (if
 *   present)
 * - data-panel-mode: The initial mode to open the profile panel in (`person` or
 *   `position`). Defaults to `person`.
 *
 * Example usage:
 * ```html
 *  <button
 *    class="profile-panel-trigger"
 *    data-position-id="123"
 *    data-person-id="456"
 *    data-panel-mode="position"
 *   >
 *    Open Profile Panel!!!
 *   </button>
 * ```
 *
 */
const usePanelListeners = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const target = event.target
      if (!(target instanceof Element)) return

      const trigger = target?.closest(TRIGGER_SELECTOR)

      if (trigger?.contains(target)) {
        event.preventDefault()
        event.stopPropagation()

        const positionId = trigger.getAttribute("data-position-id")
        const personId = trigger.getAttribute("data-person-id")
        const panelMode =
          PanelMode.safeParse(trigger.getAttribute("data-panel-mode"))?.data ||
          ProfilePanelMode.Person

        if (trigger.getAttribute("data-remove-tooltip-on-click") === "true") {
          removeTooltip(trigger, TRIGGER_SELECTOR)
        }

        if (!positionId && !personId) return

        dispatch(setProfilePanelMode(panelMode))
        dispatch(closeSuccessionPlanSidebar())
        dispatch(openProfilePanelSidebar({ positionId, personId }))
      }
    }

    const handleMouseEnter = tooltipHandler(TRIGGER_SELECTOR, t("v2.succession.info_panel"))

    document.addEventListener("click", handleClick)
    document.addEventListener("mouseenter", handleMouseEnter, true)
    return () => {
      document.removeEventListener("click", handleClick)
      document.removeEventListener("mouseenter", handleMouseEnter, true)
    }
  }, [dispatch, t])
}

export { usePanelListeners }
