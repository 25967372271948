import React from "react"

import { JsonAutocompleteInput } from "./AutocompleteControl"
import { EnhancedControlProps } from "./types"

interface Option {
  id: string
  label: string
}

const DepartmentControl = ({
  handleChange: originalHandleChange,
  ...props
}: EnhancedControlProps) => {
  const handleChange = (path: string, option: Option) => {
    originalHandleChange(path, { ...(props.data ?? {}), ...option })
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <JsonAutocompleteInput {...props} handleChange={handleChange} />
}

export { DepartmentControl }
