import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { TFunction } from "i18next"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { AllowedAttribute, HeadcountPlanParticipant, Maybe, Scalars } from "types/graphql"
import { AvatarCircleItem, AvatarCircles } from "v2/react/shared/collection/AvatarCircles"
import { Modal } from "v2/react/shared/overlay/Modal"
import { AlertBanner } from "v2/react/shared/status/AlertBanner"
import { useSubmitHeadcountPlanProposalMutation } from "v2/redux/GraphqlApi/HeadcountPlanningApi"
import { updateCellStates } from "v2/redux/slices/DatasheetSlice"
import { CellEvent } from "v2/redux/slices/DatasheetSlice/types"
import { useAppDispatch } from "v2/redux/store"

import { BudgetOverBanner } from "../banners/BudgetOverBanner"

interface Props {
  disabled: boolean
  display?: "menuItem" | "button"
  onClick?: () => void
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export function SubmitProposalButton({ disabled, display = "button", onClick, setIsOpen }: Props) {
  return (
    <button
      type="button"
      className={classNames("whitespace-nowrap", {
        "btn--large btn--secondary": display === "button",
        "dropdown-menu-link gap-2 flex": display === "menuItem",
        disabled,
      })}
      onClick={() => {
        setIsOpen(true)
        onClick?.()
      }}
      disabled={disabled}
    >
      <div className="justify-self-center">
        <FontAwesomeIcon
          icon={["far", "paper-plane"]}
          className={classNames({ "mr-1": display === "button" })}
        />
      </div>
      {"submit_proposal_button".t("headcount_plan")}
    </button>
  )
}

type HeadcountPlanChangeProjection = {
  id: Scalars["ID"]
  systemIdentifier: string
  positionAttributesWithEdits?: Maybe<Scalars["JSON"]>
}

type ModalProps = {
  allowedAttributes: AllowedAttribute[]
  budget: HeadcountPlanParticipant["budget"]
  headcountPlanId: string
  isOpen: boolean
  participantId: string
  positions: HeadcountPlanChangeProjection[] | undefined | null
  proposedBudget: HeadcountPlanParticipant["proposedBudget"]
  remainingBudget: HeadcountPlanParticipant["remainingBudget"]
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  submitsTo?: AvatarCircleItem[]
}
export function SubmitProposalModal({
  allowedAttributes,
  budget,
  headcountPlanId,
  isOpen,
  participantId,
  positions,
  proposedBudget,
  remainingBudget,
  setIsOpen,
  submitsTo,
}: ModalProps) {
  const { t } = useTranslation()
  const [note, setNote] = React.useState("")
  const [mutate, mutation] = useSubmitHeadcountPlanProposalMutation()

  React.useEffect(() => {
    if (mutation.data?.submitHeadcountPlanProposal?.success) {
      mutation.reset()
      setIsOpen(false)
    }
  }, [mutation, setIsOpen])

  const positionsMissingBudgets: string[] = []
  const cellStateUpdates: CellEvent[] = []
  const appDispatch = useAppDispatch()
  if (isOpen && budget && positions) {
    positions.forEach((position: HeadcountPlanChangeProjection) => {
      validateBudgetFields({
        cellStateUpdates,
        position,
        positionsMissingBudgets,
        allowedAttributes,
        t,
      })
    })
  }

  useEffect(() => {
    if (isOpen && cellStateUpdates.length > 0) appDispatch(updateCellStates(cellStateUpdates))
  })

  if (positionsMissingBudgets && positionsMissingBudgets.length > 0)
    return (
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false)
        }}
        title={t("v2.headcount_plan.submit_proposal.information_missing")}
        footer={
          <button
            type="button"
            className="btn--large btn--secondary"
            onClick={() => {
              setIsOpen(false)
            }}
          >
            {t("v2.headcount_plan.submit_proposal.back_to_proposal")}
          </button>
        }
      >
        <div className="p-6">
          <div className="pb-4">
            <AlertBanner
              type="critical"
              messages={[
                {
                  icon: (
                    <FontAwesomeIcon
                      icon={["far", "exclamation-triangle"]}
                      className="h-4"
                      style={{ width: "1rem" }}
                    />
                  ),
                  text: (
                    <span className="font-medium">
                      {t("v2.headcount_plan.submit_proposal.budget_missing_for_positions")}
                      <ul>
                        {positionsMissingBudgets.map((positionName) => (
                          <li key={positionName}>{positionName}</li>
                        ))}
                      </ul>
                    </span>
                  ),
                },
              ]}
            />
          </div>
        </div>
      </Modal>
    )

  if (isOpen) {
    const limit = submitsTo?.length === 5 ? 3 : 4

    return (
      <Modal
        size="md"
        isOpen={isOpen}
        onClose={() => {
          mutation.reset()
          setIsOpen(false)
        }}
        title={t("v2.headcount_plan.submit_proposal.submit_proposal")}
        footer={
          <button
            type="submit"
            className="btn--large btn--primary"
            disabled={mutation.data?.submitHeadcountPlanProposal?.success === false}
            onClick={() => {
              mutate({
                input: {
                  headcountPlanId: headcountPlanId.toString(),
                  participantId,
                  justification: note,
                },
              })
            }}
          >
            {mutation.isLoading
              ? "submit_proposal_button_pending".t("headcount_plan")
              : "submit_proposal_button".t("headcount_plan")}
          </button>
        }
      >
        <div className="p-6">
          {mutation.data?.submitHeadcountPlanProposal?.success === false && (
            <div className="pb-4">
              <AlertBanner
                type="critical"
                messages={[
                  {
                    icon: (
                      <FontAwesomeIcon
                        icon={["far", "exclamation-circle"]}
                        className="h-4"
                        style={{ width: "1rem" }}
                      />
                    ),
                    text: (
                      <span className="font-medium">
                        {mutation.data?.submitHeadcountPlanProposal?.errors?.[0]?.message}
                      </span>
                    ),
                  },
                ]}
              />
            </div>
          )}

          {budget && proposedBudget && remainingBudget?.includes("-") && (
            <BudgetOverBanner
              budget={budget}
              proposedBudget={proposedBudget}
              remainingBudget={remainingBudget}
            />
          )}

          {submitsTo ? (
            <>
              <label>{t("v2.headcount_plan.submit_proposal.send_to")}</label>
              <div className="items-center gap-2 rounded-lg border border-solid border-neutral-8 bg-white p-2 flex">
                <AvatarCircles items={submitsTo} />
                {submitsTo
                  .slice(0, limit)
                  .map((c) => c.person.name)
                  .join(", ")}
                {submitsTo.length > limit &&
                  t("v2.headcount_plan.submit_proposal.and_other_admins", {
                    count: submitsTo.length - limit,
                  })}
              </div>
              <div className="input-group mt-6">
                <label htmlFor="note">{t("v2.headcount_plan.submit_proposal.note")}</label>
                <div style={{ width: "100%" }}>
                  <textarea
                    id="note"
                    className="input"
                    name="note"
                    style={{ resize: "none" }}
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  />
                </div>
              </div>
            </>
          ) : (
            <p>{"confirm_submit_to_entire_org".t("headcount_plan")}</p>
          )}
        </div>
      </Modal>
    )
  }
}

interface validateBudgetFieldsProps {
  cellStateUpdates: CellEvent[]
  position: HeadcountPlanChangeProjection
  positionsMissingBudgets: string[]
  allowedAttributes: AllowedAttribute[]
  t: TFunction
}

const validateBudgetFields = ({
  cellStateUpdates,
  position,
  positionsMissingBudgets,
  allowedAttributes,
  t,
}: validateBudgetFieldsProps) => {
  const attributes = position.positionAttributesWithEdits
  const systemId = position.systemIdentifier
  const title = attributes.title.label
  const positionLabel = `${systemId} ${title && ": "}${title}`

  if (!attributes.budgeted_base_pay_rate) {
    if (!positionsMissingBudgets.includes(positionLabel))
      positionsMissingBudgets.push(positionLabel)

    const label = allowedAttributes.find((a) => a.id === "budgeted_base_pay_rate")?.name

    cellStateUpdates.push({
      rowId: position.id,
      columnId: "budgeted_base_pay_rate",
      type: "error",
      message: t("v2.headcount_plan.datasheet.information_missing", {
        missing: label,
      }),
    })
  }

  if (!attributes.budgeted_base_pay_type) {
    if (!positionsMissingBudgets.includes(positionLabel))
      positionsMissingBudgets.push(positionLabel)

    const label = allowedAttributes.find((a) => a.id === "budgeted_base_pay_type")?.name

    cellStateUpdates.push({
      rowId: position.id,
      columnId: "budgeted_base_pay_type",
      type: "error",
      message: t("v2.headcount_plan.datasheet.information_missing", {
        missing: label,
      }),
    })
  }

  if (
    attributes.budgeted_base_pay_type === "position_base_pay_type_hourly" &&
    !attributes.position_hours_per_week
  ) {
    if (!positionsMissingBudgets.includes(positionLabel))
      positionsMissingBudgets.push(positionLabel)

    const label = allowedAttributes.find((a) => a.id === "position_hours_per_week")?.name

    cellStateUpdates.push({
      rowId: position.id,
      columnId: "position_hours_per_week",
      type: "error",
      message: t("v2.headcount_plan.datasheet.information_missing", {
        missing: label,
      }),
    })
  }
}
