import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { HeadcountPlanningNavTitleWithSubtitle } from "v2/react/components/headcountPlanning/HeadcountPlanningNav"
import { PlanColumns } from "v2/react/components/headcountPlanning/PlanColumns"
import { PlanDates } from "v2/react/components/headcountPlanning/PlanDates"
import { PlanSetBudgetToggle } from "v2/react/components/headcountPlanning/PlanSetBudgetToggle"
import { PlanTitle } from "v2/react/components/headcountPlanning/PlanTitle"
import { FormElements } from "v2/react/components/headcountPlanning/types"
import { makeDateRange } from "v2/react/shared/forms/DateInputs/DateRange/hooks/useDateRange"
import { Spinner } from "v2/react/shared/loaders/Spinner"
import PageNav from "v2/react/shared/navigation/PageNav"
import { TitleBlockLarge } from "v2/react/shared/navigation/PageNav/TitleBlock"
import { errorFor } from "v2/react/utils/errors"
import { UrlHelper } from "v2/react/utils/urls"
import {
  useGetHeadcountPlanForEditQuery,
  useSaveHeadcountPlanMutation,
} from "v2/redux/GraphqlApi/HeadcountPlanningApi"

import { PlanNewPositionIdPrefix } from "./PlanNewPositionIdPrefix"
import { StickyFooterBar } from "./StickyFooterBar"

export function EditSetup({ headcountPlanId }: { headcountPlanId: number }) {
  const { data, isLoading } = useGetHeadcountPlanForEditQuery({ id: headcountPlanId.toString() })
  const [mutate, mutation] = useSaveHeadcountPlanMutation()
  const [budgetOn, setBudgetOn] = useState<boolean | undefined>(undefined)

  const headcountPlan = data?.headcountPlan

  useEffect(() => {
    if (headcountPlan) setBudgetOn(!!headcountPlan?.setBudget)
  }, [headcountPlan])

  useEffect(() => {
    if (mutation.data?.saveHeadcountPlan?.errors?.length === 0) {
      window.location.href = UrlHelper.headcountPlanPath(headcountPlanId)
    }
  }, [mutation.data, headcountPlanId])

  const { t } = useTranslation()

  if (isLoading || !headcountPlan || budgetOn === undefined) return <Spinner />

  return (
    <>
      <PageNav>
        <TitleBlockLarge>
          <HeadcountPlanningNavTitleWithSubtitle
            title={t("v2.headcount_plan.edit_setup.header")}
            subtitle={headcountPlan.name}
            subtitleLinkTo={UrlHelper.headcountPlanPath(headcountPlanId)}
          />
        </TitleBlockLarge>
      </PageNav>
      <div className="justify-center py-6 flex">
        <form
          id="edit-setup-form"
          className="list-group mx-4 mb-16 w-full rounded-xl bg-white p-6 sm:w-[544px]"
          onSubmit={(event) => {
            event.preventDefault()
            const target = event.target as HTMLFormElement
            const elements = target.elements as FormElements
            const input = {
              id: headcountPlanId,
              name: elements.planTitle.value,
              newPositionIdPrefix: elements.newPositionIdPrefix.value,
              setBudget: elements.setBudget.value === "1",
              allowedAttributes: Array.from(elements["columns[]"])
                .filter((el) => el.checked)
                .map((el) => el.value),
            }
            mutate({ input })
          }}
        >
          <p className="text-status-critical">
            {mutation.isSuccess &&
              errorFor("saveHeadcountPlan", mutation.data?.saveHeadcountPlan?.errors)}
          </p>
          <div className="gap-4 flex">
            <PlanTitle
              isSuccess={mutation.isSuccess}
              errors={mutation.data?.saveHeadcountPlan?.errors}
              defaultValue={headcountPlan?.name}
            />
            <PlanNewPositionIdPrefix
              isSuccess={mutation.isSuccess}
              errors={mutation.data?.saveHeadcountPlan?.errors}
              defaultValue={headcountPlan?.newPositionIdPrefix}
            />
          </div>
          <PlanDates
            dateRange={makeDateRange(headcountPlan?.startDate, headcountPlan?.endDate)}
            errors={mutation.data?.saveHeadcountPlan?.errors}
            disabled
          />
          <PlanSetBudgetToggle budgetOn={budgetOn} setBudgetOn={setBudgetOn} />
          <PlanColumns budgetOn={budgetOn} columns={headcountPlan?.allowedAttributes} />
        </form>
      </div>
      <StickyFooterBar innerClassName="w-full sm:w-[544px]">
        <a
          href={UrlHelper.headcountPlanPath(headcountPlanId)}
          className="btn--large btn--secondary"
          type="submit"
        >
          {t("v2.defaults.cancel")}
        </a>
        <button className="btn--large btn--primary" type="submit" form="edit-setup-form">
          {mutation.isLoading ? `${t("v2.defaults.saving")}...` : t("v2.defaults.save")}
        </button>
      </StickyFooterBar>
    </>
  )
}
