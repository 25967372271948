import fp from "lodash/fp"
import { useCallback, useMemo, useState } from "react"

import { FormShape } from "v2/react/components/jobRequisitions/RequisitionForm/types/FormShape"
import { mapToPositionData } from "v2/react/components/jobRequisitions/RequisitionForm/utils/mapToPositionFieldValues"

type HookArg = {
  initial?: FormShape
  initializeCurrentWith?: FormShape
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  projectionAttributes?: any
}

function useRequisitionDataForDiffValues({
  initial,
  initializeCurrentWith,
  projectionAttributes,
}: HookArg) {
  const initialFieldValues = useMemo(
    () => (initial ? mapToPositionData(initial) : undefined),
    [initial],
  )
  const initialWithoutDefaults = useMemo(
    () =>
      initialFieldValues && fp.isNil(projectionAttributes?.full_time_equivalent)
        ? { position: { ...initialFieldValues.position, fte: null } }
        : initialFieldValues,
    [initialFieldValues, projectionAttributes],
  )

  const [currentFieldValues, setCurrentFieldValues] = useState(() =>
    initializeCurrentWith ? mapToPositionData(initializeCurrentWith) : initialWithoutDefaults,
  )

  if (!currentFieldValues && !initializeCurrentWith && initialFieldValues) {
    setCurrentFieldValues(initialFieldValues)
  }

  return {
    currentFieldValues,
    initialFieldValues: initialWithoutDefaults,
    updateCurrentFieldValues: useCallback(
      (data: FormShape) => setCurrentFieldValues(mapToPositionData(data)),
      [setCurrentFieldValues],
    ),
  }
}

export { useRequisitionDataForDiffValues }
