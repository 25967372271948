import { JsonForms } from "@jsonforms/react"
import { JsonFormsStyleContext, vanillaCells } from "@jsonforms/vanilla-renderers"
import Ajv, { ErrorObject, ValidateFunction } from "ajv"
import React from "react"
import { useTranslation } from "react-i18next"

import { JsonForm } from "types/graphql"
import { createRenderers } from "v2/react/components/jobRequisitions/renderers"
import {
  ListDiffEntries,
  RenderWhenDiffEntriesNonEmpty,
} from "v2/react/components/positions/positionFieldValuesDiff"
import { Notice } from "v2/react/shared/status/Notice"

import { FormShape } from "./types/FormShape"

const styleContextValue = {
  styles: [
    {
      name: "horizontal.layout",
      classNames: ["horizontal-layout items-start"],
    },
  ],
}

interface Props {
  additionalErrors: ErrorObject[]
  ajv: Ajv
  formData: FormShape
  handleChange: ({ data }: { data: FormShape }) => void
  jsonForm: JsonForm
  submitting: boolean
  validateFunc: ValidateFunction
}

function ModalFormContent({
  additionalErrors,
  ajv,
  formData,
  handleChange,
  jsonForm,
  submitting,
  validateFunc,
}: Props) {
  const { t } = useTranslation()
  if (!jsonForm) return null

  return (
    <JsonFormsStyleContext.Provider value={styleContextValue}>
      <div className="create-req-form flex-col flex">
        <RenderWhenDiffEntriesNonEmpty ignore={["position.budgetedBaseCompensation"]}>
          <Notice wrapperClass="mb-4" type="caution" icon={["far", "exclamation-triangle"]}>
            <div className="flex-col flex">
              <div>
                <strong className="text-neutral-100">
                  {t("v2.headcount_plan.add_position_from_row_modal.form_diff_alert_into")}
                </strong>
              </div>
              <ListDiffEntries ignore={["position.budgetedBaseCompensation"]} />
            </div>
          </Notice>
        </RenderWhenDiffEntriesNonEmpty>
        <JsonForms
          additionalErrors={additionalErrors}
          schema={jsonForm.schema.data_schema}
          uischema={jsonForm.schema.ui_schema}
          data={formData}
          renderers={createRenderers}
          cells={vanillaCells}
          ajv={ajv}
          onChange={handleChange}
          config={{ submitting, validateFunc }}
        />
      </div>
    </JsonFormsStyleContext.Provider>
  )
}

export { ModalFormContent }
