import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React from "react"

interface Step {
  step: number
  label: string
}

type StepProps = Step & {
  currentStep: Step["step"]
}

const Step = ({ step, label, currentStep }: StepProps) => {
  const isCurrentStep = step === currentStep
  const isPastStep = step < currentStep
  const isFutureStep = step > currentStep

  return (
    <div className="items-center flex">
      <div
        className={cn("h-6 w-6 items-center justify-center rounded-full flex", {
          "bg-primary-100": isCurrentStep,
          "bg-primary-80": isPastStep,
          "bg-neutral-8": isFutureStep,
        })}
      >
        <span className={cn({ "text-white": !isFutureStep })}>
          {isPastStep ? <FontAwesomeIcon icon={["far", "check"]} size="sm" /> : step}
        </span>
      </div>
      <span
        className={cn("ml-2 text-base-bold", {
          "text-neutral-64": !isCurrentStep,
          "text-neutral-100": isCurrentStep,
        })}
      >
        {label}
      </span>
    </div>
  )
}

interface StepperProps {
  steps: Step[]
  currentStep: Step["step"]
  wrapperClassName?: string
}

const Stepper = ({ steps, currentStep, wrapperClassName }: StepperProps) => (
  <div className={cn("w-full items-center justify-between px-2 flex", wrapperClassName)}>
    {steps.map((step, index) => {
      const isLast = index === steps.length - 1

      return (
        <React.Fragment key={step.label}>
          <Step step={step.step} label={step.label} currentStep={currentStep} />
          {!isLast && <div className="mx-2 h-[2px] flex-grow bg-neutral-8" />}
        </React.Fragment>
      )
    })}
  </div>
)

export { Stepper }
