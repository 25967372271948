import dayjs from "dayjs"

import { OrgUnit, PositionForRequisitionQuery, VariablePay, VariablePayType } from "types/graphql"
import {
  FormBasePay,
  FormOrgUnit,
  FormPositionShape,
  FormVariablePay,
} from "v2/react/components/jobRequisitions/RequisitionForm/types/FormShape"
import { GenericFormPositionShape } from "v2/react/components/jobRequisitions/RequisitionForm/types/GenericFormShape"
import { PosigenFormPositionShape } from "v2/react/components/jobRequisitions/RequisitionForm/types/PosigenFormShape"

type BackfillPositionForRequisition = PositionForRequisitionQuery["position"]

/**
 * When the data for a backfill position is loaded, the values get formatted
 * appropriately here. Some fields on the query use a different structure than
 * would we use and persist for the job requisition, so things like name vs
 * label, option fields, etc. need to be handled here.
 *
 * Fields that are optional may come from the backend as null and need defaults,
 * where that default data should be structured as found in the FormShape types.
 * We use the defaultValues for this data.
 *
 * Position fields that do not use the backfilled position's value are omitted
 * from fields present here, and take on the form data value in `...formData`.
 * E.g. hasDirectReports, isBudgeted, schemaType, etc.
 */
const loadGenericBackfill = (
  backfillPosition: BackfillPositionForRequisition,
  defaultValues: GenericFormPositionShape,
  formData: GenericFormPositionShape,
): GenericFormPositionShape => {
  if (!backfillPosition) return defaultValues

  return {
    ...formData,
    assignedParent: backfillPosition.assignedParent || defaultValues.assignedParent,
    companyCode: backfillPosition.companyCode || defaultValues.companyCode,
    externalIdentifier: backfillPosition.externalIdentifier || defaultValues.externalIdentifier,
    fte: backfillPosition.fte || defaultValues.fte,
    projectedHireDate: projectedHireValue(backfillPosition, defaultValues),

    basePay: basePayValue(backfillPosition, defaultValues),
    orgUnits: orgUnitValues(defaultValues, formData.orgUnits, backfillPosition.orgUnits),
    eeocClassification: {
      id: backfillPosition.eeocClassificationOption?.id || defaultValues.eeocClassification?.id,
      name:
        backfillPosition.eeocClassificationOption?.label || defaultValues.eeocClassification?.name,
    },
    employeeType: {
      id: backfillPosition.employeeType?.id || defaultValues.employeeType?.id,
      name: backfillPosition.employeeType?.name || defaultValues.employeeType?.name,
    },
    flsa: {
      id: backfillPosition.flsa?.id || defaultValues.flsa?.id,
      name: backfillPosition.flsa?.name || defaultValues.flsa?.name,
    },
    jobTitle: {
      id: backfillPosition.jobTitle?.id || defaultValues.jobTitle.id,
      jobCodeTitleLabel:
        backfillPosition.jobTitle?.jobCodeTitleLabel || defaultValues.jobTitle.jobCodeTitleLabel,
      jobCode: backfillPosition.jobTitle?.jobCode || defaultValues.jobTitle.jobCode,
      title: backfillPosition.jobTitle?.title || defaultValues.jobTitle.title,
      jobLevel: backfillPosition.jobTitle?.jobLevel
        ? {
            id: backfillPosition.jobTitle.jobLevel.id,
            codeNameLabel: backfillPosition.jobTitle.jobLevel.codeNameLabel,
          }
        : null,
      payGrade: backfillPosition.jobTitle?.payGrade
        ? {
            id: backfillPosition.jobTitle.payGrade.id,
            codeNameLabel: backfillPosition.jobTitle.payGrade.codeNameLabel,
          }
        : null,
    },
    location: {
      id: backfillPosition.location?.id || defaultValues.location?.id,
      name: backfillPosition.location?.name || defaultValues.location?.name,
    },
    reportsTo: {
      id: backfillPosition.reportsTo?.id || defaultValues.reportsTo?.id,
      name: backfillPosition.reportsTo?.name || defaultValues.reportsTo?.name,
    },
    variablePayTypes: variablePayValues(
      defaultValues,
      formData.variablePayTypes,
      backfillPosition.variablePays,
    ),
  }
}

const loadPosigenBackfill = (
  backfillPosition: BackfillPositionForRequisition,
  defaultValues: PosigenFormPositionShape,
  formData: PosigenFormPositionShape,
): PosigenFormPositionShape => {
  if (!backfillPosition) return defaultValues
  return {
    ...formData,
    assignedParent: backfillPosition.assignedParent || defaultValues.assignedParent,
    budgetNumber: backfillPosition.budgetNumber || defaultValues.budgetNumber,
    projectedHireDate: projectedHireValue(backfillPosition, defaultValues),

    basePay: basePayValue(backfillPosition, defaultValues),
    department: {
      id: backfillPosition.department?.id || defaultValues.department?.id,
      name: backfillPosition.department?.name || defaultValues.department?.name,
      typeId: null,
      typeName: null,
    },
    employeeType: {
      id: backfillPosition.employeeType?.id || defaultValues.employeeType?.id,
      name: backfillPosition.employeeType?.name || defaultValues.employeeType?.name,
    },
    flsa: {
      id: backfillPosition.flsa?.id || defaultValues.flsa?.id,
      name: backfillPosition.flsa?.name || defaultValues.flsa?.name,
    },
    jobTitle: {
      id: backfillPosition.jobTitle?.id || defaultValues.jobTitle.id,
      jobCodeTitleLabel:
        backfillPosition.jobTitle?.jobCodeTitleLabel || defaultValues.jobTitle.jobCodeTitleLabel,
      jobCode: backfillPosition.jobTitle?.jobCode || defaultValues.jobTitle.jobCode,
      title: backfillPosition.jobTitle?.title || defaultValues.jobTitle.title,
    },
    location: {
      id: backfillPosition.location?.id || defaultValues.location?.id,
      name: backfillPosition.location?.name || defaultValues.location?.name,
    },
    reportsTo: {
      id: backfillPosition.reportsTo?.id || defaultValues.reportsTo?.id,
      name: backfillPosition.reportsTo?.name || defaultValues.reportsTo?.name,
    },
  }
}

const basePayValue = (
  backfillPosition: BackfillPositionForRequisition,
  defaultValues: FormPositionShape,
): FormBasePay => ({
  amount: backfillPosition?.budgetedBasePay || defaultValues.basePay.amount,
  hoursPerWeek: backfillPosition?.hoursPerWeek || defaultValues.basePay.hoursPerWeek,
  id: "base-pay",
  name: "basePay",
  payType: {
    id: backfillPosition?.budgetedBasePayType?.id || defaultValues.basePay.payType.id,
    name: backfillPosition?.budgetedBasePayType?.label || defaultValues.basePay.payType.name,
  },
})

const projectedHireValue = (
  backfillPosition: BackfillPositionForRequisition,
  defaultValues: FormPositionShape,
): string => {
  if (backfillPosition?.projectedHireDate)
    return dayjs(backfillPosition.projectedHireDate).format("YYYY-MM-DD")
  if (defaultValues.projectedHireDate)
    return dayjs(defaultValues.projectedHireDate).format("YYYY-MM-DD")
  return ""
}

const variablePayValues = (
  defaultValues: GenericFormPositionShape,
  formDataValues: VariablePayType[] | null,
  payTypes?: VariablePay[] | null,
): FormVariablePay[] => {
  if (!payTypes || payTypes.length === 0 || !formDataValues) return defaultValues.variablePayTypes
  return payTypes
    .map((vp: VariablePay) => ({
      amount: parseFloat(vp.amount || ""),
      name: vp.display_name || "",
      id: vp.variable_pay_type_id || "",
      type: vp.pay_type ? `variable_pay_pay_type_${vp.pay_type}` : "variable_pay_pay_type_amount",
    }))
    .filter((payType) => formDataValues.findIndex((v) => v.id === payType.id) !== -1)
}

const orgUnitValues = (
  defaultValues: GenericFormPositionShape,
  formDataValues: FormOrgUnit[] | null,
  orgUnits?: OrgUnit[] | null,
): FormOrgUnit[] => {
  if (!orgUnits || orgUnits.length === 0 || !formDataValues) return defaultValues.orgUnits

  return orgUnits
    ?.map((orgUnit) => ({
      id: orgUnit.id || "",
      name: orgUnit.full_name || "",
      typeId: orgUnit.field_id || "",
      typeName: orgUnit.orgUnitType?.name || "",
    }))
    .filter(
      (orgUnit) =>
        (formDataValues || []).findIndex(
          (formDataUnit) => formDataUnit.typeId === orgUnit.typeId,
        ) !== -1,
    )
}

export { loadGenericBackfill, loadPosigenBackfill }
