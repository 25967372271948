import React from "react"
import { createPortal } from "react-dom"

import { FilterableTables } from "types/graphql.enums"
import RootProvider from "v2/react/components/RootProvider"
import { useNProgressBar } from "v2/react/hooks/useNProgressBar"
import { Badge } from "v2/react/shared/tables/TableUtilities/FilterTable/Badges/Badge"
import { ClearAllButton } from "v2/react/shared/tables/TableUtilities/FilterTable/Badges/ClearAllButton"
import { useFilterBadges } from "v2/react/shared/tables/TableUtilities/FilterTable/hooks/useFilterBadges"
import type { UtilityBadgesProps } from "v2/react/shared/tables/TableUtilities/UtilityBadges"

type BadgesProps = Pick<UtilityBadgesProps, "open" | "onUtilityToggle"> & {
  table: FilterableTables
}

const WithProvider = ({ open, onUtilityToggle, table }: BadgesProps) => {
  const { filtersToShow, removeFilterValue, clearAllFilters, anyErrors, dataLoaded } =
    useFilterBadges({
      table,
    })

  useNProgressBar({ finishCondition: dataLoaded, startCondition: true })

  const openUtilityPanel = () => {
    if (!open) {
      onUtilityToggle("filter-table")
    }
  }

  if (anyErrors || !filtersToShow.length) {
    return null
  }

  return (
    <div className="page-pad-x page-pad-t flex-wrap gap-2 flex">
      {filtersToShow.map((filter) => {
        const { label, filterKey, valueKey } = filter

        return (
          <Badge
            key={`${filterKey}-${valueKey}`}
            label={label}
            filterKey={filterKey}
            valueKey={valueKey}
            onRemove={removeFilterValue}
            onClick={openUtilityPanel}
          />
        )
      })}
      {filtersToShow.length > 1 && <ClearAllButton onClick={clearAllFilters} />}
    </div>
  )
}

/**
 * We portal this badge component to just above the table. Note that the
 * presence of an element with the id "filter-badges" is required.
 */
const Badges = ({ table, open, onUtilityToggle }: BadgesProps) => {
  const portalElement = document.getElementById("filter-badges")

  if (!portalElement) {
    return null
  }

  return (
    <RootProvider>
      {createPortal(
        <WithProvider table={table} open={open} onUtilityToggle={onUtilityToggle} />,
        portalElement,
      )}
    </RootProvider>
  )
}

export { Badges }
