import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import { StandardRank } from "types/graphql.enums"
import {
  useFieldLabelHelpers,
  useIsFieldUneditable,
  usePositionController,
} from "v2/react/components/positions/positionForm/hooks"
import { Dropdown } from "v2/react/shared/collection/menus/Dropdown/Dropdown"
import { InputErrorText } from "v2/react/shared/forms/InputErrorText"

type PositionImportanceInputProps = {
  wrapperClassName?: string
}

/** Based on app/views/v2/shared/inputs/_importance_input.html.slim */
function PositionImportanceInput({ wrapperClassName }: PositionImportanceInputProps) {
  const { t } = useTranslation()
  const { tLabel } = useFieldLabelHelpers()

  const isUneditable = useIsFieldUneditable("importance")
  const {
    field: { onChange: changeImportance, value: importance },
    fieldState: { error },
  } = usePositionController({ name: "position.importance", defaultValue: "none" })
  const hasErrorClass = error ? "form-error" : undefined

  return (
    <div className="input-with-dropdown-selector">
      <div className={cn("input-group dropdown-with-image", hasErrorClass, wrapperClassName)}>
        <label>{tLabel("position.importance")}</label>
        <Dropdown>
          <Dropdown.Trigger triggerClassName="w-full p-0 bg-transparent">
            <div className={cn("dropdown-link", isUneditable && "readonly")}>
              <div className="selection-icon items-center flex">
                <div className="icons">
                  <div className={cn("position-importance-icon", importance)}>
                    <FontAwesomeIcon icon={["fas", "exclamation"]} />
                  </div>
                </div>
                <div className="text px-2">{t(`v2.defaults.${importance}`)}</div>
              </div>
              <FontAwesomeIcon icon={["fas", "caret-down"]} />
            </div>
          </Dropdown.Trigger>
          <Dropdown.Menu>
            <PositionImportanceOption
              isSelected={importance === "none"}
              importance="none"
              onSelect={changeImportance}
            />
            <PositionImportanceOption
              isSelected={importance === StandardRank.Low}
              importance="low"
              onSelect={changeImportance}
            />
            <PositionImportanceOption
              isSelected={importance === StandardRank.Medium}
              importance="medium"
              onSelect={changeImportance}
            />
            <PositionImportanceOption
              isSelected={importance === StandardRank.High}
              importance="high"
              onSelect={changeImportance}
            />
          </Dropdown.Menu>
        </Dropdown>
        {error?.message && <InputErrorText message={error.message} />}
      </div>
    </div>
  )
}

type PositionImportanceOptionProps = {
  isSelected?: boolean
  importance: string
  onSelect?: (importance: string) => void
}

function PositionImportanceOption({
  importance,
  isSelected,
  onSelect,
}: PositionImportanceOptionProps) {
  const { t } = useTranslation()
  const handleClick = () => onSelect?.(importance)

  return (
    <Dropdown.Item className="dropdown-menu-link p-2 pr-4" onClick={handleClick}>
      <div className="selection-icon items-center flex">
        <div className={cn("position-importance-icon", importance)}>
          <FontAwesomeIcon icon={["fas", "exclamation"]} />
        </div>
        <div className="text px-2">{t(`v2.defaults.${importance}`)}</div>
        {isSelected && (
          <FontAwesomeIcon className="selected-indicator ml-auto" icon={["fas", "check-circle"]} />
        )}
      </div>
    </Dropdown.Item>
  )
}

export { PositionImportanceInput }
