import cn from "classnames"
import defaultAvatar from "images/avatar/fallback/default-avatar.png"
import React from "react"

import { Person } from "types/graphql"
import { IconSize } from "v2/react/shared/icons/BlueIcon"

interface Props {
  classNames?: string
  person: Person
  showDefaultForBrokenUrl?: boolean
  size?: IconSize
}

function Avatar({ classNames, person, showDefaultForBrokenUrl, size }: Props) {
  return (
    person.avatarThumbUrl && (
      <img
        className={cn(
          "circle m-0",
          {
            "h-4 w-4 text-[11px]": size === "xs", // 1rem
            "circle-sm": size === "sm", // 1.5rem
            "circle-med": size === "med", // 2rem
            "circle-med-lg": size === "med-lg", // 2.5rem
            "circle-lg": size === "lg", // 4.5rem
          },
          classNames,
        )}
        src={
          showDefaultForBrokenUrl
            ? checkImageValidity(person.avatarThumbUrl)
            : person.avatarThumbUrl
        }
        alt={person.name}
      />
    )
  )
}

export { Avatar }

export const checkImageValidity = (url: string) => {
  const image = new Image()
  image.src = url
  if (image.width === 0) {
    return defaultAvatar
  }
  return url
}
