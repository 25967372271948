import { useTranslation } from "react-i18next"

import { HeadcountPlanActiveStatus } from "types/graphql.d"
import { usePlanActiveContext } from "v2/react/components/headcountPlanning/ListPlans/PlanActiveStatusContext"
import { useUpdateHeadcountPlanActiveStatusMutation } from "v2/redux/GraphqlApi/HeadcountPlanningApi"

export const useChangeActiveStatus = () => {
  const { handleSetErrors, openModal } = usePlanActiveContext()
  const { t } = useTranslation()

  const [updateActiveStatus, { isLoading }] = useUpdateHeadcountPlanActiveStatusMutation()

  const changeActiveStatus = async (
    newStatus: HeadcountPlanActiveStatus | null,
    id: string,
    deactiveOverlappingPlans?: boolean,
  ) => {
    try {
      handleSetErrors([])
      const response = await updateActiveStatus({
        id,
        activeStatus: newStatus,
        deactivateOverlappingPlans: deactiveOverlappingPlans || false,
      }).unwrap()

      const errors = response.updateHeadcountPlanActiveStatus.errors
      const overlappedPlans = response.updateHeadcountPlanActiveStatus.overlappedPlans || []

      if (errors && errors.length && overlappedPlans.length === 0) {
        handleSetErrors(errors)
      }

      if (overlappedPlans && overlappedPlans.length > 0) {
        openModal(overlappedPlans, id)
      }

      return response
    } catch (error) {
      const errorMessage =
        error instanceof Error ? [error] : [{ message: t("v2.defaults.error"), path: ["general"] }]
      handleSetErrors(errorMessage)
      return null
    }
  }

  return {
    changeActiveStatus,
    isLoading,
  }
}
