import { ErrorObject } from "ajv"
import fp from "lodash/fp"
import React, { MouseEvent, useEffect, useMemo, useRef, useState } from "react"

import {
  Error,
  JsonForm,
  PositionForRequisitionQuery,
  SaveJobRequisitionInput,
} from "types/graphql"
import {
  ajvInstancePath,
  ajvSchemaPath,
  overriddenAJV,
} from "v2/react/components/jobRequisitions/utils"
import { Modal, ModalFooter } from "v2/react/shared/overlay/Modal"
import { useHeadcountPlansCreateJobRequisitionMutation } from "v2/redux/GraphqlApi/HeadcountPlanningApi"
import {
  JobRequisition,
  JobRequisitionsApi,
  useSaveJobRequisitionMutation,
} from "v2/redux/GraphqlApi/JobRequisitionsApi"
import { useGetPositionTypeQuery } from "v2/redux/GraphqlApi/PositionTypesApi"

import { ModalFormContent } from "./ModalFormContent"
import { FormShape } from "./types/FormShape"
import { GenericFormPositionShape, GenericFormShape } from "./types/GenericFormShape"
import { PosigenFormPositionShape } from "./types/PosigenFormShape"
import { initialValuesForSchema } from "./utils/initialValuesForSchema"
import { handleJobTitleFieldsDisabledState, loadJobTitleFormData } from "./utils/jobTitleHandling"
import { loadGenericBackfill, loadPosigenBackfill } from "./utils/loadBackfillFormData"
import { parsePositionFieldsForSave } from "./utils/parsePositionFieldsForSave"
import { reportsToError } from "./utils/reportsToError"
import { variablePayErrors } from "./utils/variablePayErrors"

interface Props {
  closeModal: () => void
  edit: boolean
  headcountPlanId?: string
  headcountPlanParticipantId?: string | undefined
  rootEventId?: string | null
  revisionNumber?: number
  initialData: FormShape
  isOpen?: boolean
  jobRequisition?: JobRequisition
  jsonForm: JsonForm
  modalOverlayRef: React.RefObject<HTMLDivElement>
  onDataChange?: (data: FormShape) => void
  scrollToTop: (topElement?: HTMLElement) => void
}

function ModalFormInner({
  closeModal,
  edit,
  headcountPlanId,
  headcountPlanParticipantId,
  rootEventId,
  revisionNumber,
  initialData,
  isOpen = true,
  jobRequisition,
  jsonForm,
  modalOverlayRef,
  onDataChange,
  scrollToTop,
}: Props) {
  const [submitting, setSubmitting] = useState(false)
  const [mutate, { isLoading: isLoadingDirectMutation }] = useSaveJobRequisitionMutation()
  const [mutateForHCP, { isLoading: isLoadingHCPMutation }] =
    useHeadcountPlansCreateJobRequisitionMutation()
  const isLoadingMutation = isLoadingDirectMutation || isLoadingHCPMutation
  const [additionalErrors, setAdditionalErrors] = useState<ErrorObject[]>([])

  const [formData, setFormData] = useState<FormShape>(initialData)

  const currentReqType = formData.reqType
  const currentBackfillPositionId = formData.backfillPosition?.id
  const hasCurrentBackfillPositionId = !!currentBackfillPositionId
  const currentJobTitleId = formData.position.jobTitle?.id
  const isJobTitleIdPresent = !!currentJobTitleId

  const hasInitialized = useRef(false)
  useEffect(() => {
    if (!hasInitialized.current) {
      hasInitialized.current = true
      return
    }

    onDataChange?.(formData)
  }, [formData, onDataChange])

  const [getBackfillPositionTrigger, backfillPositionData] =
    JobRequisitionsApi.endpoints.getPositionForRequisition.useLazyQuery()
  const backfillData: PositionForRequisitionQuery | undefined = backfillPositionData.data
  const { data: positionTypeData, isFetching: isFetchingPositionType } = useGetPositionTypeQuery(
    { id: currentJobTitleId },
    { skip: !isJobTitleIdPresent || currentReqType === "backfill" },
  )
  const positionType = positionTypeData?.positionType
  const [initialBackfillFetched, setInitialBackfillFetched] = useState(false)

  const allErrors: ErrorObject[] = [
    ...additionalErrors,
    ...reportsToError(formData, submitting),
    ...variablePayErrors(formData),
  ]

  // If there's no backfill position currently set, we want to ensure that we
  // don't use any currently populated backfillData.
  const currentBackfillData = useMemo(() => {
    if (currentReqType === "new_position" || !hasCurrentBackfillPositionId) return undefined
    return backfillData
  }, [backfillData, hasCurrentBackfillPositionId, currentReqType])

  const currentPositionType = useMemo(() => {
    if (!isJobTitleIdPresent || isFetchingPositionType) return null
    return positionType
  }, [positionType, isJobTitleIdPresent, isFetchingPositionType])

  // Here we ensure that the correct fields are disabled based on the
  // current selected title.
  const preparedJsonForm = useMemo(() => {
    if (currentReqType === "backfill" || isFetchingPositionType) return jsonForm

    const jsonFormClone = structuredClone(jsonForm)
    handleJobTitleFieldsDisabledState({
      jsonForm: jsonFormClone,
      positionType: currentPositionType,
    })
    return jsonFormClone
  }, [jsonForm, currentPositionType, isFetchingPositionType, currentReqType])

  useEffect(() => {
    if (!currentBackfillData?.position) return
    const isGenericForm = (form: FormShape): form is GenericFormShape =>
      preparedJsonForm.options.schema_type === "generic"

    const backfillPosition = currentBackfillData.position
    if (backfillPosition) {
      setFormData((prev: FormShape) => {
        if (isGenericForm(prev)) {
          // Note that here we clear any job title related fields, as they should
          // be overwritten by the backfill position's data or reset.
          return {
            ...prev,
            jobDescription: backfillPosition?.description || "",
            position: loadGenericBackfill(
              backfillPosition,
              initialValuesForSchema(preparedJsonForm).position as GenericFormPositionShape,
              prev.position as GenericFormPositionShape,
            ),
          }
        }
        return {
          ...prev,
          position: loadPosigenBackfill(
            backfillPosition,
            initialValuesForSchema(preparedJsonForm).position as PosigenFormPositionShape,
            prev.position as PosigenFormPositionShape,
          ),
        }
      })
    } else {
      setFormData((prev: FormShape) => {
        if (isGenericForm(prev)) {
          return {
            ...prev,
            position:
              prev?.position ||
              (initialValuesForSchema(preparedJsonForm).position as GenericFormPositionShape),
          }
        }
        return {
          ...prev,
          position: prev?.position || initialValuesForSchema(preparedJsonForm).position,
        }
      })
    }
  }, [currentBackfillData?.position, preparedJsonForm, setFormData])

  useEffect(() => {
    if (!currentPositionType || currentReqType === "backfill") return
    setFormData((currentFormData: FormShape) =>
      // Assumption: Each time a job title is selected, that data should reset
      // and overwrite the Job Title related form fields (E.g. job description,
      // flsa and eeoc classifications, job code, and possibly other fields in
      // the future).
      loadJobTitleFormData(currentFormData, currentPositionType),
    )
  }, [currentPositionType, currentReqType, setFormData])

  if (!preparedJsonForm) return null

  const [ajv, validate] = overriddenAJV(jsonForm.options.schema_type)

  const handleChange = ({ data }: { data: FormShape }) => {
    // Clear errors if changing req type
    if (submitting && data.reqType !== currentReqType) setSubmitting(false)

    // additionalErrors needs to be cleared here because the error for
    // systemUid comes from the backend; the only way to know if it's valid
    // again is to clear out the additionalErrors.
    if (submitting && formData.systemUid !== data.systemUid) {
      setSubmitting(false)
      setAdditionalErrors(() => [])
    }

    if (data.reqType !== currentReqType) {
      setAdditionalErrors(() => [])
    }

    // Reset form on backfill <-> new_position change
    if (data.reqType !== currentReqType && data.reqType === "new_position") {
      const resetValues: FormShape = initialValuesForSchema(preparedJsonForm)
      setFormData({
        ...resetValues,
        reqType: "new_position",
        backfillPosition: { filledBy: "", id: "", lastFilledBy: "", name: "" },
      })
    } else if (data.reqType !== currentReqType && data.reqType === "backfill") {
      const resetValues: FormShape = initialValuesForSchema(preparedJsonForm)
      setFormData({ ...resetValues, reqType: "backfill" })
    } else if (
      data.reqType === "backfill" &&
      (formHasChangedPosition(data, formData) || !initialBackfillFetched)
    ) {
      setInitialBackfillFetched(true)
      setFormData({ ...data, backfillPosition: data.backfillPosition })
      if (
        data.backfillPosition?.id &&
        data.backfillPosition?.id !== currentBackfillData?.position?.uniqueKey
      ) {
        getBackfillPositionTrigger({ positionId: data.backfillPosition.id })
      }
    } else {
      setFormData(data)
    }
  }

  const handleSave = async (event: MouseEvent) => {
    const parsedData = parsePositionFieldsForSave(formData)

    const updatedErrors: ErrorObject[] = [
      ...additionalErrors,
      ...reportsToError(formData, true),
      ...variablePayErrors(formData),
    ]

    const valid = validate(parsedData) && updatedErrors.length < 1

    event.preventDefault()
    setSubmitting(true)

    if (valid) {
      const input: SaveJobRequisitionInput = {
        jobRequisitionId: jobRequisition?.id,
        jsonFormValues: {
          data: parsedData,
          data_schema: preparedJsonForm.schema.data_schema,
          ui_schema: preparedJsonForm.schema.ui_schema,
          ui_table_schema: preparedJsonForm.schema.ui_table_schema,
        },
      }

      let promise
      if (headcountPlanId && revisionNumber !== undefined && rootEventId)
        promise = mutateForHCP({
          ...fp.omit(["jobRequisitionId"], input),
          headcountPlanId,
          headcountPlanParticipantId,
          rootEventId,
          revisionNumber,
        })
      else promise = mutate({ input })

      const result = await promise.unwrap()

      if (result.saveJobRequisition && result.saveJobRequisition.errors.length === 0) {
        closeModal()
        if (document.getElementById("job-requisitions-list-table")) {
          window.location.reload()
        }
      } else {
        const errors: Error[] = result.saveJobRequisition?.errors || []
        if (errors.length === 0) return
        // See: https://jsonforms.io/docs/validation/#external-validation-errors
        setAdditionalErrors(() => updatedErrorsForResponse(errors))
        scrollToError(scrollToTop, inputPathFromServerError(errors[0]))
      }
      setSubmitting(false)
    } else if (validate.errors && validate.errors.length) {
      scrollToError(scrollToTop, inputPathFromAjvError(validate.errors[0]))
    } else if (updatedErrors && updatedErrors.length) {
      scrollToError(scrollToTop, inputPathFromAjvError(updatedErrors[0]))
    }
  }

  return (
    <Modal
      footer={
        <ModalFooter
          disabled={isLoadingMutation}
          onClose={closeModal}
          onSave={handleSave}
          saveButtonText={submitButtonText(isLoadingMutation, edit)}
        />
      }
      isOpen={isOpen}
      onClose={closeModal}
      overlayRef={modalOverlayRef}
      size="md"
      title={
        edit ? "edit_requisition".t("job_requisition") : "create_requisition".t("job_requisition")
      }
    >
      <ModalFormContent
        // validations not from the backend server, but also not defined in the static
        // schema. Things not easily supported by the existing non-complex schema
        // rules/patterns.
        additionalErrors={allErrors}
        ajv={ajv}
        formData={formData}
        handleChange={handleChange}
        jsonForm={preparedJsonForm}
        validateFunc={validate}
        submitting={submitting}
      />
    </Modal>
  )
}

// If the input has a label, scroll to that, otherwise use the input as the
// anchor element.
const scrollToError = (scrollToTop: (topElement?: HTMLElement) => void, inputId: string) => {
  const inputElement = document.getElementById(inputId) as HTMLInputElement | null
  const labelElements = inputElement?.labels
  if (labelElements && labelElements.length) {
    scrollToTop(labelElements[0])
  } else if (inputElement) {
    scrollToTop(inputElement)
  } else {
    scrollToTop()
  }
}

const inputPathFromServerError = (error: Error): string => {
  const firstErrorPath = error.path && error.path.length ? error.path[0] : ""
  return ajvSchemaPath(firstErrorPath).replace("/errorMessage", "")
}

const inputPathFromAjvError = (error: ErrorObject): string => {
  const firstErrorPath = error.schemaPath
  return firstErrorPath.replace("/errorMessage", "")
}

const updatedErrorsForResponse = (errors: Error[]): ErrorObject[] =>
  errors.map((e: Error) => {
    const errorPath = e.path && e.path.length > 0 ? e.path[0] : ""

    return {
      instancePath: ajvInstancePath(errorPath),
      message: e.message,
      schemaPath: ajvSchemaPath(errorPath),
      keyword: "errorMessage",
      params: {},
    }
  })

const formHasChangedPosition = (newFormData: FormShape, priorFormData: FormShape): boolean =>
  newFormData.reqType === "backfill" &&
  newFormData.backfillPosition.id !== priorFormData.backfillPosition.id

const submitButtonText = (isLoading: boolean, editing: boolean): string => {
  if (isLoading) return "requisition_saving".t("job_requisition")
  if (editing) return "save_requisition".t("job_requisition")

  return "create_requisition".t("job_requisition")
}

const ModalForm = React.memo(ModalFormInner)

export { ModalForm }
