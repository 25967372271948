import { createColumnHelper } from "@tanstack/react-table"
import dayjs from "dayjs"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { PastDueAutoImportsRow } from "types/graphql"
import { useClientTable } from "v2/react/hooks/useClientTable"
import { ExportButton } from "v2/react/shared/buttons/ExportButton"
import { Table } from "v2/react/shared/tables/Table/Table"
import { usePastDueAutoImportsQuery } from "v2/redux/GraphqlApi/AdminApi"

import { useCsvDownloadListener } from "../../headcountPlanning/TableDatasheet/hooks/useCsvDownloadListener"
import RootProvider from "../../RootProvider"

function WithProvider() {
  const { t } = useTranslation()
  const [csvDownloadRef, setCsvDownloadRef] = useState<HTMLButtonElement | null>(null)

  const { data, isLoading } = usePastDueAutoImportsQuery({})

  const rows = data?.adminReports?.pastDueAutoImports?.rows || []
  const columnHelper = createColumnHelper<PastDueAutoImportsRow>()
  const columns = [
    columnHelper.accessor("id", {
      header: t("admin.reports.past_due_auto_imports.company_id"),
    }),
    columnHelper.accessor("name", {
      header: t("admin.reports.past_due_auto_imports.company_name"),
    }),
    columnHelper.accessor("subscription", {
      header: t("admin.reports.past_due_auto_imports.subscription"),
    }),
    columnHelper.accessor("importSource", {
      header: t("admin.reports.past_due_auto_imports.import_source"),
    }),
    columnHelper.accessor((row) => t(`admin.reports.past_due_auto_imports.${row.frequency}`), {
      id: "frequency",
      header: t("admin.reports.past_due_auto_imports.frequency"),
    }),
    columnHelper.accessor(
      (row) =>
        row.lastImport
          ? dayjs(row.lastImport).format("YYYY-MM-DD")
          : t("admin.reports.past_due_auto_imports.no_imports_yet"),
      {
        id: "lastImport",
        header: t("admin.reports.past_due_auto_imports.last_import"),
      },
    ),
    columnHelper.accessor("daysSinceLastImport", {
      header: t("admin.reports.past_due_auto_imports.days_since_last_import"),
    }),
  ]

  const table = useClientTable({
    columns,
    data: rows,
  })

  useCsvDownloadListener({ current: csvDownloadRef }, table, "past_due_auto_imports_report")

  return (
    <>
      <div className="mb-2 place-content-between items-end flex">
        <div>
          <h1>{t("admin.reports.past_due_auto_imports.title")}</h1>
          <p>{t("admin.reports.past_due_auto_imports.description")}</p>
        </div>
        <ExportButton
          setRef={(element) => setCsvDownloadRef(element)}
          disabled={rows?.length === 0}
        />
      </div>
      <Table table={table} isLoading={isLoading} />
    </>
  )
}

const PastDueAutoImports = () => (
  <RootProvider>
    <WithProvider />
  </RootProvider>
)

export default PastDueAutoImports
