import { retry } from "@reduxjs/toolkit/dist/query"
import fp from "lodash/fp"
import { z } from "zod"

import { Error as GqlError } from "types/graphql"
import { adminGqlFetch, baseGqlFetch, GqlResponseBody } from "v2/graphql_client"

import { BaseGqlQueryFn, GqlQueryError, GqlQueryMeta, GqlQueryOptions } from "./types"

export const configureExtra = <Data, Variables>(options: GqlQueryOptions<Data, Variables> = {}) =>
  options

/**
 * Makes a request, catching errors, and returning data/errors properly wrapped
 * for RTK query.
 *
 * Mutations may have errors deeply nested within an operation. If an endpoint
 * wants to treat that as an error, it can specify `extraOptions.matchErrors`
 * and return errors if present. See the GraphqlApi story.
 *
 * @public
 * @see https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#customizing-queries-with-basequery
 */
export const baseGqlQueryFn = retry<BaseGqlQueryFn>(
  async (arg, api, extraOptions = {}) => {
    const meta: GqlQueryMeta = {}

    try {
      const response = extraOptions.isAdmin ? await adminGqlFetch(arg) : await baseGqlFetch(arg)
      meta.response = response

      const result = response.data || {}
      let error: GqlQueryError | null | undefined = matchErrors(result)
      error ??= extraOptions.matchErrors?.(result, { arg, api, extraOptions })

      return error ? { error, meta } : { data: result.data, meta }
    } catch (error) {
      meta.originalError = error
      if (window.Sentry) window.Sentry.captureException(error)
      if (process.env.NODE_ENV === "development")
        // eslint-disable-next-line no-console
        console.warn(
          "A request failed with a status code >= 400",
          fp.pick(["response", "config", "request"], error),
        )

      const parseResult = axiosResponseSchema.safeParse(error)
      if (parseResult.success) {
        const { errors } = parseResult.data.response.data
        if (errors && errors.length > 0) return { error: errors, meta }
      }

      if ("t" in String.prototype) return { error: [{ message: "error".t("defaults") }], meta }
      return { error: [{ message: "An unknown error occurred. Please try again." }], meta }
    }
  },
  {
    retryCondition: (error, args, { attempt, baseQueryApi, extraOptions }) =>
      baseQueryApi.type !== "mutation" && attempt < (extraOptions?.maxRetries ?? 5),
  },
)

function matchErrors<Data>(result: GqlResponseBody<Data, GqlError[]>) {
  // We're ok if this throws since it's expected the error will be caught and
  // forwarded to `dopWrapThrownResult`.
  const { data, errors } = resultSchema.parse(result)
  if (data && errors && errors.length > 0) {
    if (process.env.NODE_ENV === "development")
      // eslint-disable-next-line no-console
      console.warn(
        "Response included data and errors; this is treated as a success " +
          "case, but may warrant a closer look",
        result,
      )

    return null
  }

  if (errors && errors.length > 0) return result.errors
  return null
}

const gqlErrorsSchema = z
  .object({
    __typename: z.literal("Error").optional(),
    message: z.string(),
    details: z.any().optional().nullable(),
    location: z
      .array(z.union([z.string(), z.number()]))
      .optional()
      .nullable()
      .transform((arr) => arr?.map(String) ?? null),
    path: z
      .array(z.union([z.string(), z.number()]))
      .optional()
      .nullable()
      .transform((arr) => arr?.map(String) ?? null),
  })
  .passthrough()
const withDataSchema = z
  .object({
    data: z.any(),
    errors: z.array(gqlErrorsSchema).optional(),
  })
  .passthrough()
const withErrorsSchema = z
  .object({
    data: z.any().optional(),
    errors: z.array(gqlErrorsSchema),
  })
  .passthrough()
const resultSchema = z.union([withDataSchema, withErrorsSchema])
const axiosResponseSchema = z
  .object({
    response: z.object({ data: resultSchema }).passthrough(),
  })
  .passthrough()
