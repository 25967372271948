import { isEmpty } from "lodash"
import qs from "qs"
import { z } from "zod"

import { FilterableTables, FilterDataType, FilterType } from "types/graphql.enums"

const RangeValueSchema = z
  .object({
    min: z.coerce.number().optional(),
    max: z.coerce.number().optional(),
  })
  .refine((data) => data.min !== undefined || data.max !== undefined, {
    message: "At least one of 'min' or 'max' must be provided",
  })
  .refine(
    (data) => data.min === undefined || data.max === undefined || data.min <= data.max,
    "'min' must be less than or equal to 'max'",
  )

const DateRangeValueSchema = z
  .object({
    min: z.string().date().optional(),
    max: z.string().date().optional(),
  })
  .refine(
    (data) => data.min !== undefined || data.max !== undefined,
    "At least one of 'min' or 'max' must be provided",
  )
  .refine(
    (data) =>
      data.min === undefined || data.max === undefined || new Date(data.min) <= new Date(data.max),
    "'min' must be less than or equal to 'max'",
  )

const RangeFilterSchema = z.object({
  type: z.literal(FilterType.Range),
  field: z.string(),
  value: RangeValueSchema,
})

const DateRangeFilterSchema = z.object({
  type: z.literal(FilterType.DateRange),
  field: z.string(),
  value: DateRangeValueSchema,
})

const InclusionFilterSchema = z.object({
  type: z.literal(FilterType.Inclusion),
  field: z.string(),
  value: z.object({
    in: z.array(z.string()),
  }),
})

const FilterSchema = z.discriminatedUnion("type", [
  RangeFilterSchema,
  DateRangeFilterSchema,
  InclusionFilterSchema,
])

type RangeValue = z.infer<typeof RangeValueSchema>
type DateRangeValue = z.infer<typeof DateRangeValueSchema>
type RangeFilter = z.infer<typeof RangeFilterSchema>
type DateRangeFilter = z.infer<typeof DateRangeFilterSchema>
type InclusionFilter = z.infer<typeof InclusionFilterSchema>
type Filter = z.infer<typeof FilterSchema>

const FilterableTablesSchema = z.nativeEnum(FilterableTables)
const isFilterableTable = (table: unknown): table is FilterableTables =>
  FilterableTablesSchema.safeParse(table).success

/**
 * @see FieldUsage::Filtering
 */
const COLLECTION_BASED_DATA_TYPES = [FilterDataType.Collection, FilterDataType.Binary] as const
const CollectionBasedFilterDataType = z.enum(COLLECTION_BASED_DATA_TYPES)

const isCollectionBasedFilter = (
  dataType: FilterDataType,
): dataType is z.infer<typeof CollectionBasedFilterDataType> =>
  CollectionBasedFilterDataType.safeParse(dataType).success

/**
 * @see FieldUsage::Filtering
 */
const NUMERIC_BASED_DATA_TYPES = [
  FilterDataType.Numeric,
  FilterDataType.Percentage,
  FilterDataType.Currency,
] as const
const NumericBasedFilterDataType = z.enum(NUMERIC_BASED_DATA_TYPES)

const isNumericBasedFilter = (
  dataType: FilterDataType,
): dataType is z.infer<typeof NumericBasedFilterDataType> =>
  NumericBasedFilterDataType.safeParse(dataType).success

const isDateBasedFilter = (dataType: FilterDataType): boolean => dataType === FilterDataType.Date

const mapDataTypeToFilterType = (dataType: FilterDataType) => {
  if (isNumericBasedFilter(dataType)) {
    return FilterType.Range
  }
  if (isDateBasedFilter(dataType)) {
    return FilterType.DateRange
  }
  return FilterType.Inclusion
}

interface GetEmptyFilterProps {
  field: string
  dataType?: FilterDataType
  filterType?: FilterType
}

const getEmptyFilter = ({ field, dataType, filterType }: GetEmptyFilterProps): Filter => {
  const type = filterType ?? (dataType && mapDataTypeToFilterType(dataType)) ?? FilterType.Inclusion

  if (type === FilterType.Inclusion) {
    return { type, field, value: { in: [] } }
  }
  return { type, field, value: { min: undefined, max: undefined } }
}

const filterIsEmpty = (filter: Filter): boolean => {
  if (filter.type === FilterType.Inclusion) {
    return filter.value.in.length === 0
  }
  if (filter.type === FilterType.Range) {
    return filter.value.min === undefined && filter.value.max === undefined
  }
  if (filter.type === FilterType.DateRange) {
    return filter.value.min === undefined && filter.value.max === undefined
  }
  return false
}

const getInclusionFilterValue = (filter?: Filter) => {
  if (!filter || filter.type !== FilterType.Inclusion) {
    return []
  }

  return filter.value.in
}

const getRangedFilterValue = (filter?: Filter) => {
  if (!filter || filter.type !== FilterType.Range) {
    return { min: undefined, max: undefined }
  }

  return filter.value
}

const getDateRangedFilterValue = (filter?: Filter) => {
  if (!filter || filter.type !== FilterType.DateRange) {
    return { min: undefined, max: undefined }
  }

  return filter.value
}

interface ValidateFiltersProps {
  filters: Filter[]
  /**
   * If true, replaces invalid filters with empty filters. If false, invalid
   * filters are excluded completely from the results.
   */
  replaceInvalidWithEmpty?: boolean
}

const validateFilters = ({ filters, replaceInvalidWithEmpty }: ValidateFiltersProps) =>
  filters
    .map((filter) => {
      if (FilterSchema.safeParse(filter).success) {
        return filter
      }

      if (replaceInvalidWithEmpty) {
        return getEmptyFilter({ field: filter.field, filterType: filter.type })
      }

      return null
    })
    .filter(Boolean) as Filter[]

/**
 * Reads the query params from the URL and extracts any filters that are
 * present. Filters are validated using `Zod` and any invalid filters are
 * silently ignored.
 *
 * An example of a valid filter in the URL would be:
 *
 * ```
 *  ?filters[0][type]=range&filters[0][field]=base_pay&filters[0][value][min]=34000&filters[0][value][max]=50000&filters[1][type]=inclusion&filters[1][field]=location&filters[1][value][in][0]=location_12
 * ```
 *
 * This would be parsed into the following filters:
 * ```
 * [
 *  {
 *    type: "range",
 *    field: "base_pay",
 *    value: { min: 34000, max: 50000 }
 *  },
 *  {
 *    type: "inclusion",
 *    field: "location",
 *    value: { in: ["location_12"] }
 *  }
 * ]
 * ```
 * @param only - An optional array of filter names to restrict the extraction to
 * @returns An array of filters extracted from the URL
 */
const extractFiltersFromQueryParams = ({ only }: { only?: string[] }): Filter[] => {
  const filters = qs.parse(window.location.search, { ignoreQueryPrefix: true })?.filters

  if (!Array.isArray(filters)) {
    return []
  }

  return filters
    .map((filter) => {
      const parsedFilter = FilterSchema.safeParse(filter)

      if (parsedFilter.success) {
        return parsedFilter.data
      }
      return null
    })
    .filter((filter): filter is Filter => filter !== null && (!only || only.includes(filter.field)))
}

/**
 * Appends the provided filters to the query params in the URL.  This will
 * preserve any existing query params in the URL, and replace any existing
 * filters with the ones provided.
 *
 * @param filters Filters to append to the URL
 */
const appendFiltersToQueryParams = ({ filters }: { filters: Filter[] }): void => {
  const currentParams = qs.parse(window.location.search.substring(1))
  const updatedParams = {
    ...currentParams,
    filters,
    // Ensure that we always reset the page to 1 when filters are applied.
    page: 1,
  }
  const newQueryParams = qs.stringify(updatedParams, {
    arrayFormat: "indices",
    encode: false,
  })

  let newUrl = window.location.pathname
  if (!isEmpty(newQueryParams)) {
    newUrl += `?${newQueryParams}`
  }

  window.history.replaceState({}, "", newUrl)
}

/**
 * Returns any applied inclusion filters that are present in the url params.
 */
const extractAppliedInclusionFiltersFromParams = () =>
  extractFiltersFromQueryParams({})?.filter(
    (filter): filter is InclusionFilter => filter.type === FilterType.Inclusion,
  )

interface PrepareFiltersForSubmissionProps {
  filters: Filter[]
  excludeEmpty: boolean
}

const prepareFiltersForSubmission = ({
  filters,
  excludeEmpty,
}: PrepareFiltersForSubmissionProps): Filter[] => {
  const validatedFilters = validateFilters({ filters })
  return excludeEmpty
    ? validatedFilters.filter((filter) => !filterIsEmpty(filter))
    : validatedFilters
}

export type { Filter, RangeFilter, DateRangeFilter, InclusionFilter, RangeValue, DateRangeValue }
export {
  appendFiltersToQueryParams,
  extractFiltersFromQueryParams,
  filterIsEmpty,
  getDateRangedFilterValue,
  getEmptyFilter,
  getInclusionFilterValue,
  getRangedFilterValue,
  isCollectionBasedFilter,
  isFilterableTable,
  isNumericBasedFilter,
  prepareFiltersForSubmission,
  validateFilters,
  extractAppliedInclusionFiltersFromParams,
}
