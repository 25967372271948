import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React from "react"

export type IconSize = "xs" | "sm" | "med" | "med-lg" | "lg"

interface Props {
  classNames?: string
  icon: IconProp
  size?: IconSize
}

function BlueIcon({ classNames, icon, size }: Props) {
  return (
    <div
      className={cn(
        "shrink-0 grow-0 items-center justify-center rounded-full bg-primary-8-solid text-primary-100 flex",
        {
          "h-4 w-4 text-[11px]": size === "xs", // 1rem
          "h-6 w-6 text-[11px]": size === "sm", // 1.5rem
          "h-8 w-8 text-[14px]": size === "med", // 2rem
          "h-10 w-10 text-[14px]": size === "med-lg", // 2.5rem
          "h-18 w-18 text-[24px]": size === "lg", // 4.5rem
        },
        classNames,
      )}
    >
      <FontAwesomeIcon icon={icon} />
    </div>
  )
}

export { BlueIcon }
