import { motion } from "framer-motion"
import React from "react"
import { useTranslation } from "react-i18next"

import { LoadingIndicator } from "v2/react/shared/loaders/LoadingIndicator"
import { ErrorFallback } from "v2/react/shared/overlay/Modal/ErrorFallback"

interface LoadingWrapperProps {
  isLoading: boolean
  isError: boolean
  animationDuration?: number
  children: React.ReactNode
}

const LoadingWrapper = ({
  isLoading,
  isError,
  animationDuration = 250,
  children,
}: LoadingWrapperProps) => {
  const { t } = useTranslation()

  return (
    <LoadingIndicator isLoading={isLoading} spinnerClass="relative" wrapperClass="h-screen">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: animationDuration / 1000.0 }}
      >
        <ErrorFallback isError={isError} errorMessage={t("v2.defaults.error")}>
          {children}
        </ErrorFallback>
      </motion.div>
    </LoadingIndicator>
  )
}

export { LoadingWrapper }
