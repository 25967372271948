import { isEqual } from "lodash"
import React, { FormEvent } from "react"
import { useTranslation } from "react-i18next"

import { FilterableTables } from "types/graphql.enums"
import { Sidebar } from "v2/react/shared/layout/Sidebar"
import { useFilters } from "v2/react/shared/tables/TableUtilities/FilterTable/hooks/useFilters"
import { PanelContent } from "v2/react/shared/tables/TableUtilities/FilterTable/PanelContent"
import { type PanelProps as UtilityPanelProps } from "v2/react/shared/tables/TableUtilities/UtilityPanel"
import { selectFiltersAndAppliedFilters } from "v2/redux/slices/TableFilterSlice/tableFiltersSelectors"
import { applyFilters } from "v2/redux/slices/TableFiltersSlice"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

interface PanelProps {
  table: FilterableTables
  onUtilityToggle: UtilityPanelProps["onUtilityToggle"]
}

const Panel = ({ table, onUtilityToggle }: PanelProps) => {
  const { t } = useTranslation()
  const { dataLoaded, anyErrors, updateFilters } = useFilters({ table })

  const dispatch = useAppDispatch()
  const { filters, appliedFilters } = useAppSelector(selectFiltersAndAppliedFilters)

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const selectedFilters = filters.map((filter) => filter.field).sort()
    const initialSelectedFilters = appliedFilters.map((filter) => filter.field).sort()

    // In the case that the selected filters haven't changed, we can just
    // proceed with the pjax submit and url updates.
    if (isEqual(selectedFilters, initialSelectedFilters)) {
      dispatch(applyFilters({ filters, table }))
      return
    }

    updateFilters({ table, selectedFilterKeys: filters.map((filter) => filter.field) })
      .unwrap()
      .then(() => dispatch(applyFilters({ filters, table })))
  }

  return (
    <Sidebar.Content
      title={t("v2.defaults.filters")}
      isError={anyErrors}
      isLoading={!dataLoaded}
      onClose={() => onUtilityToggle(null)}
    >
      <PanelContent onSubmit={handleSubmit} />
    </Sidebar.Content>
  )
}

export { Panel }
