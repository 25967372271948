import React from "react"
import { useTranslation } from "react-i18next"

import type { UpdatePersonPositionReportSettingsInputObject } from "types/graphql"
import { PositionReportsWithSettings } from "types/graphql.enums"
import { Sidebar } from "v2/react/shared/layout/Sidebar"
import { PanelContent } from "v2/react/shared/tables/TableUtilities/EditPositionReportSettings/PanelContent"
import {
  getPjaxContainerForTable,
  type PanelProps as UtilityPanelProps,
} from "v2/react/shared/tables/TableUtilities/UtilityPanel"
import { pjaxReload } from "v2/react/utils/pjax"
import {
  useGetPositionReportTableSettingsQuery,
  useUpdatePersonPositionReportSettingsMutation,
} from "v2/redux/GraphqlApi/PositionReportsApi"

const NON_FIELD_KEYS = ["count_open_positions", "count_dotted_relationships"]

interface PanelProps {
  table: PositionReportsWithSettings
  onUtilityToggle: UtilityPanelProps["onUtilityToggle"]
}

const Panel = ({ table, onUtilityToggle }: PanelProps) => {
  const { t } = useTranslation()
  const { data, isFetching, isError } = useGetPositionReportTableSettingsQuery({
    table,
  })
  const [mutate, mutationState] = useUpdatePersonPositionReportSettingsMutation()

  const allColumns = data?.currentPerson?.settings?.positionTableSettings?.allColumns
  const allColumnsSet = new Set(allColumns?.map((column) => column.id))
  const tableSettings = data?.currentPerson?.settings?.positionTableSettings?.tableSettings
  const filteredSelectedColumns = tableSettings?.selectedColumns?.filter((key) =>
    allColumnsSet.has(key),
  )

  const dataLoaded =
    !isFetching &&
    !!tableSettings &&
    !!filteredSelectedColumns &&
    !!allColumns &&
    !mutationState.isLoading
  const anyErrors = isError || mutationState.isError

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()

    const form = e.target as HTMLFormElement
    const formData = new FormData(form)
    const formKeys = Array.from(formData.keys())

    const selectedColumns = formKeys.filter((key) => !NON_FIELD_KEYS.includes(key))
    const params: UpdatePersonPositionReportSettingsInputObject = {
      table,
      selectedColumns,
      countDottedRelationships: formKeys.includes("count_dotted_relationships"),
      countOpenPositions: formKeys.includes("count_open_positions"),
    }

    if (formKeys.includes("include_scheduled_terminations")) {
      params.includeScheduledTerminations = true
    }

    mutate({ params })
      .unwrap()
      .then(() => {
        const container = getPjaxContainerForTable(table)
        if (!container) return

        pjaxReload({
          container,
          fragment: container,
        })
      })
  }

  return (
    <Sidebar.Content
      title={t("v2.positions.index.edit_settings")}
      isError={anyErrors}
      isLoading={!dataLoaded}
      onClose={() => onUtilityToggle(null)}
    >
      {dataLoaded && (
        <PanelContent
          table={table}
          allFields={allColumns}
          initialTableSettings={{ ...tableSettings, selectedColumns: filteredSelectedColumns }}
          onSubmit={handleSubmit}
        />
      )}
    </Sidebar.Content>
  )
}

export { Panel }
