/* eslint-disable react/jsx-props-no-spreading */
import classNames from "classnames"
import React, { MutableRefObject } from "react"

import { CompareValues } from "./CompareValues"
import { DropdownMenu } from "./DropdownMenu"
import { useAutocompleteList } from "./hooks/useAutocompleteList"
import { useCellHandlers } from "./hooks/useCellHandlers"
import { useCellState } from "./hooks/useCellState"
import { useOptimisticValue } from "./hooks/useOptimisticValue"
import { StyleLayers } from "./StyleLayers"
import { FieldType, SaveFn } from "./types"

type Props<T> = {
  searchTerm: string
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>
  renderOption: (option: T, isActive: boolean) => React.ReactNode
  options: Array<T>
  currentValue: T
  compareValue?: T | null
  saveFn: SaveFn<T>
  onSaved?: () => void
  onErrored?: () => void
  onEditing?: () => void
  rowId: string
  columnId: string
  createOption: (option: { id: string; label: string }) => T
  editable?: boolean
}
export function SuggestedAutocompleteCell<T extends { id: string; label: string }>({
  searchTerm,
  setSearchTerm,
  renderOption,
  options,
  saveFn,
  onSaved,
  onErrored,
  onEditing,
  currentValue,
  compareValue,
  rowId,
  columnId,
  createOption,
  editable,
}: Props<T>) {
  const [optimisticValue, setOptimisticValue] = useOptimisticValue(currentValue?.label ?? "")

  const cell = useCellState<T>({
    currentValue,
    fieldType: FieldType.SuggestedAutocomplete,
    rowId,
    columnId,
    editable,
    hasNotChanged: (value) => value?.label === currentValue?.label,
    saveFn: (state) => saveFn(state.value),
    onEditing: () => {
      document.getElementById(`${columnId}-${rowId}-input`)?.focus()
      onEditing?.()
    },
    onSaved: (state) => {
      setSearchTerm(state.value?.label ?? "")
      if (state.value) {
        setOptimisticValue(state.value.label)
      }
      onSaved?.()
    },
    onErrored: () => onErrored?.(),
  })

  const autocompleteList = useAutocompleteList({ showList: cell.isEditing })

  const cellRef = autocompleteList.refs.reference as MutableRefObject<HTMLDivElement>

  const { handleCellClick, handleCellKeyUp } = useCellHandlers(cell, cellRef, {
    getSaveValue: () =>
      autocompleteList.activeIndex !== null
        ? options[autocompleteList.activeIndex]
        : createOption?.({ id: "custom", label: searchTerm }),
    moveDown: () =>
      autocompleteList.setActiveIndex((prev) =>
        prev === null ? 0 : Math.min(options.length - 1, prev + 1),
      ),
    moveUp: () =>
      autocompleteList.setActiveIndex((prev) => (prev === null ? 0 : Math.max(0, prev - 1))),
  })

  const hideCompareValue =
    cell.isEditing ||
    cell.isSaving ||
    cell.isSaved ||
    cell.isErrored ||
    cell.isErroredEditing ||
    cell.isErroredSelected ||
    cell.isErroredSaving
  const showSearchTerm =
    cell.isEditing || cell.isErroredSelected || cell.isErroredEditing || cell.isErrored
  const showDropdownMenu = (cell.isEditing || cell.isErroredEditing) && options.length > 0
  const disableInput = !cell.isEditing && !cell.isErroredEditing
  const showStateValue = cell.isSaving || cell.isSaved || cell.isErrored || cell.isErroredSaving

  // eslint-disable-next-line no-nested-ternary
  const inputValue = showSearchTerm
    ? searchTerm
    : showStateValue
    ? cell.value?.label
    : optimisticValue ?? currentValue?.label ?? ""

  const errored =
    cell.isErrored || cell.isErroredEditing || cell.isErroredSaving || cell.isErroredSelected

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      id={`${columnId}-${rowId}`}
      className={classNames(`editable-cell position-${columnId}-input`, {
        "editable-cell--errored": errored,
      })}
      onClick={handleCellClick}
      onKeyUp={handleCellKeyUp}
    >
      <CompareValues compareValue={hideCompareValue ? null : compareValue?.label}>
        <input
          id={`${columnId}-${rowId}-input`}
          ref={autocompleteList.refs.setReference}
          {...autocompleteList.getReferenceProps()}
          className="bg-white"
          value={inputValue}
          onChange={(event) => setSearchTerm(event.target.value)}
          disabled={disableInput}
          name={columnId}
        />
      </CompareValues>
      <DropdownMenu
        showList={showDropdownMenu}
        floatingRef={autocompleteList.refs.setFloating}
        floatingStyles={{
          ...autocompleteList.floatingStyles,
          zIndex: 5,
          marginLeft: "-1rem",
        }}
        floatingProps={autocompleteList.getFloatingProps}
        wrapperClasses="SelectField"
        context={autocompleteList.context}
      >
        {options.map((option, index) => (
          <li
            role="option"
            className={textClassName(autocompleteList.activeIndex, index)}
            aria-selected={autocompleteList.activeIndex === index}
            key={option.id}
            ref={(node) => {
              autocompleteList.listRef.current[index] = node
            }}
            {...autocompleteList.getItemProps({
              onClick: () => {
                cell.dispatch({ type: "save", value: option })
              },
            })}
          >
            {renderOption(option, autocompleteList.activeIndex === index)}
          </li>
        ))}
      </DropdownMenu>
      <StyleLayers cell={cell} fieldType={FieldType.SuggestedAutocomplete} />
    </div>
  )
}

const textClassName = (activeIndex: number | null, index: number) =>
  classNames("TableAutocompleteField__result", { highlight: activeIndex === index })
