import React from "react"

import { useRequisitionDataForDiffValues } from "v2/react/components/headcountPlanning/hooks/useRequisitionDataForDiffValues"
import { useWatchKeysFromAllowedAttributesForPositionDiff } from "v2/react/components/headcountPlanning/hooks/useWatchKeysFromAllowedAttributesForPositionDiff"
import { usePositionControlOptions } from "v2/react/components/jobRequisitions/RequisitionForm/hooks/usePositionControlOptions"
import { RequisitionModal } from "v2/react/components/jobRequisitions/RequisitionModal"
import { PositionFieldValuesDiffProvider } from "v2/react/components/positions/positionFieldValuesDiff"
import { JsonFormControlsOptions } from "v2/react/shared/jsonForms/JsonFormControlsOptions"

import { useJobRequisitionOverview } from "./OverviewProvider"

function OverviewEditRequisitionModal() {
  const {
    collections,
    fromHeadcountPlan,
    fromHeadcountPlanChangeProjection,
    fromHeadcountPlanJobRequisitionData,
    fromHeadcountPlanSourceParticipantDatasheet,
    jobRequisition,
  } = useJobRequisitionOverview()

  const hcpFieldValues = fromHeadcountPlanJobRequisitionData?.jsonFormValues?.data
  const formValues = jobRequisition?.jsonFormValues?.data

  const { currentFieldValues, initialFieldValues, updateCurrentFieldValues } =
    useRequisitionDataForDiffValues({
      initial: hcpFieldValues,
      initializeCurrentWith: formValues,
      projectionAttributes: fromHeadcountPlanChangeProjection?.positionAttributesWithEdits,
    })

  const watchFieldNames = useWatchKeysFromAllowedAttributesForPositionDiff({
    allowedAttributes: fromHeadcountPlan?.allowedAttributes,
  })

  const positionControlOptions = usePositionControlOptions({
    fromHeadcountPlan: Boolean(fromHeadcountPlan),
    headcountPlanRootPositionIdForDatasheet:
      fromHeadcountPlanSourceParticipantDatasheet?.position?.id,
  })

  if (!jobRequisition) return null

  return (
    <JsonFormControlsOptions positionControl={positionControlOptions}>
      <PositionFieldValuesDiffProvider
        basis={initialFieldValues}
        current={currentFieldValues}
        positionFormCollections={collections}
        watchFieldNames={watchFieldNames}
      >
        <RequisitionModal
          data={formValues}
          uiSchema={jobRequisition.jsonFormValues.ui_schema}
          dataSchema={jobRequisition.jsonFormValues.data_schema}
          uiTableSchema={jobRequisition.jsonFormValues.ui_table_schema}
          edit
          jobRequisition={jobRequisition}
          onDataChange={updateCurrentFieldValues}
        />
      </PositionFieldValuesDiffProvider>
    </JsonFormControlsOptions>
  )
}

export { OverviewEditRequisitionModal }
