/* FontAwesome SVG + JS subsetting to reduce load

   Make sure you add the icon to the appropriate import list AND
   to the library list

*/

import { config, dom, library } from "@fortawesome/fontawesome-svg-core"
import {
  faBehance,
  faBloggerB,
  faDribbble,
  faDropbox,
  faFacebookF,
  faGithub,
  faHubspot,
  faInstagram,
  faLinkedinIn,
  faMediumM,
  faPinterest,
  faSnapchatGhost,
  faTwitter,
  faVimeoV,
  faWordpressSimple,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons"
import {
  faCheckCircle as faCheckCircleDuo,
  faExclamationCircle as faExclamationCircleDuo,
  faExclamationTriangle as faExclamationTriangleDuo,
  faInfoCircle as faInfoCircleDuo,
  faMinusCircle as faMinusCircleDuo,
  faTimesCircle as faTimesDuo,
} from "@fortawesome/pro-duotone-svg-icons"
import {
  faClone as faCloneLight,
  faDownload as faDownloadLight,
  faEllipsisH,
  faStar as faStarLight,
  faTimes as faTimesLight,
  faUserPlus as faUserPlusLight,
} from "@fortawesome/pro-light-svg-icons"
import {
  faAnalytics as faAnalytics2,
  faAngleLeft,
  faArchive,
  faArrowAltRight,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faBan,
  faBars,
  faBriefcase,
  faBuilding,
  faCalendar,
  faCalendarAlt,
  faChairOffice,
  faChartBar,
  faChartPie,
  faCheck,
  faCheckCircle as faCheckCircleRegular,
  faCheckSquare,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClipboardCheck,
  faClock,
  faClone,
  faCodeMerge,
  faCog,
  faComment,
  faCommentAltLines,
  faComments,
  faCompass,
  faCompressAlt,
  faDesktop,
  faDollarSign,
  faDownload,
  faEdit,
  faEllipsisH as faEllipsisHRegular,
  faEllipsisV,
  faEnvelope,
  faEuroSign,
  faExchange,
  faExchangeAlt,
  faExclamationCircle as faExclamationCircleRegular,
  faExclamationTriangle as faExclamationTriangleRegular,
  faExpandAlt,
  faEye,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileCode,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFillDrip,
  faFilter,
  faFlag,
  faGripVertical,
  faHashtag,
  faHeart,
  faHistory,
  faHome,
  faICursor,
  faIdBadge,
  faInfoCircle as faInfoCircleRegular,
  faKey,
  faLayerGroup,
  faListUl,
  faLockAlt,
  faLockOpenAlt,
  faLongArrowAltRight,
  faMinus,
  faMoneyBill,
  faPalette,
  faPaperclip,
  faPaperPlane,
  faPen,
  faPencil,
  faPencilRuler,
  faPercent,
  faPhone,
  faPlus,
  faPlusSquare,
  faPoundSign,
  faPrint,
  faRedoAlt,
  faSearch,
  faSearchMinus,
  faSearchPlus,
  faShareSquare,
  faSignature,
  faSignIn,
  faSignOut,
  faSitemap,
  faSlidersH,
  faSparkles,
  faStickyNote,
  faStopwatch,
  faSyncAlt,
  faTimes,
  faTrashAlt,
  faUndo,
  faUndoAlt,
  faUpload,
  faUsdSquare,
  faUser,
  faUserPlus,
  faUsers,
  faYenSign,
} from "@fortawesome/pro-regular-svg-icons"
import {
  faAnalytics,
  faArrowAltRight as faArrowAltRightSolid,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCheckCircle,
  faComment as faCommentSolid,
  faEraser,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faEye as faEyeSolid,
  faInfoCircle,
  faLink,
  faLowVision,
  faMinusCircle,
  faPhone as faPhoneSolid,
  faCheck as fasCheck,
  faGripVertical as fasGripVertical,
  faSort,
  faSortDown,
  faSortUp,
  faStar,
  faTerminal,
  faTimesCircle,
  faTimes as faTimesSolid,
} from "@fortawesome/pro-solid-svg-icons"
// Custom icons
import {
  facBrazilianRealSign,
  facChartSections,
  facChfSign,
  facCodeFork,
  facDanishKroneSign,
  facFolderTree,
  facHongKongDollarSign,
  facIndianRupeeSign,
  facKrSign,
  facLadder,
  facListOl,
  facPhilippinePesoSign,
  facRectangleHistoryCirclePlus,
  facRomanianLeuSign,
  facShortText,
  facSidebarFlip,
  facSingaporeDollarSign,
  facSouthAfricanRandSign,
  facSquareBrazilianRealSign,
  facSquareChfSign,
  facSquareDanishKroneSign,
  facSquareDollarSign,
  facSquareEuroSign,
  facSquareHongKongDollarSign,
  facSquareIndianRupeeSign,
  facSquareKrSign,
  facSquarePhilippinePesoSign,
  facSquarePoundSign,
  facSquareRomanianLeuSign,
  facSquareSingaporeDollarSign,
  facSquareSouthAfricanRandSign,
  facSquareThaiBahtSign,
  facSquareTurkishLiraSign,
  facSquareUaeDirhamSign,
  facSquareUaeDirhamTextSign,
  facSquareYenSign,
  facThaiBahtSign,
  facTurkishLiraSign,
  facUaeDirhamSign,
  facUaeDirhamTextSign,
} from "icons"

// These icons comes from FontAwesome 6.4.2
// We should update the code to pull from the library once updated
const facArrowUpRightFromSquare = {
  prefix: "fac",
  iconName: "arrow-up-right",
  icon: [
    20,
    20,
    [],
    null,
    "M11.4909 3.57873C11.4909 3.99198 11.8234 4.32445 12.2367 4.32445H15.4091L8.47699 " +
      "11.2534C8.18492 11.5455 8.18492 12.0178 8.47699 12.3067C8.76906 12.5957 9.24135 " +
      "12.5988 9.53032 12.3067L16.4593 5.37777V8.55019C16.4593 8.96344 16.7918 9.2959 " +
      "17.205 9.2959C17.6183 9.2959 17.9507 8.96344 17.9507 8.55019V3.57873C17.9507 3.16547 " +
      "17.6183 2.83301 17.205 2.83301H12.2367C11.8234 2.83301 11.4909 3.16547 11.4909 " +
      "3.57873ZM4.28232 3.8273C3.04567 3.8273 2.04517 4.82781 2.04517 6.06446V16.5045C2.04517 " +
      "17.7412 3.04567 18.7417 4.28232 18.7417H14.7224C15.959 18.7417 16.9595 17.7412 16.9595 " +
      "16.5045V12.5274C16.9595 12.1141 16.6271 11.7816 16.2138 11.7816C15.8006 11.7816 15.4681 " +
      "12.1141 15.4681 12.5274V16.5045C15.4681 16.9178 15.1356 17.2502 14.7224 17.2502H4.28232C3.86907 " +
      "17.2502 3.5366 16.9178 3.5366 16.5045V6.06446C3.5366 5.6512 3.86907 5.31874 4.28232 " +
      "5.31874H8.25949C8.67274 5.31874 9.00521 4.98627 9.00521 4.57302C9.00521 4.15977 8.67274 " +
      "3.8273 8.25949 3.8273H4.28232Z",
  ],
}

const facArrowLeftRight = {
  prefix: "fac",
  iconName: "arrow-left-right",
  icon: [
    20,
    20,
    [],
    null,
    "M3.69131 14.0897C3.94834 14.3468 4.36396 14.3468 4.61826 14.0897C4.87256 " +
      "13.8327 4.87529 13.4171 4.61826 13.1628L3.11436 11.6589L12.4687 11.6562C12.8323 " +
      "11.6562 13.1249 11.3636 13.1249 10.9999C13.1249 10.6362 12.8323 10.3437 12.4687 " +
      "10.3437H3.11436L4.61826 8.83975C4.87529 8.58271 4.87529 8.16709 4.61826 " +
      "7.91279C4.36123 7.6585 3.94561 7.65576 3.69131 7.91279L1.06631 10.5351C0.809277 " +
      "10.7921 0.809277 11.2077 1.06631 11.462L3.69131 14.087V14.0897ZM12.9335 " +
      "4.46475C13.1905 4.20772 13.1905 3.79209 12.9335 3.53779L10.3085 0.910059C10.0515 " +
      "0.653027 9.63584 0.653027 9.38154 0.910059C9.12725 1.16709 9.12451 1.58271 9.38154 " +
      "1.83701L10.8854 3.34092L1.53115 3.34365C1.16748 3.34365 0.874902 3.63623 0.874902 " +
      "3.9999C0.874902 4.36357 1.16748 4.65615 1.53115 4.65615H10.8854L9.38154 " +
      "6.16006C9.12451 6.41709 9.12451 6.83271 9.38154 7.08701C9.63857 7.34131 10.0542 " +
      "7.34404 10.3085 7.08701L12.9335 4.46201V4.46475Z",
  ],
}

const facBolt = {
  prefix: "fac",
  iconName: "bolt",
  icon: [
    20,
    20,
    [],
    null,
    "M14.2891 0C15.0313 0 15.5781 0.742188 15.3828 1.44531L13.2734 8.75H16.6328C17.375 8.75 17.9609 9.375 17.9609 10.1172C17.9609 10.5469 17.8047 10.8984 17.4922 11.1719L7.375 19.7656C7.17969 19.9219 6.94531 20 6.67188 20C5.92969 20 5.38281 19.2969 5.57813 18.5938L7.6875 11.25H4.28906C3.58594 11.25 3 10.6641 3 9.96094C3 9.57031 3.15625 9.21875 3.42969 8.94531L13.5859 0.273438C13.7813 0.117188 14.0156 0 14.2891 0ZM12.8828 3.32031L5.8125 9.375H8.9375C9.21094 9.375 9.48438 9.53125 9.67969 9.76563C9.83594 10 9.91406 10.3125 9.83594 10.5859L8.07813 16.7188L15.2266 10.625H12.0625C11.75 10.625 11.4766 10.5078 11.2813 10.2734C11.125 10.0391 11.0469 9.72656 11.125 9.45313L12.8828 3.32031Z",
  ],
}

const facBoltSlash = {
  prefix: "fac",
  iconName: "bolt-slash",
  icon: [
    20,
    20,
    [],
    null,
    "M2.0085 0.73407L9.38169 6.50779L16.0917 0.773081C16.2867 0.617035 16.5208 0.5 16.7939 0.5C17.5351 0.5 18.0813 1.24122 17.8862 1.94343L15.7796 9.27761H19.1346C19.8758 9.27761 20.5 9.86279 20.5 10.604C20.5 11.0331 20.3049 11.3842 19.9928 11.6573L18.0813 13.2958L25.1034 18.8355C25.5325 19.1475 25.6105 19.7327 25.2594 20.1228C24.9473 20.552 24.3621 20.63 23.972 20.2789L0.877161 2.1775C0.448033 1.86541 0.37001 1.28023 0.721114 0.890116C1.03321 0.460988 1.61838 0.382965 2.0085 0.73407ZM10.9031 7.67814L13.673 9.86279L15.3895 3.81599L10.9031 7.67814ZM15.3115 11.1112L16.5598 12.1255L17.7302 11.1112H15.3115ZM13.634 14.6222L15.1164 15.7926L9.88884 20.2399C9.69378 20.3959 9.45971 20.4739 9.18663 20.4739C8.44541 20.4739 7.89925 19.7717 8.09431 19.0695L10.1619 11.8524L11.7614 13.1007L10.5911 17.197L13.634 14.6222ZM6.49483 8.96552L10.0059 11.7353H6.80693C6.10472 11.7353 5.48053 11.1502 5.48053 10.448C5.48053 10.0578 5.67559 9.66773 5.94867 9.43366L6.49483 8.96552Z",
  ],
}

const facBoxArchive = {
  prefix: "fac",
  iconName: "box-archive",
  icon: [
    20,
    20,
    [],
    null,
    "M2.375 2.75V4.625H18.625V2.75H2.375ZM1.75 0.875H19.25C19.9141 0.875 20.5 1.46094 20.5 2.125V5.25C20.5 5.95313 19.9141 6.5 19.25 6.5H1.75C1.04688 6.5 0.5 5.95313 0.5 5.25V2.125C0.5 1.46094 1.04688 0.875 1.75 0.875ZM6.75 9.3125C6.75 8.80469 7.14063 8.375 7.6875 8.375H13.3125C13.8203 8.375 14.25 8.80469 14.25 9.3125C14.25 9.85938 13.8203 10.25 13.3125 10.25H7.6875C7.14063 10.25 6.75 9.85938 6.75 9.3125ZM1.75 15.875V7.75H3.625V15.875C3.625 16.2266 3.89844 16.5 4.25 16.5H16.75C17.0625 16.5 17.375 16.2266 17.375 15.875V7.75H19.25V15.875C19.25 17.2813 18.1172 18.375 16.75 18.375H4.25C2.84375 18.375 1.75 17.2813 1.75 15.875Z",
  ],
}

const facBoxUnarchive = {
  prefix: "fac",
  iconName: "box-unarchive",
  icon: [
    20,
    16,
    [],
    null,
    "M1.962,0.159 L3.527,1.386 C3.599,1.369 3.673,1.361 3.75,1.361 L17.75,1.361 C18.281,1.361 18.75,1.829 18.75,2.361 L18.75,4.86 C18.75,5.423 18.281,5.86 17.75,5.86 L9.236,5.86 L11.15,7.36 L13,7.36 C13.406,7.36 13.75,7.704 13.75,8.11 C13.75,8.526 13.44,8.828 13.061,8.858 L16.25,11.357 L16.25,6.86 L17.75,6.86 L17.75,12.533 L20.462,14.659 C20.787,14.915 20.847,15.387 20.59,15.712 C20.334,16.037 19.862,16.097 19.537,15.84 L1.037,1.341 C0.712,1.084 0.653,0.612 0.909,0.287 C1.166,-0.038 1.637,-0.097 1.962,0.159 Z M16.37,15.263 C16.174,15.326 15.966,15.36 15.75,15.36 L5.75,15.36 C4.625,15.36 3.75,14.485 3.75,13.36 L3.75,6.86 L5.25,6.86 L5.25,13.36 C5.25,13.642 5.469,13.86 5.75,13.86 L14.581,13.86 Z M5.409,2.861 L7.323,4.36 L17.25,4.36 L17.25,2.861 Z M2.75,4.587 L4.375,5.86 L3.75,5.86 C3.187,5.86 2.75,5.423 2.75,4.86 Z",
  ],
}

const facDropletSplash = {
  prefix: "fac",
  iconName: "droplet-splash",
  icon: [
    18,
    15,
    [],
    null,
    "M9.01387 13.1714C10.0256 13.1714 10.9826 12.7886 11.6936 12.1323L12.7326 12.9526C11.7756 " +
      "13.9097 10.4631 14.4839 9.01387 14.4839C6.11543 14.4839 3.76387 12.1323 3.76387 9.23389C3.76387 " +
      "8.49561 4.09199 7.53857 4.55684 6.52686L5.62324 7.34717C5.5959 7.4292 5.56856 7.51123 5.51387 " +
      "7.56592C5.21309 8.33154 5.04903 8.90576 5.04903 9.23389C5.04903 11.4214 6.82637 13.1714 8.98652 " +
      "13.1714H9.01387ZM14.0725 10.6284L17.4904 13.3354C17.7912 13.5542 17.8459 13.9644 17.5998 " +
      "14.2378C17.3811 14.5386 16.9709 14.5933 16.6975 14.3472L0.509963 1.65967C0.209181 1.44092 " +
      "0.154494 1.03076 0.400588 0.757324C0.619338 0.456543 1.02949 0.401855 1.30293 0.647949L5.86934 " +
      "4.20264C6.82637 2.72607 7.83809 1.38623 8.30293 0.812012C8.46699 0.620605 8.71309 0.483887 " +
      "8.98652 0.483887H9.01387C9.28731 0.483887 9.5334 0.620605 9.69746 0.812012C10.6818 2.06982 " +
      "14.2639 6.74561 14.2639 9.23389C14.2639 9.72607 14.1818 10.1909 14.0725 10.6284ZM12.8967 " +
      "9.72607C12.924 9.56201 12.9514 9.39795 12.9514 9.23389C12.9514 8.87842 12.7873 8.33154 12.4865 " +
      "7.56592C12.1584 6.85498 11.7209 6.06201 11.2287 5.26904C10.4357 3.98389 9.56074 2.78076 9.01387 " +
      "2.06982C8.46699 2.75342 7.67403 3.84717 6.9084 5.02295L12.8967 9.72607ZM6.38887 9.01514C6.38887 " +
      "8.71436 6.60762 8.44092 6.93574 8.38623L7.70137 8.98779V9.01514C7.70137 9.86279 8.38496 10.5464 " +
      "9.23262 10.5464C9.58809 10.5464 9.88887 10.8472 9.88887 11.2026C9.88887 11.5854 9.58809 11.8589 " +
      "9.23262 11.8589C7.64668 11.8589 6.38887 10.6011 6.38887 9.01514Z",
  ],
}

// Disable autoAddCSS so we can avoid using style-src: unsafe-inline in
// our Content-Security-Policy header.
config.autoAddCss = false

library.add(
  faAnalytics,
  faAnalytics2,
  faAngleLeft,
  faArchive,
  faArrowAltRight,
  faArrowAltRightSolid,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faBan,
  faBars,
  faBehance,
  faBloggerB,
  faBriefcase,
  faBuilding,
  faCalendar,
  faCalendarAlt,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  facArrowUpRightFromSquare,
  facBolt,
  facBoltSlash,
  facBoxArchive,
  facBoxUnarchive,
  facDropletSplash,
  faChartBar,
  faChartPie,
  faCheck,
  faCheckCircle,
  faCheckCircleDuo,
  faCheckCircleRegular,
  faCheckSquare,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChairOffice,
  faClipboardCheck,
  faClock,
  faClone,
  faCloneLight,
  faCodeMerge,
  faCog,
  faComment,
  faCommentAltLines,
  faCommentSolid,
  faComments,
  faCompass,
  faCompressAlt,
  faDesktop,
  faDollarSign,
  faDownload,
  faDownloadLight,
  faDribbble,
  faDropbox,
  faEdit,
  faEllipsisH,
  faEllipsisHRegular,
  faEllipsisV,
  faEnvelope,
  faEraser,
  faEuroSign,
  faExchange,
  faExchangeAlt,
  faExclamation,
  faExclamationCircle,
  faExclamationCircleDuo,
  faExclamationCircleRegular,
  faExclamationTriangle,
  faExclamationTriangleDuo,
  faExclamationTriangleRegular,
  faExpandAlt,
  faEye,
  faEyeSlash,
  faEyeSolid,
  faFacebookF,
  faFile,
  faFileAlt,
  faFileCode,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFillDrip,
  faFilter,
  faFlag,
  faGithub,
  faGripVertical,
  faHeart,
  faHistory,
  faHome,
  faHubspot,
  faICursor,
  faIdBadge,
  faInfoCircle,
  faInfoCircleDuo,
  faInfoCircleRegular,
  faInstagram,
  faKey,
  faLayerGroup,
  faLink,
  faLinkedinIn,
  faListUl,
  faLockAlt,
  faLockOpenAlt,
  faLongArrowAltRight,
  faLowVision,
  faMediumM,
  faMinus,
  faMinusCircle,
  faMinusCircleDuo,
  faMoneyBill,
  faPalette,
  faPaperclip,
  faPaperPlane,
  faPen,
  faPencil,
  faPencilRuler,
  faPercent,
  faPhone,
  faPhoneSolid,
  faPinterest,
  faPlus,
  faPlusSquare,
  faPoundSign,
  faPrint,
  faRedoAlt,
  faSearch,
  faSearchMinus,
  faSearchPlus,
  faShareSquare,
  faSignature,
  faSignIn,
  faSignOut,
  faSitemap,
  faSlidersH,
  faSnapchatGhost,
  faSort,
  faSortDown,
  faSortUp,
  faSparkles,
  faStar,
  faStarLight,
  faStickyNote,
  faStopwatch,
  faSyncAlt,
  faTerminal,
  faTimes,
  faTimesDuo,
  faTimesCircle,
  faTimesLight,
  faTimesSolid,
  faTrashAlt,
  faTwitter,
  faUndo,
  faUndoAlt,
  faUpload,
  faUsdSquare,
  faUser,
  faUserPlus,
  faUserPlusLight,
  faUsers,
  faVimeoV,
  faWordpressSimple,
  faYenSign,
  faYoutube,
  facBrazilianRealSign,
  facCodeFork,
  facChartSections,
  facChfSign,
  facDanishKroneSign,
  facFolderTree,
  facHongKongDollarSign,
  facIndianRupeeSign,
  facKrSign,
  facLadder,
  facListOl,
  facRectangleHistoryCirclePlus,
  facRomanianLeuSign,
  facSidebarFlip,
  facSingaporeDollarSign,
  facSquareBrazilianRealSign,
  facSquareChfSign,
  facSquareDanishKroneSign,
  facSquareDollarSign,
  facSquareEuroSign,
  facSquareHongKongDollarSign,
  facSquareIndianRupeeSign,
  facSquareKrSign,
  facSquarePoundSign,
  facSquareRomanianLeuSign,
  facSquareSingaporeDollarSign,
  facSquareYenSign,
  fasCheck,
  fasGripVertical,
  faHashtag,
  facSouthAfricanRandSign,
  facSquareSouthAfricanRandSign,
  facPhilippinePesoSign,
  facSquarePhilippinePesoSign,
  facThaiBahtSign,
  facSquareThaiBahtSign,
  facTurkishLiraSign,
  facSquareTurkishLiraSign,
  facUaeDirhamSign,
  facUaeDirhamTextSign,
  facSquareUaeDirhamSign,
  facSquareUaeDirhamTextSign,
  facShortText,
  facArrowLeftRight,
)

const FontAwesomeStub = {
  config,
  dom,
  library,
  mount: () => {
    const $ = window.jQuery
    $(() => dom.watch())
  },
}

export { FontAwesomeStub, facChartSections }
