import React from "react"

import { BudgetCell } from "v2/react/components/headcountPlanning/Overview/ParticipantTable/BudgetCell"
import { ProposalCell } from "v2/react/components/headcountPlanning/Overview/ParticipantTable/ProposalCell"

interface BudgetingCellsProps {
  budget?: string | null
  completed: boolean
  proposal?: string | null
  showBudget: boolean
  showProposal: boolean
  remainingBudget?: string | null
}

const BudgetingCells = ({
  budget,
  completed,
  proposal,
  showBudget,
  showProposal,
  remainingBudget,
}: BudgetingCellsProps) => (
  <>
    <BudgetCell
      budget={budget}
      proposal={proposal}
      showBudget={showBudget}
      planApproved={!showProposal}
    />
    <ProposalCell
      completed={completed}
      proposal={proposal}
      remainingBudget={remainingBudget}
      showProposal={showProposal}
    />
  </>
)

export { BudgetingCells }
