import React, { createContext, useContext } from "react"

type JsonFormControlsOptions = {
  positionControl: {
    customized: boolean
    startingFromPositionKey?: string | null
    subordinateOnly?: boolean
    includeSelf?: boolean
  }
}

const JsonFormControlsContext = createContext<JsonFormControlsOptions>({
  positionControl: { customized: false },
})

const useJsonFormControlsOptions = () => useContext(JsonFormControlsContext)

function JsonFormControlsOptions({
  children,
  ...props
}: React.PropsWithChildren<JsonFormControlsOptions>) {
  return (
    <JsonFormControlsContext.Provider value={props}>{children}</JsonFormControlsContext.Provider>
  )
}

export { useJsonFormControlsOptions, JsonFormControlsOptions }
