import cn from "classnames"
import React, { useRef } from "react"

import { Role } from "types/graphql.enums"
import { colorWheel } from "v2/react/components/successionSettings/palettes"
import { useCurrentActiveSession } from "v2/react/hooks/useSessionHooks"
import { ColorPicker } from "v2/react/shared/forms/ColorPicker"
import { useUpdateColorCodeMutation } from "v2/redux/GraphqlApi"
import { ReferenceColorCode } from "v2/redux/slices/VisualizationSlice/types"
import { selectColorCodingConfigForActiveTab } from "v2/redux/slices/VisualizationSlice/visualizationSelectors"
import { useAppSelector } from "v2/redux/store"

interface ColorCodeProps {
  color: string
  name: string
  editColor: (domPosition: { top: number; left: number }, color: string) => void
  style: React.CSSProperties
  themeOption: ReferenceColorCode
}

function ColorCode({ color, name, editColor, style, themeOption }: ColorCodeProps) {
  const useActiveTabColorCodingConfig = () => useAppSelector(selectColorCodingConfigForActiveTab)
  const divRef = useRef<HTMLDivElement>(null)
  const { activeTab, field, isEnabled } = useActiveTabColorCodingConfig()
  const colorCode = useAppSelector((state) => state.visualization.colorPickerColorCode)
  const colorPalette = useAppSelector((state) => state.visualization.useColorsFrom)
  const { roleScopes } = useCurrentActiveSession()
  const isCompanyManagerOrHrAdmin = roleScopes.some(({ roleName }) =>
    [Role.CompanyManager, Role.HrAdmin].includes(roleName),
  )
  const disableColorPicker =
    (!isCompanyManagerOrHrAdmin && colorPalette === "company") || !isEnabled
  const showAlert = isCompanyManagerOrHrAdmin && colorPalette === "company"
  const [updateColorCode] = useUpdateColorCodeMutation()

  const onSubmit = (color: string) => {
    const ref = divRef.current
    if (!ref || !field) return

    const top = ref.getBoundingClientRect().top
    const position = { top, left: ref.clientWidth }
    editColor(position, color)

    updateColorCode({
      input: {
        color,
        colorType: activeTab === "card" ? "secondary" : "primary",
        groupKey: field,
        value: (themeOption.true_value || colorCode?.true_value || "").toLowerCase(),
        targetType: colorPalette,
      },
    })
  }

  return (
    <ColorPicker
      aria-label={name}
      colors={colorWheel}
      currentColor={color}
      disabled={disableColorPicker}
      dropdownPlacement="top-start"
      includeNoColorOption
      noColorHex="#ffffff"
      onSubmit={onSubmit}
      popoverTriggerActiveClassNames="!bg-neutral-8"
      popoverTriggerClassNames={cn("color-coding-row m-0 rounded bg-transparent px-1 py-0.5", {
        "cursor-default": disableColorPicker,
        "hover:bg-neutral-8": !disableColorPicker,
      })}
      showAlert={showAlert}
      style={style}
    >
      <div className="items-center gap-2 flex" ref={divRef}>
        <div
          className="color-box border--main h-4 w-4 rounded border-neutral-8"
          style={{ backgroundColor: color }}
        />
        <span className="color-coding-color-label text-base">{name}</span>
      </div>
    </ColorPicker>
  )
}

export { ColorCode, ColorCodeProps }
