import { Layout, LayoutProps } from "@jsonforms/core"
import { JsonFormsDispatch } from "@jsonforms/react"
import React from "react"
import invariant from "tiny-invariant"

function OneFourthThreeFourthsLayout({
  cells,
  enabled,
  path,
  renderers,
  schema,
  uischema,
  visible,
}: LayoutProps & { uischema: Layout }) {
  const { elements } = uischema
  invariant(elements?.length === 2, "OneFourthThreeFourthsLayout requires two elements")

  if (!visible) return null

  return (
    <div className="grid-cols-4 gap-4 grid">
      <div className="col-span-1">
        <JsonFormsDispatch
          schema={schema}
          uischema={elements[0]}
          path={path}
          enabled={enabled}
          renderers={renderers}
          cells={cells}
        />
      </div>
      <div className="col-span-3">
        <JsonFormsDispatch
          schema={schema}
          uischema={elements[1]}
          path={path}
          enabled={enabled}
          renderers={renderers}
          cells={cells}
        />
      </div>
    </div>
  )
}

export { OneFourthThreeFourthsLayout }
