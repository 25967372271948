import { useCallback, useEffect, useMemo, useState } from "react"

import { FilterOption } from "types/graphql"
import { useFieldsetWithSearch } from "v2/react/shared/forms/FieldsetWithSearch/hooks/useFieldsetWithSearch"
import { getEmptyFilter } from "v2/react/shared/tables/TableUtilities/FilterTable/utils/filters"
import { filterPanelSelectors } from "v2/redux/slices/TableFilterSlice/tableFiltersSelectors"
import { addFilter, removeFilter, resetFilterChanges } from "v2/redux/slices/TableFiltersSlice"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

/**
 * This hook is used to manage state for filter panels - specifically it
 * coordinates the addition and removal of filters and clearing changes.
 *
 * Used in conjunction with `useFilters` to manage the full filtering
 * experience.
 */
const useFilterPanelState = () => {
  const dispatch = useAppDispatch()
  // This state tracks which fields currently have validation errors. Useful for
  // conditionally disabling the submit button.
  const [fieldsWithErrors, setFieldsWithErrors] = useState<string[]>([])
  const { appliedFilters, allFilterOptions, formKey, anyChanges, filters } =
    useAppSelector(filterPanelSelectors)

  const selectedFieldKeys = useMemo(
    () => appliedFilters.map((filter) => filter.field),
    [appliedFilters],
  )

  const {
    selectedFields,
    unselectedFields,
    handleSearchSelect,
    setSelectedFields,
    setUnselectedFields,
    resetState,
  } = useFieldsetWithSearch({
    selectedFieldKeys,
    allFields: allFilterOptions,
  })

  // Reset local state when the formKey changes
  useEffect(() => {
    resetState()
    setFieldsWithErrors([])
  }, [formKey, resetState])

  const handleClearChanges = useCallback(() => dispatch(resetFilterChanges()), [dispatch])

  const handleFilterSelect = useCallback(
    (field: FilterOption) => {
      handleSearchSelect?.(field)

      // Add new filter to state
      dispatch(addFilter(getEmptyFilter({ field: field.id, dataType: field.dataType })))
    },
    [handleSearchSelect, dispatch],
  )

  const removeField = useCallback(
    (id: string) => {
      const field = allFilterOptions.find((filter) => filter.id === id)
      const selected = selectedFields.filter((field) => field.id !== id)

      if (field) {
        setUnselectedFields((prev) => [...prev, field])
        setSelectedFields(selected)

        // Remove from filters state
        dispatch(removeFilter(id))
        // Clear any errors associated with the field
        setFieldsWithErrors((prev) => prev.filter((field) => field !== id))
      }
    },
    [allFilterOptions, selectedFields, setSelectedFields, setUnselectedFields, dispatch],
  )

  const anyErrors = fieldsWithErrors.length > 0
  const updateFieldErrors = useCallback(
    (id: string, action: "add" | "remove") =>
      action === "add"
        ? setFieldsWithErrors((prev) => [...prev, id])
        : setFieldsWithErrors((prev) => prev.filter((field) => field !== id)),
    [setFieldsWithErrors],
  )

  return {
    anyChanges,
    anyErrors,
    filters,
    formKey,
    handleClearChanges,
    handleFilterSelect,
    removeField,
    selectedFields,
    updateFieldErrors,
    unselectedFields,
  }
}

type FilterPanelState = ReturnType<typeof useFilterPanelState>

export type { FilterPanelState }
export { useFilterPanelState }
