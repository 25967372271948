import React from "react"
import { useTranslation } from "react-i18next"

interface BudgetingHeadersProps {
  showBudget: boolean
  showProposal: boolean
}

const BudgetingHeaders = ({ showBudget, showProposal }: BudgetingHeadersProps) => {
  const { t } = useTranslation()

  return (
    <>
      {showBudget && (
        <th className="hcp-overview-table__budgeting-cell">
          <div className="h-14 place-content-center px-4">
            {t("v2.headcount_plan.overview.columns.budget")}
          </div>
        </th>
      )}
      {showProposal && (
        <th className="hcp-overview-table__budgeting-cell">
          <div className="h-14 place-content-center px-4">
            {t("v2.headcount_plan.overview.columns.proposal")}
          </div>
        </th>
      )}
    </>
  )
}

export { BudgetingHeaders }
