/* eslint-disable react/jsx-props-no-spreading */
import classNames from "classnames"
import React, { MutableRefObject } from "react"

import { Option } from "types/graphql"

import { CompareValues } from "./CompareValues"
import { DropdownMenu } from "./DropdownMenu"
import { useAutocompleteList } from "./hooks/useAutocompleteList"
import { useCellHandlers } from "./hooks/useCellHandlers"
import { useCellState } from "./hooks/useCellState"
import { useCursorSync } from "./hooks/useCursorSync"
import { useOptimisticValue } from "./hooks/useOptimisticValue"
import { StyleLayers } from "./StyleLayers"
import { FieldType, SaveFn } from "./types"

type Props<T> = {
  compareValue?: T | null
  currentValue: T
  searchTerm: string
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>
  renderOption: (option: T, isActive: boolean) => React.ReactNode
  options: Array<T>
  notFoundMsg?: string
  saveFn: SaveFn<T>
  onSaved?: () => void
  onErrored?: () => void
  onEditing?: () => void
  columnId: string
  rowId: string
  editable?: boolean
}
export function ForcedAutocompleteCell<T extends Omit<Option, "__typename">>({
  compareValue,
  searchTerm,
  setSearchTerm,
  renderOption,
  options,
  saveFn,
  onSaved,
  onErrored,
  onEditing,
  currentValue,
  notFoundMsg,
  columnId,
  rowId,
  editable,
}: Props<T>) {
  const [optimisticValue, setOptimisticValue] = useOptimisticValue(currentValue?.label ?? "")

  const cell = useCellState<T>({
    currentValue,
    fieldType: FieldType.ForcedAutocomplete,
    rowId,
    columnId,
    editable,
    hasNotChanged: (value) => value?.id === currentValue?.id,
    saveFn: (state) => saveFn(state.value),
    onEditing: () => {
      document.getElementById(`${columnId}-${rowId}-input`)?.focus()
      onEditing?.()
    },
    onSaved: (state) => {
      setSearchTerm(state.value?.label ?? "")
      if (state.value) {
        setOptimisticValue(state.value.label)
      }
      onSaved?.()
    },
    onErrored: () => onErrored?.(),
  })

  const autocompleteList = useAutocompleteList({ showList: cell.isEditing })
  const cellRef = autocompleteList.refs.reference as MutableRefObject<HTMLDivElement>

  useCursorSync({ cell, cellRef, value: currentValue })

  const { handleCellClick, handleCellKeyUp } = useCellHandlers(cell, cellRef, {
    getSaveValue: () =>
      autocompleteList.activeIndex !== null ? options[autocompleteList.activeIndex] : currentValue,
    moveDown: () =>
      autocompleteList.setActiveIndex((prev) =>
        prev === null ? 0 : Math.min(options.length - 1, prev + 1),
      ),
    moveUp: () =>
      autocompleteList.setActiveIndex((prev) => (prev === null ? 0 : Math.max(0, prev - 1))),
  })

  const hideCompareValue = cell.isEditing || cell.isSaving || cell.isSaved
  const showSearchTerm = cell.isEditing || cell.isErroredEditing
  const showNotFoundMsg =
    searchTerm.length > 0 && options.length === 0 && typeof notFoundMsg === "string"
  const disableInput = !cell.isEditing && !cell.isErroredEditing
  const showDropdownMenu =
    (cell.isEditing || cell.isErroredEditing) && (options.length > 0 || showNotFoundMsg)
  const showStateValue = cell.isSaving || cell.isSaved || cell.isErrored || cell.isErroredSaving

  // eslint-disable-next-line no-nested-ternary
  const inputValue = showSearchTerm
    ? searchTerm
    : showStateValue
    ? cell.value?.label
    : optimisticValue ?? currentValue?.label ?? ""

  const errored =
    cell.isErrored || cell.isErroredEditing || cell.isErroredSaving || cell.isErroredSelected

  return (
    // eslint-disable-next-line jsx-a11y/interactive-supports-focus
    <div
      role="button"
      id={`${columnId}-${rowId}`}
      className={classNames("editable-cell", { "editable-cell--errored": errored })}
      onClick={handleCellClick}
      onKeyUp={handleCellKeyUp}
    >
      <CompareValues compareValue={hideCompareValue ? null : compareValue?.label}>
        <input
          id={`${columnId}-${rowId}-input`}
          ref={autocompleteList.refs.setReference}
          {...autocompleteList.getReferenceProps()}
          className="bg-white"
          value={inputValue ?? ""}
          onChange={(event) => setSearchTerm(event.target.value)}
          disabled={disableInput}
          name={columnId}
          autoComplete="off"
        />
      </CompareValues>
      <DropdownMenu
        showList={showDropdownMenu}
        floatingRef={autocompleteList.refs.setFloating}
        floatingStyles={{
          ...autocompleteList.floatingStyles,
          zIndex: 5,
          marginLeft: "-1rem",
        }}
        floatingProps={autocompleteList.getFloatingProps}
        wrapperClasses="SelectField"
        context={autocompleteList.context}
      >
        {showNotFoundMsg && <li className="TableAutocompleteField__not-found">{notFoundMsg}</li>}
        {options.map((option, index) => (
          <li
            role="option"
            className={textClassName(autocompleteList.activeIndex, index)}
            aria-selected={autocompleteList.activeIndex === index}
            key={option.id}
            ref={(node) => {
              autocompleteList.listRef.current[index] = node
            }}
            {...autocompleteList.getItemProps({
              onClick: () => {
                cell.dispatch({ type: "save", value: option })
              },
            })}
          >
            {renderOption(option, autocompleteList.activeIndex === index)}
          </li>
        ))}
      </DropdownMenu>
      <StyleLayers cell={cell} fieldType={FieldType.ForcedAutocomplete} />
    </div>
  )
}

const textClassName = (activeIndex: number | null, index: number) =>
  classNames("TableAutocompleteField__result", { highlight: activeIndex === index })
