import fp from "lodash/fp"
import React, { Ref, useCallback, useState } from "react"
import { defaultCellRangeRenderer, ScrollParams } from "react-virtualized"

import { CellProps, MemoCell } from "v2/react/components/orgChart/Datasheet/Cell"
import { ColumnDropzoneProps } from "v2/react/components/orgChart/Datasheet/ColumnDropzone"
import { GridBody } from "v2/react/components/orgChart/Datasheet/GridBody"
import { GridHeader } from "v2/react/components/orgChart/Datasheet/GridHeader"
import {
  Column,
  CursorConnection,
  GroupRow,
  NodeRow,
  StatsRow,
} from "v2/react/components/orgChart/Datasheet/types"
import {
  CELL_PADDING_PX,
  ROW_HEIGHT_PX,
} from "v2/react/components/orgChart/Datasheet/utils/constants"
import { selectGroupFieldKeys } from "v2/redux/slices/GridSlice/gridSelectors"
import { useAppSelector } from "v2/redux/store"

import {
  BOTTOM_OFFSET,
  FloatingFooter,
  FOOTER_STAT_HEIGHT,
  FOOTER_STAT_HEIGHT_SM,
} from "./Datasheet/FloatingFooter"
import { statisticsRequired } from "./Datasheet/utils/statistics"

interface ForwardedHeaderProps<CType> {
  onClearFilter: (fieldKey: keyof CType) => void
  onSelectFilter: (fieldKey: keyof CType, term: string) => void
  onSortColumn: (a: keyof CType) => void
  selectFilterValues?: (
    field: Column<CType>,
    isFocused?: boolean,
  ) => { value: string | number; label: string }[]
}

interface CoreProps<TNode, CType> {
  CellComponent?: (props: CellProps<TNode, CType>) => React.ReactNode
  cellRangeRenderer?: typeof defaultCellRangeRenderer
  columns: Column<CType>[]
  containerStyle?: React.CSSProperties
  cursorConnection?: CursorConnection
  height: number
  onCellClick?: (row: NodeRow<TNode>, column: Column<CType>) => void
  onExpandCollapseGrouping: (row: GroupRow) => void
  rows: (NodeRow<TNode> | GroupRow | StatsRow)[]
  sheetRef?: Ref<{
    contains: (element: Node | null) => boolean
    scrollToCell: (c: { rowIndex: number; columnIndex: number }) => void
  }>
  // IMPORTANT: It's planned that this can be bigger than the container (thus
  // enabling horizontal scroll).
  sheetWidth: number
  sortBy: Omit<Column<CType>, "width">
  sortDirection: "asc" | "desc"
  showColumnStats: boolean
}

type DatasheetProps<TNode, CType = TNode> = ForwardedHeaderProps<CType> &
  Omit<ColumnDropzoneProps<CType>, "onAddColumn" | "onRemoveColumn"> &
  CoreProps<TNode, CType>

const fallback = () => []

export type CellRenderer = (a: {
  columnIndex: number
  key: string
  rowIndex: number
  style: React.CSSProperties
}) => ReturnType<typeof MemoCell>

function Datasheet<TNode, CType = TNode>({
  CellComponent,
  cellRangeRenderer,
  columns,
  containerStyle,
  cursorConnection,
  height,
  onCellClick = fp.noop,
  onClearFilter,
  onExpandCollapseGrouping,
  onSelectFilter,
  onSortColumn,
  rows,
  selectFilterValues,
  sheetRef,
  sheetWidth,
  sortBy,
  sortDirection,
  showColumnStats,
}: DatasheetProps<TNode, CType>) {
  const containerRef = React.useRef<HTMLDivElement>(null)
  const groupFieldKeys = useAppSelector(selectGroupFieldKeys)
  const maximumStatCount = new Set(
    columns
      .flatMap((column) => statisticsRequired(String(column.fieldKey)))
      .filter((stat) => stat === 0 || !!stat),
  ).size

  // Used to determine height of the sticky footer with larger text
  const footerHeight = maximumStatCount * FOOTER_STAT_HEIGHT + CELL_PADDING_PX * 4
  // Passed to the GridBody to determine the height of the inline grouped footers with smaller text
  const inlineFooterHeight = maximumStatCount * FOOTER_STAT_HEIGHT_SM + CELL_PADDING_PX * 4
  const [stickyFooter, setStickyFooter] = useState(false)

  const rowCount = rows.length
  const handleScroll: (params: ScrollParams) => void = useCallback(
    (params) => {
      const { scrollHeight, scrollTop, clientHeight } = params
      const altCase = scrollTop + clientHeight < scrollHeight

      if (
        altCase &&
        clientHeight > 0 &&
        clientHeight < scrollHeight &&
        rowCount * ROW_HEIGHT_PX > clientHeight &&
        scrollTop + clientHeight + footerHeight < scrollHeight
      ) {
        // make footer sticky if the table does not overflow the available space
        setStickyFooter(true)
      } else {
        setStickyFooter(
          !(
            scrollHeight > 0 &&
            scrollTop + clientHeight + footerHeight + BOTTOM_OFFSET >= scrollHeight
          ),
        )
      }
    },
    [footerHeight, rowCount],
  )

  const showFooter = groupFieldKeys.length === 0 && showColumnStats && maximumStatCount > 0

  return (
    <div
      className="Datasheet datasheet-grid grid-rows-[auto_1fr] overflow-y-hidden grid"
      style={prepareContainerStyle(containerStyle)}
      ref={containerRef}
    >
      <div className="zDatasheetHeaders bg-white">
        <GridHeader<TNode, CType>
          columns={columns}
          onClearFilter={onClearFilter}
          onSelectFilter={onSelectFilter}
          onSortColumn={onSortColumn}
          selectFilterValues={selectFilterValues || fallback}
          sortBy={sortBy}
          sortDirection={sortDirection}
        />
      </div>
      <div className="grid-body-wrapper relative overflow-hidden">
        <GridBody
          CellComponent={CellComponent}
          cellRangeRenderer={cellRangeRenderer}
          columns={columns}
          cursorConnection={cursorConnection}
          onCellClick={onCellClick}
          onExpandCollapseGrouping={onExpandCollapseGrouping}
          onScroll={handleScroll}
          height={height}
          rowCount={rows.length}
          rows={rows}
          sheetRef={sheetRef}
          width={sheetWidth}
          showColumnStats={showColumnStats}
          containerRef={containerRef}
          footerHeight={inlineFooterHeight}
        />
        {showFooter ? (
          <FloatingFooter
            columns={columns}
            height={footerHeight}
            rowCount={rows.length}
            stickyFooter={stickyFooter}
            width={sheetWidth}
          />
        ) : null}
      </div>
    </div>
  )
}

const ContainerStyleDefault: React.CSSProperties = { width: "100%" }
const prepareContainerStyle = (containerStyle?: React.CSSProperties) => ({
  ...ContainerStyleDefault,
  ...(containerStyle || {}),
})

const typedMemo: <T>(c: T) => T = React.memo
const MemoedDatasheet = typedMemo(Datasheet)

export { Datasheet, DatasheetProps, MemoedDatasheet }
