import React from "react"

import { PanelSidebar } from "v2/react/components/PanelSidebar"
import RootProvider from "v2/react/components/RootProvider"
import { SuccessionPlanPanel } from "v2/react/components/succession/SuccessionPlanPanel"
import { usePanelListeners } from "v2/react/components/succession/SuccessionPlanPanel/hooks/usePanelListeners"
import { closeSuccessionPlanSidebar } from "v2/redux/slices/SuccessionPlanPanelSlice"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

interface SuccessionPlanPanelContainerProps {
  afterClose?: () => void
}

// This component is intended to support server side views (in addition to React
// components) where components for opening the panel are also used in separate
// parts of the DOM. See: app/views/v2/succession/dashboard.html.slim, where
// each widget also includes a table with links that open the panel, and use
// button components that use:
// succession/SuccessionPlanPanel/PanelOpenerWithTooltip.tsx. These components
// rely on the global state provided by wrapping this component in the
// RootProvider.
const WithProvider = ({ afterClose }: SuccessionPlanPanelContainerProps) => {
  const dispatch = useAppDispatch()
  const activePositionId = useAppSelector((state) => state.successionPlanPanel.activePositionId)
  const activeTab = useAppSelector(
    (state) => state.successionPlanPanel.activeSuccessionPlanPanelTab,
  )
  const isSidebarOpen = useAppSelector((state) => state.successionPlanPanel.isOpen)
  usePanelListeners()

  const hidePanel = () => {
    dispatch(closeSuccessionPlanSidebar())
    if (afterClose) afterClose()
  }

  const panelType = activeTab === "summary" ? "succession" : "successionLarge"

  return (
    <PanelSidebar isOpen={isSidebarOpen} panelType={panelType}>
      <SuccessionPlanPanel hidePanel={hidePanel} positionId={activePositionId} />
    </PanelSidebar>
  )
}

const SuccessionPlanPanelContainer = ({ afterClose }: SuccessionPlanPanelContainerProps) => (
  <RootProvider>
    <WithProvider afterClose={afterClose} />
  </RootProvider>
)

export { SuccessionPlanPanelContainer }
