import { useJsonForms } from "@jsonforms/react"
import classNames from "classnames"
import { get } from "lodash"
import React, { FC } from "react"

import { formatCurrency } from "v2/react/utils/currency"

import { JsonFormControlLabel } from "./JsonFormControlLabel"
import { EnhancedControlProps } from "./types"
import { totalCompensation } from "./utils/compensation"
import { formNumericValue, formStringValue } from "./utils/forms"

type ControlPropsSubset = Pick<EnhancedControlProps, "id" | "label" | "uischema" | "visible">

const JsonTotalCompensationInput: FC<ControlPropsSubset> = ({ id, label, visible, uischema }) => {
  const context = useJsonForms()
  const formData = context?.core?.data

  if (!visible) return null

  const basePayTypeId = formStringValue(formData, ["position", "basePay", "payType", "id"])
  const hours = formNumericValue(formData, ["position", "basePay", "hoursPerWeek"])
  const basePayAmount = formNumericValue(formData, ["position", "basePay", "amount"])
  const variablePayTypes = get(formData, ["position", "variablePayTypes"]) || []

  const amount: number = totalCompensation(basePayTypeId, basePayAmount, hours, variablePayTypes)
  const displayValue = amount ? formatCurrency({ value: amount }) : "" // Assumption: Don't show "$0"

  return (
    <div>
      {label && <JsonFormControlLabel id={id} label={label} uischema={uischema} />}

      <div
        className={classNames("relative", {
          active: false,
          readonly: true,
        })}
      >
        <input type="text" className="input" id={id} name={id} value={displayValue} disabled />
      </div>
    </div>
  )
}

export { JsonTotalCompensationInput }
