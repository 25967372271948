import { ControlProps, LayoutProps, OwnPropsOfControl } from "@jsonforms/core"
import { withJsonFormsControlProps, withJsonFormsLayoutProps } from "@jsonforms/react"
import { vanillaRenderers } from "@jsonforms/vanilla-renderers"
import fp from "lodash/fp"
import React from "react"

import { JsonAutocompleteInput } from "v2/react/shared/jsonForms/JsonFormControls/AutocompleteControl"
import { JsonBasePayInput } from "v2/react/shared/jsonForms/JsonFormControls/BasePayControl"
import {
  AutocompleteControlTester,
  BasePayControlTester,
  CurrencyControlTester,
  DateControlTester,
  DepartmentControlTester,
  FileControlTester,
  FileUploadControlTester,
  ListBaseCompensationControlTester,
  ListItemControlTester,
  ListItemDescriptionControlTester,
  ListItemFilledByControlTester,
  ListItemHeadcountPlanControlTester,
  ListItemSubtitledControlTester,
  ListOrgUnitsControlTester,
  ListTotalCompensationControlTester,
  ListVariableCompensationControlTester,
  MultiSelectControlTester,
  NestedTextControlTester,
  NumberControlTester,
  NumericControlTester,
  OrgUnitControlTester,
  PercentControlTester,
  PersonControlTester,
  PositionControlTester,
  PositionTypeControlTester,
  RadioControlTester,
  SelectControlTester,
  TextAreaControlTester,
  TextControlTester,
  TotalCompensationControlTester,
  VariablePayControlTester,
} from "v2/react/shared/jsonForms/JsonFormControls/ControlTesters"
import { JsonCurrencyInput } from "v2/react/shared/jsonForms/JsonFormControls/CurrencyControl"
import { JsonDateInput } from "v2/react/shared/jsonForms/JsonFormControls/DateControl"
import { DepartmentControl } from "v2/react/shared/jsonForms/JsonFormControls/DepartmentControl"
import { JsonPositionTypeControl } from "v2/react/shared/jsonForms/JsonFormControls/EmbeddedPositionTypeControl"
import { JsonFileUpload } from "v2/react/shared/jsonForms/JsonFormControls/FileUploadControl"
import { JsonModuleListBaseCompensation } from "v2/react/shared/jsonForms/JsonFormControls/ModuleListBaseCompensationControl"
import { JsonModuleFileItem } from "v2/react/shared/jsonForms/JsonFormControls/ModuleListFileControl"
import { JsonModuleFilledByItem } from "v2/react/shared/jsonForms/JsonFormControls/ModuleListFilledByControl"
import { JsonModuleListItem } from "v2/react/shared/jsonForms/JsonFormControls/ModuleListItemControl"
import { JsonModuleListDescriptionItem } from "v2/react/shared/jsonForms/JsonFormControls/ModuleListItemDescriptionControl"
import { JsonModuleListItemHeadcountPlan } from "v2/react/shared/jsonForms/JsonFormControls/ModuleListItemHeadcountPlan"
import { JsonModuleSubtitledItem } from "v2/react/shared/jsonForms/JsonFormControls/ModuleListItemSubtitledControl"
import { JsonModuleListOrgUnits } from "v2/react/shared/jsonForms/JsonFormControls/ModuleListOrgUnitsControl"
import { JsonModuleListTotalCompensation } from "v2/react/shared/jsonForms/JsonFormControls/ModuleListTotalCompensationControl"
import { JsonModuleListVariableCompensation } from "v2/react/shared/jsonForms/JsonFormControls/ModuleListVariableCompensationControl"
import { JsonMultiSelectInput } from "v2/react/shared/jsonForms/JsonFormControls/MultiSelectControl"
import { JsonNestedTextInput } from "v2/react/shared/jsonForms/JsonFormControls/NestedTextControl"
import { JsonNumberInput } from "v2/react/shared/jsonForms/JsonFormControls/NumberControl"
import { JsonNumericInput } from "v2/react/shared/jsonForms/JsonFormControls/NumericControl"
import { JsonOrgUnitInput } from "v2/react/shared/jsonForms/JsonFormControls/OrgUnitControl"
import { JsonPercentInput } from "v2/react/shared/jsonForms/JsonFormControls/PercentControl"
import { JsonPersonInput } from "v2/react/shared/jsonForms/JsonFormControls/PersonControl"
import { JsonPositionInput } from "v2/react/shared/jsonForms/JsonFormControls/PositionControl/PositionControl"
import { JsonRadioInput } from "v2/react/shared/jsonForms/JsonFormControls/RadioControl"
import { JsonSelectInput } from "v2/react/shared/jsonForms/JsonFormControls/SelectControl"
import { JsonTextAreaInput } from "v2/react/shared/jsonForms/JsonFormControls/TextAreaControl"
import { JsonTextInput } from "v2/react/shared/jsonForms/JsonFormControls/TextControl"
import { JsonTotalCompensationInput } from "v2/react/shared/jsonForms/JsonFormControls/TotalCompensationControl"
import { JsonVariablePayInput } from "v2/react/shared/jsonForms/JsonFormControls/VariablePayControl"
import {
  GroupLayoutTester,
  OneFourthThreeFourthsLayoutTester,
} from "v2/react/shared/jsonForms/JsonFormLayouts/LayoutTesters"
import { JsonModuleGroupLayout } from "v2/react/shared/jsonForms/JsonFormLayouts/ModuleGroupLayout"
import { OneFourthThreeFourthsLayout } from "v2/react/shared/jsonForms/JsonFormLayouts/OneFourthThreeFourthsLayout"
import { JsonPanelGroupLayout } from "v2/react/shared/jsonForms/JsonFormLayouts/PanelGroupLayout"

type ControlAndLayoutHOC = (
  Component: React.ComponentType<ControlProps & LayoutProps>,
  memoize?: boolean,
) => React.ComponentType<OwnPropsOfControl>

const withJsonFormsControlAndLayoutProps: ControlAndLayoutHOC = fp.pipe(
  withJsonFormsControlProps,
  withJsonFormsLayoutProps,
)

export const createRenderers = [
  ...vanillaRenderers,
  { tester: AutocompleteControlTester, renderer: withJsonFormsControlProps(JsonAutocompleteInput) },
  {
    tester: PositionTypeControlTester,
    renderer: withJsonFormsControlProps(JsonPositionTypeControl),
  },
  { tester: BasePayControlTester, renderer: withJsonFormsControlProps(JsonBasePayInput) },
  { tester: CurrencyControlTester, renderer: withJsonFormsControlProps(JsonCurrencyInput) },
  { tester: DateControlTester, renderer: withJsonFormsControlProps(JsonDateInput) },
  { tester: DepartmentControlTester, renderer: withJsonFormsControlProps(DepartmentControl) },
  { tester: FileUploadControlTester, renderer: withJsonFormsControlProps(JsonFileUpload) },
  { tester: GroupLayoutTester, renderer: withJsonFormsLayoutProps(JsonPanelGroupLayout) },
  { tester: MultiSelectControlTester, renderer: withJsonFormsControlProps(JsonMultiSelectInput) },
  { tester: NestedTextControlTester, renderer: withJsonFormsControlProps(JsonNestedTextInput) },
  { tester: NumberControlTester, renderer: withJsonFormsControlProps(JsonNumberInput) },
  { tester: NumericControlTester, renderer: withJsonFormsControlProps(JsonNumericInput) },
  {
    tester: OneFourthThreeFourthsLayoutTester,
    renderer: withJsonFormsLayoutProps(OneFourthThreeFourthsLayout),
  },
  { tester: OrgUnitControlTester, renderer: withJsonFormsControlProps(JsonOrgUnitInput) },
  { tester: PercentControlTester, renderer: withJsonFormsControlProps(JsonPercentInput) },
  { tester: PersonControlTester, renderer: withJsonFormsControlProps(JsonPersonInput) },
  { tester: PositionControlTester, renderer: withJsonFormsControlProps(JsonPositionInput) },
  { tester: RadioControlTester, renderer: withJsonFormsControlAndLayoutProps(JsonRadioInput) },
  { tester: SelectControlTester, renderer: withJsonFormsControlProps(JsonSelectInput) },
  { tester: TextAreaControlTester, renderer: withJsonFormsControlProps(JsonTextAreaInput) },
  { tester: VariablePayControlTester, renderer: withJsonFormsControlProps(JsonVariablePayInput) },
  {
    tester: TotalCompensationControlTester,
    renderer: withJsonFormsControlProps(JsonTotalCompensationInput),
  },
  { tester: TextControlTester, renderer: withJsonFormsControlProps(JsonTextInput) },
]
export const viewRenderers = [
  ...vanillaRenderers,
  { tester: FileControlTester, renderer: withJsonFormsControlProps(JsonModuleFileItem) },
  { tester: GroupLayoutTester, renderer: withJsonFormsLayoutProps(JsonModuleGroupLayout) },
  {
    tester: ListBaseCompensationControlTester,
    renderer: withJsonFormsControlProps(JsonModuleListBaseCompensation),
  },
  {
    tester: ListItemHeadcountPlanControlTester,
    renderer: withJsonFormsControlProps(JsonModuleListItemHeadcountPlan),
  },
  { tester: ListItemControlTester, renderer: withJsonFormsControlProps(JsonModuleListItem) },
  {
    tester: ListItemDescriptionControlTester,
    renderer: withJsonFormsControlProps(JsonModuleListDescriptionItem),
  },
  {
    tester: ListItemFilledByControlTester,
    renderer: withJsonFormsControlProps(JsonModuleFilledByItem),
  },
  {
    tester: ListItemSubtitledControlTester,
    renderer: withJsonFormsControlProps(JsonModuleSubtitledItem),
  },
  {
    tester: ListOrgUnitsControlTester,
    renderer: withJsonFormsControlProps(JsonModuleListOrgUnits),
  },
  {
    tester: ListTotalCompensationControlTester,
    renderer: withJsonFormsControlProps(JsonModuleListTotalCompensation),
  },
  {
    tester: ListVariableCompensationControlTester,
    renderer: withJsonFormsControlProps(JsonModuleListVariableCompensation),
  },
]
