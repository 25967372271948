import { ExtendedRefs } from "@floating-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import React from "react"

import { InputErrorText } from "v2/react/shared/jsonForms/JsonFormControls/InputErrorText"

interface Props {
  enabled: boolean
  errorMessage: string
  getReferenceProps: (userProps?: React.HTMLProps<Element>) => Record<string, unknown>
  handleBlurEvent: (event: React.FocusEvent<HTMLInputElement>) => void
  handleChangeEvent: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleClearValue: () => void
  handleKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void
  id: string
  inputLabel: React.ReactNode
  inputValue: string
  positionName: string
  refs: ExtendedRefs<HTMLInputElement>
  showError: boolean
}

function PositionInput({
  enabled,
  errorMessage,
  getReferenceProps,
  handleBlurEvent,
  handleChangeEvent,
  handleClearValue,
  handleKeyDown,
  id,
  inputLabel,
  inputValue,
  positionName,
  refs,
  showError,
}: Props) {
  return (
    <>
      {inputLabel}
      <div className={classNames({ relative: enabled && positionName.length })}>
        <input
          aria-autocomplete="list"
          id={id}
          className="input suffix-pad"
          value={inputValue}
          ref={refs.setReference}
          disabled={!enabled}
          /* eslint-disable react/jsx-props-no-spreading */
          {...getReferenceProps({
            onChange: handleChangeEvent,
            onKeyDown: handleKeyDown,
            onBlur: handleBlurEvent,
          })}
        />
        {enabled && positionName.length ? (
          <button className="suffix" onClick={handleClearValue} type="button">
            <FontAwesomeIcon icon={["far", "times"]} />
          </button>
        ) : null}
      </div>
      <InputErrorText message={errorMessage} showError={showError} />
    </>
  )
}

export { PositionInput }
