import { TFunction } from "i18next"
import fp from "lodash/fp"
import { z } from "zod"

import { NONE_KEY } from "v2/react/constants"

import { safeNumber, SafeNumberOptionsWithoutFallback } from "./safeNumber"
import { maybeGetIDFromUniqueKey, tryParseEntityAndId } from "./uniqueKey"

//
// Common Transforms
//

type FromUniqueKeyOptions = {
  treatNoneKeyAsNull?: boolean
}

export const fromUniqueKey = (options?: FromUniqueKeyOptions) => (val: string) => {
  const treatNoneKeyAsBlank = options?.treatNoneKeyAsNull ?? true
  const idValue = maybeGetIDFromUniqueKey(val)

  return treatNoneKeyAsBlank && idValue === NONE_KEY ? null : idValue
}

//
// Number Schemas
//

export const numericInputSchema = (options?: SafeNumberOptionsWithoutFallback) =>
  z.union([z.string(), z.number()]).transform((value, ctx) => {
    // Forward a blank string as null. Expectation is that the caller needs to
    // allow null via fluent API or else it will be picked up as an error.
    if (typeof value === "string" && value.trim() === "") return null

    // Let caller use fluent API for handling null or undefined.
    if (fp.isNil(value)) return value

    // Proceed with parsing if given a string or number.
    if (typeof value === "string" || typeof value === "number") {
      const result = safeNumber(value, { ...options, fallback: null })
      if (result !== null) return result

      // Once here, we know that w/e value we got couldn't be "parsed".
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Is not a number",
        params: { code: "not_a_number" },
      })

      return z.NEVER
    }

    // We got an unexpected type value.
    return z.NEVER
  })

//
// IDs and Unique Key Schemas
//

type NumberIdOptions = {
  allowZero?: boolean
}
type StringIdOptions = {
  allowBlank?: boolean
  allowZero?: boolean
}
export type UniqueKeyOptions = {
  allowBlank?: boolean
  allowZero?: boolean
  keyType?: "either" | "id" | "string"
  only?: string | string[]
}
type IdOptions = NumberIdOptions &
  StringIdOptions &
  UniqueKeyOptions & {
    allowUniqueKeys?: boolean
  }

export const numberIdSchema = (options?: NumberIdOptions) =>
  z
    .number()
    .gte(options?.allowZero ?? true ? 0 : 1)
    .int()

export const stringIdSchema = (options?: StringIdOptions) => {
  const allowBlank = options?.allowBlank ?? true
  const allowZero = options?.allowZero ?? true

  if (allowBlank && allowZero) return z.string().regex(/^[0-9]*$/)
  if (allowBlank) return z.string().regex(/^([1-9][0-9]*)?$/)
  if (allowZero) return z.string().regex(/^[0-9]+$/)
  return z.string().regex(/^[1-9][0-9]*$/)
}

export const uniqueKeySchema = (options?: UniqueKeyOptions) => {
  const allowBlank = options?.allowBlank ?? true
  const allowZero = options?.allowZero ?? true
  const keyType = options?.keyType ?? "either"
  const only = options?.only

  return z.string().superRefine((val, ctx) => {
    if (allowBlank && val.trim() === "") return

    const addError = (message: string) =>
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message,
      })
    const id = tryParseEntityAndId(val)?.id
    const resultWithConstraint = tryParseEntityAndId(val, { only })

    if (!id && val.trim() === "") addError("Cannot be a blank string")
    if (!id && val.trim() !== "") addError("Cannot parse unique key")
    if (id && !resultWithConstraint) addError(`Unique key does not satisfy only option: ${only}`)
    if (id && keyType === "string" && !id.match(/[a-z]+/)) addError("Must encode a string")
    if (id && keyType === "id" && !id.match(/[0-9]+/)) addError("Must encode an id")
    if (id && !allowZero && id.match(/^0+$/)) addError("Cannot encode a zero id")
  })
}

export const idSchema = (options?: IdOptions) =>
  options?.allowUniqueKeys ?? true
    ? z.union([numberIdSchema(options), stringIdSchema(options), uniqueKeySchema(options)])
    : z.union([numberIdSchema(options), stringIdSchema(options)])

//
// JSON Schemas
//

export type JSONSchema = LiteralSchema | { [key: string]: JSONSchema } | JSONSchema[]
export type LiteralSchema = z.infer<typeof jsonLiteralSchema>

export const jsonLiteralSchema = z.union([z.string(), z.number(), z.boolean(), z.null()])

/**
 * Validates any JSON value, taken from Zod's docs.
 *
 * @see https://zod.dev/?id=json-type
 */
export const jsonSchema: z.ZodType<JSONSchema> = z.lazy(() =>
  z.union([jsonLiteralSchema, z.array(jsonSchema), z.record(jsonSchema)]),
)

//
// Error Handling Utilities
//

type ErrorHandlerCtx<Issue extends z.ZodIssueOptionalMessage = z.ZodIssueOptionalMessage> =
  z.ErrorMapCtx & { t: TFunction; issue: Issue }

export function makeErrorMap(t: TFunction): z.ZodErrorMap {
  return (issue, ctx) => {
    if (issue.code === "too_big") return tooBigErrorHandler({ ...ctx, issue, t })
    if (issue.code === "too_small") return tooSmallErrorHandler({ ...ctx, issue, t })
    if (issue.code === "invalid_type") return invalidTypeErrorHandler({ ...ctx, issue, t })

    return { ...issue, message: ctx.defaultError }
  }
}

function tooBigErrorHandler({ issue, t }: ErrorHandlerCtx<z.ZodTooBigIssue>) {
  const { inclusive, type } = issue
  const errorPath = inclusive
    ? "v2.simple_form.errors.defaults.too_big_inclusive"
    : "v2.simple_form.errors.defaults.too_big_exclusive"

  const error = (key: string) => ({
    ...issue,
    message: t(`${errorPath}.${key}`, { ...issue }),
  })

  if (type === "array" || type === "set") return error("collection")
  if (type === "string") return error("string")
  return error("value")
}

function tooSmallErrorHandler({ issue, t }: ErrorHandlerCtx<z.ZodTooSmallIssue>) {
  const { inclusive, type } = issue
  const errorPath = inclusive
    ? "v2.simple_form.errors.defaults.too_small_inclusive"
    : "v2.simple_form.errors.defaults.too_small_exclusive"

  const error = (key: string) => ({
    ...issue,
    message: t(`${errorPath}.${key}`, { ...issue }),
  })

  if (type === "array" || type === "set") return error("collection")
  if (type === "string") return error("string")
  return error("value")
}

function invalidTypeErrorHandler({ issue, t, ...ctx }: ErrorHandlerCtx<z.ZodInvalidTypeIssue>) {
  if (issue.received === "undefined" || issue.received === "null" || issue.received === "nan")
    return {
      ...issue,
      message: t("v2.simple_form.errors.defaults.invalid_type.blank", { ...issue }),
    }

  return { ...issue, message: ctx.defaultError }
}
