import {
  EnrichedTableFilterQuery,
  EnrichedTableFilterQueryVariables,
  TableFilterSettingsQuery,
  TableFilterSettingsQueryVariables,
  UpdateSelectedFiltersForTableInput,
} from "types/graphql.d"
import { GraphqlApi } from "v2/redux/GraphqlApi"
import {
  mergeAdditionalFilterOptionsIntoCache,
  mergeFilterIntoCacheIfNotPresent,
} from "v2/redux/GraphqlApi/TablesApi/utils"
import { flatMutationOperation, queryOperation } from "v2/redux/utils/endpoints"

const FILTER_SETTINGS_CACHE_KEY = "<FILTER_SETTINGS_CACHE_KEY>"

/**
 * This API is meant to house endpoints that are configured
 * to work generally with tables throughout the app.
 */
const TablesApi = GraphqlApi.injectEndpoints({
  endpoints: (builder) => ({
    getFilterSettings: builder.query<TableFilterSettingsQuery, TableFilterSettingsQueryVariables>({
      query: queryOperation<TableFilterSettingsQueryVariables>("TableFilterSettings"),
      serializeQueryArgs: () => FILTER_SETTINGS_CACHE_KEY,
      providesTags: ["TableFilterSettings"],
    }),
    /**
     * Fetches a single enriched filter option for a given table and filter.
     * This is useful for when we add a new filter and need to fetch more
     * information about it, including a potential collection set.
     *
     * Note that we merge the result of this with any existing enriched filters
     * in the cache.
     */
    getEnrichedFilter: builder.query<EnrichedTableFilterQuery, EnrichedTableFilterQueryVariables>({
      query: queryOperation<EnrichedTableFilterQueryVariables>("EnrichedTableFilter"),
      onQueryStarted({ table }, { dispatch, queryFulfilled }) {
        queryFulfilled.then((result) =>
          mergeFilterIntoCacheIfNotPresent({ queryResult: result.data, table, dispatch }),
        )
      },
    }),
    /**
     * Very similar to `getEnrichedFilter`, but this is meant to be used when
     * either fetching additional options for the filter (via pagination) or
     * employing server-side searching of options. The key difference is how the
     * data is merged with the cache in `onQueryStarted`. We'll typically only
     * use this endpoint when a filter has more options than can be displayed in
     * a single page.
     */
    getUpdatedEnrichedFilter: builder.query<
      EnrichedTableFilterQuery,
      EnrichedTableFilterQueryVariables
    >({
      query: queryOperation<EnrichedTableFilterQueryVariables>("EnrichedTableFilter"),
      onQueryStarted({ table }, { dispatch, queryFulfilled }) {
        queryFulfilled.then((result) =>
          mergeAdditionalFilterOptionsIntoCache({ queryResult: result?.data, table, dispatch }),
        )
      },
    }),
    updateSelectedFilters: builder.mutation({
      query: flatMutationOperation<UpdateSelectedFiltersForTableInput>(
        "UpdateSelectedFiltersForTable",
      ),
      invalidatesTags: ["TableFilterSettings"],
    }),
  }),
})

export const {
  useGetEnrichedFilterQuery,
  useGetFilterSettingsQuery,
  useUpdateSelectedFiltersMutation,
} = TablesApi
export { TablesApi, FILTER_SETTINGS_CACHE_KEY }
