/* eslint-disable jsx-a11y/control-has-associated-label */
import { Header, Table, Column as TanColumn } from "@tanstack/react-table"
import { Virtualizer } from "@tanstack/react-virtual"
import cn from "classnames"
import { isNil, remove, uniq } from "lodash"
import React from "react"

import { ColumnSort } from "v2/react/components/orgChart/Datasheet/ColumnSort"
import { Select } from "v2/react/shared/forms/Select"

interface TableHeaderProps<TRow> {
  columnVirtualizer: Virtualizer<HTMLDivElement, Element>
  table: Table<TRow>
}

function TableHeader<TRow>({ columnVirtualizer, table }: TableHeaderProps<TRow>) {
  const determineHeaderClasses = (
    headerCount: number,
    index: number,
    header: Header<TRow, unknown>,
  ) =>
    cn(
      `h-[80px] border border-t-0 border-b-0 border-l-0 border-solid border-neutral-8 p-0 truncate flex absolute left-0 top-0 bg-white`,
      header.column.columnDef.meta?.original?.cellClassName,
      {
        "border-r-0": index === headerCount - 1,
      },
    )

  return (
    <thead
      id="table-datasheet-thead"
      className="sticky top-0 z-10 h-[80px] border-b-0 bg-white grid"
    >
      {table.getHeaderGroups().map((headerGroup) => {
        const { headers } = headerGroup
        return (
          <tr key={headerGroup.id} className="w-full flex">
            {columnVirtualizer.getVirtualItems().map((virtualColumn) => {
              const header = headers[virtualColumn.index]
              return (
                <th
                  id={`th-${header.id}`}
                  key={header.id}
                  draggable={header.column.getCanGroup()}
                  onDragStart={(event) => {
                    event.dataTransfer.setData(
                      "application/json",
                      JSON.stringify({
                        fieldKey: header.column.id,
                      }),
                    )
                    /* eslint-disable-next-line no-param-reassign */
                    event.dataTransfer.dropEffect = "copy"
                  }}
                  className={determineHeaderClasses(headers.length, virtualColumn.index, header)}
                  style={{
                    transform: `translateX(${virtualColumn.start}px)`,
                    width: header.column.getSize(),
                  }}
                >
                  <div className="h-20 flex-1 flex-col gap-3.5 border-b-neutral-8 px-4 pb-2 pt-3.5 flex">
                    <ColumnSort
                      canSort={header.column.getCanSort()}
                      classes={!header.column.getCanFilter() ? "mb-6" : ""}
                      columnLabel={header.column.columnDef.header as string}
                      disabled={!header.column.getCanSort()}
                      onSort={header.column.getToggleSortingHandler()}
                      sorted={header.column.getIsSorted()}
                      info={header.column.columnDef.meta?.original.info}
                    />
                    {header.column.getCanFilter() ? (
                      <Filter
                        label={header.column.columnDef.header as string}
                        column={header.column}
                        table={table}
                      />
                    ) : null}
                  </div>
                </th>
              )
            })}
          </tr>
        )
      })}
    </thead>
  )
}

export { TableHeader }

function Filter({
  label,
  column,
  table,
}: {
  label: string
  column: TanColumn<any, unknown> // eslint-disable-line @typescript-eslint/no-explicit-any
  table: Table<any> // eslint-disable-line @typescript-eslint/no-explicit-any
}) {
  const options = React.useMemo(() => {
    const values = remove(
      table.getCoreRowModel().flatRows.map((row) => row.getValue<string>(column.id)),
      (value) => value !== undefined && value !== "",
    )
    return uniq(values)
  }, [table, column])

  return (
    <Select
      className="select--xs"
      defaultValue={`Filter by ${label}`}
      options={options.map((o) => ({ id: o, label: o }))}
      onSelect={(event) => column.setFilterValue(event.target.value)}
      selected={isNil(column.getFilterValue()) ? undefined : `${column.getFilterValue()}`}
    />
  )
}
