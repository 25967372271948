import { flow, set } from "lodash/fp"

import { EnrichedFilterOption, EnrichedTableFilterQuery, FilterableTables } from "types/graphql"
import { TablesApi } from "v2/redux/GraphqlApi/TablesApi"
import { AppDispatch } from "v2/redux/store"

interface MergeFilterDataIntoStateProps {
  queryResult: EnrichedTableFilterQuery
  table: FilterableTables
  dispatch: AppDispatch
}

const mergeFilterIntoCacheIfNotPresent = ({
  queryResult,
  table,
  dispatch,
}: MergeFilterDataIntoStateProps) => {
  const enrichedFilter = queryResult?.currentPerson?.settings?.tableFilterSettings?.enrichedFilter
  if (!enrichedFilter) return

  dispatch(
    TablesApi.util.updateQueryData("getFilterSettings", { table }, (data) => {
      const existingFilters =
        data?.currentPerson?.settings?.tableFilterSettings?.enrichedSelectedFilters

      const alreadyExists = existingFilters?.some((filter) => filter.id === enrichedFilter.id)
      if (alreadyExists) return data

      return flow(
        set("currentPerson.settings.tableFilterSettings.enrichedSelectedFilters", [
          ...(existingFilters || []),
          ...[enrichedFilter],
        ]),
      )(data)
    }),
  )
}

const mergeAdditionalFilterOptionsIntoCache = ({
  queryResult,
  table,
  dispatch,
}: MergeFilterDataIntoStateProps) => {
  const enrichedFilter = queryResult?.currentPerson?.settings?.tableFilterSettings?.enrichedFilter
  if (!enrichedFilter) return
  if (!enrichedFilter.collection) return
  const { collection: enrichedFilterCollection } = enrichedFilter

  dispatch(
    TablesApi.util.updateQueryData("getFilterSettings", { table }, (data) => {
      const existingFilters =
        data?.currentPerson?.settings?.tableFilterSettings?.enrichedSelectedFilters

      const filterInState = existingFilters?.find((filter) => filter.id === enrichedFilter.id)

      let updatedEnrichedFilters: EnrichedFilterOption[] = []
      if (!filterInState) {
        updatedEnrichedFilters = [...(existingFilters || []), enrichedFilter]
      } else {
        // If paginating, we need to merge the new options with the existing ones.
        const newOptions = enrichedFilter.collection?.nodes || []
        const existingOptions = filterInState.collection?.nodes || []
        const existingOptionsMap = new Map(existingOptions.map((option) => [option.id, option]))
        const mergedOptions = [
          ...existingOptions,
          ...newOptions.filter((newOption) => !existingOptionsMap.has(newOption.id)),
        ]

        const mergedFilter: EnrichedFilterOption = {
          ...enrichedFilter,
          // We don't need to update the selectedOptions here - we just need to
          // maintain any initial selected options.  Any other selected options
          // will already be in state.
          selectedOptions: filterInState.selectedOptions,
          collection: {
            ...enrichedFilterCollection,
            nodes: mergedOptions,
          },
        }

        updatedEnrichedFilters =
          existingFilters?.map((filter) =>
            filter.id === enrichedFilter.id ? mergedFilter : filter,
          ) || []
      }

      return flow(
        set(
          "currentPerson.settings.tableFilterSettings.enrichedSelectedFilters",
          updatedEnrichedFilters,
        ),
      )(data)
    }),
  )
}

export { mergeFilterIntoCacheIfNotPresent, mergeAdditionalFilterOptionsIntoCache }
