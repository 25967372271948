import { Role } from "types/graphql.enums"
import { useCurrentActiveSession } from "v2/react/hooks/useSessionHooks"
import { tryUniqueKeyFromEntityAndId } from "v2/react/utils/uniqueKey"

type PositionControlOptionsArg =
  | { fromHeadcountPlan: false }
  | { fromHeadcountPlan: true; headcountPlanRootPositionIdForDatasheet?: string | null | undefined }

const REPORTS_TO_UNRESTRICTED_ROLES = [
  Role.CompanyManager,
  Role.HrAdmin,
  Role.PersonManager,
  Role.RecruitingAdmin,
]

function usePositionControlOptions(arg: PositionControlOptionsArg) {
  const { roleScopes } = useCurrentActiveSession()

  if (!arg.fromHeadcountPlan) {
    return { customized: false }
  }

  const isUnrestricted = roleScopes.some((roleScope) =>
    REPORTS_TO_UNRESTRICTED_ROLES.includes(roleScope.roleName),
  )
  const startingFromPositionKey = tryUniqueKeyFromEntityAndId(
    "position",
    arg.headcountPlanRootPositionIdForDatasheet,
  )

  return isUnrestricted
    ? { customized: true, subordinateOnly: false }
    : { customized: true, subordinateOnly: false, startingFromPositionKey }
}

export { usePositionControlOptions }
