import { skipToken } from "@reduxjs/toolkit/dist/query"
import React, { useMemo } from "react"

import { FilterOption } from "types/graphql"
import { FilterDataType, FilterType } from "types/graphql.enums"
import { useNProgressBar } from "v2/react/hooks/useNProgressBar"
import { MenuOption } from "v2/react/shared/forms/CheckboxMenu"
import { DateRangeFilter } from "v2/react/shared/tables/TableUtilities/FilterTable/Filter/DateRangeFilter"
import { MultiSelectionFilter } from "v2/react/shared/tables/TableUtilities/FilterTable/Filter/MultiSelectionFilter"
import { MultiSelectionFilterWithSearch } from "v2/react/shared/tables/TableUtilities/FilterTable/Filter/MultiSelectionFilterWithSearch"
import { RangeFilter } from "v2/react/shared/tables/TableUtilities/FilterTable/Filter/RangeFilter"
import type { FilterPanelState } from "v2/react/shared/tables/TableUtilities/FilterTable/hooks/useFilterPanelState"
import {
  DateRangeValue,
  getDateRangedFilterValue,
  getInclusionFilterValue,
  getRangedFilterValue,
  isCollectionBasedFilter,
  isNumericBasedFilter,
  RangeValue,
  type Filter as FilterT,
} from "v2/react/shared/tables/TableUtilities/FilterTable/utils/filters"
import { useGetEnrichedFilterQuery } from "v2/redux/GraphqlApi/TablesApi"
import {
  getEnrichedSelectedFilter,
  selectTable,
} from "v2/redux/slices/TableFilterSlice/tableFiltersSelectors"
import { useAppSelector } from "v2/redux/store"

/**
 * The number of options that must be present before we switch to the searchable
 * checkbox menu component.
 */
const LIMIT_BEFORE_SEARCH = 10

interface FilterProps {
  filters: FilterT[]
  field: FilterOption
  removeField: FilterPanelState["removeField"]
  setFilters: (filters: FilterT[]) => void
  updateFieldErrors: FilterPanelState["updateFieldErrors"]
}

const Filter = ({ field, filters, removeField, setFilters, updateFieldErrors }: FilterProps) => {
  const filter = filters.find((filter) => filter.field === field.id)

  return (
    <>
      {isCollectionBasedFilter(field.dataType) && (
        <CollectionFilter
          field={field}
          filter={filter}
          filters={filters}
          removeField={removeField}
          setFilters={setFilters}
        />
      )}
      {isNumericBasedFilter(field.dataType) && (
        <RangeFilter
          type={field.dataType}
          field={field}
          values={getRangedFilterValue(filter)}
          onRemove={removeField}
          onSelect={handleNumericFilterChange(setFilters, filters)}
          updateFieldErrors={updateFieldErrors}
        />
      )}
      {field.dataType === FilterDataType.Date && (
        <DateRangeFilter
          values={getDateRangedFilterValue(filter)}
          field={field}
          onRemove={removeField}
          onSelect={handleDateFilterChange(setFilters, filters)}
          updateFieldErrors={updateFieldErrors}
        />
      )}
    </>
  )
}

type CollectionFilterProps = Pick<
  FilterProps,
  "field" | "filters" | "removeField" | "setFilters"
> & {
  filter?: FilterT
}

const CollectionFilter = ({
  field,
  removeField,
  setFilters,
  filter,
  filters,
}: CollectionFilterProps) => {
  const table = useAppSelector(selectTable)
  const enrichedFilter = useAppSelector(getEnrichedSelectedFilter(field.id))

  useGetEnrichedFilterQuery(!enrichedFilter && table ? { filter: field.id, table } : skipToken)

  const collection = useMemo(() => enrichedFilter?.collection, [enrichedFilter])
  const options = collection?.nodes || []

  useNProgressBar({ finishCondition: !!enrichedFilter, startCondition: true })
  if (!enrichedFilter) return null

  const totalCount = enrichedFilter.totalCount ?? 0

  if (totalCount <= LIMIT_BEFORE_SEARCH) {
    return (
      <MultiSelectionFilter
        field={field}
        collection={options}
        selectedFilters={getInclusionFilterValue(filter)}
        onRemove={removeField}
        onSelect={handleCollectionFilterChange(setFilters, filters)}
        // Binary filters do not have a "None" option.
        includeNoneOption={field.dataType !== FilterDataType.Binary}
      />
    )
  }

  return (
    <MultiSelectionFilterWithSearch
      enrichedFilter={enrichedFilter}
      filter={filter}
      onRemove={removeField}
      onSelect={handleCollectionFilterChange(setFilters, filters)}
      includeNoneOption
    />
  )
}

const handleCollectionFilterChange =
  (setFilters: FilterProps["setFilters"], filters: FilterT[]) =>
  (filterId: string, options: MenuOption[]) => {
    const values = options.filter((option) => !!option.selected).map((option) => option.id)

    const newFilters = filters.map((filter) => {
      if (filter.field === filterId && filter.type === FilterType.Inclusion) {
        return { ...filter, value: { in: values } }
      }
      return filter
    })
    setFilters(newFilters)
  }

const handleNumericFilterChange =
  (setFilters: FilterProps["setFilters"], filters: FilterT[]) =>
  (filterId: string, options: RangeValue) => {
    const newFilters = filters.map((filter) => {
      if (filter.field === filterId && filter.type === FilterType.Range) {
        return { ...filter, value: options }
      }
      return filter
    })
    setFilters(newFilters)
  }

const handleDateFilterChange =
  (setFilters: FilterProps["setFilters"], filters: FilterT[]) =>
  (filterId: string, options: DateRangeValue) => {
    const newFilters = filters.map((filter) => {
      if (filter.field === filterId && filter.type === FilterType.DateRange) {
        return { ...filter, value: options }
      }
      return filter
    })
    setFilters(newFilters)
  }

export { Filter }
