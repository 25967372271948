import React from "react"

import { HeadcountPlanParticipantStatus } from "types/graphql.enums"

interface StatusCellProps {
  status?: HeadcountPlanParticipantStatus | null
}

const StatusCell = ({ status }: StatusCellProps) => (
  <td className="hcp-overview-table__status-cell" aria-label={status || "empty"}>
    <div className="h-14 flex-col items-center justify-center px-4 flex">
      {status && <StatusBadge status={status} />}
    </div>
  </td>
)

const StatusBadge = ({ status }: { status: HeadcountPlanParticipantStatus }) => {
  const className = `badge--${mapStatusToColor(status)}`
  return <div className={className}>{`participant_status_${status}`.t("headcount_plan")}</div>
}

const mapStatusToColor = (status: HeadcountPlanParticipantStatus) => {
  switch (status) {
    case HeadcountPlanParticipantStatus.Complete:
      return "purple"
    case HeadcountPlanParticipantStatus.InProgress:
      return "yellow"
    case HeadcountPlanParticipantStatus.Invited:
      return "gray"
    default:
      return "gray"
  }
}

export { StatusCell }
