import { ControlProps } from "@jsonforms/core"
import React from "react"

import { useJobRequisitionOverview } from "v2/react/components/jobRequisitions/Overview/OverviewProvider"
import { useCurrentActiveSession } from "v2/react/hooks/useSessionHooks"

function JsonModuleListItemHeadcountPlan({ label }: ControlProps) {
  const { hasFeature } = useCurrentActiveSession()
  const { fromHeadcountPlan, fromHeadcountPlanChangeProjection } = useJobRequisitionOverview()

  return hasFeature("headcountPlanning") ? (
    <div className="module-card-list-item">
      <label className="text-neutral-64">{label}</label>
      {fromHeadcountPlan && (
        <div>
          <div>{fromHeadcountPlan?.name}</div>
          <div className="text-sm text-neutral-64">
            {fromHeadcountPlanChangeProjection?.systemIdentifier}
          </div>
        </div>
      )}
    </div>
  ) : null
}

export { JsonModuleListItemHeadcountPlan }
