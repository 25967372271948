import defaultAvatar from "images/avatar/fallback/default-avatar-thumb.png"
import React from "react"

export type AvatarCircleItem = {
  uniqueKey: string
  person: {
    name: string
    avatarThumbUrl?: string | null
  }
}
type Props = {
  items: AvatarCircleItem[]
}
export function AvatarCircles({ items }: Props) {
  const limit = items.length === 5 ? 3 : 4
  return (
    <div className="circles collaborators">
      {items.length >= 5 ? (
        <div className="circle-count circle circle-sm circle-border circle-solid tooltip tooltip-right mx-[-2px] box-content">
          <span className="count pr-1 text-[10px]">+{items.length - limit}</span>
          <div className="tooltip-content tooltip-content--sm tooltip-right align-center flex-col hidden 1225:flex">
            {items.slice(limit).map((item) => (
              <div key={item.uniqueKey}>{item.person.name}</div>
            ))}
          </div>
        </div>
      ) : null}
      {items.slice(0, limit).map((item) => (
        <div
          key={item.uniqueKey}
          className="circle circle-collaborator circle-sm circle-border tooltip tooltip-right mx-[-2px] box-content"
        >
          <img alt={item.person.name} src={item.person.avatarThumbUrl || defaultAvatar} />
          <div className="tooltip-content tooltip-content--sm hidden 1225:flex">
            {item.person.name}
          </div>
        </div>
      ))}
    </div>
  )
}
