import { isEqual } from "lodash"
import { createSelector, createStructuredSelector } from "reselect"

import { FILTER_SETTINGS_CACHE_KEY, TablesApi } from "v2/redux/GraphqlApi/TablesApi"
import { RootState } from "v2/redux/store"

const selectFilterSettingsResult =
  // We currently serialize the query args as a string to make it easier to
  // select for the cached results.  If we need to in the future, we can
  // serialize using the table key instead.
  // @ts-expect-error
  TablesApi.endpoints.getFilterSettings.select(FILTER_SETTINGS_CACHE_KEY)

const selectFilterSettings = createSelector(
  selectFilterSettingsResult,
  (result) => result?.data?.currentPerson?.settings?.tableFilterSettings,
)

const getAllFilterOptions = createSelector(
  selectFilterSettings,
  (settings) => settings?.filterableColumns || [],
)

const getSelectedFilters = createSelector(
  selectFilterSettings,
  (settings) => settings?.selectedFilters || [],
)

const getEnrichedSelectedFilters = createSelector(
  selectFilterSettings,
  (settings) => settings?.enrichedSelectedFilters || [],
)

/**
 * Finds a specific enriched selected filter by its id.
 */
const getEnrichedSelectedFilter = (filterId: string) =>
  createSelector(getEnrichedSelectedFilters, (enrichedFilters) =>
    enrichedFilters.find((filter) => filter.id === filterId),
  )

const selectFormKey = (state: RootState) => state.tableFilters.formKey
const selectFilters = (state: RootState) => state.tableFilters.filters
const selectAppliedFilters = (state: RootState) => state.tableFilters.appliedFilters
const selectTable = (state: RootState) => state.tableFilters.table

const selectFiltersAndAppliedFilters = createStructuredSelector({
  filters: selectFilters,
  appliedFilters: selectAppliedFilters,
})

const selectAnyFilterChanges = createSelector(
  [selectFilters, selectAppliedFilters],
  (filters, appliedFilters) => !isEqual(filters, appliedFilters),
)

/**
 * Contains all of the variables needed for the filter panel.
 */
const filterPanelSelectors = createStructuredSelector({
  allFilterOptions: getAllFilterOptions,
  anyChanges: selectAnyFilterChanges,
  filters: selectFilters,
  formKey: selectFormKey,
  appliedFilters: selectAppliedFilters,
})

export {
  filterPanelSelectors,
  getAllFilterOptions,
  getEnrichedSelectedFilter,
  getEnrichedSelectedFilters,
  getSelectedFilters,
  selectAnyFilterChanges,
  selectFiltersAndAppliedFilters,
  selectAppliedFilters,
  selectFilterSettings,
  selectTable,
}
