import classNames from "classnames"
import { GraphQLFormattedError } from "graphql"
import React from "react"

import { InputErrorText } from "v2/react/shared/forms/InputErrorText"
import { errorFor } from "v2/react/utils/errors"

export function PlanTitle({
  isSuccess,
  errors,
  defaultValue,
}: {
  isSuccess: boolean
  errors: GraphQLFormattedError[]
  defaultValue?: string
}) {
  const errorMessage = errorFor("name", errors)
  return (
    <div className="input-group flex-1">
      <div>
        <label htmlFor="title">{"title_label".t("headcount_plan")}</label>
      </div>
      <div className={classNames("w-full", { "form-error": errorMessage })}>
        <input
          type="text"
          id="planTitle"
          className="input"
          name="planTitle"
          defaultValue={defaultValue}
        />
      </div>
      {isSuccess && errorMessage && <InputErrorText message={errorMessage} />}
    </div>
  )
}
