import { useEffect } from "react"

interface UseNProgressBarProps {
  finishCondition: boolean
  startCondition: boolean
}

const useNProgressBar = ({ finishCondition, startCondition }: UseNProgressBarProps) => {
  useEffect(() => {
    if (!startCondition) return
    if (typeof window === "undefined") return
    if (typeof window.NProgress === "undefined") return

    if (finishCondition) {
      window.NProgress?.done?.()
    } else if (!window.NProgress?.isStarted?.()) {
      window.NProgress?.start?.()
    }
  }, [finishCondition, startCondition])
}

export { useNProgressBar }
